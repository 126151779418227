<template>
  <base-menu home="/admin" color="#1f1161" />
</template>

<script>
import BaseMenu from '@/components/Menu';

export default {
  components: { BaseMenu },
};
</script>
