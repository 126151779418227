<template lang="html">
  <div
    class="gantt-route"
    :style="{ backgroundColor: bgColor }"
    @click.exact="select"
    @click.self.alt="selectAndDisplay"
    @dblclick.self="selectAndDisplay"
    @mouseenter="mouseIn"
    @mouseleave="mouseOut"
  >
    <div class="timeline-route">
      <div class="timeline-route-head">
        <div v-if="editable" class="timeline-route-actions">
          <route-menu v-model="menuVisible">
            <cs-button
              icon-type="material"
              :icon="displayed ? 'visibility' : 'visibility_off'"
              @click.stop.prevent="toggleDisplay"
            />
            <edit-resource v-if="route.resource" :route="route" />
            <!-- <cs-button
              icon-type="material"
              :icon="route.locked ? 'lock' : 'lock_open'"
            /> -->
            <cs-button
              icon-type="material"
              icon="local_dining"
              :disabled="!canAddTimeoff"
              @click.stop.prevent="addTimeoff"
            />
          </route-menu>
        </div>
        <div class="route-title secondary-color">
          <span>{{ route.name }}</span>
          <el-tooltip
            effect="dark"
            placement="top"
            :content="$t('commons.overtime')"
          >
            <i
              v-show="route.isOvertime"
              class="route-icon material-icons secondary-color help"
            >
              access_time
            </i>
          </el-tooltip>
        </div>
      </div>
      <div class="route-line"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import { invertColor, hexToRgbA } from '@/utils/color';
import RouteMenu from '../RouteMenu';
import EditResource from '../EditResource';

export default {
  components: {
    RouteMenu,
    EditResource,
  },
  props: {
    routeId: {
      type: String,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menuVisible: false,
    showTitle: false,
  }),
  computed: {
    route() {
      return (
        this.$parent.routesObject[this.routeId] ||
        this.$store.getters.routes.items[this.routeId]
      );
    },
    displayed() {
      return this.route.displayPolyline || this.route.displayMarkers;
    },
    bgColor() {
      return hexToRgbA(this.route.color, 0.2);
    },
    blackOrWhite() {
      if (this.displayed) {
        return invertColor(this.route.color, true);
      }
      return this.route.color;
    },
    canAddTimeoff() {
      return isEmpty(this.route.timeoffs);
    },
  },
  watch: {
    route(v) {
      if (!v) {
        this.$destroy();
      }
    },
  },
  methods: {
    ...mapActions(['setSelectedRoutes', 'setSelectionSidebar', 'queueAction']),
    addTimeoff() {
      this.route.addTimeoff();
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
    mouseIn() {
      this.menuVisible = true;
      this.showTitle = true;
    },
    mouseOut() {
      this.menuVisible = false;
      this.showTitle = false;
    },
    select() {
      if (this.selectable) {
        this.setSelectedRoutes({
          data: [this.$store.getters.routes.items[this.routeId]],
        });
      }
    },
    selectAndDisplay() {
      if (this.selectable) {
        this.setSelectedRoutes({
          data: [this.$store.getters.routes.items[this.routeId]],
        });
        this.setSelectionSidebar(true);
      }
    },
    toggleDisplay() {
      this.route.displayPolyline = !this.route.displayPolyline;
      this.route.displayMarkers = !this.route.displayMarkers;
    },
    editResource() {
      this.route.resource = null;
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
  },
};
</script>
