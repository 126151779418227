<template lang="html">
  <div v-if="activity" :style="{ marginTop: '18mm' }">
    <div class="activityPrint-details">
      <el-row :gutter="20">
        <el-col :span="12" style="text-align: left" class="activityPrint-date">
          <p>
            {{ activity.planned_start | format('dd.MM.yyyy') }} <br />
            <object-display :data="organization" :only="['siret']" />
          </p>
        </el-col>
        <el-col :span="9" :offset="3">
          <div class="activityPrint-logo">
            <img :src="organization.image" />
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24" style="text-align: left; margin-top: 30px">
          <h3 class="white-title upperFirst activityPrint-title">
            {{ activity.planned_start | format('HH:mm') }}
            <span class="bullet-point"> . </span>
            {{ activity.planned_end | format('HH:mm') }}
          </h3>
          <h3 v-if="activity" class="white-title activityPrint-routeName">
            <address-entity :address="activity.place" :full="false" />
          </h3>
          <em>{{ activity.place.secondary }}</em>
          <br />
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
          <h4 class="activityPrint-category-h4">{{ $t('commons.order') }}</h4>
          <div class="print-round-orders">
            <span>
              {{ activity.order }}
              <span v-if="deliveries">/{{ deliveries }}</span>
            </span>
          </div>
          <span> </span> <br />
        </el-col>

        <el-col :span="6">
          <h4 class="activityPrint-category-h4">
            {{ $t('commons.activity.single') }}
          </h4>
          <object-display :data="activityData" :sort="false" />
          <br />
        </el-col>

        <el-col :span="12">
          <h4 class="activityPrint-category-h4">Contact</h4>
          <object-display
            :data="activity"
            :only="{
              receiver_name: 'commons.name',
              receiver_phone: 'commons.phone',
              destination_name: 'commons.destination',
              text: 'commons.note',
            }"
          />
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <h4 class="activityPrint-category-h4">
            {{ activity.item_count }}
            {{
              activity.item_count > 1
                ? $t('commons.item.plural')
                : $t('commons.item.single')
            }}
          </h4>
        </el-col>
      </el-row>
      <el-row v-if="hasParcels" :gutter="20">
        <el-col
          v-for="item in activity.items"
          :key="item.barcode"
          :span="6"
          :style="{ padding: '10px' }"
        >
          <span v-if="item.barcode">
            {{ $t('commons.barcode') }} <br />
            <span class="print-barcode">{{ item.barcode }}</span>
          </span>
          <br v-if="item.barcode" />
          <span v-if="item.quantity">
            {{ $t('commons.quantity') }}
            <span class="print-barcode">{{ item.quantity }}</span>
          </span>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <br />
        <el-col :span="12">
          <h4 class="activityPrint-category-h4">
            {{ $t('pages.routes.RoutePrint.note') }}
          </h4>
          <span v-for="(data, idx) in activity.external_data" :key="idx">
            <p v-if="data.value">
              {{ data.name.replace('_', ' ') }}: {{ data.value }}
            </p>
          </span>
        </el-col>
        <el-col :span="12" :style="{ minHeight: '200px' }">
          <h4 class="activityPrint-category-h4">
            {{ $t('pages.routes.RoutePrint.proof') }}
          </h4>
          <img
            v-if="activity.proof"
            :src="activity.proof"
            :style="{ maxWidth: '100%' }"
          />
        </el-col>
      </el-row>
      <div class="page-break"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, merge, reduce } from 'lodash';
import AddressEntity from '../Address';
import ObjectDisplay from '../ObjectDisplay';

export default {
  name: 'ActivityPrint',
  components: {
    AddressEntity,
    ObjectDisplay,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    routePrint: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    deliveries: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(['organization']),
    route() {
      const r = this.$store.getters.routes.items[this.activity.route_id];
      if (!r) {
        return {};
      }
      return r;
    },
    hasParcels() {
      return !isEmpty(this.activity.items);
    },
    consistOf() {
      if (this.activity) {
        return reduce(
          this.activity.consist_of,
          (acc, item) => {
            acc[item.type] = item.value;
            return acc;
          },
          {}
        );
      }
      return null;
    },
    activityData() {
      return merge(
        {
          external_id: this.activity.external_id,
          state: this.activity.state,
        },
        this.consistOf
      );
    },
  },
  created() {
    if (!this.route) {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions(['get']),
    fetchData() {
      this.loading = true;
      this.get({ type: 'route', id: this.activity.route_id }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.activityPrint-logo {
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
