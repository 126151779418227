<template>
  <section class="proof white-bg">
    <img v-if="value" class="proof-img" :src="value" :alt="$t('commons.pod')" />
    <span v-else class="secondary-text-color upperFirst">
      {{ $t('commons.noData') }}
    </span>
  </section>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.proof {
  display: flex;
  justify-content: center;
  font-size: 1.1em;
  padding: 5px;
  .proof-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
