<template lang="html">
  <cs-button
    type="danger"
    icon="delete"
    icon-type="material"
    @click="deleteTimeoff"
  >
    {{ $t('commons.delete') }}
  </cs-button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(['queueAction', 'clearSelection']),
    deleteTimeoff() {
      this.$delete(this.route.timeoffs, this.index);
      this.queueAction({
        action: 'patch',
        type: 'route',
        id: this.route.id,
        data: this.route,
      }).then(this.clearSelection);
    },
  },
};
</script>
