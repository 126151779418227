<template lang="html">
  <section
    class="sidebar-head-bg primary-color-dark"
    :style="{ backgroundColor: color }"
    @click="$emit('click')"
  >
    <el-row class="activity-head" type="flex">
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="18">
          <h5>Pause {{ index + 1 }}</h5>
        </el-col>

        <el-col :span="3" align="middle">
          <activity-icon
            material
            text="local_dining"
            color="#31363B"
            :svg="path"
          />
        </el-col>
        <el-col :span="3">
          <cs-button
            :style="{ width: '100%' }"
            outline
            icon="close"
            icon-type="material"
            @click.stop="clearSelection"
          />
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="18">
          {{ timeoff | formatDistance('start', 'end') }} le
          <strong>
            {{ timeoff.start | moment('DD MMM HH[h]mm') }} -
            {{ timeoff.end | moment('HH[h]mm') }}
          </strong>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col>
          <a
            class="activity-head-link"
            :style="{ textDecoration: 'solid' }"
            @click.stop="goToRoute"
          >
            {{ route.name }}
          </a>
        </el-col>
      </el-row>

      <div class="activity-head-plusBtn">
        <i v-if="!showDetails" class="el-icon-plus"></i>
        <i v-else class="el-icon-minus"></i>
      </div>
    </el-row>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { areIntervalsOverlapping } from 'date-fns';
import { selected } from '@/iconPaths';
import ActivityIcon from '@/components/ActivityIcon';

export default {
  components: {
    ActivityIcon,
  },
  props: {
    timeoff: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return get(this.route, 'color');
    },
    inSla() {
      return areIntervalsOverlapping(this.timeoff, this.timeoff.sla);
    },
    path() {
      if (!this.inSla) {
        return selected.timeoff.plannedWithWarnings;
      }
      return selected.timeoff.planned;
    },
  },
  methods: {
    ...mapActions([
      'setSelectedRoutes',
      'clearSelection',
      'setSelectionSidebar',
    ]),
    goToRoute() {
      this.setSelectedRoutes({
        data: [this.route],
      });
      this.setSelectionSidebar(true);
    },
  },
};
</script>
