<template lang="html">
  <el-container>
    <shortkey :shortkeys="['s']" :action="focusOnSelection" />
    <shortkey :shortkeys="['ctrl', 's']" :action="clearSelection" />

    <slot name="left" :data="{ displayTools }"></slot>

    <el-main class="map-main" style="padding: 0">
      <span
        class="map-overlap-left"
        :style="{ height: $slots.mapleft ? 'inherit' : 'auto' }"
      >
        <map-toolbox
          :value="displayTools"
          :zoom="zoom"
          :cluster="cluster"
          @toggleAllPolylines="toggleAllPolylines"
          @toggleAllMarkers="toggleAllMarkers"
          @updateZoom="updateZoom"
          @updateCluster="updateCluster"
          @toggleLasso="lasso = !lasso"
          @refreshBounds="refreshBounds"
        >
          <slot name="maptools"></slot>
        </map-toolbox>
        <slot name="mapleft"></slot>
      </span>

      <l-map
        ref="map"
        :zoom="zoom"
        :center="center"
        :bounds="bounds"
        :options="mapOptions"
        @update:zoom="updateZoom"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <div v-for="route in filteredData" :key="route.id">
          <l-layer-group :visible="route.displayMarkers">
            <l-marker-cluster v-if="cluster">
              <template v-for="delivery in route.deliveries">
                <l-marker
                  v-if="
                    (delivery.hasOwnProperty('matchFilter')
                      ? delivery.matchFilter
                      : !filterActive) &&
                    route.id &&
                    !delivery.isSelected
                  "
                  :key="delivery.id"
                  :lat-lng="delivery.position"
                  :icon="delivery.icon"
                  @click="addActivityToSelection($event, delivery)"
                />
              </template>
            </l-marker-cluster>

            <template v-for="delivery in route.deliveries">
              <l-marker
                v-if="
                  (delivery.hasOwnProperty('matchFilter')
                    ? delivery.matchFilter
                    : !filterActive) &&
                  (route.id || !cluster || delivery.isSelected)
                "
                :key="delivery.id"
                :lat-lng="delivery.position"
                :icon="delivery.icon"
                @click="addActivityToSelection($event, delivery)"
              />
            </template>

            <l-marker
              v-if="route.startLocation && route.realRoute"
              :lat-lng="route.startLocation"
              :icon="route.startIcon"
            />
            <l-marker
              v-if="route.endLocation && route.realRoute"
              :lat-lng="route.endLocation"
              :icon="route.endIcon"
            />
          </l-layer-group>

          <l-polyline
            v-if="route.polyline && route.realRoute"
            fill-color="transparent"
            :visible="route.displayPolyline"
            :lat-lngs="route.polyline"
            :color="route.color"
            @click="addRouteToSelection($event, route)"
          />
        </div>
        <map-free-draw
          v-model="lasso"
          :data="filteredData"
          @setFreeDraw="setFreeDraw"
        />
      </l-map>
    </el-main>
  </el-container>
</template>

<script>
import _ from 'lodash';
import {
  LMap,
  LTileLayer,
  LPolyline,
  LMarker,
  LLayerGroup,
} from 'vue2-leaflet';
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster';
import { latLng } from 'leaflet';
import { mapGetters, mapActions } from 'vuex';
import { setBounds } from '@/utils';
import MapToolbox from './MapToolbox';
import MapFreeDraw from './MapFreeDraw';
import Shortkey from './Shortkey';

export default {
  components: {
    MapToolbox,
    MapFreeDraw,
    LMap,
    LTileLayer,
    LPolyline,
    LLayerGroup,
    LMarker,
    'l-marker-cluster': Vue2LeafletMarkercluster,
    Shortkey,
  },
  props: {
    filteredData: {
      type: Array,
      required: true,
    },
    filterActive: {
      type: Boolean,
      required: false,
    },
    routes: {
      type: Array,
      required: true,
    },
    lockFilteredBounds: {
      type: Boolean,
      default: false,
    },
    displayTools: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    cluster: false,
    lasso: false,
    mapOptions: { zoomControl: false },
    map: null,
    freeDraw: undefined,
    zoom: 6,
    center: latLng([46.592, 3.2]),
    minZoom: 1,
    maxZoom: 20,
    opacity: 0.6,
    url: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER}`,
    attribution:
      '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    bounds: null,
  }),
  computed: {
    ...mapGetters(['selection']),
    selectionPolyline() {
      if (this.selection.type === 'activity') {
        return _.map(this.selection.data, (act) => act.position);
      }
      return [];
    },
  },
  beforeDestroy() {
    if (this.freeDraw) {
      this.$refs.map.mapObject.removeLayer(this.freeDraw, true);
    }
  },
  methods: {
    ...mapActions([
      'setSelectedActivities',
      'setSelectedRoutes',
      'addActivitiesToSelection',
      'addRoutesToSelection',
      'clearSelection',
    ]),
    focusOnSelection() {
      this.bounds = setBounds(this.selection.data);
    },
    updateCluster(v) {
      this.cluster = v;
    },
    updateLasso(v) {
      this.lasso = v;
    },
    updateZoom(v) {
      this.zoom = v;
    },
    addActivityToSelection(event, activity) {
      const data = { data: [activity] };
      if (event.originalEvent.shiftKey) {
        this.addActivitiesToSelection(data);
      } else {
        data.display = event.originalEvent.altKey;
        this.setSelectedActivities(data);
      }
    },
    addRouteToSelection(event, route) {
      const data = { data: [route] };
      if (event.originalEvent.shiftKey) {
        this.addRoutesToSelection(data);
      } else {
        data.display = event.originalEvent.altKey;
        this.setSelectedRoutes(data);
      }
    },
    refreshBounds() {
      this.bounds = setBounds(this.routes);
    },
    setFreeDraw(freeDraw) {
      this.freeDraw = freeDraw;
      this.$refs.map.mapObject.addLayer(this.freeDraw, true);
    },
    toggleAllPolylines() {
      _.forEach(this.filteredData, (route) => {
        route.displayPolyline = !route.displayPolyline;
      });
    },
    toggleAllMarkers() {
      _.forEach(this.filteredData, (route) => {
        route.displayMarkers = !route.displayMarkers;
      });
    },
  },
};
</script>
<style>
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
<style lang="scss">
.leaflet-bottom {
  z-index: 499 !important;
}
.leaflet-container {
  height: 100%;
}
.map-main {
  height: inherit;
}
.map-overlap-left {
  position: absolute;
  width: 25%;
  min-width: 388px;
  z-index: 500;
  display: flex;
  flex-direction: column;
}
</style>
