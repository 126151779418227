<template>
  <section>
    <el-alert
      v-show="isOwnRole"
      class="role-warning"
      type="warning"
      show-icon
      :title="$t('role.ownRoleWarning.title') | upperFirst"
      :description="$t('role.ownRoleWarning.description') | upperFirst"
      :closable="false"
    />
    <el-row type="flex" :gutter="20">
      <el-col>
        <h4>{{ $t('role.moduleTitle') }}</h4>
        <span class="column-modules">
          <module-edit
            v-for="(value, name) in role.modules"
            :key="name"
            :name="name"
            :value="value"
            :abilities="role.abilities"
            :advised-abilities="getAdvisedModule(name)"
            @input="updateModule(name, $event)"
          />
        </span>
      </el-col>
      <el-col :span="10">
        <h4>{{ $t('role.abilityTitle') }}</h4>
        <ability-edit v-model="role.abilities" />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { get } from 'lodash';
import AbilityEdit from './AbilityEdit';
import ModuleEdit from './ModuleEdit';

export default {
  components: {
    AbilityEdit,
    ModuleEdit,
  },
  props: {
    role: {
      type: Object,
      default: () => ({}),
    },
    advisedAbilities: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isOwnRole() {
      return this.$store.getters.loggedUser.role.id === this.role.id;
    },
  },
  methods: {
    updateModule(name, value) {
      this.role.modules[name] = value;
    },
    getAdvisedModule(name) {
      return get(this.advisedAbilities, name);
    },
  },
};
</script>

<style lang="scss">
.column-modules {
  columns: auto;
  column-width: 20em;
  display: block;
  margin-bottom: 1em;
}
.role-warning {
  margin-bottom: 1em !important;
}
</style>
