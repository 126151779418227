<template lang="html">
  <span>
    <cs-button
      icon-type="material"
      icon="account_circle"
      @click.stop.prevent="dialogVisible = true"
    />
    <el-dialog
      width="30%"
      :modal-append-to-body="true"
      :append-to-body="true"
      :title="$t('sentence.changeResource') | upperFirst"
      :visible.sync="dialogVisible"
    >
      <div class="upperFirst label-name">
        {{ $t('sentence.changeResourceForRoute', [route.name]) }}
      </div>

      <p>
        <label><i class="material-icons">person</i></label>
        {{ route.resource.user.name }}
      </p>
      <p>
        <label><i class="material-icons">local_shipping</i></label>
        {{ route.resource.vehicle.name }}
      </p>

      <el-select
        v-model="route.resource"
        value-key="id"
        size="mini"
        :placeholder="$t('sentence.selectResource')"
        @change="change"
      >
        <el-option
          v-for="item in resources"
          :key="item.id"
          :disabled="!item.shifts"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>

      <span slot="footer" class="btn-group">
        <cs-button @click="cancel">
          {{ $t('commons.cancel') }}
        </cs-button>
        <cs-button type="primary" @click="dialogVisible = false">
          {{ $t('commons.validate') }}
        </cs-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
import { values } from 'lodash';
import { mapActions } from 'vuex';

export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialogVisible: false,
  }),
  computed: {
    resources() {
      return values(this.$store.getters.resources.items);
    },
  },
  methods: {
    ...mapActions(['queueAction', 'cancelAction']),
    cancel() {
      this.cancelAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
      this.dialogVisible = false;
    },
    change() {
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
  },
};
</script>
