<template>
  <div id="nav" :style="{ backgroundColor: color }">
    <el-menu mode="horizontal" :router="true">
      <!-- Home -->
      <el-menu-item index="1" :route="home" class="brand-image">
        <i class="material-icons nav show-small-screen">home</i>
        <slot name="logo">
          <img :src="logo" alt="home" class="hide-small-screen logo" />
        </slot>
      </el-menu-item>
      <slot name="menu-left"></slot>
    </el-menu>

    <slot></slot>

    <!-- Language & Logout -->
    <el-menu id="nav_right" mode="horizontal" :router="true">
      <slot name="menu-right"></slot>
      <el-submenu index="202" :value="true">
        <template slot="title">
          <span>{{ currentLanguage }}</span>
        </template>
        <template v-for="(language, idx) in languages">
          <div :key="idx" class="btn-group btn-column">
            <cs-button class="lang-button upperFirst" @click="language.action">
              {{ language.name }}
            </cs-button>
          </div>
        </template>
      </el-submenu>

      <el-menu-item index="200" :route="{ name: 'logout' }">
        <i class="material-icons">power_settings_new</i>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import logo from '@/images/logitricks_logo.png';

export default {
  props: {
    home: {
      type: String,
      default: '/',
    },
    color: {
      type: String,
      default: '#32353b',
    },
  },
  data: () => ({
    languages: {},
  }),
  computed: {
    logo() {
      return logo;
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.languages = Object.keys(this.$i18n.messages).map((lang) => ({
      name: this.$i18n.messages[lang].languages[lang],
      action: () => {
        localStorage.setItem('lang', lang);
        this.$i18n.locale = lang;
        this.axios.defaults.headers.common['Accept-Language'] = lang;
      },
    }));
  },
};
</script>
<style lang="scss">
.lang-button {
  width: 100%;
  border: 0;
}

#nav {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  min-height: 60px;
  flex-wrap: wrap;

  .el-menu {
    border-bottom: none;
    background-color: inherit;
    .el-menu-item,
    .el-submenu {
      i {
        margin-right: 4px;
      }
    }
    .el-menu-item.is-active {
      color: $primary-color;
    }
  }
  .el-submenu {
    height: 55px;
  }

  .menu-placeholder {
    display: flex;
    height: 60px;
    line-height: 60px;
    margin: 0 5px;
  }

  @media (max-width: 1494px) {
    .hide-small-screen {
      display: none;
    }
    .show-small-screen {
      display: inline-block;
    }
  }
  @media (min-width: 1494px) {
    .show-small-screen {
      display: none !important;
    }
    .brand-image {
      > img {
        max-height: 50px;
        padding-bottom: 3px;
      }
    }
  }
}
</style>
