import axios from 'axios';
import { reduce, get } from 'lodash';
import { Message } from 'element-ui';
import { logout } from './auth';
import i18n from '@/locales';

const token = localStorage.getItem('id_token');
const instance = axios.create();
instance.defaults.baseURL = process.env.VUE_APP_API_URL;
if (token) {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}
instance.defaults.headers.common['Accept-Language'] = i18n.locale;
instance.interceptors.response.use(
  (r) => r,
  (e) => {
    switch (get(e, 'response.data.error')) {
      case 'validator_error':
        Message({
          showClose: true,
          message: reduce(
            e.response.data.message,
            (sum, err) => `${sum} ${err} \r\n`,
            ''
          ),
          type: 'error',
        });
        break;
      case 'unauthorized':
        Message({
          showClose: true,
          duration: 6000,
          message: i18n.t('error.unauthorized'),
          type: 'error',
        });
        break;
      case 'invalid_credentials':
        Message({
          showClose: true,
          duration: 6000,
          message: i18n.t('error.invalid_credentials'),
          type: 'error',
        });
        break;
      case 'user_not_found':
      case 'user_unauthorized':
      case 'token_not_provided':
      case 'token_invalid':
      case 'token_expired':
        logout();
        break;
      default:
        Message({
          showClose: true,
          duration: 6000,
          message: e.response.data.message,
          type: 'error',
        });
        break;
    }

    return Promise.reject(e);
  }
);

export default instance;
