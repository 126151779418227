<template lang="html">
  <el-dialog
    class="geocode-container"
    :append-to-body="true"
    :title="$t('components.GeocodeGate.editAddress') | upperFirst"
    :visible="visible"
    @close="$emit('close')"
  >
    <el-row v-loading="loading">
      <el-row v-if="displayForm">
        <address-form v-model="address" />
      </el-row>

      <div v-else>
        <el-row>
          <el-col :span="12">
            <h4>
              {{ $t('sentence.addressOrigin')
              }}<span class="bullet-point">.</span>
              <el-tooltip
                class="btn-edit"
                :content="$t('components.GeocodeGate.editAddress')"
              >
                <i class="material-icons" @click="displayForm = true"
                  >mode_edit</i
                >
              </el-tooltip>
            </h4>

            <address-entity :address="address" />
          </el-col>
        </el-row>

        <el-row>
          <el-col> <hr class="separator" /> </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <h4>
              {{ $t('components.GeocodeGate.resultTitle')
              }}<span class="bullet-point">.</span>
            </h4>
            <address-entity
              v-if="placeGeocoded || address.geocoded"
              :address="address.geocoded"
            />
          </el-col>
          <el-col v-if="placeGeocoded || address.geocoded" :span="12">
            <single-map
              draggable
              :position="position"
              @dragged="manualPosition"
            />
            {{ $t('components.GeocodeGate.confidence') }}:
            {{ address.geocoded.score | round }} %
          </el-col>
          <el-col v-else :span="12">
            <h5>{{ $t('pages.place.addressGeocoding') }}</h5>
          </el-col>
        </el-row>
      </div>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <cs-button
        v-if="displayForm"
        class="upperFirst"
        type="primary"
        @click="displayForm = false"
      >
        Valider l'adresse
      </cs-button>
      <cs-button
        v-show="!displayForm"
        class="upperFirst"
        type="primary"
        :disabled="displayForm"
        @click="geocode"
      >
        {{ $t('components.GeocodeGate.geocode') }}
      </cs-button>
      <cs-button
        v-show="!displayForm"
        class="upperFirst"
        type="success"
        :disabled="!enableValidate || displayForm"
        @click.native="validate"
      >
        {{ $t('commons.save') }}
      </cs-button>
    </span>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import _ from 'lodash';
import { latLng } from 'leaflet';
import AddressEntity from './Address';
import SingleMap from './SingleMap';
import AddressForm from './AddressForm';
import api from './../services/api';

export default {
  components: {
    SingleMap,
    AddressEntity,
    AddressForm,
  },
  filters: {
    round: (v) => _.round(v),
  },
  model: {
    prop: 'placeGeocoded',
    event: 'change',
  },
  props: {
    entityId: {
      type: String,
      required: true,
    },
    placeGeocoded: {
      type: Object,
      default: () => ({}),
    },
    place: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    displayForm: false,
    enableValidate: false,
    entity: {},
    address: {
      geocoded: {},
    },
  }),
  computed: {
    test() {
      return this.address.geocoded.latitude;
    },
    position() {
      return latLng(
        this.address.geocoded.latitude,
        this.address.geocoded.longitude
      );
    },
    placePosition() {
      if (_.get(this.place, 'latitude') && _.get(this.place, 'longitude')) {
        return latLng(this.place.latitude, this.place.longitude);
      }
      return null;
    },
    threshold() {
      const score = _.get(this.entity, 'place_geocoded.score', 0);
      return score > 60 && score < 100;
    },
  },
  watch: {
    place(v) {
      this.address = _.cloneDeep(v, true);
    },
    placeGeocoded(v) {
      this.address.geocoded = _.cloneDeep(v, true);
    },
  },
  mounted() {
    this.address = _.cloneDeep(this.place, true);
    Vue.set(this.address, 'geocoded', _.cloneDeep(this.placeGeocoded, true));
  },
  methods: {
    ...mapActions(['patch']),
    geocode() {
      this.loading = true;
      api
        .geocode({
          type: 'delivery',
          data: {
            id: this.entityId,
            toGeocode: this.address,
          },
        })
        .catch((e) => {
          this.$message({
            type: 'error',
            message: e.data.message,
          });
          this.loading = false;
        })
        .then((result) => {
          Vue.set(this.address, 'geocoded', result.data.place_geocoded);
          this.entity = result.data;
          this.enableValidate = true;
          this.loading = false;
        });
    },
    manualPosition(location) {
      this.address.geocoded.latitude = location.lat;
      this.address.geocoded.longitude = location.lng;
    },
    manualPositionPlace(location) {
      this.place.latitude = location.lat;
      this.place.longitude = location.lng;
    },
    validate() {
      this.entity.place_geocoded.score = 100;
      this.patch({
        type: 'delivery',
        id: this.entityId,
        data: this.entity,
      }).then(() => {
        this.$emit('change', this.address.geocoded);
        this.$emit('close');
      });
    },
  },
};
</script>

<style lang="scss">
.geocode-container {
  .el-col {
    display: block !important;
  }
}
</style>
