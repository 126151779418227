<template>
  <base-layout :load="!organization">
    <h3 slot="title">{{ $t('commons.edit') }} {{ name }}</h3>
    <el-tabs
      v-if="organization"
      v-model="activeTab"
      type="card"
      @tab-click="handleTabClick"
    >
      <el-tab-pane name="details" :label="$t('commons.generalInfos')">
        <organization-form v-model="organization" @change="handleChange">
          <el-col :span="16" :offset="4">
            <h3>{{ $t('commons.engine') }}</h3>
          </el-col>

          <el-col :span="8" :offset="4">
            <el-form-item>
              <el-switch
                v-model="organization.engine"
                active-text="Graphhopper"
                active-value="graphhopper"
                inactive-text="Redplanr"
                inactive-value="redplanr"
                @input="handleChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item>
              <el-select
                v-model="organization.reason_prefix"
                clearable
                @input="handleChange"
              >
                <el-option
                  v-for="(prefix, idx) in prefixes"
                  :key="idx"
                  :value="prefix"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </organization-form>
      </el-tab-pane>

      <el-tab-pane name="workflow" :label="$t('commons.workflow')">
        <workflow
          v-model="organization.workflow"
          :default-steps="workflowDefault"
          :required-steps="workflowRequired"
          @input="handleChange"
        />
      </el-tab-pane>

      <el-tab-pane name="modules" :label="$t('commons.module.plural')">
        <module-edit v-model="organization.modules" @change="handleChange" />
      </el-tab-pane>
      <el-tab-pane name="users" :label="$t('commons.user.plural')">
        <user-list :users="users">
          <el-table-column slot="columns">
            <template slot-scope="scope">
              <div class="pull-right btn-group">
                <cs-button type="warning" @click="loginAs(scope.row)">
                  login as
                </cs-button>
              </div>
            </template>
          </el-table-column>
        </user-list>
      </el-tab-pane>
      <el-tab-pane name="clients" :label="$t('commons.client.plural')">
        <client-list :clients="clients">
          <template v-slot:edit-action="{ client }">
            <cs-button
              square
              icon="edit"
              icon-type="material"
              type="primary"
              @click="
                $router.push({
                  name: 'adminEditClient',
                  params: {
                    organization: $route.params.id,
                    id: client.id,
                  },
                })
              "
            />
          </template>
        </client-list>
      </el-tab-pane>
    </el-tabs>

    <cs-button
      slot="footer"
      type="danger"
      icon-type="material"
      icon="delete"
      @click="deleteOrg"
    >
      {{ $t('commons.delete') }}
    </cs-button>
    <cs-button slot="footer" :disabled="!hasChanged" @click="handleCancel">
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :disabled="!hasChanged"
      :loading="saving"
      @click="saveOrg"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { get, values } from 'lodash';
import { loginAs } from '@/services/auth';
import OrganizationForm from '@/pages/organization/forms/OrganizationForm';
import Workflow from '@/components/admin/workflow/Workflow';
import ModuleEdit from '@/components/admin/ModuleEdit';
import UserList from '@/components/lists/UserList';
import ClientList from '@/components/lists/ClientList';

export default {
  components: { Workflow, ModuleEdit, OrganizationForm, UserList, ClientList },
  data: () => ({
    initData: {},
    activeTab: 'details',
    organization: undefined,
    hasChanged: false,
    saving: false,
  }),
  computed: {
    name() {
      return get(this.organization, 'name', '');
    },
    users() {
      return values(this.$store.getters.users.items);
    },
    clients() {
      return values(this.$store.getters.clients.items);
    },
    prefixes() {
      return get(this.initData, 'options.prefix', []);
    },
    workflowDefault() {
      return get(this.initData, 'options.workflow.default', []);
    },
    workflowRequired() {
      return get(this.initData, 'options.workflow.required', []);
    },
  },
  async created() {
    const { data } = await this.axios.get('admin/organization/create');
    this.initData = data;
    await this.get({
      type: 'organization',
      url: `admin/organization/${this.$route.params.id}`,
    });
    this.organization =
      this.$store.getters.organizations.items[this.$route.params.id];
  },
  methods: {
    ...mapActions([
      'get',
      'delete',
      'queueAction',
      'cancelAction',
      'processActionQueue',
    ]),
    async loginAs({ id }) {
      loginAs(id);
    },
    async handleTabClick() {
      switch (this.activeTab) {
        case 'users':
          await this.get({
            type: 'user',
            url: `admin/organization/${this.$route.params.id}/users`,
            clear: true,
          });
          break;
        case 'clients':
          await this.get({
            type: 'client',
            url: `admin/organization/${this.$route.params.id}/clients`,
            clear: true,
          });
          break;
      }
    },
    handleChange() {
      this.hasChanged = true;
      this.queueAction({
        action: 'patch',
        type: 'organization',
        url: `admin/organization/${this.$route.params.id}`,
        data: this.organization,
      });
    },
    async handleCancel() {
      this.hasChanged = false;
      await this.cancelAction({
        action: 'patch',
        type: 'organization',
        data: this.organization,
      });
      this.organization =
        this.$store.getters.organizations.items[this.$route.params.id];
    },
    async saveOrg() {
      try {
        this.saving = true;
        await this.processActionQueue(['organization']);
        this.hasChanged = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteOrg() {
      try {
        await this.$confirm(
          this.$t('sentence.wantedToDeleteOrg', {
            name: this.organization.name,
          })
        );
        await this.delete({
          type: 'organization',
          id: this.organization.id,
          url: `admin/organization/${this.organization.id}`,
        });
        this.$router.push('/admin');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
