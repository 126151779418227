<template lang="html">
  <section>
    <el-row :gutter="20">
      <el-col :span="12">
        <h4>{{ $t('components.GeocodeGate.editAddress') }}</h4>
        <address-form v-model="address" />
      </el-col>
      <el-col :span="12">
        <single-map
          v-if="placePosition"
          draggable
          :position="placePosition"
          @dragged="manualPositionPlace"
        />

        <div>
          <h4>{{ $t('components.GeocodeGate.resultTitle') }}</h4>

          <single-map
            v-if="position"
            draggable
            :position="position"
            @dragged="manualPosition"
          />
          <label>
            <em>{{ $t('components.GeocodeGate.confidence') }}: </em
            ><strong>{{ score | round }} %</strong>
          </label>
          <address-entity
            v-if="geocoded"
            style="font-size: 1.2em"
            :full="false"
            :address="geocoded"
          />
        </div>
      </el-col>
    </el-row>
    <portal to="footer" class="btn-group">
      <cs-button
        v-show="!displayForm"
        class="upperFirst footer-button"
        type="success"
        :disabled="!enableValidate || displayForm"
        @click.native="validate()"
      >
        {{ $t('commons.validate') }}
      </cs-button>
      <cs-button
        v-show="!displayForm"
        class="upperFirst footer-button"
        type="primary"
        :disabled="displayForm"
        @click="geocode"
      >
        {{ $t('components.GeocodeGate.geocode') }}
      </cs-button>
    </portal>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { clone, cloneDeep, round, isNil, get } from 'lodash';
import { latLng } from 'leaflet';
import AddressEntity from './Address';
import AddressForm from './AddressForm';
import SingleMap from './SingleMap';
import api from './../services/api';

export default {
  components: {
    SingleMap,
    AddressEntity,
    AddressForm,
  },
  filters: {
    round: (v) => round(v),
  },
  props: {
    entityId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    place: {
      type: Object,
      default: () => ({}),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    displayForm: false,
    dialogVisible: false,
    address: {
      street_number: null,
      street_name: null,
      secondary: null,
      postcode: null,
      city: null,
      latitude: null,
      longitude: null,
    },
    geocoded: {
      street_number: null,
      street_name: null,
      secondary: null,
      postcode: null,
      city: null,
      latitude: null,
      longitude: null,
      score: 0,
    },
  }),
  computed: {
    position() {
      if (this.geocoded && this.geocoded.latitude) {
        return latLng(this.geocoded.latitude, this.geocoded.longitude);
      }
      return null;
    },
    placePosition() {
      if (this.place && this.place.latitude) {
        return latLng(this.place.latitude, this.place.longitude);
      }
      return null;
    },
    autoValidation() {
      return this.score > 80 && this.score < 100;
    },
    enableValidate() {
      return !isNil(this.position);
    },
    score() {
      return get(this.geocoded, 'score', get(this.value, 'score', 0));
    },
  },
  watch: {
    place(v) {
      if (v) {
        this.address = clone(v);
      }
    },
    value(v) {
      if (v) {
        this.geocoded = clone(v);
      }
    },
  },
  mounted() {
    if (this.place) {
      this.address = clone(this.place);
    }
    if (this.value) {
      this.geocoded = clone(this.value);
    }
  },
  methods: {
    ...mapActions(['patch']),
    geocode() {
      this.loading = true;
      api
        .geocode({
          type: 'delivery',
          data: {
            id: this.entityId,
            toGeocode: this.address,
          },
        })
        .catch((e) => {
          this.$message({
            type: 'error',
            message: e.data.message,
          });
          this.loading = false;
        })
        .then((result) => {
          this.geocoded = result.data.place_geocoded;
          if (this.autoValidation) {
            this.sendMessage();
          }
          this.$emit('change', this.geocoded);
          this.loading = false;
        });
    },
    manualPosition(latLng) {
      this.geocoded.latitude = latLng.lat;
      this.geocoded.longitude = latLng.lng;
    },
    manualPositionPlace(latLng) {
      this.place.latitude = latLng.lat;
      this.place.longitude = latLng.lng;
    },
    validate() {
      const data = this.$store.getters.deliveries.items[this.entityId];
      data.place_geocoded = cloneDeep(this.geocoded);
      data.place_geocoded.score = 100;
      this.patch({
        type: 'delivery',
        id: this.entityId,
        data,
      }).then(() => {
        this.$emit('change', this.geocoded);
        this.geocoded = {
          street_number: null,
          street_name: null,
          secondary: null,
          postcode: null,
          city: null,
          latitude: null,
          longitude: null,
          score: 0,
        };
        this.dialogVisible = false;
      });
    },
    sendMessage() {
      this.$message({
        message: this.$t('sentence.geocodeSuccess'),
        type: 'success',
        center: true,
      });
    },
  },
};
</script>
