<template>
  <base-list :data="users">
    <el-table-column
      prop="name"
      sortable
      :label="$t('commons.name')"
      min-width="150"
    >
    </el-table-column>
    <el-table-column prop="email" :label="$t('commons.mail')" min-width="150">
    </el-table-column>
    <el-table-column
      prop="role.name"
      sortable
      :label="$t('commons.role.single')"
      width="100"
    >
    </el-table-column>
    <slot name="columns"></slot>
  </base-list>
</template>

<script>
import BaseList from './BaseList';

export default {
  components: { BaseList },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
