<template>
  <table class="grid">
    <tr class="grid__header">
      <th class="firstCol"></th>
      <th v-for="col in colList" :key="col">
        <label>{{ (col + 1) | column }}</label>
      </th>
      <th>
        <cs-button
          square
          icon-type="material"
          icon="add_circle"
          class="spot-btn-sec"
          @click="addColumn"
        />
      </th>
    </tr>

    <tr v-if="isEmpty(spotsGrid)">
      <td>
        <div class="spot-wrapper add-btn-wrapper">
          <cs-button
            icon-type="material"
            icon="add"
            class="spot-btn"
            @click="createSpotFromGrid('AA', 1)"
          />
        </div>
      </td>
    </tr>

    <tr v-for="rowId in rowList" v-else :key="rowId">
      <td class="firstCol">
        <label>{{ rowId | row }}</label>
      </td>
      <td v-for="(spotsCol, colId) in spotsGrid[rowId]" :key="colId">
        <div v-if="isEmpty(spotsCol)" class="spot-wrapper add-btn-wrapper">
          <cs-button
            icon-type="material"
            icon="add"
            class="spot-btn"
            @click="createSpotFromGrid(rowId, parseInt(colId) + 1)"
          />
        </div>

        <spot
          v-else
          class="spot-wrapper"
          :spots="spotsCol"
          @delete="deleteSpots"
          @addSpot="addSpot"
        >
        </spot>
      </td>
    </tr>
    <tr>
      <td>
        <cs-button
          square
          icon-type="material"
          icon="add_circle"
          class="spot-btn-sec"
          @click="addRow"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import {
  isEmpty,
  isArray,
  upperFirst,
  groupBy,
  reduce,
  range,
  zipObject,
  size,
  keys,
  last,
} from 'lodash';
import Spot from './Spot';
import { numberToLetter, letterToNumber, toAlphaNum } from '@/utils/helpers';
import { getBarcode } from '@/utils/spotUtils';

const getSpotsByCols = (spots) =>
  groupBy(spots, (spot) => parseInt(spot.barcode.slice(2, 5)) - 1);

export default {
  name: 'SpotGrid',
  components: {
    Spot,
  },
  filters: {
    column: (v) => toAlphaNum(v, 2),
    row: (v) => numberToLetter(v).toUpperCase(),
  },
  model: {
    prop: 'spots',
    event: 'change',
  },
  props: {
    spots: {
      type: Array,
      default: () => [],
    },
    placeNumericId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rowList: [],
      colList: [],
    };
  },
  computed: {
    spotsByRow() {
      return groupBy(this.spots, (spot) =>
        letterToNumber(spot.barcode.slice(0, 2))
      );
    },
    colCount() {
      return reduce(
        this.spotsByRow,
        (acc, spots) => {
          const spostCols = getSpotsByCols(spots);
          return acc < size(spostCols) ? size(spostCols) : acc;
        },
        0
      );
    },
    spotsGrid() {
      return this.rowList.reduce((grid, row) => {
        const spotsByCols = getSpotsByCols(this.spotsByRow[row]);
        const values = this.colList.map((col) => {
          return spotsByCols.hasOwnProperty(col) ? spotsByCols[col] : [];
        });
        grid[row] = zipObject(this.colList, values);
        return grid;
      }, {});
    },
  },
  created() {
    this.initGrid();
  },
  methods: {
    // helpers
    isEmpty,
    initGrid() {
      this.rowList = keys(this.spotsByRow).map((k) => parseInt(k));
      if (!this.rowList.length) {
        this.rowList.push(27);
      }

      this.colList = range(this.colCount);
      if (!this.colList.length) {
        this.colList.push(0);
      }
    },
    createSpotFromGrid(row, col) {
      if (parseInt(col) === 999) {
        this.$message({
          type: 'error',
          message: upperFirst(this.$t('pages.place.spot.error.columnLimit')),
          showClose: true,
        });
      } else {
        const barcode = getBarcode(row, col, this.placeNumericId);
        this.addSpot({ barcode, type: 'storage' });
      }
    },
    addSpot(spot) {
      this.$emit('change', [...this.spots, spot]);
    },
    updateSpots(spots) {
      this.$emit('change', spots);
    },
    deleteSpots(spots) {
      const validateSpot = (spot) => {
        if (!isEmpty(spot.items)) {
          this.$message({
            type: 'error',
            message: upperFirst(this.$t('pages.place.spot.error.spotNotEmpty')),
            showClose: true,
          });
          return false;
        }
        return true;
      };
      let spotsToDelete = [];
      if (isArray(spots)) {
        spotsToDelete = spots.filter(validateSpot);
      } else if (validateSpot(spots)) {
        spotsToDelete.push(spots);
      }
      const barcodes = spotsToDelete.map((s) => s.barcode);

      this.updateSpots(
        this.spots.filter((spot) => !barcodes.includes(spot.barcode))
      );
    },
    addColumn() {
      this.colList.push(last(this.colList) + 1);
    },
    addRow() {
      this.rowList.push(last(this.rowList) + 1);
    },
  },
};
</script>

<style lang="scss">
$tileSize: 125px;
.grid {
  overflow: inherit !important;
  &__header {
  }
  td,
  th {
    text-align: center;
    vertical-align: middle !important;
  }
  .spot-wrapper {
    margin: 12px;
    background-color: white;
    width: $tileSize;
    height: $tileSize;
    text-align: center;
  }
  .add-btn-wrapper {
    width: $tileSize;
    height: $tileSize;
    display: flex;
    justify-content: center;
  }
}
</style>
