import { findIndex, cloneDeep, isEmpty, values } from 'lodash';
import { startOfDay, endOfDay, startOfToday, endOfToday } from 'date-fns';
import filtering from '@/components/filter/filtering';

const state = {
  dateRange: {
    from: startOfToday(),
    to: endOfToday(),
  },
  dateRangeFetched: {
    from: startOfToday(),
    to: endOfToday(),
  },
  datePicker: {
    clickHandle() {},
    display: false,
  },
  datePickerLoading: false,
  displayHeader: true,
  displayNav: true,
  navOpen: true,
  inited: false,
  notifications: [],
  logged: false,
  loggedUser: {},
};

const mutations = {
  DELETE_NOTIFICATION: (myState, notification) => {
    myState.notifications.splice(
      findIndex(myState.notifications, notification),
      1
    );
  },
  ADD_NOTIFICATION: (myState, notification) => {
    notification.datetime = new Date();
    myState.notifications.push(notification);
  },
  SET_DATE_RANGE: (myState, dateRange) => {
    dateRange.from = startOfDay(dateRange.from);
    dateRange.to = endOfDay(dateRange.to);
    myState.dateRange = dateRange;
  },
  SET_DATE_PICKER: (myState, datePicker) => {
    myState.datePicker = datePicker;
  },
  SET_DATE_PICKER_LOADING: (myState, datePickerLoading) => {
    myState.datePickerLoading = datePickerLoading;
    if (!datePickerLoading) {
      myState.dateRangeFetched = cloneDeep(myState.dateRange);
    }
  },
  SET_DISPLAY_HEADER: (myState, displayHeader) => {
    myState.displayHeader = displayHeader;
  },
  SET_DISPLAY_NAV: (myState, displayNav) => {
    myState.displayNav = displayNav;
  },
  SET_DISPLAY_DATE_PICKER: (myState, value) => {
    myState.datePicker.display = value;
  },
  SET_INIT: (myState, isInit) => {
    myState.inited = isInit;
  },
  SET_LOGGED_USER: (myState, loggedUser) => {
    myState.loggedUser = loggedUser;
  },
  SET_LOGIN: (myState, isLogged) => {
    myState.logged = isLogged;
  },
  SET_NAV_OPEN: (myState, isOpen) => {
    myState.navOpen = isOpen;
  },
};

const getters = {
  datePicker: (myState) => myState.datePicker,
  datePickerLoading: (myState) => myState.datePickerLoading,
  dateRange: (myState) => myState.dateRange,
  dateRangeFetched: (myState) => myState.dateRangeFetched,
  displayHeader: (myState) => myState.displayHeader,
  displayNav: (myState) => myState.displayNav,
  inited: (myState) => myState.inited,
  logged: (myState) => myState.logged,
  loggedUser: (myState) => myState.loggedUser,
  navOpen: (myState) => myState.navOpen,
  notificationCount: (myState) => myState.notifications.length,
  notifications: (myState) => myState.notifications,
  organization: (myState, myGetters) => {
    if (!isEmpty(Object.values(myGetters.organizations.items))) {
      return Object.values(myGetters.organizations.items)[0];
    }
    return {};
  },
  permissions: (myState, myGetters) => {
    if (!isEmpty(Object.values(myGetters.organizations.items))) {
      return Object.values(myGetters.organizations.items)[0].modules;
    }
    return [];
  },
  routesByDate: (myState, myGetters, rootState, rootGetters) => {
    return filtering.filterDataset(
      values(rootGetters.routes.items),
      {
        type: 'Range',
        path: 'planned_start',
        path2: 'planned_end',
        operator: 'overlapping',
        value: myGetters.dateRange.from.toISOString(),
        value2: myGetters.dateRange.to.toISOString(),
        options: {
          opposite: false,
          caseSensitive: false,
        },
      },
      true,
      'initialMatch'
    );
  },
  unplannedByDate: (myState, myGetters, rootState, rootGetters) => {
    return filtering.filterDataset(
      values(rootGetters.deliveries.items),
      {
        operator: 'AND',
        filters: [
          {
            type: 'Range',
            path: 'slas[*].start',
            path2: 'slas[*].end',
            operator: 'overlapping',
            value: myGetters.dateRange.from.toISOString(),
            value2: myGetters.dateRange.to.toISOString(),
            options: {
              opposite: false,
              caseSensitive: false,
            },
          },
          {
            type: 'String',
            path: 'route_id',
            operator: 'equals',
            value: null,
            options: {
              opposite: false,
              caseSensitive: false,
            },
          },
        ],
      },
      true,
      'initialMatch'
    );
  },
};

const actions = {
  addNotification({ commit }, notification) {
    commit('ADD_NOTIFICATION', notification);
  },
  removeNotification({ commit }, notification) {
    commit('DELETE_NOTIFICATION', notification);
  },
  setDateRange({ commit }, dateRange) {
    commit('SET_DATE_RANGE', dateRange);
  },
  setDatePicker({ commit }, datePicker) {
    commit('SET_DATE_PICKER', datePicker);
  },
  setDisplayDatePicker({ commit }, value) {
    commit('SET_DISPLAY_DATE_PICKER', value);
  },
  setDatePickerLoading({ commit }, datePickerLoading) {
    commit('SET_DATE_PICKER_LOADING', datePickerLoading);
  },
  setDisplayHeader({ commit }, displayHeader) {
    commit('SET_DISPLAY_HEADER', displayHeader);
  },
  setDisplayNav({ commit }, displayNav) {
    commit('SET_DISPLAY_NAV', displayNav);
  },
  setInit({ commit }, isInit) {
    commit('SET_INIT', isInit);
  },
  setLoggedUser({ commit }, loggedUser) {
    commit('SET_LOGGED_USER', loggedUser);
  },
  setIsLogged({ commit }, isLogged) {
    commit('SET_LOGIN', isLogged);
  },
  setNavOpen({ commit }, isOpen) {
    commit('SET_NAV_OPEN', isOpen);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
