<template>
  <el-card class="box-card">
    <div slot="header">
      <span>{{ shiftName }}</span>
    </div>
    <calendar
      :planning="planning || undefined"
      :editable="editable"
      @change="update"
    />
    <div class="footer"><slot name="footer" /></div>
  </el-card>
</template>

<script>
import Calendar from '@/components/Calendar';

export default {
  name: 'ShiftType',
  components: { Calendar },
  props: {
    shiftName: {
      type: String,
      required: false,
      default: () => null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      required: false,
      default: () => 650,
    },
    planning: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialogVisible: false,
  }),
  methods: {
    update(v) {
      this.$emit('change', v);
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-type-calendar {
  margin: 5px;
}
.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
