<template>
  <base-layout :load="!optimization" class="optim-result">
    <optim-title
      v-if="optimization"
      slot="title"
      :name="optimization.name"
      :state="optimization.state"
    >
      <cs-button
        slot="actions-right"
        icon-type="material"
        icon="delete"
        class="btn-invisible optim-title__btn"
        @click="removeOptim"
      />
    </optim-title>

    <span v-if="optimization">
      <el-row :gutter="20" type="flex">
        <el-col :span="12" class="optim-result-map">
          <el-tabs v-model="tab">
            <el-tab-pane :label="$t('commons.map')" name="map">
              <l-map
                v-if="tab === 'map' && optimization"
                :zoom="zoom"
                :center="center"
                :bounds="unplannedBounds"
                style="height: 340px; width: 100%"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                  :token="token"
                ></l-tile-layer>
                <l-layer-group
                  v-for="unplanned in outUnplanned"
                  :key="unplanned.id"
                >
                  <l-marker
                    :visible="true"
                    :lat-lng="unplanned.position"
                    :icon="unplannedIcon"
                  />
                </l-layer-group>
                <l-layer-group
                  v-for="route in outputUsedRoutes"
                  :key="route.id"
                >
                  <l-layer-group :visible="route.displayMarkers">
                    <l-marker
                      v-for="delivery in route.deliveries"
                      :key="delivery.id"
                      :lat-lng="delivery.position"
                      :icon="delivery.icon"
                    />

                    <l-marker
                      v-if="route.startLocation"
                      :lat-lng="route.startLocation"
                      :icon="route.startIcon"
                    />
                    <l-marker
                      v-if="route.endLocation"
                      :lat-lng="route.endLocation"
                      :icon="route.endIcon"
                    />
                  </l-layer-group>
                  <l-polyline
                    v-if="route.polyline"
                    fill-color="transparent"
                    :visible="route.displayPolyline"
                    :lat-lngs="route.polyline"
                    :color="route.color"
                  />
                </l-layer-group>
              </l-map>
            </el-tab-pane>
            <el-tab-pane :label="$t('commons.gantt')" name="gantt">
              <optim-gantt
                v-if="tab === 'gantt'"
                max-height="340px"
                :resource-edit="false"
                :routes="outputUsedRoutes"
                :start="optimization.input.range.from"
                :end="optimization.input.range.to"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>

        <el-col :span="12" class="optim-result-summary">
          <table class="table">
            <thead>
              <tr>
                <th class="optim-result-summary__title">
                  {{ $t('commons.summary') }}
                </th>
                <th class="upperFirst">{{ $t('commons.before') }}</th>
                <th class="upperFirst optim-result-summary__highlight">
                  {{ $t('commons.optimization.single') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.routeUsed') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.usedRoute.current.count }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.usedRoute.optim.count }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.globalUsage') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.globalUse.current | percent }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.globalUse.optim | percent }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.currentUsedRoutes') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.avgActivity.current }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.avgActivity.optim }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">{{ $t('components.optim.inSla') }}</td>
                <td class="optim-result-summary__data">
                  {{ stats.sla.current | percent }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.sla.optim | percent }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.inAppointment') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.appointment.current | percent }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.appointment.optim | percent }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('commons.planned.male.plural') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.countActivity.current.planned }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.countActivity.optim.planned }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.unPlanned') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.countActivity.current.unplanned }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.countActivity.optim.unplanned }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.totalDist') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.distance.current | km }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.distance.optim | km }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.totalTravelTime') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.travel.current.duration | seconds }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.travel.optim.duration | seconds }}
                </td>
              </tr>
              <tr>
                <td class="upperFirst">
                  {{ $t('components.optim.totalDurationWork') }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.work.current | seconds }}
                </td>
                <td class="optim-result-summary__data">
                  {{ stats.work.optim | seconds }}
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>

      <hr />

      <el-row :gutter="20">
        <el-table
          ref="multipleTable"
          border
          style="width: 100%; margin-bottom: 10px"
          :data="paginatedList"
          row-key="optim.id"
          class="optim-result-table"
          @select-all="toggleAllRoutes"
          @select="toggleRoute"
        >
          <el-table-column label="">
            <el-table-column
              type="selection"
              align="center"
              :label="$t('commons.showHide')"
              width="55"
            />
          </el-table-column>

          <el-table-column :label="$t('commons.route.plural')">
            <el-table-column prop="current.name" align="left">
              <template slot-scope="scope">
                <route-tag :route="scope.row.optim" />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column :label="$t('components.optim.activityPlanned')">
            <el-table-column
              sortable
              prop="current.deliveries.length"
              class-name="optim-result-table__head-contrast"
              :label="$t('commons.before')"
              width="150"
            />
            <el-table-column
              sortable
              prop="optim.deliveries.length"
              class-name="optim-result-table__head-highlight optim-result-table__head-contrast"
              :label="$t('commons.optimization.single')"
              width="150"
            />
          </el-table-column>

          <el-table-column :label="$t('commons.distance')">
            <el-table-column
              sortable
              prop="current.distance"
              :formatter="formatDistance"
              :label="$t('commons.before')"
              width="150"
            />
            <el-table-column
              sortable
              prop="optim.distance"
              class-name="optim-result-table__head-highlight"
              :formatter="formatDistance"
              :label="$t('commons.optimization.single')"
              width="150"
            />
          </el-table-column>

          <el-table-column :label="$t('commons.duration')">
            <el-table-column
              sortable
              prop="current.duration"
              class-name="optim-result-table__head-contrast"
              :formatter="formatDuration"
              :label="$t('commons.before')"
              width="150"
            />
            <el-table-column
              sortable
              prop="optim.duration"
              class-name="optim-result-table__head-highlight optim-result-table__head-contrast"
              :formatter="formatDuration"
              :label="$t('commons.optimization.single')"
              width="150"
            />
          </el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        :page-size="pageSize"
        :current-page="current"
        layout="total, prev, pager, next"
        :total="dataTable.length"
        @current-change="setPage"
      >
      </el-pagination>
    </span>

    <el-card slot="footer" class="optim-result-actions">
      <div class="optim-result-actions__stat">
        <i class="material-icons">show_chart</i>
        {{ stats.usedRoute.current.count }}
        <i class="material-icons">trending_flat</i>
        <span class="optim-result-actions__stat__highlight">
          {{ stats.usedRoute.optim.count }}
        </span>
      </div>

      <div class="optim-result-actions__stat">
        <i class="material-icons">room</i>
        {{ stats.countActivity.current.planned | empty }}
        <i class="material-icons">trending_flat</i>
        <span class="optim-result-actions__stat__highlight">
          {{ stats.countActivity.optim.planned | empty }}
        </span>
      </div>

      <div class="optim-result-actions__cta">
        <el-button
          type="primary"
          class="btn-highlight upperFirst"
          @click="applyOptim"
        >
          {{ $t('components.optim.applyOptim') }}
        </el-button>
      </div>
    </el-card>
  </base-layout>
</template>

<script>
import {
  get,
  filter,
  find,
  some,
  flatMap,
  differenceBy,
  isEmpty,
  groupBy,
  size,
} from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  LMap,
  LTileLayer,
  LPolyline,
  LLayerGroup,
  LMarker,
} from 'vue2-leaflet';
import L from 'leaflet';
import { differenceInSeconds } from 'date-fns';
import RouteTag from './create/RouteTag';
import svgIcon from '@/svgIcon';
import { normal } from '@/iconPaths';
import OptimGantt from '@/components/gantt/GanttRouting';
import { setBounds } from '@/utils';
import OptimTitle from './OptimTitle';

const getTotalTravel = function (routes) {
  return flatMap(routes, 'travels').reduce(
    (acc, travel) => {
      if (travel) {
        acc.distance += travel.distance;
        acc.duration += travel.duration;
      }
      return acc;
    },
    { distance: 0, duration: 0 }
  );
};

const getTotalWork = function (routes) {
  return routes.reduce((acc, { activityDuration }) => {
    acc += activityDuration;
    return acc;
  }, 0);
};
const getTotalDistance = function (routes) {
  return routes.reduce((acc, { distance }) => {
    acc += distance;
    return acc;
  }, 0);
};

const routeUsage = function (routes) {
  const total = routes.reduce((usage, r) => {
    return (
      usage + r.duration / differenceInSeconds(r.planned_end, r.planned_start)
    );
  }, 0);
  const res = Math.round((total / routes.length) * 100, 2);
  return res || 0;
};

const getSlaRate = function (activities) {
  return activities
    ? Math.round(
        (filter(activities, (a) => a.inSla).length / activities.length) * 100
      )
    : 0;
};
const getAppointmentRate = function (activities) {
  const withAppointment = filter(activities, (a) => a.appointment);
  return withAppointment
    ? Math.round(
        (filter(withAppointment, (a) => a.inAppointment).length /
          withAppointment.length) *
          100
      )
    : 'N/A';
};

export default {
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    LLayerGroup,
    LMarker,
    OptimGantt,
    RouteTag,
    OptimTitle,
  },
  data: () => ({
    tab: 'map',
    token: 'your token if using mapbox',
    saving: false,
    zoom: 5,
    center: L.latLng([46.592, 3.2]),
    url: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER}`,
    attribution:
      '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    unplannedIcon: svgIcon({
      text: '',
      svg: normal.unplanned,
      markerColor: '#1a4e95',
      iconSize: [30, 30],
      iconAnchor: [15, 30],
    }),
    current: 1,
    pageSize: 10,
  }),
  computed: {
    ...mapGetters(['dateRange']),
    routes() {
      return get(this.optimization, 'input.routes', []);
    },
    activities() {
      return get(this.optimization, 'input.deliveries', []);
    },
    optimization() {
      const o = this.$store.getters.optimizations.items[this.$route.params.id];
      return o && o.has_details ? o : undefined;
    },
    stats() {
      const routeCurrentUsed = filter(
        this.routes,
        (r) => r.deliveries.length > 0
      );
      const activityCurrent = groupBy(this.activities, (a) =>
        a.route_id ? 'planned' : 'unplanned'
      );
      const activityOut = groupBy(this.outputActivities, (a) =>
        a.route_id ? 'planned' : 'unplanned'
      );

      return {
        usedRoute: {
          current: {
            count: routeCurrentUsed.length,
          },
          optim: {
            count: this.outputUsedRoutes.length,
          },
        },
        globalUse: {
          current: routeUsage(routeCurrentUsed),
          optim: routeUsage(this.outputUsedRoutes),
        },
        distance: {
          current: getTotalDistance(routeCurrentUsed),
          optim: getTotalDistance(this.outputUsedRoutes),
        },
        work: {
          current: getTotalWork(routeCurrentUsed),
          optim: getTotalWork(this.outputUsedRoutes),
        },
        travel: {
          current: getTotalTravel(routeCurrentUsed),
          optim: getTotalTravel(this.outputUsedRoutes),
        },
        sla: {
          current: getSlaRate(activityCurrent.planned),
          optim: getSlaRate(activityOut.planned),
        },
        appointment: {
          current: getAppointmentRate(activityCurrent.planned),
          optim: getAppointmentRate(activityOut.planned),
        },
        avgActivity: {
          current:
            Math.round(
              size(activityCurrent.planned) / routeCurrentUsed.length
            ) || 'N/A',
          optim:
            Math.round(
              size(activityOut.planned) / this.outputUsedRoutes.length
            ) || 'N/A',
        },
        countActivity: {
          current: {
            planned: size(activityCurrent.planned),
            unplanned: size(activityCurrent.unplanned),
          },
          optim: {
            planned: size(activityOut.planned),
            unplanned: size(activityOut.unplanned),
          },
        },
      };
    },
    output() {
      return get(this.optimization, 'output', []);
    },
    dataTable() {
      return this.routes.reduce((acc, current) => {
        const optim = find(this.output, (o) => o.id === current.id);
        if (optim) {
          acc.push({ current, optim });
        }
        return acc;
      }, []);
    },
    paginatedList() {
      return this.dataTable.slice(
        this.pageSize * (this.current - 1),
        this.pageSize * this.current
      );
    },
    currentActivities() {
      return filter(this.activities, (a) =>
        some(this.optimization.input.deliveries, (i) => i.id === a.id)
      );
    },
    currentUnplanned() {
      return filter(this.currentActivities, { route_id: null });
    },
    outputActivities() {
      return flatMap(this.output, 'deliveries');
    },
    outUnplanned() {
      return differenceBy(
        this.optimization.input.deliveries,
        this.outputActivities,
        'id'
      );
    },
    outputUsedRoutes() {
      return this.output.filter((r) => r.deliveries.length > 0);
    },
    unplannedBounds() {
      const bounds = L.latLngBounds(
        this.outUnplanned
          .map((a) => a.position)
          .concat(setBounds(this.outputUsedRoutes))
      );
      return isEmpty(bounds) ? null : bounds;
    },
  },
  async mounted() {
    if (!this.optimization) {
      await this.get({
        id: this.$route.params.id,
        type: 'optimization',
        forceFetch: true,
        query: 'include=input,output',
      });
    }
    this.loading = false;
    this.setPage();
  },
  methods: {
    ...mapActions(['get', 'delete', 'patch']),
    formatDistance(r, c) {
      return this.$options.filters.km(get(r, c.property));
    },
    formatDuration(r, c) {
      return this.$options.filters.seconds(get(r, c.property));
    },
    toggleAllRoutes(s) {
      this.outputUsedRoutes.forEach((r) => {
        if (some(s, ['optim.id', r.id])) {
          r.displayPolyline = true;
          r.displayMarkers = true;
        } else {
          r.displayPolyline = false;
          r.displayMarkers = false;
        }
      });
    },
    toggleRoute(s, r) {
      r.optim.displayPolyline = !r.optim.displayPolyline;
      r.optim.displayMarkers = !r.optim.displayMarkers;
    },
    async applyOptim() {
      this.saving = true;
      this.optimization.input.routes.forEach(({ id }) => {
        const route = this.$store.getters.routes.items[id];
        if (route) {
          route.invalidateGeometry();
        }
      });

      try {
        await this.patch({
          type: 'route',
          id: 'all',
          clear: false,
          data: this.output,
        });
        this.$emit('apply');
        this.$router.back();
      } finally {
        this.saving = false;
      }
    },
    removeOptim() {
      this.$confirm(
        `${this.$t('components.optim.deleteOptim')} ${this.optimization.name}`
      ).then(() => {
        this.delete({ type: 'optimization', id: this.$route.params.id });
        this.$router.back();
      });
    },
    setPage(v) {
      if (v) {
        this.current = v;
      }
    },
  },
};
</script>

<style lang="scss">
.optim-result {
  .layout-footer {
    background-color: $secondary-color-light !important;
  }
  color: $primary-text-color;

  .layout-head {
    padding: 0 !important;
  }
}

.optim-result-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left,
  &__right {
    display: flex;
  }

  &__left {
    > * {
      margin-right: 20px;
    }
  }
  &__right {
    justify-content: flex-end;
    > * {
      margin-left: 20px;
    }
  }

  &__name,
  &__edit-name {
    line-height: 60px;
  }

  &__name {
    margin-bottom: 0;
  }

  &__status {
    align-items: center;
  }

  &__icon,
  &__btn {
    height: 60px !important;
    width: 60px;
    text-align: center;

    &.material-icons,
    .material-icons {
      font-size: 24px;
    }
  }
  &__icon {
    line-height: 60px;
  }
}

.optim-result-map {
  .el-tabs {
    background: white;

    &__header {
      margin-bottom: 1px;
    }

    &__nav-wrap {
      &::after {
        content: none;
      }
    }

    &__active-bar {
      background-color: $highlight-color;
    }

    &__item {
      // padding: 0 20px !important;
      text-transform: uppercase;
      color: $primary-color-light;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 20px;
      }

      &.is-active {
        color: $highlight-color;
      }
    }
  }
}

.optim-result-summary {
  align-self: stretch;

  .table {
    width: 100%;
    min-height: 100%;
    background: white;
    border-collapse: collapse;

    tbody {
      background: rgba($secondary-color-light, 0.4);
    }

    tr {
      border-bottom: 1px solid $secondary-color-light;
    }

    th,
    td {
      padding: 5px 20px;
      vertical-align: middle;
    }

    th {
      padding: 15px 20px;
      text-transform: uppercase;
    }
  }

  &__title {
    text-transform: capitalize !important;
    font-size: 18px;
  }

  &__highlight {
    color: $highlight-color;
  }

  &__data {
    font-size: 14px;
  }
}

.optim-result-table {
  table {
    border-collapse: collapse;

    th,
    td {
      border: none;
    }
  }

  thead {
    text-transform: capitalize;

    tr:nth-of-type(1) {
      th {
        background: white;

        &:nth-of-type(2) {
          text-transform: uppercase;
        }
      }
    }

    tr:nth-of-type(2) {
      th {
        background: $secondary-color-light;
        text-transform: uppercase;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:first-of-type {
          overflow: visible;

          .cell {
            display: inline-block;
            position: relative;
            bottom: 58px - 5px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(black, 0.07);
    }
  }

  th#{&}__head-highlight {
    color: $highlight-color;
  }

  th#{&}__head-contrast {
    background-color: rgba(black, 0.07) !important;
  }

  &__tournee {
    display: inline-block;
    padding: 5px;
    border-bottom: 2px solid $primary-color-text;
    color: white;
    vertical-align: middle;

    .material-icons {
      vertical-align: -2px;
    }
  }
}

.optim-result-actions {
  width: 50vw;
  $actions: &;

  &.el-card {
    box-shadow: none;
    border: none;
  }

  .el-card__body {
    display: flex;
    align-items: center;

    #{$actions}__stat {
      flex-basis: 25%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .material-icons {
        margin: 0 5px;

        &:first-of-type {
          font-size: 26px;
        }
      }

      &__highlight {
        color: $highlight-color;
      }
    }

    #{$actions}__cta {
      margin-left: auto;
      flex-basis: calc(50% - 10px);

      .el-button {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
