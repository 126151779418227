import { isArray, isEmpty, isNil, map } from 'lodash';
import ApiEntity from './ApiEntity';
import Activity from './Activity';
import Range from './Range';
import Route from './Route';

class OptimizationInput {
  range;
  routes = [];
  deliveries;
  constructor(o) {
    this.range = new Range(o.range);
    this.deliveries = Activity.create(o.deliveries);
    this.initRoutes(o.routes);
  }

  async initRoutes(routes) {
    this.routes = await Route.create(routes, { shouldUpdate: false });
  }
}

class OptimActivity extends Activity {
  #route;
  constructor(o) {
    super(o);
  }

  set route(data) {
    this.#route = data;
  }

  get route() {
    return this.#route;
  }
}

export default class Optimization extends ApiEntity {
  id;
  name = null;
  state;
  created_at;
  input;
  output;
  constructor(o) {
    super();
    this.fill(o);
    this.created_at = this.castDate(o.created_at);
    if (o.input) {
      this.input = new OptimizationInput(o.input);
    }
  }

  static async create(data) {
    const create = async (d) => {
      const optimization = new Optimization(d);
      if (optimization.output) {
        const routes = await Route.create(optimization.output, {
          shouldUpdate: false,
        });
        optimization.output = map(routes, (route) => {
          route.deliveries = route.deliveries.map((activity) => {
            activity = new OptimActivity(activity);
            activity.route = route;
            return activity;
          });
          return route;
        });
      }

      return optimization;
    };

    return isArray(data) ? Promise.all(data.map(create)) : create(data);
  }

  get has_details() {
    return !isEmpty(this.input) && !isNil(this.output);
  }
}
