import { toString } from 'lodash';
import svgIcon from '@/svgIcon';
import { selected } from '@/iconPaths';

export const getIconSelected = (order) =>
  svgIcon({
    text: toString(order) || '',
    markerColor: '#32353b',
    iconColor: '#ffffff',
    svg: selected.unplanned,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    className: 'top-marker',
  });

export const getDepotIcon = () =>
  svgIcon({
    text: 'business',
    prefix: '',
    extraClasses: 'material-icons',
    className: 'top-marker',
    markerColor: '#a7aab3',
    iconColor: '#ffffff',
  });

export const getHouseIcon = () =>
  svgIcon({
    text: 'home',
    prefix: '',
    extraClasses: 'material-icons',
    className: 'top-marker',
    markerColor: '#a7aab3',
    iconColor: '#ffffff',
  });
