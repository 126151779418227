<template>
  <base-layout>
    <h3 slot="title">{{ $t('pages.client.create') }}</h3>

    <client-form ref="form" :client="client" :saving="saving"></client-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexClient' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveClient"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import ClientForm from './forms/ClientForm';

export default {
  name: 'ClientCreate',
  components: { ClientForm },
  data: () => ({
    client: {
      siret: null,
      image: null,
      email: null,
      activityDuration: 600,
      policy: 1,
      scan_for_prepare: false,
      scan_for_load: false,
      scan_for_deliver: false,
      signature_proof: false,
      photo_proof: false,
      distance_geoloc: 200,
      mandatory_proof: false,
      disable_item_reasons: false,
      auto_state: false,
      disable_activity_reasons: false,
      // report_type: 'api',
      // import_format: 'api',
      api_key: null,
      // report_format: null,
      // ftp_password: null,
      // ftp_host: null
    },
    saving: false,
  }),
  methods: {
    ...mapActions(['post']),
    saveClient() {
      this.saving = true;
      this.post({ type: 'client', data: this.client })
        .then(() => {
          this.$router.push({ name: 'indexClient' });
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style></style>
