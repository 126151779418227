<template>
  <table v-if="Array.isArray(filteredValue)" class="external-data">
    <tr v-for="(data, idx) in filteredValue" :key="idx">
      <th class="row-key" :style="{ color: colors.left, width: widthTable }">
        {{ data.name | formatName }}
      </th>
      <th class="row-value" :style="`color: ${colors.right}`">
        {{ data.value | empty('N/A') }}
      </th>
    </tr>
  </table>
</template>

<script>
import { isEmpty, isNil } from 'lodash';

export default {
  filters: {
    formatName: (v) => v.replace('_', ' '),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Object,
      default: () => ({
        left: '#606266',
        right: '#32363B',
      }),
    },
    without: {
      type: Array,
      default: () => [],
    },
    widthTable: {
      type: String,
      default: null,
    },
  },
  computed: {
    filteredValue() {
      const shouldFilter = !isEmpty(this.without) && !isNil(this.value);
      let listWithout = this.without;
      if (shouldFilter) {
        return this.value.reduce((acc, currentValue) => {
          if (!listWithout.includes(currentValue.name)) {
            acc.push(currentValue);
          }
          return acc;
        }, []);
      } else {
        return this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.external-data {
  width: 100%;
  .row-key,
  .row-value {
    text-transform: capitalize;
  }
  .column {
    word-break: break-word;
    &.left {
      margin-right: 10px;
    }
  }
}
</style>
