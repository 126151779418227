<template>
  <base-menu>
    <img
      slot="logo"
      class="hide-small-screen logo"
      :src="logo"
      :alt="organization.name"
    />

    <template slot="menu-left" v-for="(module, idx) in modules">
      <template v-if="!module.submodules">
        <el-menu-item
          v-if="$isAllowed(module.allowed)"
          :key="idx"
          :index="module.index"
          :route="{ name: module.link }"
        >
          <i class="material-icons">{{ module.icon }}</i>
          <span class="hide-small-screen">{{
            $t(module.title_key) | upperFirst
          }}</span>
        </el-menu-item>
      </template>
      <template v-else>
        <el-submenu :key="idx" :index="module.index">
          <template slot="title">
            <i class="material-icons">{{ module.icon }}</i>
            <span class="hide-small-screen">{{
              $t(module.title_key) | upperFirst
            }}</span>
          </template>
          <template v-for="(submodule, idxc) in module.submodules">
            <el-menu-item
              v-if="$isAllowed(submodule.allowed)"
              :key="idxc"
              :index="submodule.index"
              :route="{ name: submodule.link }"
            >
              <i class="material-icons">{{ submodule.icon }}</i>
              <span>{{ $t(submodule.title_key) | upperFirst }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </template>

    <!-- Date Picker -->
    <portal-target name="search" class="menu-placeholder">
      <date-picker
        v-show="datePicker.display"
        :click="datePicker.clickHandle"
      />
    </portal-target>

    <el-menu-item
      slot="menu-right"
      index="201"
      :route="{ name: 'notification' }"
    >
      <el-badge
        v-if="notificationCount > 0"
        class="notification-badge"
        :value="notificationCount"
      >
        <i class="material-icons">notifications_active</i>
      </el-badge>
      <i v-else class="material-icons">notifications</i>
    </el-menu-item>
  </base-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import DatePicker from '@/components/DatePicker';
import BaseMenu from '@/components/Menu';
import logo from '@/images/logitricks_logo.png';

export default {
  components: {
    BaseMenu,
    DatePicker,
    PortalTarget,
  },
  computed: {
    ...mapGetters(['organization', 'notificationCount', 'datePicker']),
    modules() {
      return [
        {
          id: 'search',
          index: '2',
          title_key: 'components.Menu.search',
          link: 'search',
          icon: 'search',
          allowed: 'search',
        },
        {
          id: 'intake',
          index: '3',
          title_key: 'commons.collection.plural',
          link: 'indexIntake',
          icon: 'move_to_inbox',
          allowed: 'intake',
        },
        {
          id: 'route',
          index: '4',
          title_key: 'commons.route.plural',
          link: 'routing',
          icon: 'map',
          allowed: 'route',
        },
        // {
        //   id: 'appointment',
        //   index: '4',
        //   title_key: 'commons.appointment.plural',
        //   link: 'appointments',
        //   icon: 'event',
        // },
        {
          id: 'tracking',
          index: '5',
          title_key: 'components.Menu.follow',
          link: 'trackPage',
          icon: 'track_changes',
          allowed: 'tracking',
        },
        {
          id: 'import',
          index: '7',
          title_key: 'commons.import',
          link: 'selectClient',
          icon: 'cloud_upload',
          allowed: 'upload',
        },
        {
          id: 'parameters',
          index: '6',
          title_key: 'commons.param.plural',
          icon: 'settings',
          submodules: [
            {
              id: 'organization',
              index: '6-1',
              title_key: 'commons.organization',
              link: 'indexOrganization',
              icon: 'business_center',
              allowed: 'organization',
            },
            {
              id: 'place',
              index: '6-2',
              title_key: 'commons.warehouse.plural',
              link: 'indexPlace',
              icon: 'location_city',
              allowed: 'place',
            },
            {
              id: 'client',
              index: '6-3',
              title_key: 'commons.customer.plural',
              link: 'indexClient',
              icon: 'account_box',
              allowed: 'client',
            },
            {
              id: 'user',
              index: '6-4',
              title_key: 'commons.user.plural',
              link: 'indexUser',
              icon: 'people',
              allowed: 'user',
            },
            {
              id: 'resource',
              index: '6-5',
              title_key: 'commons.resource.plural',
              link: 'indexResource',
              icon: 'person_pin',
              allowed: 'resource',
            },
            {
              id: 'resource',
              index: '5-6',
              title_key: 'commons.vehicle.plural',
              link: 'indexVehicle',
              icon: 'local_shipping',
              allowed: 'vehicle',
            },
            {
              id: 'role',
              index: '5-7',
              title_key: 'commons.role.plural',
              link: 'indexRole',
              icon: 'perm_contact_calendar',
              allowed: 'role',
            },
          ],
        },
      ];
    },
    logo() {
      return this.organization.image || logo;
    },
  },
};
</script>
