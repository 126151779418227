<template>
  <base-layout :load="loading">
    <h3 v-if="client" slot="title">
      {{ $t('commons.customer.single') }} : {{ client.name }}
    </h3>

    <client-form
      v-if="client"
      ref="form"
      @validate="isValid = $event"
      @changeTab="changeActiveTab"
      :client="client"
    >
      <div v-if="activeTab == 'config'">
        <el-row :gutter="60">
          <el-col
            :xs="{ span: 11, offset: 0 }"
            :sm="{ span: 11, offset: 0 }"
            :md="{ span: 8, offset: 3 }"
            :lg="{ span: 8, offset: 4 }"
          >
            <el-form-item
              :label="$t('pages.client.import')"
              prop="import_format"
            >
              <el-select
                v-if="initData"
                id="import"
                v-model="client.import_format"
                name="import_format"
                :placeholder="$t('commons.selected')"
              >
                <el-option
                  v-for="(item, index) in initData.options.imports"
                  :key="index"
                  :value="index"
                  :label="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item :label="$t('pages.client.report')" prop="report_type">
              <el-select
                v-if="initData"
                id="report_type"
                v-model="client.report_type"
                name="report_type"
                :placeholder="$t('commons.selected')"
              >
                <el-option
                  v-for="(item, index) in initData.options.reports.type"
                  :key="index"
                  :value="index"
                  :label="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xs="{ span: 11, offset: 0 }"
            :sm="{ span: 11, offset: 0 }"
            :md="{ span: 8, offset: 3 }"
            :lg="{ span: 8, offset: 4 }"
            style="padding: 0px 13px"
          >
            <el-form-item
              :label="$t('pages.client.importerLimit')"
              prop="importerLimit"
            >
              <el-input-number
                v-model="client.importerLimit"
                :min="300"
                :max="10000"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            v-show="client.report_type == 'ftp'"
            :sm="{ span: 24, offset: 0 }"
            :md="{ span: 10, offset: 11 }"
            :lg="{ span: 8, offset: 12 }"
          >
            <div class="panel panel-default">
              <h3 class="panel-heading">
                {{ $t('pages.client.ClientForm.infosFtpConnect') }}
              </h3>

              <div class="panel-body">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.sftpAddress')"
                      prop="ftp_host"
                    >
                      <el-input
                        id="ftp_host"
                        v-model="client.ftp_host"
                        name="ftp_host"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.sftpPort')"
                      prop="ftp_port"
                    >
                      <el-input
                        id="ftp_port"
                        v-model="client.ftp_port"
                        name="ftp_port"
                        min="0"
                        max="65535"
                        step="1"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.sftpUser')"
                      prop="ftp_username"
                    >
                      <el-input
                        id="ftp_clientname"
                        v-model="client.ftp_username"
                        autocomplete="off"
                        name="ftp_clientname"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.sftpPass')"
                      prop="ftp_password"
                    >
                      <el-input
                        id="ftp_password"
                        v-model="client.ftp_password"
                        autocomplete="off"
                        name="ftp_password"
                        type="password"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.sftpPath')"
                      prop="ftp_path"
                    >
                      <el-input
                        id="ftp_path"
                        v-model="client.ftp_path"
                        name="ftp_path"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.reportType')"
                      prop="report_format"
                    >
                      <el-select
                        v-if="initData"
                        id="report_format"
                        v-model="client.report_format"
                        name="report_format"
                        :placeholder="$t('commons.selected')"
                      >
                        <el-option
                          v-for="(item, index) in initData.options.reports
                            .format"
                          :key="index"
                          :value="index"
                          :label="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.ftpSsl')"
                      prop="ftp_ssl"
                    >
                      <el-checkbox
                        id="ftp_ssl"
                        v-model="client.ftp_ssl"
                        name="ftp_ssl"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('pages.client.ClientForm.ftpSslImplicit')"
                      prop="ftp_ssl_implicit"
                    >
                      <el-checkbox
                        id="ftp_ssl_implicit"
                        v-model="client.ftp_ssl_implicit"
                        name="ftp_ssl_implicit"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item
                    :label="$t('pages.client.ClientForm.ftps')"
                    prop="ftp_ftps"
                  >
                    <el-checkbox
                      id="ftp_ftps"
                      v-model="client.ftp_ftps"
                      name="ftp_ftps"
                    />
                  </el-form-item>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="activeTab == 'mobile'">
        <el-row :gutter="20">
          <el-col :md="{ offset: 3, span: 21 }" :lg="{ offset: 5, span: 19 }">
            <el-form-item :label="$t('pages.client.ClientForm.autoStatus')">
              <el-switch
                v-model="client.auto_state"
                :active-text="$t('commons.enable')"
                :inactive-text="$t('commons.disable')"
                @change="isValid = true"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </client-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="
        $router.push({
          name: 'adminEditOrganization',
          params: { id: $route.params.organization },
        })
      "
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      :disabled="!isValid"
      @click.stop="saveClient"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapActions } from 'vuex';
import ClientForm from '../client/forms/ClientForm';

export default {
  name: 'ClientEdit',
  components: { ClientForm },
  data: () => ({
    loading: false,
    saving: false,
    isValid: false,
    initData: undefined,
    activeTab: 'informations',
  }),
  computed: {
    client() {
      return this.$store.getters.clients.items[this.$route.params.id];
    },
  },
  async mounted() {
    this.loading = true;
    await this.get({
      type: 'client',
      id: this.$route.params.id,
    });
    const { data } = await this.axios.get('client/create');
    this.initData = data;
    this.loading = false;
  },
  methods: {
    ...mapActions(['get', 'patch']),
    async saveClient() {
      this.saving = true;
      await this.patch({
        type: 'client',
        id: this.client.id,
        data: this.client,
      });
      this.saving = false;
    },
    changeActiveTab(value) {
      this.activeTab = value;
    },
  },
};
</script>
