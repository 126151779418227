<template>
  <base-layout :load="loading">
    <h3 v-if="resource" slot="title">
      {{ $t('commons.resource.single') | upperFirst }}: {{ resource.name }}
    </h3>
    <resource-form
      v-if="resource"
      ref="form"
      :saving="saving"
      :resource="resource"
      :is-edit="true"
    />

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexResource' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="success"
      icon-type="material"
      icon="date_range"
      @click="$refs.form.visible = true"
    >
      {{ $t('components.shift.loadPlanning') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveResource"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ResourceForm from './forms/ResourceForm';

export default {
  components: { ResourceForm },
  data: () => ({
    loading: false,
    saving: false,
  }),
  computed: {
    ...mapGetters(['resources']),
    resource() {
      return this.resources.items[this.$route.params.id];
    },
  },
  created() {
    this.get({ type: 'resource', id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['get', 'patch']),
    saveResource() {
      this.$refs.form
        .validate()
        .then(() => {
          this.saving = true;
          return this.patch({
            type: 'resource',
            id: this.resource.id,
            data: this.resource,
          });
        })
        .then(() => {
          this.saving = false;
          this.$router.push({ name: 'indexResource' });
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
