import frElementLocale from 'element-ui/lib/locale/lang/fr';
import { fr } from 'date-fns/locale';

export default {
  ...frElementLocale,
  dateFns: fr,
  longDateFormat: 'EEEE d MMM y HH:mm',
  languages: {
    fr: 'français',
    en: 'anglais',
  },
  commons: {
    action: {
      choice: 'action | action | actions',
      plural: 'actions',
      single: 'action',
    },
    activity: {
      choice: 'livraison | livraison | livraisons',
      plural: 'livraisons',
      single: 'livraison',
      undetermined: 'livraison(s)',
    },
    add: 'ajouter',
    address: {
      plural: 'adresses',
      single: 'adresse',
      undetermined: 'adresse(s)',
    },
    affected: {
      female: 'affectée',
      male: 'affecté',
    },
    alert: {
      choice: 'aucune alerte | alerte | alertes',
      plural: 'alertes',
      single: 'alerte',
      undetermined: 'alerte(s)',
    },
    all: 'tous',
    agree: 'ok',
    and: 'et',
    appointment: {
      plural: 'rendez-vous',
      single: 'rendez-vous',
    },
    at: 'à',
    barcode: 'code-barre',
    batch: 'lots',
    before: 'avant',
    begin: 'début',
    beginDate: '@:commons.date de @:commons.begin',
    cancel: 'annuler',
    capacity: {
      plural: 'capacités',
      single: 'capacité',
      undetermined: 'capacité(s)',
    },
    city: 'ville',
    client: {
      plural: 'clients',
      single: 'client',
    },
    close: 'fermer',
    companyName: "nom d'entreprise",
    collection: {
      plural: 'réceptions',
      single: 'réception',
    },
    color: {
      single: 'couleur',
      plural: 'couleurs',
    },
    country: {
      choice: 'pays | pays',
      plural: 'pays',
      single: 'pays',
    },
    countryCode: 'code pays',
    create: 'créer',
    created_at: 'créée le',
    current: {
      female: 'courante',
      male: 'courant',
    },
    customer: {
      plural: 'clients',
      single: 'client',
    },
    dashboard: 'dashboard',
    dataOfficerEmail: 'email délégué à la protection des données',
    date: 'date',
    datePlanned: 'date plannifiée',
    dateSla: 'date du SLA',
    delete: 'supprimer',
    depot: {
      plural: 'dépôts',
      single: 'dépôt',
    },
    destination: 'destination',
    details: 'détails',
    disable: 'désactivé',
    dispatch: 'valider',
    distance: 'distance',
    driver: 'conducteur',
    duration: 'durée',
    edit: 'éditer',
    editName: '@:commons.edit le @:commons.name',
    editState: "@:commons.edit l'@:commons.state",
    enable: 'activé',
    end: 'fin',
    endDate: '@:commons.date de @:commons.end',
    engine: "moteur d'optimisation",
    error: 'erreur',
    externalId: '@:commons.id externe',
    externalData: 'infos additionnelles',
    newNote: 'nouvelle note',
    editNote: 'modifier la note',
    field: {
      plural: 'champs',
      single: 'champ',
    },
    fieldError: '@:commons.error dans le @:commons.field.single saisi',
    fieldEmpty: '@:commons.field.single non renseigné',
    first_name: 'prénom',
    french: 'français',
    from: 'du',
    gantt: 'gantt',
    generalInfos: '@:commons.infos générales',
    geocoder: 'geocoder',
    hide: 'masquer',
    hotlineEmail: 'email du service client',
    hotlinePhone: 'téléphone du service client',
    hour: {
      plural: 'heures',
      single: 'heure',
    },
    id: 'identifiant',
    image: 'image',
    import: 'importer',
    infos: 'informations',
    invalideName: '@:commons.name invalide',
    item: {
      choice: '@:commons.item.single | @:commons.item.plural',
      plural: 'colis',
      single: 'colis',
      undetermined: 'colis',
    },
    item_count: 'nombre de @:commons.item.undetermined',
    km: 'km',
    latitude: 'latitude',
    longitude: 'longitude',
    languages: 'languages',
    last_name: 'nom',
    list: 'liste',
    loading: 'chargement',
    locked: {
      female: {
        plural: 'verrouillées',
        single: 'verrouillée',
      },
      male: {
        plural: 'verrouillés',
        single: 'verrouillé',
      },
    },
    logo: 'logo',
    mail: 'email',
    map: 'carte',
    minutes: 'minutes',
    mobilityId: '@:commons.id mobilité',
    module: {
      plural: 'modules',
      single: 'module',
    },
    name: 'nom',
    need: {
      plural: 'besoins',
      single: 'besoin',
    },
    new: {
      female: {
        plural: 'nouvelles',
        single: 'nouvelle',
      },
      male: {
        plural: 'nouveaux',
        single: 'nouveau',
      },
    },
    newTab: 'ouvrir dans un nouvel onglet (Beta)',
    no: {
      female: {
        plural: 'aucunes',
        single: 'aucune',
      },
      male: {
        plural: 'aucuns',
        single: 'aucun',
      },
    },
    noData: '@:commons.none.female donnée',
    none: {
      female: 'aucune',
      male: 'aucun',
    },
    not: 'non',
    note: 'note',
    optimize: 'optimiser',
    optimization: {
      plural: 'optimisations',
      single: 'optimisation',
    },
    option: {
      plural: 'options',
      single: 'option',
    },
    order: 'ordre',
    organization: 'organisation',
    overtime: 'heures supplémentaires',
    param: {
      plural: 'paramètres',
      single: 'paramètre',
    },
    password: 'mot de passe',
    phone: 'téléphone',
    place: {
      choice: 'lieu | lieux',
      plural: 'lieux',
      single: 'lieu',
      start: 'départ',
      end: 'arrivée',
    },
    planned: {
      choice: {
        female: 'planifiée | planifiée | planifiées',
        male: 'planifié | planifié | planifiés',
      },
      female: {
        plural: 'planifiées',
        single: 'planifiée',
      },
      male: {
        plural: 'planifiés',
        single: 'planifié',
      },
      undetermined: 'planifiée(s)',
    },
    plannedActivity:
      '@:commons.activity.plural @:commons.planned.female.plural',
    planned_end: 'date de fin',
    planned_start: 'date de début',
    planning: 'prévue',
    planningDate: '@:commons.date de planification',
    pod: 'preuve de livraison',
    points: 'points',
    postcode: 'code postal',
    quantity: 'quantité',
    range: 'plage',
    reason: 'raison',
    reasons: {
      away: 'parti',
      closed: 'fermé',
      damaged: 'endomagée',
      holidays: 'vacances',
      moved: 'déplacée',
      new_place: 'nouvelle adresse',
      partial: '@:commons.activity.single partielle',
      refused_msg: 'refusée',
      too_late: 'trop tard',
      too_long: 'trop longue',
      unknown: 'inconnue',
      wait_proof: 'en attente de preuve',
      weekend: 'weekend',
      wrong_place: 'mauvaise adresse',
    },
    recenter: 'recentrer',
    recipient: 'destinataire',
    remove: 'supprimer',
    reorganize: 'réorganiser',
    replan: 'replanifier',
    repository: 'référentiel',
    required: {
      female: 'requise',
      male: 'requis',
    },
    resource: {
      plural: 'ressources',
      single: 'ressource',
    },
    resourcedetails: '@:commons.details de la @:commons.resource.single',
    return: 'retour',
    role: {
      plural: 'rôles',
      single: 'rôle',
    },
    route: {
      choice: 'tournée |tournée | tournées',
      plural: 'tournées',
      single: 'tournée',
      undetermined: 'tournée(s)',
    },
    routedetails: '@:commons.details de la @:commons.route.single',
    save: 'enregistrer',
    saveProgress: 'enregistrement en cours',
    search: 'rechercher',
    secondaryAddress: '@:commons.address.single secondaire',
    seconds: 'secondes',
    select: 'sélectionner',
    selectAll: 'sélectionner tout',
    selected: 'sélectionné',
    selectResource: '@:commons.select la @:commons.resource.single',
    selection: 'sélection',
    settings: 'configuration',
    show: 'afficher',
    showChild: 'afficher replanifiée',
    showParent: 'afficher origine',
    showHide: '@:commons.show/@:commons.hide',
    siret: 'siret',
    sla: {
      plural: 'SLAs',
      single: 'SLA',
    },
    start: 'départ',
    start_place: 'lieu de départ',
    end_place: "lieu d'arrivée",
    startWarehouse: '@:commons.warehouse.single de @:commons.start',
    state: 'état',
    state_history: 'historique',
    states: {
      delivery: {
        awaiting: 'en attente',
        cleared: 'soldée',
        done: 'livrée',
        en_route: 'en route',
        failed: 'non livrée',
        finished: 'terminée',
        incoming: 'entrant',
        loaded: 'chargée',
        loading: 'en chargement',
        not_received: 'non reçue',
        on_site: 'sur site',
        prepared: 'préparée',
        preparing: 'en préparation',
        ready: 'prête',
        received: 'reçue',
        reception_started: 'en réception',
        refused: 'refusée',
        returned: 'retournée',
        replaned: 'replanifiée',
      },
      intake: {
        arrived: 'arrivée',
        completed: 'terminée',
        incoming: 'en attente de réception',
        started: 'démarrée',
        uploading: 'téléchargement',
      },
      item: {
        awaiting: 'en attente',
        cleared: 'soldé',
        done: 'livré',
        failed: 'non livré',
        finished: 'terminé',
        incoming: 'entrant',
        loaded: 'chargé',
        lost: 'perdu',
        not_received: 'non reçu',
        prepared: 'préparé',
        ready: 'prêt',
        received: 'reçu',
        refused: 'refusé',
        returned: 'retourné',
      },
      route: {
        created: 'créée',
        done: 'terminée',
        finished: 'cloturée',
        in_progress: 'en cours',
        loaded: 'chargée',
        loading: 'en chargement',
        paused: 'en pause',
        prepared: 'préparée',
        preparing: 'en préparation',
        ready: 'prête',
      },
      optimization: {
        new: 'nouvelle',
        canceled: 'annulée',
        created: 'créée',
        ended: 'finie',
        failed: 'échouée',
        resolved: 'résolue',
        running: 'en cours',
      },
    },
    street: 'rue',
    streetName: '@:commons.name de @:commons.street',
    streetNumber: 'numéro de @:commons.street',
    street_name: 'rue',
    street_number: 'numéro de rue',
    summary: 'résumé',
    survey: 'enquête de satisfaction',
    time: {
      days: 'jour | jours',
      hours: 'heure | heures',
      minutes: 'minute | minutes',
      seconds: 'second | seconds',
    },
    to: 'au',
    toEmpty: 'vider',
    tool: {
      plural: 'outils',
      single: 'outil',
    },
    total: 'total',
    transitions: {
      delivery: {
        arrive: 'sur site',
        await: 'en attente',
        clear: 'soldée',
        do: 'livrée',
        end_loading: 'chargée',
        end_prepare: 'préparée',
        fail: 'non livrée',
        finish: 'terminée',
        go: 'en route',
        not_receive: 'non reçue',
        receive: 'reçue',
        receiving: 'en réception',
        refuse: 'refusée',
        return: 'retournée',
        start_loading: 'en chargement',
        start_prepare: 'en préparation',
      },
      intake: {
        arrive: 'arrivée',
        end: 'terminée',
        start: 'démarrée',
        uploaded: 'en attente de réception',
      },
      parcel: {
        clear: 'soldé',
        do: 'livré',
        fail: 'non livré',
        finish: 'terminé',
        loade: 'chargé',
        not_receive: 'non reçu',
        prepare: 'préparé',
        receive: 'reçu',
        refus: 'refusé',
        return: 'retourné',
        set_lost: 'perdu',
      },
      route: {
        do: 'terminée',
        end_loading: 'chargée',
        end_prepare: 'préparée',
        finish: 'cloturée',
        pause: 'en pause',
        resume: 'reprendre',
        set_created: 'créée',
        set_ready: 'prête',
        start: 'démarrée',
        start_loading: 'en chargement',
        start_prepare: 'en préparation',
      },
    },
    type: 'type',
    unassigned: '@:commons.not @:commons.affected.female',
    unplanned: {
      choice: {
        female: 'non planifiée | non planifiée | non planifiées',
        male: 'non planifié | non planifié | non planifiés',
      },
      female: {
        plural: 'non planifiées',
        single: 'non planifiée',
      },
      male: {
        plural: 'non planifiés',
        single: 'non planifié',
      },
      undetermined: 'non planifiée(s)',
    },
    unselect: 'désélectionner',
    unselectAll: 'désélectionner tout',
    upload: 'envoyer',
    used: {
      choice: {
        female: 'utilisée | utilisée | utilisées',
        male: 'utilisé | utilisé | utilisés',
      },
      female: {
        plural: 'utilisées',
        single: 'utilisée',
      },
      male: {
        plural: 'utilisés',
        single: 'utilisé',
      },
      undetermined: 'utilisée(s)',
    },
    unused: {
      choice: {
        female: 'non utilisée | non utilisée | non utilisées',
        male: 'non utilisé | non utilisé | non utilisés',
      },
      female: {
        plural: 'non utilisées',
        single: 'non utilisée',
      },
      male: {
        plural: 'non utilisés',
        single: 'non utilisé',
      },
      undetermined: 'non utilisée(s)',
    },
    user: {
      plural: 'utilisateurs',
      single: 'utilisateur',
    },
    validate: 'valider',
    validateField: '@:commons.field.single {field} @:commons.required.male',
    value: {
      plural: 'valeurs',
      single: 'valeur',
    },
    vehicle: {
      plural: 'véhicules',
      single: 'véhicule',
    },
    view: 'visualiser',
    volume: 'volume',
    wantedTo: 'souhaitez-vous',
    warehouse: {
      plural: 'entrepôts',
      single: 'entrepôt',
    },
    warning: 'attention',
    week_days: {
      fri: 'vendredi',
      mon: 'lundi',
      sat: 'samedi',
      sun: 'dimanche',
      thur: 'jeudi',
      tue: 'mardi',
      wed: 'mercredi',
    },
    weight: 'poids',
    when: 'quand',
    where: 'où',
    workflow: 'workflow',
    yes: 'oui',
    zoom_in: 'zoomer',
    zoom_out: 'dézoomer',
  },
  sentence: {
    addressFound: '@:commons.address.single trouvée',
    addressOrigin: '@:commons.address.single fournie',
    addName: '@:commons.add un nom',
    changeResource: 'modifier la @:commons.resource.single',
    changeResourceForRoute:
      'sélectionnez une @:commons.resource.single pour la @:commons.route.single {0}',
    createRoute: '@:commons.create des @:commons.route.plural',
    createOptimization:
      '@:commons.new.female.single @:commons.optimization.single',
    noOptimization: '@:commons.none.female @:commons.optimization.single',
    organizationList: 'Liste des organisations',
    editDuration: '@:commons.edit la @:commons.duration',
    geocodeSuccess: "l'@:commons.address.single a été trouvée avec succès",
    goToActivity: 'voir la @:commons.activity.single',
    goToRoute: 'voir la @:commons.route.single',
    modifyPassword: 'modifier le mot de passe',
    manualGeocode: '@:commons.edit le géocodage manuellement',
    noRouteCreated: "aucune @:commons.route.single n'a encore été créée.",
    noTransition: '@:commons.no.female.single transition possible',
    rdvDate: 'date de @:commons.appointment.single',
    rdvTime: 'heure de @:commons.appointment.single',
    receiveAll: 'tout recevoir',
    replanDate: 'date de replanification',
    replanDesc:
      'merci de selectioner la date à laquelle cette @:commons.activity.single doit être replanifiée.',
    replanWarn:
      'tous les colis seront transferés vers la nouvelle @:commons.activity.single',
    savePaper: 'économiser du papier',
    selectDriver: 'choix du chauffeur',
    selectResource: 'choix de la @:commons.resource.single',
    selectClientUpload:
      '@:commons.select le @:commons.customer.single pour lequel les données seront importées',
    endPlaceNotStartPlace: "lieu d'arrivé différent du lieu de départ",
    toOptimize: 'Liste à optimiser',
    wantedToDeleteOrg:
      "@:commons.wantedTo vraiment @:commons.delete l'@:commons.organization {name} ?",
  },
  role: {
    advise: '@:role.abilityTitle conseillées',
    abilityTitle: 'permissions',
    abilityHeader: {
      create: 'création',
      destroy: 'suppression',
      read: 'lecture',
      update: 'mise à jour',
    },
    ability: {
      client: '@:commons.client.single',
      delivery: '@:commons.activity.single',
      intake: '@:commons.collection.single',
      item: '@:commons.item.single',
      organization: '@:commons.organization',
      place: '@:commons.depot.single',
      resource: '@:commons.resource.single',
      role: '@:commons.role.single',
      route: '@:commons.route.single',
      user: '@:commons.user.single',
      vehicle: '@:commons.vehicle.single',
    },
    create: 'créer un role',
    moduleTitle: 'Modules',
    module: {
      client: {
        title: 'module @:commons.client.single',
        description:
          "permet d'accéder à la page de gestion des @:commons.client.plural",
      },
      intake: {
        title: 'module @:commons.collection.single',
        description:
          "permet d'accéder à la page des @:commons.collection.plural",
      },
      mobile: {
        title: 'module app mobile',
        description: "acces à l'app mobile",
      },
      organization: {
        title: 'module @:commons.organization',
        description:
          "permet d'accéder à la page de gestion de votre @:commons.organization",
      },
      place: {
        title: 'module @:commons.depot.single',
        description:
          "permet d'accéder à la page de gestion des @:commons.depot.plural",
      },
      resource: {
        title: 'module @:commons.resource.single',
        description:
          "permet d'accéder à la page de gestion des @:commons.resource.plural",
      },
      role: {
        title: 'module @:commons.role.single',
        description:
          "permet d'accéder à la page de gestion des @:commons.role.plural",
      },
      route: {
        title: 'module @:commons.route.single',
        description: "permet d'accéder à la page des @:commons.route.plural",
      },
      search: {
        title: 'module recherche',
        description:
          "permet d'accéder à la page de recherche des @:commons.activity.plural",
      },
      statistics: {
        title: 'module statistiques',
        description: "permet d'accéder à la page des statistiques",
      },
      tracking: {
        title: 'module suivi',
        description: "permet d'accéder à la page de suivi",
      },
      upload: {
        title: 'module upload',
        description: "permet d'accéder à la page d'upload de données",
      },
      user: {
        title: 'module @:commons.user.single',
        description:
          "permet d'accéder à la page de gestion des @:commons.user.plural",
      },
      vehicle: {
        title: 'module @:commons.vehicle.single',
        description:
          "permet d'accéder à la page de gestion des @:commons.vehicle.plural",
      },
    },
    name: 'entrer le nom du role',
    ownRoleWarning: {
      description:
        'faites attention lorsque vous modifiez votre propre @:commons.role.single car vous pourriez désactiver votre propre accès.',
      title: 'vous avez ce @:commons.role.single',
    },
  },
  components: {
    BarcodeScanner: {
      activate: 'activer la douchette',
      transfer: 'transfert en cours',
    },
    ConstraintList: {
      capacities: {
        title: '@:commons.capacity.plural',
        create:
          '@:commons.create une @:commons.new.female.single @:commons.capacity.single',
        labelType: '@:commons.name de @:commons.capacity.single',
        labelValue: '@:commons.value.single @:commons.capacity.single',
      },
      consist_of: {
        title: '@:commons.need.plural',
        create:
          '@:commons.create un @:commons.new.male.single @:commons.need.single',
        labelType: '@:commons.name de @:commons.need.single',
        labelValue: '@:commons.value.single @:commons.need.single',
      },
      tools: {
        title: '@:commons.tool.plural',
        create: '@:commons.create un nouvel @:commons.tool.single',
        labelType: "@:commons.name d'@:commons.tool.single",
      },
      error: 'cette entrée existe déjà',
    },
    DatePicker: {
      currentWeek: 'cette semaine',
      refresh: 'rafraîchir',
      today: "aujourd'hui",
      tomorrow: 'demain',
    },
    Filter: {
      caseSensitiveOff: 'insensible à la casse',
      caseSensitiveOn: 'sensible à la casse',
      filter: 'filtre',
      filterError: '@:commons.error dans le @:components.Filter.filter',
      filterErrorText: 'veuillez renseigner une valeur',
      items: {
        activity: '@:commons.activity.single',
        all: 'tous',
        capacities: '@:commons.capacity.plural',
        city: '@:commons.city',
        consist_of: '@:commons.need.plural',
        country: '@:commons.country.single',
        country_code: '@:commons.countryCode',
        deliveries: '@:commons.activity.plural',
        depot: '@:commons.depot.single',
        distance: '@:commons.distance',
        duration: '@:commons.duration',
        email: '@:commons.mail',
        external_id: '@:commons.external_id',
        first_name: '@:commons.first_name',
        item_count: '@:commons.item_count',
        last_name: '@:commons.last_name',
        name: '@:commons.name',
        order: '@:commons.order',
        place: '@:commons.place.single',
        planned_end: '@:commons.planned_end',
        planned_start: '@:commons.planned_start',
        postcode: '@:commons.postcode',
        resource: '@:commons.resource.single',
        route: '@:commons.route.single',
        slas: '@:commons.sla.plural',
        state: '@:commons.state',
        street_name: '@:commons.street_name',
        street_number: '@:commons.street_number',
        tools: '@:commons.tool.plural',
        type: '@:commons.type',
        user: '@:commons.user.single',
        value: '@:commons.value.single',
        vehicle: '@:commons.vehicle.single',
      },
      operators: {
        EQ: 'égal',
        GT: 'plus que',
        GTE: 'plus ou égal à',
        LT: 'moins que',
        LTE: 'moins ou égal à',
        NEQ: "n'est pas égal",
        between: 'entre',
        endsWith: 'finis par',
        equals: 'égal',
        includes: 'inclus',
        intersect: 'recoupe',
        notBetween: 'pas entre',
        notEndsWith: 'ne finis pas par',
        notEquals: "n'est pas égal",
        notIncludes: "n'inclus pas",
        notIntersect: 'ne recoupe pas',
        notStartsWith: 'ne commence pas par',
        notWithin: 'en dehors',
        startsWith: 'commence par',
        within: 'dans',
      },
    },
    GeocodeGate: {
      addressFound: '@:commons.address.undetermined trouvée(s)',
      addressList: '@:commons.list des @:commons.address.plural',
      addressProvided: '@:commons.address.undetermined fournie(s)',
      confidence: 'note de confiance',
      defectiveAddress: '{0} @:commons.address.plural sont défectueuses.',
      editAddress: "editez l'@:commons.address.single",
      editPointList:
        'merci de bien vouloir @:commons.edit la @:commons.list de @:commons.points suivants.',
      geocode: 'géocoder',
      locate: 'localiser',
      noMatchFound: '@:commons.no.female.single correspondance trouvée',
      noResult: '@:commons.no.male.single résultat',
      notFound: 'aucune correspondance trouvée',
      resultTitle: 'résultat du géocodage',
      validateAddress: "@:commons.validate l'@:commons.address.single",
      validateAll: '@:commons.validate tout',
    },
    Menu: {
      search: '@:commons.search',
      follow: 'suivi',
      logout: 'déconnexion',
      notification: 'notifications',
    },
    RouteBatchCreator: {
      datePlaceholder: 'sélectionner une plage',
      periodGenerateRoute: 'période de génération des @:commons.route.plural',
      resourceForRouteGen:
        '@:commons.resource.plural pour lesquelles les @:commons.route.plural seront générées',
      selectStartDepot:
        '@:commons.selected un @:commons.warehouse.single de @:commons.start',
      selectWarehouse: "@:commons.selected l'@:commons.warehouse.single",
    },
    RouteBatchValidator: {
      resourceForValidation:
        'sélectionner les @:commons.route.plural qui seront validées',
    },
    selection: {
      remove:
        'retirer la @:commons.activity.single de la @:commons.route.single | retirer les {count} @:commons.activity.plural de la @:commons.route.single',
    },
    calendar: {
      addShift: 'ajouter une plage horaire',
      selectShift: 'selectioner une @:commons.range horaire',
      selectDay: 'selectioner un jour',
    },
    XlsxImporter: {
      label: {
        external_id: 'identifiant unique',
        duration: 'durée',
        item_count: 'quantité',
        date: 'date de @:commons.activity.single',
        slas: 'slas',
        street_number: 'numéro de rue',
        street_name: 'rue',
        secondary: 'adresse secondaire',
        city: 'ville',
        postcode: 'code postal',
        country: 'pays',
        country_code: 'code pays',
        default_country_code: 'code pays par défaut',
        latitude: 'latitude',
        longitude: 'longitude',
        consist_of: 'contraintes',
        tools: 'outils',
        receiver_name: 'destinataire',
        receiver_phone: 'téléphone',
        email: 'email',
      },
      desc: {
        external_id:
          "Référence unique de la @:commons.activity.single . Il permet de retrouver facilement une @:commons.activity.single et d'éviter les doublons",
        duration: 'Durée de la @:commons.activity.single',
        item_count:
          'Quantité de objets/colis associés à la @:commons.activity.single',
        date: 'La date à laquelle la @:commons.activity.single doit être effectuée',
        slas: 'Période pendant laquelle la @:commons.activity.single peut être effectuée. Cette valeur remplace le champ date',
        street_number: 'numéro de rue',
        street_name: 'nom de rue',
        secondary: "Autre information sur l'adresse",
        city: 'nom de la ville',
        postcode: 'code postal',
        country: 'nom du pays',
        country_code: 'code pays (ex: FR, EN, IT...)',
        default_country_code: 'Code pays par défaut lorsque le champs est vide',
        latitude: 'Point GPS: latitude',
        longitude: 'Point GPS: longitude',
        consist_of: 'Contraintes associées avec la @:commons.activity.single',
        tools: 'Outils nécéssaires pour effectuer la @:commons.activity.single',
        receiver_name: 'Nom du destinataire',
        receiver_phone: 'Téléphone du destinataire',
        email: 'Email du destinataire',
      },
      sample: "fichier d'exemple",
      addFile: 'ajouter un fichier',
      successCount: '{count} @:commons.activity.plural importées avec succès',
      errorCount: '{count} @:commons.activity.plural en erreurs',
      uploading: 'envois des données',
      new: 'nouvel import',
      title: 'importer des données',
      mappingTitle: 'identifier les colonnes',
      accepted: 'fichiers acceptés',
      dragNdrop: 'déposez un fichier ici ou',
      upload: 'cliquez pour upload',
      done: 'fichier traité',
      fileUpload: 'uploader un fichier',
      fields: 'champs',
      columnMatch: 'colonne équivalente',
      parsers: 'nombre de séparateurs ?',
      proposition: 'ordre des séparateurs, éditable',
      preview: 'prévisualisation',
      maxrow: 'Nombre de lignes max',
    },
    entities: {
      Constraints: {
        toolsPerk: '@:commons.tool.plural & compétences',
      },
      ResourceDetails: {
        createResource: '@:commons.create une @:commons.resource.single',
        selectDriver: '@:commons.selected le @:commons.driver',
        selectVehicle: '@:commons.selected le @:commons.vehicle.single',
        selectPlace: '@:commons.selected @:commons.depot.single',
      },
      activityDetails: {
        description: 'description',
        moreInfos: '@:commons.infos',
        recipientName: '@:commons.name du @:commons.recipient',
        recipientPhone: 'téléphone du @:commons.recipient',
      },
    },
    optim: {
      activityPlanned:
        '@:commons.activity.plural @:commons.planned.female.plural',
      addToOptim: "@:commons.add les éléments selectionnés à l'optimisation",
      applyOptim: "appliquer l'@:commons.optimization.single",
      currentUsedRoutes:
        'moyenne de @:commons.activity.plural par @:commons.route.plural',
      deleteOptim:
        "@:commons.wantedTo vraiment @:commons.remove l'@:commons.optimization.single",
      delta: 'delta',
      errorOptim: "@:commons.error d'@:commons.optimization.single",
      globalUsage: "taux d'utilisation des @:commons.route.plural",
      inSla: 'respect du @:commons.sla.single',
      inAppointment: 'respect du @:commons.appointment.single',
      minOneActivity:
        'vous devez @:commons.select au moins une @:commons.activity.single',
      minOneRoute:
        'vous devez @:commons.select au moins une @:commons.route.single',
      nameNotEmpty: 'vous devez entrer un nom',
      optimSolved: '@:commons.optimization.single résolue',
      optimView: "voir l'@:commons.optimization.single",
      outDeplanned:
        '@:commons.activity.undetermined déplanifier du @:commons.current',
      outReplanned:
        '@:commons.activity.undetermined @:commons.planned.undetermined depuis le restant',
      removeFromOptim: "supprimer les éléments selectionnés de l'optimisation",
      removeOptim: "supprimer l'@:commons.optimization.single",
      routeList: '@:commons.list des @:commons.route.plural',
      routeUsage: "taux d'utilisation moyen",
      routeUsed: '@:commons.route.plural utilisées',
      sended: 'envoyer',
      totalDist: '@:commons.distance totale (approx.)',
      totalDuration: '@:commons.duration totale (approx.)',
      totalDurationWork: '@:commons.duration totale de travail',
      totalTravelTime: '@:commons.duration totale de trajet (approx.)',
      unPlanned: '@:commons.not @:commons.planned.undetermined',
    },
    shift: {
      assignPlanning: 'assigner le @:commons.planning',
      assignResource: 'assigner aux @:commons.resource.plural',
      loadPlanning: 'charger un @:commons.planning',
    },
  },
  error: {
    invalid_credentials: 'email ou mot de passe invalide.',
    unauthorized: "Vous n'êtes pas autorisé(e) à faire cette action.",
    moduleUnauthorized:
      "Vous n'êtes pas autorisé(e) à accéder au module {0}. Veuillez contacter votre administrateur.",
    optim: {
      nameEmpty: "Veuillez ajouter un nom d'optimisation",
      noActivity: 'Veuillez ajouter au moins une @:commons.activity.single',
      tooManyActivities:
        'Nombre maximum de @:commons.activity.plural dépassé (maximum {0})',
      noRoute: 'Veuillez ajouter au moins une @:commons.route.single',
      routeNoStartPlace:
        'La @:commons.route.single {0} doit avoir une @:commons.start_place et une @:commons.end_place.',
    },
    password: {
      length: 'doit avoir entre 6 et 64 caractères',
      number: 'doit contenir 1 chiffre',
      upper: 'doit contenir 1 majuscule',
      lower: 'doit contenir 1 minuscule',
    },
    route: {
      locked: 'la tournée {0} est bloquée',
      noResource:
        "la tournée {0} n'a pas de @:commons.resource.single associée",
      notSaved: "la tournée {0} n'est pas encore sauvegardée",
      someNotReceived:
        'certaine @:commons.activity.single ne sont pas encore reçues',
      thisNoResource:
        "cette tournée n'a pas de @:commons.resource.single associée",
    },
    search: {
      unavailable: {
        title: 'recherche indisponible',
        description:
          "La recherche n'est pas disponible actuellement. Veuillez contacter votre administrateur.",
      },
    },
    shouldBeAfter:
      'la date sélectionné doit être postérieure à la date initiale.',
    shouldBeFuture: 'la date sélectionné doit être dans le futur',
    validator: {
      coord: {
        greater: '{name} ne peut être au-delà de {max}',
        lower: '{name} ne peut être en deçà de {min}',
      },
    },
  },
  pages: {
    HomePage: {
      generalKPI: 'indicateurs de performance généraux',
      incoming: 'entrant',
      started: 'commencé',
      summaryFrom: '@:commons.summary du',
      summaryOf: '@:commons.summary du',
      to: 'au',
      welcomeMessage: 'bienvenue sur votre dashboard',
    },
    LoginPage: {
      errorMessage:
        "une erreur s'est produite, impossible de se connecter avec ces informations d'identification.",
      loading: 'chargement',
      logIn: 'se connecter',
      welcome: 'bienvenue',
    },
    Notifications: {
      notificationNotFound: 'le panneau de notification est vide',
    },
    client: {
      create: '@:commons.create un @:commons.customer.single',
      ClientForm: {
        apiKey: 'clé API',
        config: 'configuration',
        configMobile: 'configuration mobile',
        configOther: 'configuration autre',
        configTech: 'configuration technique',
        optionnal: 'optionnel',
        autoStatus: 'status automatiques pendant la livraison',
        mandatory: 'requis',
        mobile: 'mobile',
        scanItems: 'scanner les colis',
        geolocation: 'géolocalisation',
        distance:
          "distance minimum d'une activité pour activer les actions liées a la géolocalisation",
        report: 'rapport',
        deliveryProof: 'preuve de livraison',
        defaultActivityPolicy: '@:commons.activity.single par défaut  à J+{0}',
        defaultDurationActivity:
          '@:commons.duration de @:commons.activity.single par défaut (en minutes)',
        itemReasons: "activer les motifs à propos des colis sur l'app mobile",
        activityReasons:
          "activer les motifs à propos des activitées sur l'app mobile",
        delayReason: "activer les motifs a propos des retards sur l'app mobile",
        infosFtpConnect: '@:commons.infos de connexion sFTP',
        lambdaName: '@:commons.name de la fonction Lambda personalisée',
        reportType: '@:commons.type de format de rapport',
        sftpAddress: 'url sFTP',
        sftpPass: 'mot de passe sFTP',
        sftpPort: 'port sFTP',
        sftpUser: "@:commons.name d'utilisateur sFTP",
        sftpPath: 'chemin sFTP',
        ftpSsl: 'SSL',
        ftpSslImplicit: 'SSL implicite',
        ftps: 'mode FTPS',
        same_day: 'à J',
        next_day: 'J+1',
        two_days: 'J+2',
        three_days: 'J+3',
        scanForPrepare:
          "Scanner les @:commons.item.plural lors de la préparation d'une @:commons.route.single",
        scanForLoad:
          "Scanner les @:commons.item.plural lors du chargement d'une @:commons.route.single",
        scanForDeliver: 'Scanner les @:commons.item.plural à la livraison',
        signatureProof: 'Demander une signature comme preuve de livraison',
        photoProof: 'Prendre une photo pour prouver la livraison',
      },
      IndexPage: {
        contact: 'contact',
        customerAdd: '@:commons.add un @:commons.customer.single',
        customerList: '@:commons.list des @:commons.customer.plural',
        wantedToDeleteCustomer:
          '@:commons.wantedTo vraiment @:commons.delete le @:commons.customer.single',
      },
      import: 'import',
      importerLimit: "limite d'activités pour l'import",
      importCanal: "canal d'importation",
      notifTexteDone: 'la clé a été copiée dans votre presse-papier',
      notifTexteError: "la clé n'a pas été copié dans votre presse-papier",
      notifTitleDone: 'copie réussie',
      report: 'rapport',
      reportCanal: 'canal de rapport',
    },
    intake: {
      EditIntake: {
        destination: 'destination',
      },
      IndexPage: {
        collectionNotFound:
          '@:commons.no.female.single @:commons.collection.single trouvée',
        from: 'du',
        of: 'du',
        selectAnotherDateRange:
          'veuillez @:commons.select une autre @:commons.range de @:commons.date',
        to: 'au',
      },
      wantedToDeleteIntake:
        '@:commons.wantedTo vraiment @:commons.delete la réception {0}',
    },
    organization: {
      tabs: {
        references: {
          title: '@:commons.repository',
        },
        details: {
          title: '@:commons.generalInfos',
        },
        planning: {
          title: '@:commons.planning',
        },
      },
      OrganizationShifts: {
        addShift:
          'veuillez saisir le @:commons.name du @:commons.planning @:commons.type',
        infoError: 'une @:commons.errror est survenu, veuillez réessayer',
        invalideName: '@:commons.name invalide',
      },
      myOrganization: 'mon organisation',
    },
    optimization: {
      traffic: {
        with: 'avec trafic',
        without: 'sans trafic',
      },
    },
    password: {
      passwordForgotten: 'mot de passe oublié ?',
      passwordReset: 'réinitialisation du mot de passe',
      resetLink: 'réinitialisation',
      successLink:
        'un email de réinitialisation vous a été envoyé, merci de suivre les instructions qui vous sont fournies',
      errorLink:
        'cet email ne correspond à aucun utilisateur, merci de le vérifier et recommencer.',
      noMatch: 'les deux mots de passe doivent être identiques',
      notLongEnough: 'le mot de passe doit comporter au moins 6 caractères.',
      newPassword: 'nouveau mot de passe',
      passwordConfirm: 'confirmer nouveau mot de passe',
    },
    place: {
      create: '@:commons.create un @:commons.warehouse.single',
      IndexPage: {
        wantedToDeleteWarehouse:
          "@:commons.wantedTo vraiment @:commons.delete l'@:commons.warehouse.single",
        warehouseAdd: '@:commons.add un @:commons.warehouse.single',
        warehouseList: '@:commons.list des @:commons.warehouse.plural',
      },
      PlaceForm: {
        number: 'numéro',
        secondaryAddress: '@:commons.address.single secondaire',
      },
      addressGeocoding:
        '@:commons.address.undetermined en attente de géocodage',
      spot: {
        type: {
          storage: 'Stockage',
          dock: 'Quai',
        },
        edit: 'Gérer les emplacements',
        level: {
          plural: 'niveaux',
          single: 'niveau',
          choice:
            '@:pages.place.spot.level.single | @:pages.place.spot.level.plural',
        },
        plural: 'emplacements',
        single: 'emplacement',
        undetermined: 'emplacement(s)',
        dock: {
          single: 'quai de chargement',
          plural: 'quais de chargement',
        },
        print: {
          all: 'Imprimer tous les code-barres',
          loading_dock: 'Imprimer les @:pages.place.spot.dock.plural',
          storage: 'Imprimer les @:pages.place.spot.plural',
        },
        error: {
          spotNotEmpty:
            'il y a des @:commons.item.plural dans cet @:pages.place.spot.single',
          columnLimit: 'impossible de créer plus de 999 colonnes.',
          levelsLimit:
            'impossible de créer plus de 99 @:pages.place.spot.level.plural',
          dockLimit:
            'impossible de créer plus de 99 @:pages.place.spot.dock.plural',
        },
      },
    },
    resource: {
      addResource: '@:commons.add une @:commons.resource.single',
      addVehicle: '@:commons.add un @:commons.vehicle.single',
      generateManyRoutes: 'générer des @:commons.route.plural',
      resourceList: '@:commons.list des @:commons.resource.plural',
      wantedToDeleteResource:
        '@:commons.wantedTo vraiment @:commons.delete la @:commons.resource.single',
    },
    role: {
      Client: 'client',
      Delivery: 'livraison',
      Intake: 'réception',
      Organization: 'organisation',
      Place: 'entrepôt',
      Resource: 'ressource',
      Role: 'rôle',
      Route: 'route',
      User: 'utilisateur',
      Vehicle: 'véhicule',
      addRole: '@:commons.add un @:commons.role.single',
      create: '@:commons.create',
      editRole: 'edition du @:commons.role.single',
      editUserRole: 'edition des @:commons.role.plural @:commons.user.plural',
      manage: 'gestion',
      manageClient: 'gestion des clients',
      manageDashboard: 'gestion du tableau de bord',
      manageDelivery: 'gestion des livraisons',
      manageIntake: 'gestion des réceptions',
      managePlace: 'gestion des entrepôts',
      manageResource: 'gestion des ressources',
      manageRole: 'gestion des rôles',
      manageRoute: 'gestion des routes',
      manageOrganization: 'gestion de mon organisation',
      manageOutstanding: 'gestion des retours',
      manageUser: 'gestion des utilisateurs',
      manageVehicle: 'gestion des véhicules',
      noPermission: '@:commons.no.female.single permission',
      roleList: '@:commons.list des @:commons.role.plural',
      uploadCsv: 'upload Csv',
      wantedToDeleteRole:
        '@:commons.wantedTo vraiment @:commons.delete le @:commons.role.single',
    },
    routes: {
      RoutePrint: {
        note: 'note',
        print: 'imprimer',
        proof: 'justificatif',
        required: 'requis',
      },
      activityRemaining: '@:commons.activity.plural restantes',
      components: {
        addRoute: '@:commons.add une @:commons.route.single',
        associateToRoute: 'associer à la @:commons.route',
        confirmChoice: 'valider le choix',
        currentSelection: '@:commons.selection @:commons.current.female',
        plannedFrom: '@:commons.planned.female.single du',
        removeFromSelect: 'retirer de la @:commons.selection',
        saveAllRoute: '@:commons.save definitivement la @:commons.route',
        saveCurrentChange: '@:commons.save la modifictaion en cours',
        showPointOnMap:
          '@:commons.show les @:commons.points sur la @:pages.routes.map',
        showTraceOnMap: '@:commons.show le tracé sur la @:pages.routes.map',
        to: 'au',
        wantedToDeleteRoute:
          '@:commons.wantedTo vraiment @:commons.delete le @:commons.route.single',
      },
      map: 'carte',
      removeSelection: 'vider la @:commons.selection',
    },
    track: {
      DashboardPage: {},
      RouteList: {
        toggleMarkers: 'afficher les @:commons.points',
        togglePolyline: 'afficher les @:commons.road.plurals',
        lassoSelection: '@:commons.selection au lasso',
        clusterView: 'vue en grappe',
        selectAll:
          "@:commons.add toutes les @:commons.route.plural à l'@:commons.optimization.single + Alt pour les @:commons.activity.plural",
        unselectAll:
          "@:commons.remove toutes les @:commons.route.plural à l'@:commons.optimization.single + Alt pour les @:commons.activity.plural",
        saveCurrentTheChange: 'sauvegarder la modification en cours',
      },
      TrackDetail: {
        followRoute: '@:components.Menu.follow',
      },
      TrackingPage: {
        checkInfoShowActivity:
          'cocher les @:commons.infos que vous souhaitez voir sur les @:commons.activity.plural',
        checkInfoShowRoute:
          'cocher les @:commons.infos que vous souhaitez voir sur les @:commons.route.plural',
        infosActivity: '@:commons.infos sur les @:commons.activity.plural',
        infosRoute: '@:commons.infos sur les @:commons.route.plural',
        recapRoute: 'récapitulatif @:commons.route.plural',
        showAllRoute: 'afficher le tracé de toutes les @:commons.route.plural',
        showAllTrace:
          'afficher les @:commons.points.plural de toutes les @:commons.route.plural',
        stateActivity: '@:commons.state @:commons.activity.single',
        totalActivity: 'total @:commons.activity.single',
      },
      anomaly: 'anomalies',
      closed: 'fermées',
      hourReal: 'réelle',
      hourValued: 'prévue',
      inRoute: 'en @:commons.route.single',
      late: 'en retard',
      loaded: 'chargées',
      loading: '@:pages.track.inProgress de chargement',
      prepared: 'préparées',
      preparing: '@:pages.track.inProgress de préparation',
      running: '@:pages.track.inProgress de livraison',
      slaEnd: '@:commons.end',
      slaStart: '@:commons.begin',
      outOfSla: 'hors @:commons.sla.single',
      outOfAppointment: 'hors @:commons.appointment.single',
      notes: 'notes',
    },
    user: {
      create: '@:commons.create un @:commons.user.single',
      IndexPage: {
        addUser: '@:commons.add un @:commons.user.single',
        removeUser:
          "@:commons.wantedTo vraiment @:commons.remove l' @:commons.user.single",
        userList: '@:commons.list des @:commons.user.plural',
      },
      UserForm: {
        firstName: 'prénom',
        password: 'mot de passe',
      },
    },
    vehicle: {
      create: '@:commons.create un @:commons.vehicle.single',
      IndexPage: {
        addVehicle: '@:commons.add un @:commons.vehicle.single',
        removeVehicle:
          '@:commons.wantedTo vraiment @:commons.remove le @:commons.vehicle.single',
        vehicleList: '@:commons.list des @:commons.vehicle.plural',
      },
      VehicleForm: {
        addCapacity: '@:commons.add une @:commons.capacity.single',
        addTool: '@:commons.add un @:commons.need.single',
        value: 'valeur',
      },
    },
  },
};
