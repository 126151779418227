<template lang="html">
  <el-dialog
    class="create-place-container"
    :append-to-body="true"
    :title="$t('components.GeocodeGate.editAddress') | upperFirst"
    :visible="visible"
    @close="close"
  >
    <el-row v-loading="loading">
      <el-row v-if="displayForm">
        <address-form ref="addressForm" v-model="address" />
      </el-row>
      <div v-else>
        <el-row>
          <el-col :span="12">
            <h4>
              {{ $t('sentence.addressOrigin') }}
              <span class="bullet-point">.</span>
              <el-tooltip
                class="btn-edit"
                :content="$t('components.GeocodeGate.editAddress')"
              >
                <i class="material-icons" @click="displayForm = true"
                  >mode_edit</i
                >
              </el-tooltip>
            </h4>

            <address-entity :address="address" />
          </el-col>
        </el-row>

        <el-row>
          <el-col> <hr class="separator" /> </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <h4>
              {{ $t('components.GeocodeGate.resultTitle')
              }}<span class="bullet-point">.</span>
            </h4>
            <address-entity v-if="placeGeocoded" :address="placeGeocoded" />
          </el-col>
          <el-col v-if="placeGeocoded" :span="12">
            <single-map
              draggable
              :position="position"
              @draggable="manualPosition"
            />
            {{ $t('components.GeocodeGate.confidence') }}:
            {{ placeGeocoded.score | round }} %
          </el-col>
        </el-row>
      </div>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <cs-button class="upperFirst" type="primary" @click="geocode">
        {{ $t('components.GeocodeGate.geocode') }}
      </cs-button>
      <cs-button
        v-show="!displayForm"
        class="upperFirst"
        type="success"
        @click.native="validate"
      >
        {{ $t('commons.save') }}
      </cs-button>
    </span>
  </el-dialog>
</template>

<script>
import { compact, round } from 'lodash';
import { mapActions } from 'vuex';
import { latLng } from 'leaflet';
import AddressEntity from './Address';
import SingleMap from './SingleMap';
import AddressForm from './AddressForm';
import api from './../services/api';

export default {
  components: {
    SingleMap,
    AddressEntity,
    AddressForm,
  },
  filters: {
    round: (v) => round(v),
  },
  props: {
    place: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
    },
    edition: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    address: {},
    displayForm: true,
    placeGeocoded: null,
  }),
  computed: {
    position() {
      return this.placeGeocoded &&
        this.placeGeocoded.latitude &&
        this.placeGeocoded.longitude
        ? latLng(this.placeGeocoded.latitude, this.placeGeocoded.longitude)
        : null;
    },
  },
  watch: {
    visible: function (newV, oldV) {
      if (!oldV && newV) {
        this.placeGeocoded = null;
        this.displayForm = this.edition;
      }
    },
  },
  mounted() {
    if (this.place) {
      this.address = this.place;
    }
    this.displayForm = this.edition;
  },
  methods: {
    ...mapActions(['post']),
    validate() {
      this.post({
        type: 'place',
        data: {
          ...this.placeGeocoded,
          type: 'private',
        },
      }).then((result) => {
        this.close(result);
      });
    },
    close(place) {
      this.$emit('close', place);
    },
    manualPosition(location) {
      this.placeGeocoded.latitude = location.lat;
      this.placeGeocoded.longitude = location.lng;
    },
    geocode() {
      api
        .geocode({
          type: '',
          data: {
            toGeocode: this.address,
          },
        })
        .then(({ data }) => {
          this.placeGeocoded = data;
          this.placeGeocoded.name = compact([
            data.street_number,
            data.street_name,
            data.postcode,
            data.city,
          ]).join(' ');
          this.placeGeocoded.is_geocoded = true;
        });
      this.displayForm = false;
    },
  },
};
</script>
<style lang="scss">
.create-place-container {
  .el-col {
    display: block !important;
  }
}
</style>
