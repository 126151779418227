<template>
  <div style="height: 100%">
    <menu-nav />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuNav from '@/components/admin/AdminMenu';

export default {
  components: { MenuNav },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {},
};
</script>
