import { render, staticRenderFns } from "./Sla.vue?vue&type=template&id=f2c83c4a&lang=html&"
import script from "./Sla.vue?vue&type=script&lang=js&"
export * from "./Sla.vue?vue&type=script&lang=js&"
import style0 from "./Sla.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports