<script>
import Info from './info/Info';
import Selection from './selection/Selection';
import Optimization from './optim/OptimList';

export default {
  functional: true,
  props: {
    type: {
      type: String,
      default: () => '',
    },
    unplannedRoute: {
      type: Object,
      default: () => ({}),
    },
    routes: {
      type: Array,
      default: () => [],
    },
    resources: {
      type: Object,
      default: () => ({}),
    },
  },
  render(createElement, context) {
    const { data } = context;
    data.props = {
      unplannedRoute: context.props.unplannedRoute,
      routes: context.props.routes,
    };

    const getSidebar = () => {
      if (context.props.type === 'info') {
        return Info;
      }
      if (context.props.type === 'selection') {
        return Selection;
      }
      if (context.props.type === 'optimization') {
        data.props.resources = context.props.resources;
        return Optimization;
      }
      return undefined;
    };

    return createElement(getSidebar(), data, context.children);
  },
};
</script>
