<template lang="html">
  <span>
    <el-popover ref="popover" placement="right" width="500" :trigger="trigger">
      <shift-type v-if="shifts" :editable="false" :planning="shifts">
      </shift-type>
    </el-popover>
    <cs-button
      v-popover:popover
      square
      icon-type="material"
      icon="date_range"
      type="info"
      :disabled="!shifts"
    />
  </span>
</template>

<script>
import ShiftType from './ShiftType';

export default {
  name: 'ShiftPopover',
  components: { ShiftType },
  props: {
    shifts: {
      type: Array,
      default: () => [],
    },
    trigger: {
      type: String,
      default: () => 'hover',
    },
  },
};
</script>
