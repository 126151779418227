<template lang="html">
  <section class="activity-content">
    <!-- when component -->
    <edit-details :title="$t('commons.when')">
      <div :style="{ width: '100%' }">
        <el-row type="flex">
          <el-col :span="6">
            <span class="left-key-display"> {{ $t('commons.duration') }} </span>
          </el-col>
          <el-col :span="18">
            <span class="right-key-display">
              {{ timeoff | formatDistance('start', 'end') }}
            </span>
          </el-col>
        </el-row>
      </div>
      <template slot="editContent" class="test">
        <el-row>
          <el-col :span="6">
            <span class="left-key-display"> {{ $t('commons.duration') }} </span>
          </el-col>
          <el-col :span="18">
            <el-input-number
              v-model="duration"
              placeholder="__________"
              controls-position="right"
              size="mini"
              :min="0"
              :controls="true"
              @change="saveToQueue"
            />
            <span>min</span>
          </el-col>
        </el-row>
      </template>
    </edit-details>

    <!-- sla component -->
    <edit-details no-separator :title="$t('commons.sla.plural')">
      <div :style="{ width: '100%' }">
        <sla v-model="timeoff.sla" />
      </div>
      <template
        slot="editContent"
        slot-scope="{ edit }"
        :style="{ width: '100%' }"
      >
        <sla v-model="timeoff.sla" :edit="edit" @change="saveToQueue" />
      </template>
    </edit-details>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addMinutes, differenceInMinutes } from 'date-fns';
import EditDetails from '../EditDetails';
import Sla from '../Sla';

export default {
  components: {
    EditDetails,
    Sla,
  },
  props: {
    timeoff: {
      type: Object,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['routes']),
    duration: {
      get: function () {
        return differenceInMinutes(this.timeoff.end, this.timeoff.start);
      },
      set: function (v) {
        this.timeoff.end = addMinutes(this.timeoff.start, v);
      },
    },
  },
  methods: {
    ...mapActions(['queueAction']),
    saveToQueue() {
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
  },
};
</script>

<style lang="scss"></style>
