<script>
import ActivitySidebar from './activity/ActivitySidebar';
import RouteSidebar from './route/RouteSidebar';
import TimeoffSidebar from './timeoff/TimeoffSidebar';

export default {
  functional: true,
  render(createElement, ctx) {
    const { type } = ctx.parent.$store.getters.selection;
    let component;
    switch (type) {
      case 'activity':
        component = ActivitySidebar;
        break;
      case 'route':
        component = RouteSidebar;
        break;
      case 'timeoff':
        component = TimeoffSidebar;
        break;
    }

    return createElement(component, ctx);
  },
};
</script>
