<template lang="html">
  <span v-show="value" class="map-toolbox">
    <cs-button
      square
      icon="zoom_out"
      icon-type="material"
      :disabled="zoom === 0"
      :tooltip="$t('commons.zoom_out')"
      @click.stop="$emit('updateZoom', zoom - 1)"
    />
    <cs-button
      square
      icon="zoom_in"
      icon-type="material"
      :disabled="zoom >= 18"
      :tooltip="$t('commons.zoom_in')"
      @click.stop="$emit('updateZoom', zoom + 1)"
    />

    <i class="material-icons">more_vert</i>

    <el-dropdown placement="bottom-start" trigger="click">
      <cs-button>
        <i class="material-icons">visibility</i>
        <i class="material-icons">arrow_drop_down</i>
      </cs-button>

      <el-dropdown-menu slot="dropdown" class="map-toolbox-dropdown-popper">
        <el-dropdown-item>
          <cs-button
            square
            icon="map-marker"
            placement="right"
            :tooltip="$t('pages.routes.components.showPointOnMap')"
            @click="$emit('toggleAllMarkers')"
          />
        </el-dropdown-item>
        <el-dropdown-item>
          <cs-button
            square
            icon="road"
            placement="right"
            :tooltip="$t('pages.routes.components.showTraceOnMap')"
            @click="$emit('toggleAllPolylines')"
          />
        </el-dropdown-item>
        <el-dropdown-item>
          <cs-button
            square
            icon-type="material"
            placement="right"
            :icon="cluster ? 'blur_on' : 'blur_off'"
            :shortkeys="['c']"
            :tooltip="$t('pages.track.RouteList.clusterView')"
            @click="$emit('updateCluster', !cluster)"
          >
          </cs-button>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <i class="material-icons">more_vert</i>

    <cs-button
      square
      icon="pencil-square-o"
      :shortkeys="['l']"
      :tooltip="$t('pages.track.RouteList.lassoSelection')"
      @click="$emit('toggleLasso')"
    />

    <i class="material-icons">more_vert</i>

    <cs-button
      square
      icon-type="material"
      icon="gps_not_fixed"
      :tooltip="$t('commons.recenter')"
      :shortkeys="['r']"
      @click="$emit('refreshBounds')"
    >
    </cs-button>

    <i v-show="$slots.maptools" class="material-icons">more_vert</i>

    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
    cluster: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 6,
    },
  },
};
</script>

<style lang="scss">
.map-toolbox {
  min-height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f0f3f2;
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 0 1px 1px 0;
  min-width: fit-content;

  button,
  i {
    color: #909399;
  }
}
.map-toolbox-dropdown-popper {
  background-color: #f0f3f2 !important;
  margin: 0 10px !important;
  .el-dropdown-menu__item {
    &:focus,
    &:hover {
      background-color: transparent !important;
    }
  }
  .popper__arrow {
    &::after {
      border-bottom-color: #f0f3f2 !important;
    }
  }
  button,
  i {
    background-color: #f0f3f2;
    color: #909399;
  }
}
</style>
