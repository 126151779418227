import { Icon, Util, point } from 'leaflet';

const SvgIcon = Icon.extend({
  initialize(options) {
    this.options = {
      iconSize: [21, 26],
      iconAnchor: [10, 26],
      popupAnchor: [2, -40],
      shadowAnchor: [7, 45],
      shadowSize: [54, 51],
      path: 'm10.494,25.5l-5.037,-5.013c-3.781,-3.817 -5.463,-6.88 -5.457,-9.93c0,-5.816 4.711,-10.55 10.502,-10.557c2.82,0 5.461,1.104 7.445,3.106c1.969,1.99 3.055,4.633 3.053,7.444c-0.004,3.029 -1.699,6.092 -5.502,9.93l-5.004,5.02z',
      className: '',
      prefix: 'ic',
      spinClass: 'fa-spin',
      extraClasses: 'map-icon',
      icon: 'text',
      markerColor: 'blue',
      stroke: false,
    };
    Util.setOptions(this, options);
  },
  createIcon(oldIcon) {
    const div =
      oldIcon && oldIcon.tagName === 'DIV'
        ? oldIcon
        : document.createElement('div');
    const { ...options } = this.options;
    let icon;
    if (options.icon) {
      icon = this.createInner();
    }
    const pinPath = options.path;
    const fillMode = options.stroke
      ? `stroke="${options.markerColor}" fill="white" stroke-width="2"`
      : `fill="${options.markerColor}"`;
    const svgPath = options.svg
      ? options.svg
      : `<path d="${pinPath}" ${fillMode}"></path>`;
    div.innerHTML = `<svg width="${options.iconSize[0]}px" height="${options.iconSize[1]}px" viewBox="0 0 ${options.iconSize[0]} ${options.iconSize[1]}" fill="${options.markerColor}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${svgPath}</svg>${icon}`;
    this.setIconStyles(div, 'icon');
    this.setIconStyles(div, `icon-${options.markerColor}`);
    return div;
  },
  createInner() {
    let iconClass;
    let iconSpinClass = '';
    const iconColorClass = '';
    const { ...options } = this.options;
    let iconStyle = `width: ${options.iconSize[0]}px;height: ${options.iconSize[1]}px;line-height:${options.iconSize[1]}px;`;
    if (
      options.icon.slice(0, options.prefix.length + 1) === `${options.prefix}-`
    ) {
      iconClass = options.icon;
    } else {
      iconClass = `${options.prefix}-${options.icon}`;
    }
    if (options.spin && typeof options.spinClass === 'string') {
      iconSpinClass = options.spinClass;
    }
    if (options.top) {
      iconStyle += `top: ${options.top}px;`;
    }
    if (options.iconColor) {
      if (options.stroke) {
        iconStyle += `color: ${options.markerColor};padding-top: 2px;`;
      } else {
        iconStyle += `color: ${options.iconColor};`;
      }
    }
    return `<i style='${iconStyle}' class='${options.extraClasses} ${options.prefix} ${iconClass} ${iconSpinClass} ${iconColorClass}'>${options.text}</i>`;
  },
  setIconStyles(img, name) {
    const { ...options } = this.options;
    const size = point(options[name === 'shadow' ? 'shadowSize' : 'iconSize']);
    let anchor;
    if (name === 'shadow') {
      anchor = point(options.shadowAnchor || options.iconAnchor);
    } else {
      anchor = point(options.iconAnchor);
    }
    if (!anchor && size) {
      anchor = size.divideBy(2, true);
    }
    img.className = `vector-marker-${name} vector-marker ${options.className}`;
    img.style.position = 'absolute';
    if (anchor) {
      img.style.marginLeft = `${-anchor.x}px`;
      img.style.marginTop = `${-anchor.y}px`;
    }
    if (size) {
      img.style.width = `${size.x}px`;
      img.style.height = `${size.y}px`;
    }
    return img;
  },
});

export default (options) => new SvgIcon(options);
