<template lang="html">
  <div
    v-if="timeoff"
    class="gantt-timeoff"
    :class="[hiddenClass]"
    :style="{ cursor }"
    @click.exact="select"
    @dblclick="selectAndDisplay"
  >
    <activity-icon
      material
      gantt-centered
      text="local_dining"
      color="#31363B"
      :svg="path"
    />
    <div class="activity-line"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { get, some } from 'lodash';
import { areIntervalsOverlapping } from 'date-fns';
import { selected, normal } from '@/iconPaths';
import ActivityIcon from '@/components/ActivityIcon';

export default {
  components: {
    ActivityIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    routeId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    route() {
      return this.$store.getters.routes.items[this.routeId];
    },
    hiddenClass() {
      return get(this.route, 'displayPolyline') ||
        get(this.route, 'displayMarkers')
        ? ''
        : 'gantt-hidden';
    },
    timeoff() {
      return get(this.route, `timeoffs[${this.index}]`);
    },
    selected() {
      return some(this.$store.getters.selection.data, ['id', this.id]);
    },
    inSla() {
      return areIntervalsOverlapping(this.timeoff, this.timeoff.sla);
    },
    path() {
      const iconSet = this.selected ? selected.timeoff : normal.timeoff;
      if (!this.inSla) {
        return iconSet.plannedWithWarnings;
      }
      return iconSet.planned;
    },
    displayed() {
      return (
        get(this.route, 'displayPolyline') || get(this.route, 'displayMarkers')
      );
    },
    cursor() {
      let cursor = 'pointer';
      if (this.selected) {
        cursor = 'move';
      }
      if (!this.displayed) {
        cursor = 'no-drop';
      }
      return cursor;
    },
  },
  watch: {
    route(v) {
      if (!v) {
        this.$destroy();
      }
    },
  },
  methods: {
    ...mapActions(['setSelected', 'setSelectionSidebar']),
    select() {
      this.setSelected({
        type: 'timeoff',
        draggable: false,
        data: [
          {
            id: this.id,
            index: this.index,
            routeId: this.routeId,
          },
        ],
      });
    },
    selectAndDisplay() {
      this.select();
      this.setSelectionSidebar(true);
    },
  },
};
</script>

<style lang="css"></style>
