import Vue from 'vue';
import VueRouter from 'vue-router';
import { Message } from 'element-ui';
import { isNil, has } from 'lodash';
import {
  checkLogin,
  logout,
  isAllowed,
  isAdmin,
  setToken,
  hasUser,
  loadBoot,
} from '@/services/auth';
import App from '@/pages/App';
import Admin from '@/pages/Admin';
import i18n from '@/locales';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete
        resolve(this.currentRoute);
      },
      (error) => {
        // on abort
        // only ignore NavigationDuplicated error
        if (error.name === 'NavigationDuplicated') {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

Vue.use(VueRouter);

/* eslint-disable global-require */
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: App,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: 'Dashboard',
          alias: '',
          path: 'dashboard',
          component: require('@/pages/dashboard/IndexPage.vue').default,
          requiresAuth: true,
        },
        {
          path: 'import',
          component: require('@/pages/importer/ImporterPage.vue').default,
          children: [
            {
              name: 'selectClient',
              path: '',
              component: require('@/pages/importer/SelectClient.vue').default,
              meta: {
                module: 'upload',
                requiresAuth: true,
              },
            },
            {
              name: 'mapper',
              path: ':id',
              component: require('@/pages/importer/Mapper.vue').default,
              meta: {
                module: 'upload',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'collection',
          component: require('@/pages/intake/IntakePage.vue').default,
          children: [
            {
              name: 'indexIntake',
              path: '',
              component: require('@/pages/intake/IndexPage.vue').default,
              meta: {
                module: 'intake',
                requiresAuth: true,
              },
            },
            {
              name: 'editIntake',
              path: ':id',
              component: require('@/pages/intake/EditIntake.vue').default,
              meta: {
                module: 'intake',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/notification',
          name: 'notification',
          component: require('@/pages/notification/NotificationPage.vue')
            .default,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/optimization',
          component: require('@/pages/optimization/OptimizationPage.vue')
            .default,
          children: [
            {
              name: 'createOptimization',
              path: '/optimization/create',
              component: require('@/pages/optimization/OptimCreate.vue')
                .default,
              meta: {
                module: 'route',
                requiresAuth: true,
              },
            },
            {
              name: 'editOptimization',
              path: '/optimization/:id',
              component: require('@/pages/optimization/OptimResult.vue')
                .default,
              meta: {
                module: 'route',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/route',
          component: require('@/pages/route/RoutePage.vue').default,
          children: [
            {
              name: 'routing',
              path: '',
              component: require('@/pages/route/Routingview.vue').default,
              meta: {
                module: 'route',
                requiresAuth: true,
              },
            },
            {
              name: 'planning',
              path: 'planning',
              component: require('@/pages/route/Planningview.vue').default,
              meta: {
                module: 'route',
                requiresAuth: true,
              },
            },
            // {
            //   name: 'TabMap',
            //   path: 'map',
            //   component: require('./components/tabmap/TabMapClient.vue')
            //     .default,
            //   meta: {
            //     module: 'route',
            //     requiresAuth: true
            //   }
            // },
            {
              name: 'print',
              path: 'print/:id',
              component: require('@/pages/route/RoutePrint.vue').default,
              meta: {
                module: 'route',
                requiresAuth: true,
                layout: 'print-layout',
              },
            },
          ],
        },
        {
          path: '/search',
          name: 'search',
          component: require('@/pages/search/SearchTable.vue').default,
          meta: {
            module: 'search',
            requiresAuth: true,
          },
        },
        // {
        //   path: '/appointment',
        //   component: require('@/pages/appointment/AppointmentPage.vue').default,
        //   children: [
        //     {
        //       name: 'appointments',
        //       path: '',
        //       component: require('@/pages/appointment/IndexPage.vue').default,
        //       meta: {
        //         module: 'appointment',
        //         requiresAuth: true
        //       }
        //     },
        //     {
        //       name: 'editAppointment',
        //       path: ':id',
        //       component: require('@/pages/appointment/EditAppointment.vue')
        //         .default,
        //       meta: {
        //         module: 'appointment',
        //         requiresAuth: true
        //       }
        //     }
        //   ]
        // },
        {
          path: '/tracking',
          component: require('@/pages/track/TrackPage.vue').default,
          children: [
            {
              name: 'trackPage',
              path: '',
              component: require('@/pages/track/TrackingPage.vue').default,
              meta: {
                module: 'tracking',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/details',
          component: require('@/pages/track/DetailsPage.vue').default,
          children: [
            {
              name: 'deliveryDetail',
              path: 'activity/:id',
              alias: 'delivery/:id',
              component: require('@/pages/track/ActivityDetail.vue').default,
              meta: {
                module: 'tracking',
                requiresAuth: true,
              },
            },
            {
              name: 'routeDetail',
              path: '/details/route/:id',
              component: require('@/pages/track/RouteDetail.vue').default,
              meta: {
                module: 'tracking',
                requiresAuth: true,
              },
            },
            {
              name: 'printActivity',
              path: 'activity/print/:id',
              component: require('@/pages/track/ActivityPrint.vue').default,
              meta: {
                module: 'tracking',
                requiresAuth: true,
                layout: 'print-layout',
              },
            },
          ],
        },
        {
          path: '/client',
          component: require('@/pages/client/ClientPage.vue').default,
          children: [
            {
              name: 'indexClient',
              path: '',
              component: require('@/pages/client/IndexPage.vue').default,
              meta: {
                module: 'client',
                requiresAuth: true,
              },
            },
            {
              name: 'createClient',
              path: 'create',
              component: require('@/pages/client/CreateClient.vue').default,
              meta: {
                module: 'client',
                requiresAuth: true,
              },
            },
            {
              name: 'editClient',
              path: ':id',
              component: require('@/pages/client/EditClient.vue').default,
              meta: {
                module: 'client',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/organization',
          component: require('@/pages/organization/OrganizationPage.vue')
            .default,
          children: [
            {
              name: 'indexOrganization',
              path: '',
              component: require('@/pages/organization/IndexPage.vue').default,
              meta: {
                module: 'organization',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/place',
          component: require('@/pages/place/PlacePage.vue').default,
          children: [
            {
              name: 'indexPlace',
              path: '',
              component: require('@/pages/place/IndexPage.vue').default,
              meta: {
                module: 'place',
                requiresAuth: true,
              },
            },
            {
              name: 'createPlace',
              path: 'create',
              component: require('@/pages/place/CreatePlace.vue').default,
              meta: {
                module: 'place',
                requiresAuth: true,
              },
            },
            {
              name: 'editPlace',
              path: ':id',
              component: require('@/pages/place/EditPlace.vue').default,
              meta: {
                module: 'place',
                requiresAuth: true,
              },
            },
            {
              name: 'editSpot',
              path: 'spot/:id',
              component: require('@/pages/place/spot/EditSpots.vue').default,
              meta: {
                module: 'place',
                requiresAuth: true,
              },
            },
            {
              name: 'printSpots',
              path: 'spot/print/:type/:id',
              component: require('@/pages/place/spot/SpotsPrint.vue').default,
              meta: {
                module: 'place',
                requiresAuth: true,
                layout: 'print-layout',
              },
            },
          ],
        },
        {
          path: '/resource',
          component: require('@/pages/resource/ResourcePage.vue').default,
          children: [
            {
              name: 'indexResource',
              path: '',
              component: require('@/pages/resource/IndexPage.vue').default,
              meta: {
                module: 'resource',
                requiresAuth: true,
              },
            },
            {
              name: 'createResource',
              path: 'create',
              component: require('@/pages/resource/CreateResource.vue').default,
              meta: {
                module: 'resource',
                requiresAuth: true,
              },
            },
            {
              name: 'editResource',
              path: ':id',
              component: require('@/pages/resource/EditResource.vue').default,
              meta: {
                module: 'resource',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/role',
          component: require('@/pages/role/RolePage.vue').default,
          children: [
            {
              name: 'indexRole',
              path: '',
              component: require('@/pages/role/IndexPage.vue').default,
              meta: {
                module: 'role',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/user',
          component: require('@/pages/user/UserPage.vue').default,
          children: [
            {
              name: 'indexUser',
              path: '',
              component: require('@/pages/user/IndexPage.vue').default,
              meta: {
                module: 'user',
                requiresAuth: true,
              },
            },
            {
              name: 'createUser',
              path: 'create',
              component: require('@/pages/user/CreateUser.vue').default,
              meta: {
                module: 'user',
                requiresAuth: true,
              },
            },
            {
              name: 'editUser',
              path: ':id',
              component: require('@/pages/user/EditUser.vue').default,
              meta: {
                module: 'user',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: '/vehicle',
          component: require('@/pages/vehicle/VehiclePage.vue').default,
          children: [
            {
              name: 'indexVehicle',
              path: '',
              component: require('@/pages/vehicle/IndexPage.vue').default,
              meta: {
                module: 'vehicle',
                requiresAuth: true,
              },
            },
            {
              name: 'createVehicle',
              path: 'create',
              component: require('@/pages/vehicle/CreateVehicle.vue').default,
              meta: {
                module: 'vehicle',
                requiresAuth: true,
              },
            },
            {
              name: 'editVehicle',
              path: ':id',
              component: require('@/pages/vehicle/EditVehicle.vue').default,
              meta: {
                module: 'vehicle',
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/admin',
      component: Admin,
      meta: {
        requiresAdmin: true,
        requiresAuth: true,
      },
      children: [
        {
          name: 'indexAdmin',
          path: '',
          component: require('@/pages/admin/IndexPage.vue').default,
        },
        {
          name: 'adminCreateOrganization',
          path: 'organization/create',
          component: require('@/pages/admin/CreateOrganization.vue').default,
        },
        {
          name: 'adminEditOrganization',
          path: 'organization/:id',
          component: require('@/pages/admin/EditOrganization.vue').default,
        },
        {
          name: 'adminEditClient',
          path: 'organization/:organization/client/:id',
          component: require('@/pages/admin/EditClient.vue').default,
        },
      ],
    },
    {
      name: 'pdfActivity',
      path: '/pdf/:id',
      component: require('@/pages/track/ActivityPrintExternal.vue').default,
      meta: {
        layout: 'print-layout',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: require('@/pages/login/LoginPage.vue').default,
      meta: {
        requiresAuth: false,
      },
      beforeEnter: async (to, from, next) => {
        try {
          if (await checkLogin()) {
            next({ path: '/' });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        next();
      },
    },
    {
      path: '/password',
      component: require('@/pages/login/PasswordPage.vue').default,
      children: [
        {
          name: 'forgotPassword',
          path: 'email',
          component: require('@/pages/login/ForgotPassword.vue').default,
          meta: {
            requiresAuth: false,
          },
        },
        {
          name: 'resetPassword',
          path: 'reset/:token',
          component: require('@/pages/login/ResetPassword.vue').default,
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: (to, from, next) => {
        logout();
        next();
      },
    },
  ],
});
/* eslint-enable global-require */

const checkMetaModule = (meta) => {
  if (!isNil(meta.module) && !isAllowed(meta.module)) {
    Message({
      showClose: true,
      duration: 6000,
      message: i18n.t('error.moduleUnauthorized', [
        i18n.t(`role.module.${meta.module}.title`),
      ]),
      type: 'error',
    });
    throw new Error('unauthorized');
  }
};

const checkAdmin = () => {
  if (!isAdmin()) {
    throw new Error('unauthorized');
  }
};

router.beforeEach(async (to, from, next) => {
  if (has(to.query, 'token') && setToken(to.query.token)) {
    delete to.query.token;
    next({
      path: to.path,
      query: to.query,
    });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!checkLogin()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }

    if (!hasUser()) {
      await loadBoot(true);
    }
  }
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    try {
      checkAdmin();
    } catch {
      next({ path: '/' });
    }
  }
  checkMetaModule(to.meta);

  return next();
});

export default router;
