<template lang="html">
  <div v-if="routes.length === 1">
    <cs-button
      v-if="routes[0].state !== 'ready'"
      type="success"
      @click="mutateRoutes('set_ready')"
    >
      {{ $t('commons.dispatch') }}
    </cs-button>
    <cs-button v-else type="warning" @click="mutateRoutes('set_created')">
      {{ $t('commons.cancel') }} {{ $t('commons.dispatch') }}
    </cs-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { filter, isNil } from 'lodash';

export default {
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    toValidate() {
      return filter(this.routes, ['state', 'created']);
    },
  },
  methods: {
    ...mapActions(['patch']),
    mutateRoutes(transition) {
      this.routes.forEach((route) => {
        if (this.$store.getters.routes.actionQueue.hasId(route.id)) {
          this.$message({
            type: 'error',
            message: this.$t('error.route.notSaved', [route.name]),
          });
          return false;
        }
        if (isNil(route.resource)) {
          this.$message({
            type: 'error',
            message: this.$t('error.route.noResource', [route.name]),
          });
          return false;
        }

        return this.patch({
          type: 'route',
          id: route.id,
          url: `route/${route.id}/${transition}`,
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: e.data.message,
          });
        });
      });
    },
  },
};
</script>
