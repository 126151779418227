import { render, staticRenderFns } from "./AbilityEdit.vue?vue&type=template&id=23ce2b00&lang=html&"
import script from "./AbilityEdit.vue?vue&type=script&lang=js&"
export * from "./AbilityEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports