const state = {
  filters: [],
};

const mutations = {
  REGISTER_FILTER: (myState, namespace) => {
    if (!myState.filters.find((f) => f.namespace === namespace)) {
      myState.filters.push({
        namespace,
        operator: 'OR',
        filters: [],
      });
    }
  },
  STORE_FILTER: (myState, o) => {
    const filter = myState.filters.find((f) => f.namespace === o.namespace);
    filter.filters.push(o.filter);
  },
};

const getters = {
  filters: (myState) => myState.filters,
  getFiltersByNamespace: (myState, myGetters) => (namespace) => {
    const filter = myGetters.filters.find((f) => f.namespace === namespace);

    if (filter) {
      return filter.filters;
    }
    return null;
  },
};

const actions = {
  registerFilter({ commit }, namespace) {
    commit('REGISTER_FILTER', namespace);
  },
  storeFilter({ commit }, o) {
    commit('STORE_FILTER', o);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
