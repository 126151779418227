<template>
  <div class="back">
    <section id="login">
      <h1 class="title">{{ $t('pages.LoginPage.welcome') }}</h1>

      <el-form
        ref="formLogin"
        label-position="top"
        :model="form"
        :rules="rules"
      >
        <div v-if="logingError" class="alert alert-danger">
          <p class="upperFirst">{{ $t('pages.LoginPage.errorMessage') }}</p>
        </div>

        <el-form-item :label="$t('commons.mail')" prop="email">
          <el-input
            v-model="form.email"
            name="email"
            type="email"
            :placeholder="$t('commons.mail')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('pages.user.UserForm.password')"
          prop="password"
        >
          <el-input
            v-model="form.password"
            name="password"
            type="password"
            :placeholder="$t('pages.user.UserForm.password')"
            @keyup.enter.native="submitForm('formLogin')"
          />
        </el-form-item>

        <el-row type="flex" justify="space-between">
          <el-form-item>
            <router-link
              :to="{ name: 'forgotPassword' }"
              class="forgotPassword"
              >{{ $t('pages.password.passwordForgotten') }}</router-link
            >
          </el-form-item>
          <el-form-item>
            <cs-button
              type="primary"
              icon-type="material"
              icon="lock_open"
              :loading="loading"
              @click="submitForm('formLogin')"
            >
              {{ $t('pages.LoginPage.logIn') }}
            </cs-button>
          </el-form-item>
        </el-row>
      </el-form>
    </section>
  </div>
</template>

<script>
import { upperFirst } from 'lodash';
import { validate } from '@/utils';

export default {
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    rules: {
      email: [
        { validator: validate, trigger: 'blur', message: '' },
        { type: 'email', message: '', trigger: 'blur,change' },
      ],
      password: [{ validator: validate, trigger: 'blur', message: '' }],
    },
    logingError: false,
    loading: false,
  }),
  mounted() {
    this.rules.email[0].message = `${upperFirst(
      this.$t('commons.validateField', { field: this.$t('commons.mail') })
    )}`;
    this.rules.email[1].message = `${upperFirst(
      this.$t('commons.fieldError')
    )}`;
    this.rules.password[0].message = `${upperFirst(
      this.$t('commons.validateField', {
        field: this.$t('pages.user.UserForm.password'),
      })
    )}`;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$login(
            this.form.email,
            this.form.password,
            this.$route.query.redirect
          ).catch(() => {
            this.error = true;
            this.loading = false;
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.forgotPassword {
  color: #aaaaaa;
  font-size: 11px;
  cursor: pointer;
  transition: 1s color ease-in-out;

  &:hover {
    color: #000;
    transition: 1s color ease-in-out;
  }
}
</style>
