<template lang="html">
  <section v-if="entity" class="place-component">
    <el-row type="flex" align="middle">
      <el-col :span="24">
        <slot> {{ $t('commons.address.single') | upperFirst }} </slot>
        <address-entity v-if="small" :full="false" :address="smallAddress" />
      </el-col>
    </el-row>
    <span v-if="!small">
      <el-row>
        <el-col :span="24">
          <address-entity :full="full" :address="entity.place" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <h4 class="dialog-subtitle">{{ $t('sentence.addressFound') }}</h4>
        </el-col>
        <el-col :span="24">
          <address-entity :full="full" :address="entity.place_geocoded" />
        </el-col>
      </el-row>
    </span>
  </section>
</template>

<script>
import { round, get } from 'lodash';
import AddressEntity from './Address';

export default {
  name: 'PlaceEntity',
  components: {
    AddressEntity,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    score() {
      return round(get(this.entity, 'place_geocoded.score', 0));
    },
    smallAddress() {
      return this.entity.place_geocoded || this.entity.place;
    },
  },
};
</script>

<style lang="scss">
.place-component {
  address {
    display: inline-block;
  }
}
</style>
