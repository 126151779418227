<template lang="html">
  <div style="display: inline-block">
    <cs-button @click="toggleDialog">
      {{ $t('components.shift.assignResource') }}
    </cs-button>

    <el-dialog
      :modal-append-to-body="false"
      :title="$t('components.shift.assignPlanning') + ' ' + name"
      :visible.sync="dialogVisible"
    >
      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th>
              <input
                v-model="checkAll"
                type="checkbox"
                @change="handleCheckAllChange"
              />
            </th>
            <th>{{ $t('commons.name') }}</th>
            <th>{{ $t('commons.planning') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in resources.items" :key="resource.id">
            <td>
              <input
                v-model="selectedResources"
                type="checkbox"
                :value="resource"
              />
            </td>
            <td>{{ resource.name }}</td>
            <td><shift-popover :shifts="resource.shifts" trigger="click" /></td>
          </tr>
        </tbody>
      </table>

      <div slot="footer">
        <cs-button type="success" :loading="saving" @click="assignShift">
          {{ $t('commons.validate') }}
        </cs-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ShiftPopover from './ShiftPopover';

export default {
  name: 'ShiftToResources',
  components: { ShiftPopover },
  props: {
    shift: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    dialogVisible: false,
    checkAll: false,
    selectedResources: [],
    saving: false,
  }),
  computed: {
    ...mapGetters(['resources']),
  },
  mounted() {
    this.get({ type: 'resource', id: 'all' })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['get', 'patch']),
    handleCheckAllChange(event) {
      this.selectedResources = event.target.checked
        ? Object.values(this.resources.items)
        : [];
    },
    handleCheckedResourcesChange() {
      this.checkAll =
        this.selectedResources.length === this.resources.items.length;
    },
    toggleDialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    assignShift() {
      _.map(this.selectedResources, (r) => {
        r.shifts = this.shift;
      });

      this.saving = true;
      this.patch({
        type: 'resource',
        id: 'all',
        data: this.selectedResources,
      }).then(() => {
        this.dialogVisible = false;
        this.saving = false;
      });
    },
  },
};
</script>
