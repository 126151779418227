<template lang="html">
  <section>
    <!-- edit needs -->

    <edit-details :title="$t('commons.need.plural')">
      <div>
        <constraint-list-edit
          v-model="entity.consist_of"
          name="consist_of"
          key-value
          :edit="editCapacities"
          @change="$emit('change')"
        />
      </div>
      <div
        slot="editContent"
        slot-scope="{ edit }"
        :style="{ width: '100%' }"
        class="edit-element"
      >
        <constraint-list-edit
          v-model="entity.consist_of"
          name="consist_of"
          key-value
          :edit="edit"
          @change="$emit('change')"
        />
      </div>
    </edit-details>

    <edit-details :title="$t('commons.tool.plural')">
      <div>
        <p v-if="displayNoTools" class="upperFirst">
          {{ `${$t('commons.no.male.single')} ${$t('commons.tool.single')}` }}
        </p>
        <constraint-list-edit
          v-else
          v-model="entity.tools"
          name="tools"
          :edit="editTools"
          @change="$emit('change')"
        />
      </div>
      <div
        slot="editContent"
        slot-scope="{ edit }"
        :style="{ width: '100%' }"
        class="edit-element"
      >
        <constraint-list-edit
          v-model="entity.tools"
          name="tools"
          :edit="edit"
          @change="$emit('change')"
        />
      </div>
    </edit-details>
  </section>
</template>

<script>
import { isEmpty } from 'lodash';
import ConstraintListEdit from './ConstraintListEdit';
import EditDetails from '../EditDetails';

export default {
  components: {
    ConstraintListEdit,
    EditDetails,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'delivery',
    },
  },
  data: () => ({
    editCapacities: false,
    editTools: false,
    editColor: '#32363B',
    tonic: '#50E3C2',
  }),
  computed: {
    displayNoCapacities() {
      return isEmpty(this.entity.consist_of) && !this.editCapacities;
    },
    displayNoTools() {
      return isEmpty(this.entity.tools) && !this.editTools;
    },
  },
};
</script>

<style lang="scss">
.constraints-content-separator {
  margin-left: 10px;
  height: 2px;
  background-color: white;
  width: 97%;
}
</style>
