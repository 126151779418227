<template lang="html">
  <section class="module-edit">
    <el-card>
      <span slot="header">
        <h5 class="module-edit-title">{{ $t(`role.module.${name}.title`) }}</h5>
        <el-switch :value="value" @change="update" />
      </span>
      <p class="upperFirst">{{ $t(`role.module.${name}.description`) }}.</p>
      <span class="secondary-text-color">
        <u>{{ $t('role.advise') }}</u>
        <el-row
          v-for="(list, abilityName) in advisedAbilities"
          :key="abilityName"
        >
          <i
            ><strong>{{ $t(`role.ability.${abilityName}`) }}:</strong>
            {{ list.map((s) => $t(`role.abilityHeader.${s}`)).join(', ') }}</i
          >
        </el-row>
      </span>
    </el-card>
  </section>
</template>

<script>
import { forEach, has } from 'lodash';

export default {
  props: {
    value: {
      type: Boolean,
    },
    name: {
      type: String,
      default: () => '',
    },
    abilities: {
      type: Object,
      default: () => ({}),
    },
    advisedAbilities: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value);
      if (value) {
        forEach(this.advisedAbilities, (advise, name) => {
          forEach(advise, (a) => {
            if (has(this.abilities, name)) {
              this.$set(this.abilities[name], a, true);
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.module-edit {
  width: 250px;
  margin-bottom: 1em;
  break-inside: avoid-column;
  .el-card {
    width: 100%;
    box-shadow: none;
  }
  .el-card__header {
    display: flex;
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .module-edit-title {
    margin: 0;
  }
}
</style>
