<template>
  <section>
    <el-row class="summary" type="flex" justify="space-around">
      <el-col class="box box-state">
        <state
          v-model="value"
          editable
          type="delivery"
          display="title"
          :transitions="value.transitions"
          :exclude-transitions="['replan']"
          @change="mutate"
        />

        <div v-if="lastState && lastState.reason" class="reason">
          <p class="title">
            {{ $t('commons.reason') }}
            <em>{{ lastState.reason }}</em>
          </p>

          <el-tooltip
            v-if="lastState.message"
            placement="top"
            :content="lastState.message"
          >
            <cs-button square icon="comment" icon-type="material" />
          </el-tooltip>
        </div>

        <span v-if="!value.inSla">
          <i class="danger-color material-icons">warning</i>
          <em class="danger-color">{{ $t('pages.track.outOfSla') }}</em>
        </span>
      </el-col>

      <el-col class="box">
        <h4>{{ value.receiver_name }}</h4>
        <address-entity
          style="font-weight: 100"
          :address="value.place"
          :full="false"
        />
        <el-row class="edit" type="flex" justify="space-around">
          <el-col :span="3">
            <i class="material-icons">mail</i>
          </el-col>
          <el-col :span="16">
            <span v-if="!edit_mail">{{
              value.email || $t('commons.mail')
            }}</span>
            <el-input
              v-else
              v-model="value.email"
              @change="hasChange"
            ></el-input>
          </el-col>
          <el-col :span="2">
            <cs-button
              v-if="edit_mail"
              icon="save"
              class="toogle"
              icon-type="material"
              @click="save_mail"
            >
            </cs-button>
          </el-col>
          <el-col :span="3">
            <edit-toggle class="toogle" v-model="edit_mail" />
          </el-col>
        </el-row>
        <el-row class="edit" type="flex" justify="space-around">
          <el-col :span="3">
            <i class="material-icons">phone</i>
          </el-col>
          <el-col :span="16">
            <span v-if="!edit_phone">{{
              value.receiver_phone || $t('commons.phone')
            }}</span>
            <el-input
              v-else
              v-model="value.receiver_phone"
              @change="hasChange"
            ></el-input>
          </el-col>
          <el-col :span="2">
            <cs-button
              v-if="edit_phone"
              icon="save"
              class="toogle"
              icon-type="material"
              @click="save_phone"
            >
            </cs-button>
          </el-col>
          <el-col :span="3">
            <edit-toggle class="toogle" v-model="edit_phone" />
          </el-col>
        </el-row>
      </el-col>

      <el-col class="box">
        <h4>Prévisons</h4>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.hourValued') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{ value.planned_start | format(SHORT_DATETIME_FORMAT) }}
            </p>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.hourReal') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{
                value.completed_at
                  | format(SHORT_DATETIME_FORMAT)
                  | empty('N/A')
              }}
            </p>
          </el-col>
        </el-row>
        <el-row v-if="delta" type="flex" justify="space-between">
          <el-col :span="12"> <p class="title">delta</p> </el-col>
          <el-col :span="12">
            <p :class="value.getDelayClass()" class="data">
              {{ delta | seconds }}
            </p>
          </el-col>
        </el-row>
      </el-col>

      <el-col class="box">
        <h4>SLA</h4>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.slaStart') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">{{ sla.start | format(SHORT_DATETIME_FORMAT) }}</p>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.slaEnd') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{ sla.end | format(SHORT_DATETIME_FORMAT) | empty('N/A') }}
            </p>
          </el-col>
        </el-row>
        <el-row v-if="outOfSlaDelta" type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.outOfSla') }}</p>
          </el-col>
          <el-col v-show="outOfSlaDelta > 0" :span="12">
            <p class="data danger-color">{{ outOfSlaDelta | seconds }}</p>
          </el-col>
        </el-row>
      </el-col>

      <el-col class="box">
        <h4>RDV</h4>
        <el-row v-if="value.appointment">
          <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="12">
              <el-row type="flex">
                <p class="title">{{ $t('commons.from') }}</p>
                <p class="data">
                  {{
                    value.appointment.start
                      | format(SHORT_DATETIME_FORMAT)
                      | empty('N/A')
                  }}
                </p>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row type="flex">
                <p class="title">{{ $t('commons.to') }}</p>
                <p class="data">
                  {{
                    value.appointment.end
                      | format(SHORT_DATETIME_FORMAT)
                      | empty('N/A')
                  }}
                </p>
              </el-row>
            </el-col>
          </el-row>
          <el-row
            v-if="!value.inAppointment"
            type="flex"
            justify="space-between"
          >
            <el-col :span="12">
              <p class="title">{{ $t('pages.track.outOfAppointment') }}</p>
            </el-col>
            <el-col v-show="!value.inAppointment" :span="12">
              <p class="data danger-color">
                {{ outOfAppointmentDelta | seconds }}
              </p>
            </el-col>
          </el-row>
        </el-row>
        <el-row v-else type="flex" justify="space-around">
          <p class="title">{{ $t('commons.none.male') }}</p>
        </el-row>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { find, map } from 'lodash';
import { mapActions } from 'vuex';
import {
  differenceInSeconds,
  isWithinInterval,
  closestIndexTo,
} from 'date-fns';
import AddressEntity from '@/components/Address';
import EditToggle from '@/components/EditToggle';
import State from '@/components/State';
import { SHORT_DATETIME_FORMAT } from '@/utils/date';

export default {
  components: {
    AddressEntity,
    State,
    EditToggle,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    SHORT_DATETIME_FORMAT: SHORT_DATETIME_FORMAT,
    edit_mail: false,
    edit_phone: false,
  }),
  computed: {
    lastState() {
      return this.value.state_history.last();
    },
    delta() {
      return this.value.getDelay();
    },
    sla() {
      let needle = this.value.completed_at;

      const within = find(this.value.slas, (sla) =>
        isWithinInterval(needle, sla)
      );
      if (within) {
        return within;
      }

      needle = this.value.completed_at || this.value.planned_start;
      const idxStart = closestIndexTo(needle, map(this.value.slas, 'start'));
      const deltaStart = Math.abs(
        differenceInSeconds(this.value.slas[idxStart].start, needle)
      );
      const idxEnd = closestIndexTo(needle, map(this.value.slas, 'end'));
      const deltaEnd = Math.abs(
        differenceInSeconds(this.value.slas[idxEnd].end, needle)
      );

      return deltaStart > deltaEnd
        ? this.value.slas[idxEnd]
        : this.value.slas[idxStart];
    },
    outOfSlaDelta() {
      if (this.value.completed_at) {
        return differenceInSeconds(this.value.completed_at, this.sla.end);
      }
      return null;
    },
    outOfAppointmentDelta() {
      if (this.value.starting_at < this.value.appointment.start) {
        return differenceInSeconds(
          this.value.starting_at,
          this.value.appointment.start
        );
      }
      if (this.value.starting_at > this.value.appointment.end) {
        return differenceInSeconds(
          this.value.starting_at,
          this.value.appointment.end
        );
      }

      return null;
    },
  },
  methods: {
    ...mapActions(['patch']),
    mutate(transition) {
      this.patch({
        type: 'delivery',
        id: this.value.id,
        url: `delivery/${this.value.id}/${transition}`,
      });
    },
    save_mail() {
      this.$emit('save');
      this.edit_mail = false;
    },
    save_phone() {
      this.$emit('save');
      this.edit_phone = false;
    },
    hasChange() {
      this.$emit('change');
    },
  },
};
</script>
<style lang="scss" scoped>
.reason {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.edit {
  color: #5e5e5e;
  font-size: 1.3em;
  font-weight: 100;
  margin-top: 3px;
}
.material-icons {
  position: absolute;
  bottom: 0;
}
.toogle {
  height: auto !important;
  line-height: inherit !important;
  position: absolute;
  bottom: 0;
  padding: 0px;
  background-color: transparent;
  color: #50e3c2;
}
</style>

<style lang="scss">
.edit {
  .el-input__inner {
    text-align: left;
    background-color: transparent;
    border: none;
    padding: 0 !important;
    height: auto !important;
    border-bottom: 1px solid #5e5e5e;
  }
}
</style>
