<template>
  <base-layout v-if="!loading">
    <h3 slot="title">
      {{ $t('pages.track.TrackDetail.followRoute') }} {{ route.name }}
    </h3>

    <route-summary :value="route" />

    <el-row type="flex" justify="space-between" :gutter="20">
      <el-col :span="8">
        <h4>Tracking</h4>
        <l-map
          v-if="route"
          ref="map"
          class="map-tracking"
          :bounds="route.bounds"
        >
          <l-tile-layer :url="url" :attribution="attribution"> </l-tile-layer>
          <l-layer-group>
            <l-marker
              v-for="delivery in route.deliveries"
              :key="delivery.id"
              :lat-lng="delivery.position"
              :icon="delivery.icon"
            >
            </l-marker>
            <l-marker :lat-lng="route.startLocation" :icon="route.startIcon" />
            <l-marker :lat-lng="route.endLocation" :icon="route.endIcon" />
          </l-layer-group>
          <l-circle-marker
            v-if="selectedState && selectedState.position"
            ref="selectedMarker"
            :lat-lng="selectedState.position"
            :class-name="`state-${selectedState.to}`"
            :radius="7"
          >
            <l-popup :lat-lng="selectedState.position">
              {{ $t(`commons.states.route.${selectedState.to}`) }}
              {{ $t('commons.at') }}
              {{ selectedState.datetime | moment('llll') }}
              <em>{{ selectedState.message }}</em>
            </l-popup>
          </l-circle-marker>
          <l-polyline
            fill-color="transparent"
            :lat-lngs="route.polyline"
            :color="route.color"
          />
          <l-polyline
            v-if="route.trackingPolyline"
            fill-color="transparent"
            color="red"
            :lat-lngs="route.trackingPolyline"
          />
        </l-map>
      </el-col>

      <el-col :span="19">
        <h4>{{ $t('commons.activity.plural') }}</h4>

        <el-table :data="route.deliveries" style="width: 100%">
          <el-table-column
            prop="order"
            width="80"
            :label="$t('commons.order')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="state"
            width="130"
            :label="$t('commons.state')"
            sortable
          >
            <template slot-scope="scope">
              <el-popover placement="top" width="200" trigger="hover">
                <router-link
                  slot="reference"
                  :to="{ name: 'deliveryDetail', params: { id: scope.row.id } }"
                >
                  <state type="delivery" :value="scope.row.state" />
                </router-link>
                <activity-card :activity="scope.row" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="external_id"
            :label="$t('commons.id')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="receiver_name"
            :label="$t('commons.recipient')"
          >
          </el-table-column>
          <el-table-column
            prop="planned_start"
            :label="$t('pages.track.hourValued')"
          >
            <template slot-scope="scope">
              {{ scope.row.planned_start | format('dd/MM H:mm') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="completed_at"
            :label="$t('pages.track.hourReal')"
          >
            <template slot-scope="scope">
              {{ scope.row.completed_at | format('dd/MM H:mm') }}
              <em
                v-if="scope.row.completed_at"
                :class="scope.row.getDelayClass()"
              >
                ({{ scope.row.getDelay() | seconds }})
              </em>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="5">
        <h4>{{ $t('commons.state_history') }}</h4>

        <state-history
          :history="route.state_history"
          entity-type="route"
          @selected="setSelectedState"
        />
      </el-col>
    </el-row>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="print"
      target="_blank"
      :to="{ name: 'print', params: { id: route.id } }"
    />
    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.back()"
    >
      {{ $t('commons.return') }}
    </cs-button>
  </base-layout>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LPolyline,
  LLayerGroup,
  LMarker,
  LPopup,
  LCircleMarker,
} from 'vue2-leaflet';
import { mapActions } from 'vuex';
import { isNil } from 'lodash';
import RouteSummary from './RouteSummary';
import ActivityCard from '@/components/ActivityCard';
import StateHistory from '@/components/StateHistory';
import State from '@/components/State';

export default {
  name: 'RouteDetail',
  components: {
    ActivityCard,
    LCircleMarker,
    LPopup,
    LMap,
    LTileLayer,
    LPolyline,
    LLayerGroup,
    LMarker,
    StateHistory,
    RouteSummary,
    State,
  },
  data: () => ({
    selectedState: null,
    opacity: 0.6,
    url: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER}`,
    attribution:
      '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
  }),
  computed: {
    loading() {
      return isNil(this.route);
    },
    route() {
      return this.$store.getters.routes.items[this.$route.params.id];
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLanguage: function () {
      this.fetchData(true);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['get']),
    setSelectedState(state) {
      this.selectedState = state;
      // Fix for leaflet rerender
      if (this.$refs.selectedMarker) {
        this.$refs.map.mapObject.removeLayer(
          this.$refs.selectedMarker.mapObject
        );
        this.$refs.selectedMarker.setClassName(
          `state-${this.selectedState.to}`
        );
        this.$refs.map.mapObject.addLayer(this.$refs.selectedMarker.mapObject);
      }
    },
    fetchData(forceFetch) {
      this.get({
        type: 'route',
        id: this.$route.params.id,
        query: 'include=tracking,transitions',
        forceFetch,
      });
    },
  },
};
</script>
