<template>
  <el-popover placement="bottom" width="300" trigger="click">
    <span
      slot="reference"
      class="item-status-reference"
      :class="referenceClass"
    ></span>
    <div class="item-status">
      <div class="item-status-head">
        <span class="activity-head-state">
          {{ $t(`commons.states.item.${item.state}`) | upperFirst }}
        </span>
        <strong>{{ title | truncate(20) }}</strong>
        <edit-toggle
          v-model="displayDetails"
          icon-on="expand_more"
          icon-off="expand_less"
          disable-tooltip
        />
      </div>
      <div v-show="displayDetails" class="item-status-details">
        <constraint-list
          v-if="item.consist_of"
          v-model="item.consist_of"
          name="consist_of"
        >
          <label slot="title">{{
            $t('components.ConstraintList.consist_of.title')
          }}</label>
        </constraint-list>

        <constraint-list v-if="item.tools" v-model="item.tools" name="tools">
          <label slot="title">{{
            $t('components.ConstraintList.tool.title')
          }}</label>
        </constraint-list>

        <div class="item-status-external-data">
          <external-data :value="item.external_data" />
        </div>
      </div>
      <slot name="actions">
        <div class="btn-group">
          <cs-button
            v-for="(transition, idx) in availableTransitions"
            :key="idx"
            type="primary"
            @click="modifyTransition(transition)"
          >
            {{ $t(`commons.transitions.parcel.${transition}`) }}
          </cs-button>
        </div>
      </slot>
    </div>
  </el-popover>
</template>

<script>
import ConstraintList from './ConstraintList';
import EditToggle from './EditToggle';
import ExternalData from './ExternalData';

export default {
  components: {
    ConstraintList,
    EditToggle,
    ExternalData,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    availableTransitions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    displayDetails: false,
    isModified: false,
  }),
  computed: {
    title() {
      return this.item.title || this.item.barcode;
    },
    referenceClass() {
      return `is-${this.item.state} ${this.isModified ? 'is-modified' : ''}`;
    },
  },
  methods: {
    modifyTransition(t) {
      this.item.transition = t;
      this.isModified = true;
    },
  },
};
</script>

<style lang="scss">
.item-status-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item-status-external-data {
  }
}
</style>
