<template lang="html">
  <div>
    <timeoff-head
      :timeoff="routeTimeoff"
      :index="timeoff.index"
      :show-details="showDetails"
      :route="route"
      @click="showDetails = !showDetails"
    />
    <timeoff-content :timeoff="routeTimeoff" :route="route" />
  </div>
</template>

<script>
import TimeoffHead from './TimeoffHead';
import TimeoffContent from './TimeoffContent';

export default {
  components: {
    TimeoffHead,
    TimeoffContent,
  },
  props: {
    timeoff: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showDetails: false,
  }),
  computed: {
    route() {
      return this.$store.getters.routes.items[this.timeoff.routeId];
    },
    routeTimeoff() {
      return this.route.timeoffs[this.timeoff.index];
    },
  },
};
</script>
