<template lang="html">
  <div>
    <route-head
      :route="route"
      :show-details="showDetails"
      @unplan="$emit('unplan', route.deliveries)"
      @click.native="showDetails = !showDetails"
    />

    <route-content v-show="showDetails" :route="route" />
  </div>
</template>

<script>
import RouteHead from './RouteHead';
import RouteContent from './RouteContent';

export default {
  components: {
    RouteContent,
    RouteHead,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showDetails: false,
  }),
};
</script>
