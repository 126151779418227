<template lang="html">
  <base-layout>
    <el-alert
      v-if="!hasNotification"
      type="info"
      show-icon
      :title="$t('pages.Notifications.notificationNotFound') | upperFirst"
      :closable="false"
    />
    <div v-else>
      <notification
        v-for="(notification, idx) in notifications"
        :key="idx"
        remove
        :notification="notification"
        :title="notification.title"
        :type="notification.type"
        :text="notification.text"
        :datetime="notification.datetime"
        :timeout="false"
        :action="notification.action"
      />
    </div>
  </base-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '@/components/notification/Notification';

export default {
  name: 'NotificationPage',
  components: {
    Notification,
  },
  computed: {
    ...mapGetters(['notifications']),
    hasNotification() {
      return this.notifications.length > 0;
    },
  },
  methods: {
    ...mapActions(['removeNotification']),
  },
};
</script>
