<template>
  <section id="base-layout" v-loading="load">
    <div
      v-show="hasContentFor(['actionTitle', 'title', 'subtitle'])"
      class="layout-head"
    >
      <slot name="actionTitle" />
      <portal-target name="actionTitle" />
      <slot name="title" />
      <portal-target name="title" />
      <slot name="subtitle" />
      <portal-target name="subtitle" />
    </div>
    <span class="layout-sidebar left">
      <slot name="sidebar-left" />
      <portal-target name="sidebar-left" />
    </span>
    <span class="layout-sidebar right">
      <slot name="sidebar-right" />
      <portal-target name="sidebar-right" />
    </span>
    <div class="layout-main"><slot /></div>
    <div v-show="hasContentFor('footer')" class="layout-footer">
      <slot name="footer" />
      <portal-target name="footer" />
    </div>
  </section>
</template>

<script>
import { Wormhole } from 'portal-vue';
import { has, isArray, some } from 'lodash';

export default {
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hasContentFor(slot) {
      const hasContent = (name) =>
        has(this.$slots, name) || Wormhole.hasContentFor(name);
      return isArray(slot) ? some(slot, hasContent) : hasContent(slot);
    },
  },
};
</script>
