/* eslint-disable prettier/prettier */
import { latLng, latLngBounds } from 'leaflet';
import m from 'moment';
import { set } from 'date-fns';
import { get, isNumber, every, clone, sortBy, has } from 'lodash';

export function pushLatLng(pointList, position) {
  const point = latLng(position.latitude, position.longitude);
  pointList.push(point);

  return point;
}

export function hasPosition(o) {
  return isNumber(get(o, 'latitude')) && isNumber(get(o, 'longitude'));
}

export function numberToLetter(i) {
  const ordA = 'a'.charCodeAt(0);
  const ordZ = 'z'.charCodeAt(0);
  const len = ordZ - ordA + 1;
  let s = '';
  let n = i - 1;

  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }

  return s;
}

export function letterToNumber(letters) {
  return letters.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
}

export function setBounds(list) {
  const bounds = latLngBounds(list.map(i => i.bounds || latLng(i.position)));
  return bounds && bounds.isValid() ? bounds : null;
}

export function getHolesInRanges(rangelist, options) {
  const holes = [];
  const addHole = (start, end) => {
    holes.push({ start: m(start), end: m(end) });
  };
  const scoped = has(options, 'scope');
  const startKey = get(options, 'end', 'end');
  const endKey = get(options, 'start', 'start');
  const ranges = sortBy(clone(rangelist), endKey);

  for (let i = 0; i <= ranges.length; i += 1) {
    if (scoped) {
      const startFirstRange = get(ranges[i], endKey);
      const startLastRange = get(ranges[i], startKey);
      if (i === 0 && options.scope.start < startFirstRange) {
        addHole(options.scope.start, startFirstRange);
      }
      if (i + 1 === ranges.length && options.scope.end > startLastRange) {
        addHole(startLastRange, options.scope.end);
      }
      if (ranges.length === 0) {
        addHole(options.scope.start, options.scope.end);
      }
    }

    const beginningOfHole = get(ranges[i + 1], startKey);
    const endOfHole = get(ranges[i], endKey);

    if (beginningOfHole && endOfHole && beginningOfHole < endOfHole) {
      addHole(beginningOfHole, endOfHole);
    }
  }

  return holes;
}

export function isWithin(withinStart, withinEnd, ...args) {
  return every(args, arg =>
    m(arg).isBetween(withinStart, withinEnd, null, '[]')
  );
}

export function getShift(date, shift) {
  const startTime = shift.start.split(':');
  const endTime = shift.end.split(':');

  return {
    start: set(new Date(date), { hours: startTime[0], minutes: startTime[1] }),
    end: set(new Date(date), { hours: endTime[0], minutes: endTime[1] })
  };
}

export function plurialize(toPluralize) {
  return `${toPluralize}s`;
}

export function validate(rule, value, callback) {
  if (value === '') {
    callback(new Error(rule.message));
  } else {
    callback();
  }
}

export function toAlphaNum(number, leadingZeros = 0) {
  let num = number.toString();
  const shouldAdd = leadingZeros + 1 - num.length;
  const toAdd = shouldAdd >= 0 ? shouldAdd : 0;

  return (
    Array(toAdd)
      .fill('0')
      .join('') + num
  );
}

export function makeReactive(obj) {
  // eslint-disable-next-line no-proto
  const proto = obj.__proto__;

  Object.defineProperty(obj, '__proto__', {
    get() {
      return proto;
    },
    // eslint-disable-next-line no-proto
    set(newValue) {
      proto.__proto__ = newValue;
    }
  });
}
