<template>
  <div>
    <div v-if="geocodeFail"><geocode-gate :to-geocode="lowGecodeScore" /></div>

    <div v-if="!geocodeFail">
      <middlebar>
        <cs-button
          slot="end"
          type="primary"
          :disabled="!hasChange"
          :loading="saveLoad"
          @click="save"
        >
          save
        </cs-button>
        <cs-button slot="end" :disabled="!hasChange" @click="cancel">
          cancel
        </cs-button>
        <cs-button
          slot="end"
          square
          icon="user-plus"
          type="primary"
          placement="top"
          :tooltip="$t('sentence.createRoute')"
          @click="displayRouteCreator = true"
        />
        <cs-button
          slot="end"
          type="primary"
          icon="arrow_back"
          icon-type="material"
          @click="$router.back()"
        >
        </cs-button>
      </middlebar>

      <gantt v-if="ganttRoutes.length > 0" ref="gantt" :routes="ganttRoutes">
      </gantt>

      <el-row v-else type="flex" justify="space-around">
        <div class="flex-center">
          <p>{{ $t('sentence.noRouteCreated') }}</p>
          <cs-button
            icon="user-plus"
            placement="top"
            @click="displayRouteCreator = true"
          >
            {{ $t('sentence.createRoute') | upperFirst }}
          </cs-button>
        </div>
      </el-row>
    </div>
    <route-batch-creator v-model="displayRouteCreator" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, filter, includes, concat } from 'lodash';
import { differenceInMinutes } from 'date-fns';
import GeocodeGate from './../../components/GeocodeGate';
import Gantt from './../../components/gantt/GanttPlanning';
import RouteBatchCreator from './../../components/RouteBatchCreator';
import Middlebar from './../../components/Middlebar';

export default {
  components: {
    RouteBatchCreator,
    Gantt,
    GeocodeGate,
    Middlebar,
  },
  data: () => ({
    loading: true,
    saving: false,
    saveLoad: false,
    displayRouteCreator: false,
  }),
  computed: {
    ...mapGetters(['dateRange', 'routes', 'routesByDate', 'resources']),
    hasChange() {
      return this.routes.hasAction;
    },
    ganttRoutes() {
      return this.routesByDate;
    },
    geocodeFail() {
      return !isEmpty(this.lowGecodeScore);
    },
    allRoutes() {
      const filtered = filter(this.routesByDate, (r) =>
        includes(['created', 'ready', 'preparing'], r.state)
      );
      return concat(filtered, [this.unplannedRoute]);
    },
  },
  mounted() {
    this.$store.dispatch('setDatePicker', {
      clickHandle: this.fetchData,
      display: true,
    });
    if (
      isEmpty(this.routesByDate) ||
      differenceInMinutes(new Date(), this.routes.lastLoad) > 5
    ) {
      this.fetchData();
    }
  },
  methods: {
    ...mapActions(['get', 'cancelActionQueue', 'processActionQueue']),
    save() {
      this.processActionQueue(['route']);
    },
    cancel() {
      this.cancelActionQueue(['route']);
    },
    fetchData() {
      this.loading = true;
      this.cancel();
      this.$store.dispatch('setDatePickerLoading', true);
      const getRoute = this.get({
        type: 'route',
        id: 'all',
        query: {
          from: this.dateRange.from,
          to: this.dateRange.to,
          include: 'deliveries',
          state: 'created',
        },
      });

      const getResource = this.get({
        type: 'resource',
        id: 'all',
      });

      Promise.all([getRoute, getResource]).then(() => {
        this.loading = false;
        this.$store.dispatch('setDatePickerLoading', false);
      });
    },
  },
};
</script>
