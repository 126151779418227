import { get, some, isNil, toString } from 'lodash';
import { differenceInSeconds } from 'date-fns';
import { latLng } from 'leaflet';
import { invertColor } from '@/utils/color';
import { hasPosition } from '@/utils/helpers';
import svgIcon from '@/svgIcon';
import { selected, normal } from '@/iconPaths';
import ApiEntity from './ApiEntity';
import Appointment from './Appointment';
import StateHistory from './StateHistory';
import Sla from './Sla';
import * as Sentry from '@sentry/browser';
import store from '@/store';

const DELTA_WARN = 600;
const DELTA_MAX = 1200;

class ApiActivity extends ApiEntity {
  id;
  state;
  external_id;
  external_data;
  item_count;
  notification;
  order;
  text;
  place;
  place_geocoded;
  appointment;
  slas;
  planned_start;
  planned_end;
  start;
  end;
  consist_of;
  tools;
  receiver_name;
  receiver_phone;
  email;
  delivery_term;
  destination_name;
  route_id = null; // This default value is for filterDataset
  duration;
  proof;
  state_history;
  items;
  transitions;
  parent_id;
  child;
  parent;
  constructor(o) {
    super();
    this.fill(o);
    if (o.appointment) {
      this.appointment = new Appointment(o.appointment);
    }
    this.planned_start = this.castDate(o.planned_start);
    this.planned_end = this.castDate(o.planned_end);
    this.start = this.castDate(o.start);
    this.end = this.castDate(o.end);
    this.state_history = new StateHistory(o.state_history);
    this.child = Activity.create(o.child);
    this.parent = Activity.create(o.parent);
    this.slas = Sla.create(o.slas);
  }
}

export default class Activity extends ApiActivity {
  isOptimMode = false;
  defaultColor = '#1a4e95';
  isSelected = false;
  iconText;
  constructor(apiObject) {
    super(apiObject);
  }

  static create(data) {
    return Activity.createInstance(data);
  }

  get inAppointment() {
    return this.appointment && this.appointment.isValid()
      ? this.appointment.isWithin(this.planned_start)
      : undefined;
  }

  get route() {
    return store.getters.routes.items[this.route_id];
  }

  get svg() {
    let svg = this.isSelected ? selected : normal;
    if (this.appointment && this.appointment.isValid()) {
      svg = svg.rdv;
    }
    if (this.route_id) {
      if (this.appointment && this.appointment.isValid()) {
        svg = !this.inAppointment ? svg.plannedWithWarnings : svg.planned;
      } else {
        svg = !this.inSla ? svg.plannedWithWarnings : svg.planned;
      }
    } else {
      svg = svg.unplanned;
    }

    return svg;
  }

  get icon() {
    let text = this.iconText || this.order;
    let extraClasses = '';
    if (this.isOptimMode) {
      text = '';
      extraClasses = `${extraClasses} fa fa-compass`;
    } else {
      extraClasses = `${extraClasses} text-icon`;
    }
    if (isNil(this.route_id) && !this.isSelected) {
      text = '';
    }
    const options = {
      text: toString(text),
      svg: this.svg,
      markerColor: this.color,
      iconColor: this.route_id ? invertColor(this.color, true) : this.color,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      className: 'top-marker',
      extraClasses,
    };

    return svgIcon(options);
  }

  get color() {
    return this.route ? this.route.color : this.defaultColor;
  }

  get completed_at() {
    return this.state_history.findItem(['done', 'refused'])?.datetime;
  }

  get starting_at() {
    const startingAt = get(this.state_history.findItem('on_site'), 'datetime');

    return startingAt ? new Date(startingAt) : undefined;
  }

  get position() {
    if (hasPosition(this.place) && get(this, 'place_geocoded.score', 0) < 100) {
      return latLng(
        get(this, 'place.latitude', 0),
        get(this, 'place.longitude', 0)
      );
    }

    return latLng(
      get(this, 'place_geocoded.latitude', 0),
      get(this, 'place_geocoded.longitude', 0)
    );
  }

  get inSla() {
    return some(this.slas, (sla) => {
      try {
        return sla.isWithin(this.planned_start);
      } catch (e) {
        Sentry.captureMessage(
          `${e.message} for slas of activity id : ${this.id}`
        );
        return false;
      }
    });
  }

  getDelay() {
    return this.planned_start && this.completed_at
      ? differenceInSeconds(this.completed_at, this.planned_start)
      : undefined;
  }

  getDelayClass() {
    const diff = this.getDelay();
    if (isNil(diff)) {
      return 'primary-color';
    }
    if (diff > DELTA_MAX) {
      return 'danger-color';
    }
    if (diff > DELTA_WARN) {
      return 'warning-color';
    }
    return 'success-color';
  }

  resetIcon() {
    this.iconText = undefined;
  }
}
