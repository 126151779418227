<template>
  <section>
    <el-row class="summary" type="flex" justify="space-around">
      <el-col :span="6" class="box box-state">
        <state
          v-model="value"
          editable
          type="route"
          display="title"
          :transitions="value.transitions"
          @change="mutate"
        />
        <span v-if="lastState.reason">
          <p class="title">
            {{ $t('commons.reason') }}
            <em>{{ lastState.reason }}</em>
          </p>
          <p v-if="lastState.message">{{ lastState.message }}</p>
        </span>
        <div v-if="value.isOvertime" class="subtitle">
          <i class="warning-color material-icons">access_time</i>
          <em class="warning-color">{{ $t('commons.overtime') }}</em>
        </div>
      </el-col>

      <el-col :span="6" class="box">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('commons.planning') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">{{ planning | seconds({ noSign: true }) }}</p>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('commons.duration') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">{{ value.duration | seconds({ noSign: true }) }}</p>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('commons.distance') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">{{ value.distance | km }}</p>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="6" class="box">
        <h4>{{ $t('commons.start') }}</h4>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.hourValued') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{ value.firstStart | format(SHORT_DATETIME_FORMAT) }}
            </p>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.hourReal') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{ value.start | format(SHORT_DATETIME_FORMAT) | empty('N/A') }}
            </p>
          </el-col>
        </el-row>

        <el-row v-if="deltaStart" type="flex" justify="space-between">
          <el-col :span="12"> <p class="title">delta</p> </el-col>
          <el-col :span="12">
            <p :class="deltaClass(deltaStart)" class="data">
              {{ deltaStart | seconds }}
            </p>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="6" class="box">
        <h4>{{ $t('commons.end') }}</h4>
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.hourValued') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{ value.lastEnd | format(SHORT_DATETIME_FORMAT) }}
            </p>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <p class="title">{{ $t('pages.track.hourReal') }}</p>
          </el-col>
          <el-col :span="12">
            <p class="data">
              {{ value.end | format(SHORT_DATETIME_FORMAT) | empty('N/A') }}
            </p>
          </el-col>
        </el-row>

        <el-row v-if="deltaEnd" type="flex" justify="space-between">
          <el-col :span="12"> <p class="title">delta</p> </el-col>
          <el-col :span="12">
            <p :class="deltaClass(deltaEnd)" class="data">
              {{ deltaEnd | seconds }}
            </p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { differenceInSeconds, parseISO } from 'date-fns';
import State from '@/components/State';
import { SHORT_DATETIME_FORMAT } from '@/utils/date';

export default {
  components: {
    State,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    SHORT_DATETIME_FORMAT: SHORT_DATETIME_FORMAT,
  }),
  computed: {
    lastState() {
      return this.value.state_history.last();
    },
    deltaStart() {
      return this.getDelta(this.value.planned_start, this.value.start);
    },
    deltaEnd() {
      return this.getDelta(this.value.planned_end, this.value.end);
    },
    planning() {
      return this.getDelta(this.value.planned_start, this.value.planned_end);
    },
  },
  methods: {
    ...mapActions(['patch']),
    getDelta(start, end) {
      if (start && end) {
        return differenceInSeconds(parseISO(end), parseISO(start));
      }
      return null;
    },
    deltaClass(delta) {
      if (delta > 300) {
        return 'danger-color';
      }
      if (delta > 0 && delta < 600) {
        return 'warning-color';
      }
      return 'success-color';
    },
    mutate(transition) {
      this.patch({
        type: 'route',
        id: this.value.id,
        url: `route/${this.value.id}/${transition}`,
        query: {
          include: 'transitions',
        },
      });
    },
  },
};
</script>
