<template lang="html">
  <span>
    <table>
      <tr>
        <th
          v-for="(label, idx_label) in labels"
          :key="idx_label"
          class="array-mapper-item"
        >
          {{ label }}
        </th>
      </tr>
      <tr v-for="(item, idx) in maps" :key="idx">
        <td
          v-for="(value, name) in item.map"
          :key="name"
          class="array-mapper-item"
        >
          <select-column
            class="array-mapper"
            :path="`${idx}.map.${name}`"
            :options="options"
            :value="value"
            @change="updateItemMap"
          />
        </td>
      </tr>
    </table>

    <cs-button @click="increment"> + </cs-button>
    <cs-button v-if="maps.length > 1" @click="decrement"> - </cs-button>
  </span>
</template>

<script>
import { zipObject, keys, isEmpty, isNil, set } from 'lodash';
import SelectColumn from './SelectColumn';

export default {
  name: 'ArrayMapper',
  components: { SelectColumn },
  props: {
    arrayModels: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultField: {
      type: Object,
    },
    preview: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    maps: [],
    labels: [], // table header
  }),
  computed: {
    modelItems() {
      return keys(this.arrayModels);
    },
  },
  mounted() {
    this.increment();
    this.labels = Object.keys(this.maps[0].map);
  },
  methods: {
    increment() {
      this.maps.push({
        map: zipObject(this.modelItems),
      });
    },
    decrement() {
      this.maps.splice(this.maps.length - 1, 1);
      this.$emit('change', this.maps);
    },
    updateItemMap({ value, path }) {
      if (isEmpty(value) || isNil(value)) {
        set(this.maps, path, undefined);
      } else {
        set(this.maps, path, value);
      }
      this.$emit('change', this.maps);
    },
  },
};
</script>

<style lang="scss">
.array-mapper-item {
  text-align: center !important;
}
</style>
