<template>
  <div class="constraint-edit">
    <el-row v-if="edit" type="flex" align="middle" class="edit-element">
      <el-form
        ref="form"
        :model="form"
        label-position="top"
        :rules="rules"
        @submit.native.prevent
      >
        <el-row type="flex" align="middle">
          <el-col :span="6">
            <span class="left-key-display"> {{ $t('commons.type') }} </span>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="type">
              <el-select
                v-if="!repository"
                v-model="type"
                clearable
                filterable
                allow-create
                class="constraint-select"
                placeholder="_________________"
                :no-data-text="$t(`components.ConstraintList.${name}.create`)"
                @keyup.enter.native="add"
                @change.native="newTag"
              >
                <el-option
                  v-for="item in organization[organizationKey]"
                  :key="item.type"
                  :value="item.type"
                  :label="item.type"
                >
                </el-option>
              </el-select>
              <el-input
                v-else
                v-model="type"
                :placeholder="$t('commons.type')"
                :value="type"
                :label="type"
                @keyup.enter.native="add"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" align="middle">
          <el-col v-if="!repository && keyValue" :span="6">
            <span class="left-key-display">
              {{ $t(`components.ConstraintList.${name}.labelValue`) }}
            </span>
          </el-col>
          <el-col v-if="!repository && keyValue" :span="18">
            <el-form-item prop="value">
              <el-input-number
                v-model.number="value"
                size="mini"
                :controls="true"
                controls-position="right"
                placeholder="_________________"
                :step="1"
                :min="0"
                @keyup.enter.native="add"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="6">
            <span class="left-key-display" @click="add">
              {{ $t('commons.validate') }} &nbsp;
            </span>
            <el-form-item>
              <el-tooltip :content="$t('commons.add')">
                <i class="el-icon-check" @click="add"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>

    <el-row>
      <div class="list-tags">
        <span
          v-for="(item, index) in list"
          :key="item.type"
          :class="`constraint-tag constraint-tag-${name}`"
        >
          <span v-if="item.value > 0" class="tag-text">
            {{ item.type | upperFirst }} {{ item.value }}
          </span>
          <span v-else class="tag-text"> {{ item.type }} </span>
          <span v-if="edit" @click="deleteItem(index)">
            <i class="material-icons closing">close</i>
          </span>
        </span>
      </div>
    </el-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { getRequireRule } from '@/validation';

export default {
  name: 'ConstraintListEdit',
  model: {
    prop: 'list',
    event: 'change',
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
      validator: (v) => ['capacities', 'consist_of', 'tools'].includes(v),
    },
    keyValue: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    repository: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    type: null,
    value: null,
  }),
  computed: {
    ...mapGetters(['organization']),
    rules() {
      return {
        type: [
          getRequireRule('commons.type'),
          {
            validator: this.validatorType,
            trigger: 'blur',
          },
        ],
        value: [
          {
            validator: this.validatorValue,
            trigger: 'blur',
          },
        ],
      };
    },
    form() {
      return {
        type: this.type,
        value: this.value,
      };
    },
    organizationKey() {
      return this.keyValue ? 'capacities' : 'tools';
    },
    validationList() {
      return !this.repository
        ? this.list
        : this.organization[this.organizationKey];
    },
  },
  methods: {
    ...mapActions(['patch']),
    add() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const item = this.keyValue
            ? { type: this.type, value: this.value }
            : { type: this.type };
          this.list.push(item);

          if (
            !this.repository &&
            !_.some(this.organization[this.organizationKey], {
              type: this.type,
            })
          ) {
            this.patch({
              type: 'organization',
              id: this.organization.id,
              url: `organization/${this.organization.id}/${this.organizationKey}`,
              data: { type: this.type },
            });
          }
        }
      });
      this.type = null;
    },
    deleteItem(idx) {
      this.list.splice(idx, 1);
    },
    newTag(event) {
      if (event.target.value) {
        this.type = event.target.value;
      }
    },
    validatorType(rule, value, callback) {
      return _.some(this.validationList, { type: value })
        ? callback(new Error(this.$t('components.ConstraintList.error')))
        : callback();
    },
    validatorValue(rule, value, callback) {
      return this.keyValue && _.isNil(value)
        ? callback(new Error('required'))
        : callback();
    },
  },
};
</script>

<style lang="scss">
.constraint-edit {
  .el-form {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
