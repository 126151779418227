import ApiEntity from './ApiEntity';

export default class Travel extends ApiEntity {
  start;
  end;
  distance = 0;
  duration = 0;
  constructor(o) {
    super();
    this.fill(o);
    this.start = this.castDate(o.start);
    this.end = this.castDate(o.end);
  }

  static create(data) {
    return Travel.createInstance(data);
  }
}
