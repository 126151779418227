<template>
  <div>
    <cs-button
      type="warning"
      icon="barcode"
      @click.native="dialogVisible = true"
      @click.exact="addListener"
    >
      {{ $t('components.BarcodeScanner.activate') }}
    </cs-button>

    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :title="$t('components.BarcodeScanner.transfer') | upperFirst"
      :visible.sync="dialogVisible"
      size="tiny"
    >
      <el-row type="flex" justify="center">
        <el-col :sm="15" :md="8">
          <el-progress
            :stroke-width="18"
            :percentage="percentage"
            :state="percentage == '100%' ? '' : 'success'"
            type="circle"
          />
        </el-col>
      </el-row>

      <span slot="footer">
        <cs-button
          class="float-none"
          type="default"
          @click.native="dialogVisible = false"
          @click.exact="removeListener"
          >{{ $t('commons.close') }}</cs-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'BarcodeScanner',
  props: {
    items: {
      type: Array,
      required: true,
      validator(items) {
        let error = false;

        _.forEach(items, (i) => {
          if (!_.has(i, 'barcode')) error = true;
        });

        return !error;
      },
    },
    transitionSuccess: {
      type: String,
      required: true,
    },
    transitionError: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialogVisible: false,
    success: [],
    errors: [],
    current: '',
    error: false,
  }),
  computed: {
    percentage() {
      const expected = _.filter(this.items, (i) => i.state === 'incoming');
      const diff = _.difference(
        _.map(expected, 'barcode'),
        _.concat(this.success, this.errors)
      ).length;
      const sum = expected.length === 0 ? 1 : expected.length;
      return Math.round(((sum - diff) / sum) * 100);
    },
  },
  watch: {
    success(barcodes) {
      this.updateTransition(barcodes, this.transitionSuccess);
    },
    errors(barcodes) {
      this.updateTransition(barcodes, this.transitionError);
    },
  },
  methods: {
    updateTransition(barcodes, transition) {
      _.map(this.items, (i) => {
        if (_.indexOf(barcodes, i.barcode) !== -1) {
          i.transition = transition;
        }
      });
    },
    addListener(e) {
      e.target.blur();
      window.addEventListener('keyup', this.barcodeBuilder);
    },
    removeListener() {
      window.removeEventListener('keyup', this.barcodeBuilder);
    },
    barcodeBuilder(e) {
      e.preventDefault();

      const keyCode = e.charCode === 0 ? e.keyCode : e.charCode;

      if (keyCode === 13) {
        if (this.current.charAt(0) === 'V' && this.current.charAt(1) === '1') {
          // VALIDATION
          this.current = '';
          return this.end();
        } else if (
          this.current.charAt(0) === 'A' &&
          this.current.charAt(1) === '1'
        ) {
          // ERREUR
          this.current = '';
          this.error = true;
          return this.error;
        }

        if (this.error) {
          this.errors = _.union(this.errors, [this.current]);
          _.pull(this.success, this.current);
          this.error = false;
        } else {
          this.success = _.union(this.success, [this.current]);
          _.pull(this.errors, this.current);
        }

        this.current = '';
      }

      this.current += this.translateBarcode(keyCode);
      return null;
    },
    translateBarcode(keyCode) {
      let code = '';

      if (keyCode === 38 || keyCode === 49) code = '1';
      if (keyCode === 233 || keyCode === 50) code = '2';
      if (keyCode === 34 || keyCode === 51) code = '3';
      if (keyCode === 39 || keyCode === 52) code = '4';
      if (keyCode === 40 || keyCode === 53) code = '5';
      if (keyCode === 167 || keyCode === 54) code = '6';
      if (keyCode === 232 || keyCode === 55) code = '7';
      if (keyCode === 33 || keyCode === 56) code = '8';
      if (keyCode === 231 || keyCode === 57) code = '9';
      if (keyCode === 224 || keyCode === 48) code = '0';

      if (keyCode === 97 || keyCode === 65) code = 'A';
      if (keyCode === 98 || keyCode === 66) code = 'B';
      if (keyCode === 99 || keyCode === 67) code = 'C';
      if (keyCode === 100 || keyCode === 68) code = 'D';
      if (keyCode === 101 || keyCode === 69) code = 'E';
      if (keyCode === 102 || keyCode === 70) code = 'F';
      if (keyCode === 103 || keyCode === 71) code = 'G';
      if (keyCode === 104 || keyCode === 72) code = 'H';
      if (keyCode === 105 || keyCode === 73) code = 'I';
      if (keyCode === 106 || keyCode === 74) code = 'J';
      if (keyCode === 107 || keyCode === 75) code = 'K';
      if (keyCode === 108 || keyCode === 76) code = 'L';
      if (keyCode === 109 || keyCode === 77) code = 'M';
      if (keyCode === 110 || keyCode === 78) code = 'N';
      if (keyCode === 111 || keyCode === 79) code = 'O';
      if (keyCode === 112 || keyCode === 80) code = 'P';
      if (keyCode === 113 || keyCode === 81) code = 'Q';
      if (keyCode === 114 || keyCode === 82) code = 'R';
      if (keyCode === 115 || keyCode === 83) code = 'S';
      if (keyCode === 116 || keyCode === 84) code = 'T';
      if (keyCode === 117 || keyCode === 85) code = 'U';
      if (keyCode === 118 || keyCode === 86) code = 'V';
      if (keyCode === 119 || keyCode === 87) code = 'W';
      if (keyCode === 120 || keyCode === 88) code = 'X';
      if (keyCode === 121 || keyCode === 89) code = 'Y';
      if (keyCode === 122 || keyCode === 90) code = 'Z';

      return code;
    },
    end() {
      this.removeListener();
      this.current = '';
      this.dialogVisible = false;
    },
  },
};
</script>
