<template>
  <section>
    <el-row :gutter="20">
      <el-col :span="12" style="text-align: left" class="activityPrint-date">
        <p>
          {{ route.planned_start | format('dd.MM.yyyy') }} <br />
          <object-display :data="organization" :only="['siret']" />
        </p>
      </el-col>
      <el-col :span="9" :offset="3">
        <div class="activityPrint-logo"><img :src="organization.image" /></div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="24" style="text-align: left; margin-top: 30px">
        <h3 class="white-title upperFirst activityPrint-title">
          {{ $t('commons.route.single') }} <span class="bullet-point"> . </span>
        </h3>
        <h3 v-if="route" class="white-title activityPrint-routeName">
          {{ route.name }}
        </h3>
        <br />
      </el-col>
    </el-row>

    <el-row v-if="route.resource" :gutter="20">
      <el-col :span="6">
        <h4 class="activityPrint-category-h4">
          {{ $t('commons.activity.plural') }}
        </h4>
        <div class="print-round-orders">
          <span v-if="deliveries"> {{ deliveries.length }} </span>
        </div>
        <br />
      </el-col>

      <el-col :span="6">
        <h4 class="activityPrint-category-h4">{{ $t('commons.driver') }}</h4>
        <object-display
          :data="route.resource.user"
          :only="{
            first_name: 'commons.first_name',
            last_name: 'commons.last_name',
          }"
        />
        <br />
      </el-col>

      <el-col :span="6">
        <h4 class="activityPrint-category-h4">
          {{ $t('commons.vehicle.single') }}
        </h4>
        <object-display :data="route.resource.vehicle" :only="['name']" />
      </el-col>

      <el-col :span="6">
        <h4 class="activityPrint-category-h4">
          {{ $t('commons.item.plural') }}
        </h4>
        <object-display
          :data="{
            total: deliveriesTotal,
            weight: `${total('weight')} kg`,
            volume: `${total('volume')} m3`,
          }"
        />
      </el-col>
    </el-row>

    <div class="page-break"></div>

    <span v-if="deliveries">
      <activity-print
        v-for="activity in deliveries"
        :key="activity.id"
        :route-print="true"
        :activity="activity"
        :deliveries="deliveries.length"
      />
    </span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { reduce, get, filter, first } from 'lodash';
import ActivityPrint from './ActivityPrint';
import ObjectDisplay from '../ObjectDisplay';

export default {
  components: {
    ActivityPrint,
    ObjectDisplay,
  },
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['organization']),
    deliveries() {
      return get(this.route, 'deliveries');
    },
    deliveriesTotal() {
      return reduce(
        this.deliveries,
        (acc, item) => {
          let sum = acc;
          sum += item.items.length;
          return sum;
        },
        0
      );
    },
  },
  methods: {
    total(type) {
      const $total = reduce(
        this.deliveries,
        (acc, item) => {
          const value = get(
            first(filter(item.consist_of, (detail) => detail.type === type)),
            'value',
            0
          );
          return acc + parseFloat(value);
        },
        0
      );

      return $total.toFixed(2);
    },
  },
};
</script>
