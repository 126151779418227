<template lang="html">
  <span>
    <div class="btn-group">
      <cs-button
        v-show="parentId"
        type="success"
        icon-type="material"
        icon="arrow_upward"
        @click="
          $router.push({
            name: 'deliveryDetail',
            params: { id: parentId },
          })
        "
      >
        {{ $t('commons.showParent') }}
      </cs-button>

      <cs-button
        v-show="child"
        type="success"
        icon-type="material"
        icon="arrow_downward"
        @click="
          $router.push({
            name: 'deliveryDetail',
            params: { id: child.id },
          })
        "
      >
        {{ $t('commons.showChild') }}
      </cs-button>

      <cs-button
        v-show="canReplan"
        type="warning"
        icon-type="material"
        icon="autorenew"
        @click="visible = true"
      >
        {{ $t('commons.replan') }}
      </cs-button>
    </div>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="visible"
      :title="$t('commons.replan') | upperFirst"
    >
      <p class="upperFirst">{{ $t('sentence.replanDesc') }}</p>
      <p class="upperFirst">
        <em>{{ $t('sentence.replanWarn') }}.</em>
      </p>

      <el-form :model="form" :rules="rules">
        <el-form-item prop="date" :label="$t('sentence.replanDate')">
          <el-date-picker v-model="form.date" type="date" name="datepicker" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="btn-group">
        <cs-button @click="visible = false">
          {{ $t('commons.cancel') }}
        </cs-button>
        <cs-button type="primary" @click="replan">
          {{ $t('commons.validate') }}
        </cs-button>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import { isBefore, isPast, format } from 'date-fns';
import { mapActions } from 'vuex';
import { QUERY_DATE_FORMAT } from '@/utils/date';

export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },
    unavailableStates: {
      type: Array,
      default: () => ['refused', 'failed', 'returned'],
    },
  },
  data() {
    return {
      visible: false,
      form: {
        date: undefined,
      },
      rules: {
        date: [
          {
            required: true,
            message: this.$t('commons.required.female'),
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (isBefore(value, this.activity.planned_start)) {
                callback(new Error(this.$t('error.shouldBeAfter')));
              }
              if (isPast(value)) {
                callback(new Error(this.$t('error.shouldBeFuture')));
              }
            },
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    canReplan() {
      return (
        this.unavailableStates.includes(this.activity.state) && !this.child
      );
    },
    child() {
      return this.activity.child;
    },
    parentId() {
      return this.activity.parent_id;
    },
  },
  methods: {
    ...mapActions(['patch']),
    replan() {
      this.patch({
        type: 'delivery',
        id: this.activity.id,
        url: `delivery/${this.activity.id}/replan`,
        query: {
          date: format(this.form.date, QUERY_DATE_FORMAT),
        },
      }).then(() => {
        this.visible = false;
      });
    },
  },
};
</script>

<style lang="scss"></style>
