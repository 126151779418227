import _ from 'lodash';

const Address = {
  name: 'Address',
  properties: {
    street_number: {
      model_type: 'String',
    },
    street_name: {
      model_type: 'String',
    },
    city: {
      model_type: 'String',
    },
    postcode: {
      model_type: 'String',
    },
    country: {
      model_type: 'String',
    },
    country_code: {
      model_type: 'String',
    },
  },
};

const Depot = {
  name: 'Depot',
  properties: Object.assign(
    {
      name: {
        model_type: 'String',
      },
      external_id: {
        model_type: 'String',
      },
    },
    Address.properties
  ),
};

const Place = {
  name: 'Place',
  properties: Object.assign({}, Address.properties),
};

const Capacity = {
  name: 'Capacity',
  properties: {
    type: {
      model_type: 'String',
    },
    value: {
      model_type: 'Comparable',
    },
  },
};

const Tool = {
  name: 'Tool',
  properties: {
    type: {
      model_type: 'String',
    },
  },
};

const User = {
  name: 'User',
  properties: {
    name: {
      model_type: 'String',
    },
    first_name: {
      model_type: 'String',
    },
    last_name: {
      model_type: 'String',
    },
    email: {
      model_type: 'String',
    },
    capacities: {
      model_type: 'Array',
      items: Capacity.properties,
    },
    tools: {
      model_type: 'Array',
      items: Tool.properties,
    },
  },
};

const Vehicle = {
  name: 'Vehicle',
  properties: {
    name: {
      model_type: 'String',
    },
    capacities: {
      model_type: 'Array',
      items: Capacity.properties,
    },
    tools: {
      model_type: 'Array',
      items: Tool.properties,
    },
  },
};

const Resource = {
  name: 'Resource',
  properties: {
    name: {
      model_type: 'String',
    },
    external_id: {
      model_type: 'String',
    },
    user: User.properties,
    vehicle: Vehicle.properties,
    depot: Depot.properties,
  },
};

const activityModel = {
  name: 'Activity',
  properties: {
    external_id: {
      model_type: 'String',
    },
    order: {
      model_type: 'Comparable',
    },
    state: {
      model_type: 'String',
    },
    duration: {
      model_type: 'Comparable',
    },
    item_count: {
      model_type: 'Comparable',
    },
    slas: {
      model_type: 'Range',
      paths: ['start', 'end'],
    },
    place: Place.properties,
    consist_of: {
      model_type: 'Array',
      items: Capacity.properties,
    },
    tools: {
      model_type: 'Array',
      items: Tool.properties,
    },
  },
};

const routeModel = {
  name: 'Route',
  properties: {
    name: {
      model_type: 'String',
    },
    state: {
      model_type: 'String',
    },
    duration: {
      model_type: 'Comparable',
    },
    distance: {
      model_type: 'Comparable',
    },
    planned_start: {
      model_type: 'Date',
    },
    planned_end: {
      model_type: 'Date',
    },
    deliveries: {
      model_type: 'Array',
      items: activityModel.properties,
    },
    depot: Depot.properties,
    resource: Resource.properties,
  },
};

const routePaths = {};
const activityPaths = {};

const buildPath = (source, result, previousPath) => {
  const getPath = (previous, path) => _.join(_.compact([previous, path]), '.');
  const setType = (previous, path, type) => {
    result[getPath(previous, path)] = type;
  };

  _.forEach(source, (v, k) => {
    if (k === 'model_type') {
      return;
    }
    if (_.get(v, 'model_type') === 'Array') {
      buildPath(v.items, result, getPath(previousPath, k));
    }
    if (_.isObject(v) && !_.has(v, 'model_type')) {
      buildPath(v, result, getPath(previousPath, k));
    }
    setType(previousPath, k, _.get(v, 'model_type'));
  });
};

buildPath(routeModel.properties, routePaths);
buildPath(activityModel.properties, activityPaths);

export { routeModel, activityModel, activityPaths, routePaths };
