<template lang="html">
  <sidebar-layout>
    <div
      v-for="(entity, index) in entities"
      :key="index"
      class="sidebar-container"
    >
      <route-sidebar-item :route="entity" @unplan="unplanRoute(entity)" />
    </div>

    <route-footer
      slot="footer"
      :routes="entities"
      @unplan="unplanRoute(entity)"
    />
  </sidebar-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import SidebarLayout from '../../SidebarLayout';
import RouteSidebarItem from './RouteSidebarItem';
import RouteFooter from './RouteFooter';

export default {
  components: {
    RouteSidebarItem,
    RouteFooter,
    SidebarLayout,
  },
  computed: {
    ...mapGetters(['selection', 'routes']),
    entities() {
      return this.selection.data;
    },
  },
  watch: {
    entities(v) {
      if (isEmpty(v)) {
        this.setSelectionSidebar(false);
      }
    },
  },
  methods: {
    ...mapActions(['setSelectionSidebar', 'queueAction']),
    unplanRoute(r) {
      const unplanned = r.deliveries.map((a) => {
        a.route_id = null;
        a.resetIcon();
        return a;
      });
      r.deliveries = [];
      r.travels = [];
      r.polyline = null;
      this.queueAction({
        action: 'patch',
        type: 'delivery',
        data: unplanned,
      });
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: r,
      });
    },
  },
};
</script>
