var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('portal',{attrs:{"to":"search"}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-search","type":"search","placeholder":_vm.$t('commons.search')},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),(_vm.searchClient)?_c('ais-instant-search',{attrs:{"index-name":_vm.indexName,"search-client":_vm.searchClient,"class-names":{
      'ais-InstantSearch': 'search-page',
    }}},[_c('ais-configure',{attrs:{"query":_vm.query,"filters":_vm.filters,"hitsPerPage":_vm.hitsPerPage}}),_c('div',{staticClass:"result-row"},[_c('div',{staticClass:"search-controls"},[_c('label',{staticClass:"label",attrs:{"for":"datepicker"}},[_vm._v("trier par date")]),_c('el-date-picker',{attrs:{"name":"datepicker","type":"daterange","align":"right"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('el-switch',{staticClass:"switch",attrs:{"active-text":_vm._f("upperFirst")(_vm.$t('commons.datePlanned')),"inactive-text":_vm._f("upperFirst")(_vm.$t('commons.dateSla'))},model:{value:(_vm.dateSwitch),callback:function ($$v) {_vm.dateSwitch=$$v},expression:"dateSwitch"}}),_c('label',{staticClass:"label",attrs:{"for":"statesort"}},[_vm._v("trier par état")]),_c('ais-refinement-list',{attrs:{"name":"statesort","attribute":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var items = ref.items;
    var refine = ref.refine;
return _c('div',{},_vm._l((items),function(item){return _c('div',{key:item.value,staticClass:"item-refinement-list"},[_c('el-checkbox',{attrs:{"value":item.isRefined},on:{"change":function($event){return refine(item.value)}}},[_c('activity-state',{attrs:{"type":"delivery","value":item.label}},[_vm._v(" "+_vm._s(_vm.$t(("commons.states.delivery." + (item.value))))+" ("+_vm._s(item.count.toLocaleString())+") ")])],1)],1)}),0)}}],null,false,2264433435)})],1),_c('section',{staticClass:"result-left"},[_c('ais-state-results',{staticClass:"table-search-results",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hits = ref.hits;
return [_c('el-table',{attrs:{"fit":"","data":hits},on:{"row-click":_vm.focus}},[_c('el-table-column',{attrs:{"label":_vm.$t('commons.state'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('activity-state',{attrs:{"type":"delivery","value":row}})]}}],null,true)}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('p',[_c('label',{staticClass:"label",attrs:{"for":"recipient"}},[_vm._v(" "+_vm._s(_vm.$t('commons.recipient'))+": ")]),_vm._v(" "+_vm._s(_vm._f("empty")(row.receiver_name,'N/A'))+" ")]),_c('p',_vm._l((row.items),function(item){return _c('item-status',{key:item.id,attrs:{"item":item}})}),1)]}}],null,true)}),_c('el-table-column',{attrs:{"label":_vm.$t('commons.address.single'),"width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('activity-address',{attrs:{"address":row.address_geocode || row.address_origin,"full":false,"no-label":""}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":_vm.$t('commons.action.single'),"min-width":"200","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('div',{staticClass:"btn-group btn-column"},[(row.objectID)?_c('cs-button',{on:{"click":function($event){return _vm.$router.push({
                          name: 'deliveryDetail',
                          params: { id: row.objectID },
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('sentence.goToActivity'))+" ")]):_vm._e(),(row.route_id && _vm.$can('route', 'read'))?_c('cs-button',{on:{"click":function($event){return _vm.$router.push({
                          name: 'routeDetail',
                          params: { id: row.route_id },
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('sentence.goToRoute'))+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var currentRefinement = ref.currentRefinement;
                        var nbHits = ref.nbHits;
                        var refine = ref.refine;
return _c('span',{},[_c('el-pagination',{staticClass:"pagination",attrs:{"background":"","layout":"prev, pager, next, sizes","total":nbHits,"current-page":currentRefinement + 1,"page-size":_vm.hitsPerPage},on:{"update:pageSize":function($event){_vm.hitsPerPage=$event},"update:page-size":function($event){_vm.hitsPerPage=$event},"current-change":function (p) { return refine(p - 1); }}})],1)}}],null,true)})]}}],null,false,1536190584)})],1),_c('section',{staticClass:"result-right"},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hits = ref.hits;
return [_c('search-map',{ref:"map",attrs:{"latitude-key":"_geoloc.lat","longitude-key":"_geoloc.lng","custom-style":"height: 50vh","zoom":2,"hits":hits}})]}}],null,false,717408346)}),_c('ais-stats')],1)])],1):_c('el-alert',{attrs:{"type":"warning","show-icon":"","title":_vm._f("upperFirst")(_vm.$t('error.search.unavailable.title')),"description":_vm._f("upperFirst")(_vm.$t('error.search.unavailable.description')),"closable":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }