<template>
  <base-layout class="organization-container">
    <h3 slot="title">{{ $t(`pages.organization.tabs.${activeTab}.title`) }}</h3>

    <el-tabs v-if="organization" v-model="activeTab" type="card">
      <el-tab-pane
        :label="$t('pages.organization.myOrganization')"
        name="details"
      >
        <organization-form
          ref="form"
          v-model="organization"
          :saving="saving"
          @change="handleChange"
        >
        </organization-form>
      </el-tab-pane>

      <el-tab-pane :label="$t('commons.planning')" name="planning">
        <organization-shifts
          v-model="organization.shifts"
          @input="handleChange"
        >
        </organization-shifts>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('commons.repository')"
        name="references"
        class="references-pane"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <constraint-list
              v-model="organization.capacities"
              editable
              repository
              name="capacities"
              @change="handleChange"
            >
              <label slot="title">{{ $t('commons.capacity.plural') }}</label>
            </constraint-list>
          </el-col>

          <el-col :span="12">
            <constraint-list
              v-model="organization.tools"
              editable
              repository
              name="tools"
              @change="handleChange"
            >
              <label slot="title">{{ $t('commons.tool.plural') }}</label>
            </constraint-list>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <cs-button
      v-show="activeTab === 'planning'"
      slot="footer"
      type="warning"
      icon="add"
      icon-type="material"
      @click="addShift"
    >
      {{ $t('commons.add') }}
    </cs-button>
    <cs-button slot="footer" :disabled="!hasChanged" @click="handleCancel">
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon="save"
      icon-type="material"
      :disabled="!hasChanged"
      :loading="saving"
      @click="saveOrg"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import OrganizationForm from './forms/OrganizationForm';
import OrganizationShifts from './OrganizationShifts';
import ConstraintList from './../../components/ConstraintList';

export default {
  name: 'Organization',
  components: {
    OrganizationForm,
    OrganizationShifts,
    ConstraintList,
  },
  data: () => ({
    saving: false,
    activeTab: 'details',
    organization: undefined,
    hasChanged: false,
  }),
  created() {
    this.organization = this.$store.getters.organization;
  },
  methods: {
    ...mapActions(['queueAction', 'cancelAction', 'processActionQueue']),
    addShift() {
      this.$prompt(
        this.$t('pages.organization.OrganizationShifts.addShift'),
        this.$t('commons.name'),
        {
          confirmButtonText: this.$t('commons.agree'),
          cancelButtonText: this.$t('commons.cancel'),
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]/,
          inputErrorMessage: this.$t(
            'pages.organization.OrganizationShifts.invalideName'
          ),
        }
      )
        .then((o) => {
          const newShiftType = {
            name: o.value,
            shifts: [
              [],
              [{ start: '09:00', end: '18:00' }],
              [{ start: '09:00', end: '18:00' }],
              [{ start: '09:00', end: '18:00' }],
              [{ start: '09:00', end: '18:00' }],
              [{ start: '09:00', end: '18:00' }],
              [],
            ],
          };
          if (!this.organization.shifts) {
            this.organization.shifts = [];
          }
          this.organization.shifts.push(newShiftType);
        })
        .catch((e) => {
          if (e === 'cancel') {
            return;
          }
          this.$message({
            type: 'info',
            message: this.$t('pages.organization.OrganizationShifts.infoError'),
          });
        });
    },
    handleChange() {
      this.hasChanged = true;
      this.queueAction({
        action: 'patch',
        type: 'organization',
        data: this.organization,
      });
    },
    async handleCancel() {
      this.hasChanged = false;
      await this.cancelAction({
        action: 'patch',
        type: 'organization',
        data: this.organization,
      });
      this.organization =
        this.$store.getters.organizations.items[this.organization.id];
    },
    async saveOrg() {
      try {
        this.saving = true;
        await this.processActionQueue(['organization']);
        this.hasChanged = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss">
.organization-container {
  padding-bottom: 50px;
}
</style>
