import { upperFirst } from 'lodash';
import { MessageBox } from 'element-ui';

const StandardMessage = {};
StandardMessage.install = (Vue, { i18n }) => {
  Vue.prototype.$confirm = (message) =>
    MessageBox.confirm(message, upperFirst(i18n.t('commons.warning')), {
      confirmButtonText: upperFirst(i18n.t('commons.agree')),
      cancelButtonText: upperFirst(i18n.t('commons.cancel')),
      type: 'warning',
    });
  Vue.prototype.$log = () => {
    // Should we log  ??
  };
  Vue.prototype.$null = () => null;
};

export default StandardMessage;
