import m from 'moment';
import {
  isNil,
  upperFirst,
  get,
  reduce,
  isNaN,
  isDate,
  isEmpty,
  isObject,
  isArray,
  truncate,
} from 'lodash';
import { format, formatDistance, intervalToDuration } from 'date-fns';
import i18n from '../locales';
import { QUERY_DATE_FORMAT } from '@/utils/date';

const VueFiltersPlugin = {};
const locale = i18n.t('dateFns');

VueFiltersPlugin.install = (Vue) => {
  Vue.filter('date', (date, f) =>
    isDate(date)
      ? format(new Date(date), f || QUERY_DATE_FORMAT, {
          locale,
        })
      : date
  );

  Vue.filter('moment', (time, format) => {
    let res = null;

    if (!isNil(time)) {
      m.locale(Vue.config.lang);
      res = m(time).format(format || 'LLLL');
    }

    return res;
  });

  Vue.filter('format', (time, f) =>
    isDate(time)
      ? format(new Date(time), f || i18n.t('longDateFormat'), {
          locale,
        })
      : time
  );

  Vue.filter('formatDistance', (obj, start, end, options) =>
    formatDistance(obj[start], obj[end], {
      ...options,
      locale,
    })
  );

  Vue.filter('length', (a) => (a ? a.length : 0));

  Vue.filter('upperFirst', (v) => upperFirst(v));

  Vue.filter('fromNow', (time) => m(time).locale(Vue.config.lang).fromNow());

  Vue.filter('km', (d) => (d ? `${(d / 1000).toFixed()} km` : 'N/A'));

  Vue.filter('percent', (d) => (!isNaN(d) ? `${d} %` : 'N/A'));

  Vue.filter('get', (object, path) => get(object, path));

  Vue.filter('seconds', (s, opts) => {
    if (!s) {
      return 'N/A';
    }
    const initial = !get(opts, 'noSign', false) && s > 0 ? '+' : '';
    const duration = intervalToDuration({ start: 0, end: s * 1000 });
    return reduce(
      duration,
      (res, value, idx) => {
        if (value === 0) {
          return res;
        }
        const newValue = get(opts, 'full', false)
          ? i18n.tc(`commons.time.${idx}`, value)
          : i18n.tc(`commons.time.${idx}`, value).charAt(0);

        return `${res} ${value}${newValue}`;
      },
      initial
    );
  });

  Vue.filter('empty', (value, replace) => {
    if (isObject(value) || isArray(value)) {
      return isEmpty(value) ? replace : value;
    }
    if (isNil(value)) {
      return replace;
    }
    return value;
  });
  Vue.filter('truncate', (value, length) => truncate(value, { length }));
};

export default VueFiltersPlugin;
