<template lang="html">
  <section>
    <div class="header">
      <h5>{{ activity.external_id }}</h5>
      <state type="delivery" :value="activity.state" />
    </div>

    <strong>{{ activity.receiver_name }}</strong>
    <activity-address no-label :address="activity.place" />
  </section>
</template>

<script>
import ActivityAddress from '@/components/Address';
import State from '@/components/State';

export default {
  components: { ActivityAddress, State },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}
.state {
  height: 10px;
}
</style>
