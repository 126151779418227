<template lang="html">
  <section
    class="sidebar-head-bg primary-color-dark"
    :style="{ backgroundColor: route.color }"
  >
    <el-row class="activity-head" type="flex">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="21">
          <h5 class="sidebar-title">{{ route.name }}</h5>
        </el-col>

        <el-col :span="3">
          <cs-button
            outline
            icon="close"
            icon-type="material"
            @click.stop="close"
          >
          </cs-button>
        </el-col>
      </el-row>

      <div v-if="route" class="activity-head-plusBtn">
        <i v-if="!showDetails" class="el-icon-plus"></i>
        <i v-else class="el-icon-minus"></i>
      </div>

      <el-row type="flex">
        <el-col
          :span="6"
          :style="{
            display: 'flex',
            flexDirection: 'column',
            color: '#6D737F',
          }"
        >
          <span>{{ $t(`commons.state`) | upperFirst }}</span>
          <span>{{ $t(`commons.duration`) | upperFirst }}</span>
          <span>{{ $t(`commons.distance`) | upperFirst }}</span>
          <span>{{ $t(`commons.resource.plural`) | upperFirst }}</span>
        </el-col>
        <el-col
          :span="18"
          :style="{
            color: '#32363B',
            textTransform: 'capitalize',
            display: 'flex',
            flexDirection: 'column',
          }"
        >
          <span class="sidebar-details">{{
            $t(`commons.states.route.${route.state}`) | upperFirst
          }}</span>
          <span class="sidebar-details">{{
            route.duration | seconds | empty('N/A')
          }}</span>
          <span class="sidebar-details">{{
            route.distance | km | empty('N/A')
          }}</span>
          <span class="sidebar-details">{{
            route | get('resource.name') | empty('N/A')
          }}</span>
        </el-col>
      </el-row>

      <el-row v-if="route" type="flex" align="middle">
        <el-col :span="2">
          <el-tooltip :content="$tc('components.selection.remove', 1, 1)">
            <cs-button
              circle
              class="activity-head-linkBtn"
              icon="link"
              icon-type="material"
              @click.stop="$emit('unplan')"
            ></cs-button>
          </el-tooltip>
        </el-col>
        <el-col :span="20" :offset="1" :style="{ cursor: 'pointer' }">
          <a class="activity-head-link" @click.stop="goToActivities">
            {{ route.deliveries.length }}
            {{ $tc('commons.activity.choice', route.deliveries.length) }}
          </a>
        </el-col>
      </el-row>

      <el-row v-else>
        <el-col>
          <span
            >{{ $t('commons.none.female') }}
            {{ $t('commons.activity.single') }}</span
          >
        </el-col>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { get, clone } from 'lodash';

export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ressourceName() {
      return get(this.route, 'resource.name');
    },
  },
  methods: {
    ...mapActions(['removeRoutesFromSelection', 'setSelectedActivities']),
    close() {
      this.removeRoutesFromSelection({ data: [this.route] });
    },
    goToActivities() {
      this.setSelectedActivities({
        display: true,
        data: clone(this.route.deliveries),
      });
    },
  },
};
</script>
