<template lang="html" functional>
  <div class="route-activity-tag__option">
    <div
      class="
        route-activity-tag__option__label
        route-activity-tag__option__label--activity
      "
      :style="`color: ${props.route.color}`"
    >
      <i class="material-icons">room</i>
      <i class="material-icons">room</i>
    </div>

    <div class="route-activity-tag__option__count">
      {{ props.route.deliveries.length }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.route-activity-tag__option {
  display: flex;

  &__label {
    flex: 1 0 auto;

    &--activity {
      .material-icons {
        text-shadow: 1px 0 0 #f0f3f4;

        &:not(:first-of-type) {
          margin-left: -13px;
          z-index: -1;
        }
      }
    }
  }

  &__count {
    flex: 0 1 auto;
  }
}
</style>
