<template>
  <list-layout :load="loading">
    <h3 slot="title">{{ $t('pages.client.IndexPage.customerList') }}</h3>

    <client-list :clients="Object.values(clients.items)" />

    <cs-button
      slot="footer"
      icon="add"
      icon-type="material"
      type="primary"
      @click.stop="$router.push({ name: 'createClient' })"
    >
      {{ $t('pages.client.IndexPage.customerAdd') }}
    </cs-button>
  </list-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ListLayout from '@/layouts/ListLayout';
import ClientList from '@/components/lists/ClientList';

export default {
  name: 'Clients',
  components: {
    ListLayout,
    ClientList,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters(['clients']),
  },
  created() {
    this.get({ type: 'client', id: 'all' })
      .then((this.loading = false))
      .catch((this.loading = false));
  },
  methods: {
    ...mapActions(['get']),
  },
};
</script>
<style scope></style>
