import { isWithinInterval } from 'date-fns';
import ApiEntity from './ApiEntity';

export default class Sla extends ApiEntity {
  start;
  end;
  priority = 1;
  constructor(o) {
    super();
    this.fill(o);
    this.start = this.castDate(o.start);
    this.end = this.castDate(o.end);
  }

  static create(data) {
    return Sla.createInstance(data);
  }

  isWithin(date) {
    return this.start && this.end
      ? isWithinInterval(date, { start: this.start, end: this.end })
      : false;
  }
}
