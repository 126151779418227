<template lang="html">
  <cs-button
    square
    outline
    icon-type="material"
    size="20px"
    :icon="icon"
    @click.stop.prevent="toggle"
    @dblclick.prevent.self
  />
</template>

<script>
import { some, forEach } from 'lodash';

export default {
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    icon() {
      return this.displayed ? 'visibility_off' : 'visibility';
    },
    displayed() {
      return some(this.routes, (r) => !r.displayPolyline || !r.displayMarkers);
    },
  },
  methods: {
    toggle() {
      const display = this.displayed;

      forEach(this.routes, (r) => {
        r.displayPolyline = display;
        r.displayMarkers = display;
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
