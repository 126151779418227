<template>
  <div class="dock-item">
    <el-row :gutter="20">
      <el-col :span="6">
        <p>{{ dock.barcode }}</p>
      </el-col>
      <el-col :span="18">
        <el-input
          v-model="dock.name"
          :placeholder="$t('commons.name')"
          clearable
          maxlength="25"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Dock',
  props: {
    dock: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.dock-item {
  margin: 10px 0;
}
</style>
