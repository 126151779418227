<template>
  <base-layout>
    <h3 slot="title">
      {{ $t('components.entities.ResourceDetails.createResource') }}
    </h3>

    <resource-form
      v-if="resource"
      ref="form"
      :saving="saving"
      :resource="resource"
    />

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexResource' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="success"
      icon-type="material"
      icon="date_range"
      @click="$refs.form.visible = true"
    >
      {{ $t('components.shift.loadPlanning') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveResource"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapActions } from 'vuex';
import ResourceForm from './forms/ResourceForm';

export default {
  components: { ResourceForm },
  data: () => ({
    saving: false,
    resource: {
      user: null,
      vehicle: null,
      depot: null,
      shifts: [[], [], [], [], [], [], []],
    },
  }),
  methods: {
    ...mapActions(['post']),
    saveResource() {
      this.$refs.form.validate().then(
        () => {
          this.saving = true;
          this.post({
            type: 'resource',
            data: this.resource,
          })
            .then(() => {
              this.saving = false;
              this.$router.push({ name: 'indexResource' });
            })
            .catch(() => {
              this.saving = false;
            });
        },
        () => null
      );
    },
  },
};
</script>
