<template lang="html">
  <l-map
    ref="map"
    :bounds="bounds"
    :center="center"
    :zoom="zoom"
    :style="customStyle"
  >
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker
      v-for="(hit, idx) in data"
      :key="hit.objectID"
      :lat-lng="getLatLng(hit)"
      :icon="getHitIcon(hit, idx)"
      @click="$emit('click', hit.objectID)"
    />
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { latLng } from 'leaflet';
import { get, has, filter } from 'lodash';
import { getRouteColor } from '@/utils';
import svgIcon from '@/svgIcon';
import { normal } from '@/iconPaths';

export default {
  components: { LMap, LTileLayer, LMarker },
  props: {
    hits: {
      type: Array,
      default: () => [],
    },
    latitudeKey: {
      type: String,
      required: true,
    },
    longitudeKey: {
      type: String,
      required: true,
    },
    attribution: {
      type: String,
      default:
        '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    },
    url: {
      type: String,
      default: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER}`,
    },
    zoom: {
      type: Number,
      default: 16,
    },
    customStyle: {
      type: String,
      default: 'height: 256px;',
    },
    icon: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    center: [0, 0],
  }),
  computed: {
    data() {
      return filter(
        this.hits,
        (h) => has(h, this.latitudeKey) && has(h, this.longitudeKey)
      );
    },
    bounds() {
      if (this.data.length > 0) {
        return this.data.map((hit) =>
          latLng(get(hit, this.latitudeKey), get(hit, this.longitudeKey))
        );
      }

      return null;
    },
  },
  methods: {
    getLatLng(hit) {
      return latLng(get(hit, this.latitudeKey), get(hit, this.longitudeKey));
    },
    getHitIcon(hit, idx) {
      const svg = hit.route_id ? normal.planned : normal.unplanned;
      return svgIcon({
        text: (idx + 1).toString(),
        markerColor: getRouteColor(hit),
        iconColor: '#ffffff',
        svg,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        className: 'top-marker',
      });
    },
    setCenter(position, zoom) {
      this.$refs.map.setCenter(position);
      this.$refs.map.setZoom(zoom);
    },
  },
};
</script>
