<template lang="html">
  <div
    class="gantt-route"
    @mouseenter="menuVisible = true"
    @mouseleave="menuVisible = false"
  >
    <div class="timeline-route">
      <div class="timeline-route-head">
        <div class="timeline-route-actions">
          <route-menu v-model="menuVisible">
            <cs-button
              icon-type="material"
              icon="delete"
              @click.stop.prevent="$emit('delete-route', route)"
            />
            <!-- <cs-button
              icon-type="material"
              icon="local_dining"
              :disabled="!canAddTimeoff"
              @click.stop.prevent="addTimeoff"
            /> -->
          </route-menu>
        </div>
        <div class="route-title secondary-color">
          <span>{{ route.name }}</span>
          <el-tooltip
            effect="dark"
            placement="top"
            :content="$t('commons.overtime')"
          >
            <i
              v-show="route.isOvertime"
              class="route-icon material-icons secondary-color help"
            >
              access_time
            </i>
          </el-tooltip>
        </div>
      </div>
      <div
        class="timeline-route"
        :style="{ backgroundColor: bgColor, color: blackOrWhite }"
      >
        <div class="route-content" style="backgroundcolor: transparent" />
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import { invertColor, hexToRgbA } from '@/utils/color';
import RouteMenu from '../RouteMenu';

export default {
  components: { RouteMenu },
  props: {
    routeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menuVisible: false,
  }),
  computed: {
    route() {
      return this.$parent.routesObject[this.routeId];
    },
    bgColor() {
      if (this.selected) {
        return this.route.color;
      }
      return hexToRgbA(this.route.color, 0.2);
    },
    blackOrWhite() {
      if (this.selected) {
        return invertColor(this.route.color, true);
      }
      return this.route.color;
    },
    selected() {
      return this.$parent.selected === this.route.id;
    },
    isDisabled() {
      return !['ready', 'created'].includes(this.route.state);
    },
    canAddTimeoff() {
      return isEmpty(this.route.timeoffs);
    },
  },
  watch: {
    route(v) {
      if (!v) {
        this.$destroy();
      }
    },
  },
  methods: {
    ...mapActions(['queueAction']),
    addTimeoff() {
      this.route.addTimeoff();
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
  },
};
</script>
