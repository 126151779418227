<template lang="html">
  <print-template> <route-print :route="route" /> </print-template>
</template>

<script>
import { mapActions } from 'vuex';
import RoutePrint from '../../components/print/RoutePrint';
import PrintTemplate from '../../components/print/PrintTemplate';

export default {
  name: 'Print',
  components: {
    RoutePrint,
    PrintTemplate,
  },
  computed: {
    route() {
      const r = this.$store.getters.routes.items[this.$route.params.id];
      if (!r) {
        return {};
      }
      return r;
    },
  },
  created() {
    const loadRoute = this.get({ type: 'route', id: this.$route.params.id });

    Promise.all([loadRoute])
      .then((this.loading = false))
      .catch((this.loading = false));
  },
  methods: {
    ...mapActions(['get']),
  },
};
</script>

<style lang="scss"></style>
