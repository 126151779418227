var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('shortkey',{attrs:{"shortkeys":['s'],"action":_vm.focusOnSelection}}),_c('shortkey',{attrs:{"shortkeys":['ctrl', 's'],"action":_vm.clearSelection}}),_vm._t("left",null,{"data":{ displayTools: _vm.displayTools }}),_c('el-main',{staticClass:"map-main",staticStyle:{"padding":"0"}},[_c('span',{staticClass:"map-overlap-left",style:({ height: _vm.$slots.mapleft ? 'inherit' : 'auto' })},[_c('map-toolbox',{attrs:{"value":_vm.displayTools,"zoom":_vm.zoom,"cluster":_vm.cluster},on:{"toggleAllPolylines":_vm.toggleAllPolylines,"toggleAllMarkers":_vm.toggleAllMarkers,"updateZoom":_vm.updateZoom,"updateCluster":_vm.updateCluster,"toggleLasso":function($event){_vm.lasso = !_vm.lasso},"refreshBounds":_vm.refreshBounds}},[_vm._t("maptools")],2),_vm._t("mapleft")],2),_c('l-map',{ref:"map",attrs:{"zoom":_vm.zoom,"center":_vm.center,"bounds":_vm.bounds,"options":_vm.mapOptions},on:{"update:zoom":_vm.updateZoom}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._l((_vm.filteredData),function(route){return _c('div',{key:route.id},[_c('l-layer-group',{attrs:{"visible":route.displayMarkers}},[(_vm.cluster)?_c('l-marker-cluster',[_vm._l((route.deliveries),function(delivery){return [(
                  (delivery.hasOwnProperty('matchFilter')
                    ? delivery.matchFilter
                    : !_vm.filterActive) &&
                  route.id &&
                  !delivery.isSelected
                )?_c('l-marker',{key:delivery.id,attrs:{"lat-lng":delivery.position,"icon":delivery.icon},on:{"click":function($event){return _vm.addActivityToSelection($event, delivery)}}}):_vm._e()]})],2):_vm._e(),_vm._l((route.deliveries),function(delivery){return [(
                (delivery.hasOwnProperty('matchFilter')
                  ? delivery.matchFilter
                  : !_vm.filterActive) &&
                (route.id || !_vm.cluster || delivery.isSelected)
              )?_c('l-marker',{key:delivery.id,attrs:{"lat-lng":delivery.position,"icon":delivery.icon},on:{"click":function($event){return _vm.addActivityToSelection($event, delivery)}}}):_vm._e()]}),(route.startLocation && route.realRoute)?_c('l-marker',{attrs:{"lat-lng":route.startLocation,"icon":route.startIcon}}):_vm._e(),(route.endLocation && route.realRoute)?_c('l-marker',{attrs:{"lat-lng":route.endLocation,"icon":route.endIcon}}):_vm._e()],2),(route.polyline && route.realRoute)?_c('l-polyline',{attrs:{"fill-color":"transparent","visible":route.displayPolyline,"lat-lngs":route.polyline,"color":route.color},on:{"click":function($event){return _vm.addRouteToSelection($event, route)}}}):_vm._e()],1)}),_c('map-free-draw',{attrs:{"data":_vm.filteredData},on:{"setFreeDraw":_vm.setFreeDraw},model:{value:(_vm.lasso),callback:function ($$v) {_vm.lasso=$$v},expression:"lasso"}})],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }