import {
  get,
  forEach,
  has,
  sortBy,
  last,
  map,
  isEmpty,
  some,
  round,
  first,
  find,
  isNil,
  isArray,
} from 'lodash';
import axios from '@/services/axios';
import {
  addSeconds,
  areIntervalsOverlapping,
  closestTo,
  subSeconds,
  differenceInSeconds,
  getISODay,
  isAfter,
  isWithinInterval,
} from 'date-fns';
import * as Sentry from '@sentry/browser';
import { latLng, latLngBounds } from 'leaflet';
import { decode } from '@mapbox/polyline';
import { getDepotIcon, getHouseIcon } from '@/utils/activityUtils';
import { pushLatLng, getShift } from '@/utils/helpers';
import { getRandomColor } from '@/utils/color';
import Activity from './Activity';
import ApiEntity from './ApiEntity';
import Timeoff from './Timeoff';
import StateHistory from './StateHistory';
import Travel from './Travel';

class ApiRoute extends ApiEntity {
  id;
  name = null;
  state;
  external_id;
  planned_start;
  planned_end;
  distance;
  travels = [];
  transitions;
  timeoffs = [];
  start_place;
  end_place;
  resource;
  deliveries = [];
  state_history;
  color;
  geometry;
  constructor(o) {
    super();
    this.fill(o);
    this.color = !isNil(o.color)
      ? o.color
      : getRandomColor(this.id, '#F00', -0.2);
    this.planned_start = this.castDate(o.planned_start);
    this.planned_end = this.castDate(o.planned_end);
    if (o.travels) {
      this.travels = Travel.create(o.travels);
    }
    this.state_history = new StateHistory(o.state_history);
    if (o.deliveries) {
      this.deliveries = sortBy(Activity.create(o.deliveries), 'order');
    }
    if (o.timeoffs) {
      this.timeoffs = Timeoff.create(o.timeoffs);
    }
  }
}

export default class Route extends ApiRoute {
  trackingPolyline;
  polyline;
  displayMarkers = true;
  displayPolyline = true;
  locked = false;
  isSelected = false;
  initialMatch = false;
  shouldUpdate = true;
  constructor(apiObject) {
    super(apiObject);
  }

  static async create(data, options) {
    const create = async (d) => {
      const route = new Route(d);
      route.shouldUpdate = get(options, 'shouldUpdate', route.shouldUpdate);
      if (route.deliveries.length) {
        if (!route.travels.length) {
          await route.reorder();
        } else if (!route.geometry) {
          await route.fetchPolyline();
        } else {
          route.polyline = decode(route.geometry);
        }
      }

      return route;
    };
    return isArray(data) ? Promise.all(data.map(create)) : create(data);
  }

  get shifts() {
    if (has(this, 'resource.shifts')) {
      const currentShifts = this.resource.shifts[getISODay(this.planned_start)];
      return map(currentShifts, (s) => getShift(this.planned_start, s)).filter(
        ({ start, end }) => start < end
      );
    }
    return [];
  }

  get start() {
    return this.state_history?.findItem('start', 'event')?.datetime;
  }

  get end() {
    return this.state_history?.findLastItem('finish', 'event')?.datetime;
  }

  get isOvertime() {
    return !some(
      this.shifts,
      (shift) =>
        isWithinInterval(this.planned_start, shift) &&
        isWithinInterval(this.planned_end, shift)
    );
  }

  get realRoute() {
    return !isNil(this.id);
  }

  get bounds() {
    if (this.polyline) {
      return latLngBounds(this.polyline);
    }
    return latLngBounds(map(this.deliveries, 'position'));
  }

  get startIcon() {
    if (!this.start_place) return getDepotIcon();
    return this.start_place.type === 'depot' ? getDepotIcon() : getHouseIcon();
  }

  get endIcon() {
    if (!this.end_place) return getDepotIcon();
    return this.end_place.type === 'depot' ? getDepotIcon() : getHouseIcon();
  }

  get startLocation() {
    return latLng(
      get(this.start_place, 'latitude', 0),
      get(this.start_place, 'longitude', 0)
    );
  }

  get endLocation() {
    return latLng(
      get(this.end_place, 'latitude', 0),
      get(this.end_place, 'longitude', 0)
    );
  }

  get firstStart() {
    const firstTravel = first(this.travels);
    if (firstTravel) {
      return firstTravel.start;
    }
    const firstDelivery = first(this.deliveries);
    if (firstDelivery) {
      return firstDelivery.planned_start;
    }
    return this.planned_start;
  }

  get lastEnd() {
    const lastTravel = last(this.travels);
    if (lastTravel) {
      return lastTravel.end;
    }
    const lastDelivery = last(this.deliveries);
    if (lastDelivery) {
      return lastDelivery.planned_end;
    }
    return this.planned_end;
  }

  get duration() {
    return this.travelDuration + this.activityDuration;
  }

  get travelDuration() {
    return this.travels.reduce((acc, travel) => {
      acc += travel.duration;
      return acc;
    }, 0);
  }

  get activityDuration() {
    return this.deliveries.reduce((acc, activity) => {
      acc += activity.duration;
      return acc;
    }, 0);
  }

  // getDistanceFromTravels() {
  //   return this.travels.reduce((acc, travel) => {
  //     if (travel) {
  //       acc += travel.distance;
  //     }
  //     return acc;
  //   }, 0);
  // }

  addTimeoff() {
    const middle =
      differenceInSeconds(this.planned_end, this.planned_start) / 2;
    const start = addSeconds(this.planned_start, middle - 1800);
    const end = addSeconds(this.planned_start, middle + 1800);
    this.timeoffs.push(
      new Timeoff({
        start,
        end,
        sla: {
          start: this.planned_start,
          end: this.planned_end,
        },
      })
    );
  }

  invalidateGeometry() {
    this.geometry = undefined;
  }

  updateDistanceAndDuration() {
    if (this.realRoute && this.shouldUpdate) {
      axios.patch(`route/${this.id}`, {
        duration: this.duration,
        distance: this.distance,
        geometry: this.geometry,
      });
    }
  }

  getPoints() {
    const points = [];
    if (this.realRoute) {
      pushLatLng(points, this.start_place);
      if (this.deliveries.length > 0) {
        forEach(this.deliveries, (delivery) => {
          points.push(delivery.position);
        });
      }
      pushLatLng(points, this.end_place);
    }

    return points;
  }

  async callMatchingApi(points, withoutIstructions) {
    const { data } = await axios.post('route/snap', {
      points: points.map((p) => [p.lng, p.lat]),
      instructions: !withoutIstructions,
    });

    if (data.instructions) {
      let distance = 0;
      let duration = 0;
      for (const instruction of data.instructions) {
        if (instruction.sign == 5 || instruction.sign == 4) {
          data.legs.push({
            distance,
            duration: duration / 1000,
          });
          distance = 0;
          duration = 0;
        } else {
          distance += instruction.distance;
          duration += instruction.time;
        }
      }
    }
    return data;
  }

  fetchPolyline() {
    const points = this.getPoints();
    const shouldMatch = points.length > 2;
    return shouldMatch
      ? this.callMatchingApi(points, true)
          .then((snap) => {
            this.polyline = decode(snap.points);
            this.distance = round(snap.distance);
            this.geometry = snap.points;
            this.updateDistanceAndDuration();
          })
          .catch((e) => {
            Sentry.captureException(e);
            this.polyline = points;
          })
      : Promise.resolve();
  }

  updatePolyline() {
    if (this.tracking && this.tracking.length > 0) {
      const trackingPoints = [];

      forEach(this.tracking, (track) => {
        pushLatLng(trackingPoints, track);
      });

      this.trackingPolyline = trackingPoints;
    }

    return this.realRoute ? this.fetchPolyline() : Promise.resolve();
  }

  reorder(droppedActivityId) {
    if (this.realRoute) {
      this.deliveries = sortBy(
        this.deliveries,
        (o) => new Date(o.planned_start)
      );
      const points = this.getPoints();
      return this.callMatchingApi(points)
        .then((snap) => {
          this.travels = [];
          this.polyline = decode(snap.points);
          this.distance = round(snap.distance);
          this.geometry = snap.points;

          const findAfterBreakStart = (start, duration) => {
            const timeoff = find(this.timeoffs, (timeoff) =>
              areIntervalsOverlapping(
                { start, end: addSeconds(start, duration) },
                timeoff
              )
            );

            return timeoff ? timeoff.end : start;
          };

          const getTravel = (index, start) => {
            const duration = round(snap.legs[index].duration);
            const s = findAfterBreakStart(new Date(start), duration);

            return {
              start: s,
              end: addSeconds(s, duration),
              distance: round(snap.legs[index].distance),
              duration,
            };
          };

          const findPreviousEnds = (index) => {
            let prevEnd;
            const duration = snap.legs[index].duration;
            if (index === 0) {
              prevEnd = new Date(this.deliveries[index].planned_start);
              // Set first travel
              const firstTraveStart = subSeconds(prevEnd, duration);
              this.travels.push(getTravel(index, firstTraveStart));
              return new Date(prevEnd);
            }
            prevEnd = this.deliveries[index - 1].planned_end;
            return addSeconds(prevEnd, duration);
          };

          const findStart = (activity, shouldStart, isDropped) => {
            let start;
            if (isDropped) {
              start = isAfter(new Date(shouldStart), activity.planned_start)
                ? new Date(shouldStart)
                : activity.planned_start;
            } else {
              start = new Date(shouldStart);
              if (activity.appointment && activity.appointment.start) {
                if (
                  !isWithinInterval(start, activity.appointment) &&
                  isAfter(activity.appointment.start, start)
                ) {
                  start = new Date(activity.appointment.start);
                }
              } else {
                const inSla = some(activity.slas, (sla) =>
                  isWithinInterval(start, sla)
                );
                if (!inSla) {
                  const nextStart = activity.slas.filter((sla) =>
                    isAfter(sla.start, start)
                  );
                  if (!isEmpty(nextStart)) {
                    start = closestTo(start, map(nextStart, 'start'));
                  }
                }
              }
            }

            return findAfterBreakStart(start, activity.duration);
          };

          forEach(this.deliveries, (d, idx) => {
            d.route_id = this.id;
            const isDropped = d.id === droppedActivityId;

            // Unplanned
            if (!this.realRoute) {
              d.planned_end = null;
              d.planned_start = null;
              d.order = 0;
            } else {
              const order = idx + 1;
              const prevTravelEnd = findPreviousEnds(idx);
              d.planned_start = findStart(d, prevTravelEnd, isDropped);
              d.planned_end = addSeconds(d.planned_start, d.duration);
              d.order = order;
              this.travels.push(getTravel(order, d.planned_end));
            }
            d.resetIcon();
          });

          if (this.deliveries.length) {
            const lastTravel = getTravel(
              this.deliveries.length,
              last(this.deliveries).planned_end
            );
            this.travels.push(lastTravel);

            if (isAfter(lastTravel.end, this.planned_end)) {
              this.planned_end = new Date(lastTravel.end);
            }
          }
        })
        .then(() => this.updateDistanceAndDuration())
        .catch(() => {
          this.polyline = points;
        });
    }
    return Promise.resolve(false);
  }
}
