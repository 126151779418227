<template>
  <el-tooltip
    :open-delay="60"
    :disabled="!tooltip"
    :effect="effect"
    :content="tooltipText | upperFirst"
    :placement="placement"
  >
    <button
      v-if="!target"
      ref="button"
      v-shortkey="shortkeys"
      class="btn"
      :style="style"
      :active="true"
      :disabled="disabled"
      :autofocus="autofocus"
      :loading="loading"
      :type="nativeType"
      :class="buttonClassObject"
      :circle="circle"
      @shortkey="handleClick"
      @click="handleClick"
      @dblclick="handleDblClick"
    >
      <i v-if="loading" class="el-icon-loading"></i>
      <i v-if="icon && !loading" :class="iconClassObject">{{ iconContent }}</i>
      <span class="upperFirst"> <slot></slot> </span>
    </button>
    <a
      v-else
      ref="button"
      v-shortkey="shortkeys"
      class="btn"
      style="color: unset"
      :style="style"
      :target="target"
      :active="true"
      :disabled="disabled"
      :autofocus="autofocus"
      :loading="loading"
      :type="nativeType"
      :class="buttonClassObject"
      :circle="circle"
      :href="href"
      @click.stop="() => {}"
    >
      <i v-if="loading" class="el-icon-loading"></i>
      <i v-if="icon && !loading" :class="iconClassObject">{{ iconContent }}</i>
      <span class="upperFirst"> <slot></slot> </span>
    </a>
  </el-tooltip>
</template>
<script>
import { isObject } from 'lodash';
export default {
  name: 'CsButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: '36px',
    },
    icon: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: 'fa',
      validator: (v) => ['fa', 'material'].includes(v),
    },
    nativeType: {
      type: String,
      default: 'button',
    },
    effect: {
      type: String,
      default: 'dark',
    },
    tooltip: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    shortkeys: {
      type: Array,
      default: () => [],
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: '',
    },
    loading: Boolean,
    disabled: Boolean,
    plain: Boolean,
    autofocus: Boolean,
    rotate: Boolean,
    square: Boolean,
    circle: Boolean,
    outline: Boolean,
  },
  computed: {
    href() {
      return isObject(this.to) ? this.$router.resolve(this.to).href : this.to;
    },
    iconContent() {
      return this.iconType === 'material' ? this.icon : '';
    },
    tooltipText() {
      if (this.shortkeys && this.shortkeys.length > 0) {
        return `${this.tooltip} (${this.shortkeys.join(' + ').toUpperCase()})`;
      }
      return this.tooltip ? this.tooltip : '';
    },
    iconClassObject() {
      const icon =
        this.iconType === 'fa' ? `fa fa-${this.icon}` : 'material-icons';
      return {
        rotate: this.rotate,
        [icon]: this.icon,
      };
    },
    buttonClassObject() {
      const o = {
        square: this.square,
        outline: this.outline,
        'is-disabled': this.disabled,
        'is-loading': this.loading,
        'is-plain': this.plain,
      };
      if (this.type) {
        o[`btn-${this.type}`] = this.type;
      }
      return o;
    },
    style() {
      const style = {
        height: this.size,
        lineHeight: this.size,
      };

      if (this.square) {
        style.width = this.size;
        style.minWidth = this.size;
      }

      return style;
    },
  },
  methods: {
    handleClick(evt) {
      if (!this.disabled) {
        this.$refs.button.focus();
        this.$emit('click', evt);
      }
    },
    handleDblClick(evt) {
      if (!this.disabled) {
        this.$refs.button.focus();
        this.$emit('dblclick', evt);
      }
    },
  },
};
</script>
