<template>
  <timeline
    ref="timeline"
    class="planning"
    :items="items"
    :groups="groups"
    :options="options"
    :events="['select']"
    @select="select"
  />
</template>

<script>
import _ from 'lodash';
import m from 'moment';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Timeline } from '@vue2vis/timeline';
import {
  addSeconds,
  addDays,
  differenceInDays,
  differenceInSeconds,
  getDay,
  subSeconds,
} from 'date-fns';
import { getShift } from '@/utils';
import Label from './Label';
import Route from './planning/Route';

const GanttLabel = Vue.extend(Label);
const GanttRoute = Vue.extend(Route);

export default {
  components: {
    Timeline,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    slas: [],
    boundaries: {},
    templates: {},
    groupTemplates: {},
    selected: null,
  }),
  computed: {
    ...mapGetters(['selection', 'dateRangeFetched']),
    groups() {
      return _.map(this.resourcesObject, this.getResourceGroup);
    },
    routesWithResource() {
      return _.filter(this.routes, 'resource');
    },
    shiftItems() {
      const items = [];
      const days =
        differenceInDays(this.dateRangeFetched.from, this.dateRangeFetched.to) +
        1;
      let dayPointer = new Date(this.dateRangeFetched.from);
      const getRouteCreation = (currentDay) => {
        _.forEach(this.resourcesObject, (resource) => {
          const shifts = resource.shifts[getDay(currentDay)];
          _.forEach(shifts, (s) => {
            const shift = getShift(currentDay, s);
            items.push({
              id: `${
                resource.id
              }_${shift.start.getTime()}_${shift.end.getTime()}`,
              start: shift.start,
              end: shift.end,
              group: resource.id,
              type: 'background',
              className: 'timeline-shift',
              entity: 'shift',
            });
          });
        });
      };

      for (let sum = 0; sum < days; sum += 1) {
        getRouteCreation(dayPointer);
        dayPointer = addDays(dayPointer, 1);
      }

      return items;
    },
    resourceRoutes() {
      return _.groupBy(this.routesWithResource, (r) => _.get(r, 'resource.id'));
    },
    items() {
      return this.routesWithResource
        .reduce(
          (acc, route) => [
            ...acc,
            this.getRouteItems(route),
            // ...this.getTimeoffItems(route)
          ],
          []
        )
        .concat(this.shiftItems);
    },
    routesObject() {
      return _.merge(
        _.zipObject(
          _.map(this.routesWithResource, 'id'),
          this.routesWithResource
        ),
        this.routeToCreate
      );
    },
    itemsByGroup() {
      return _.groupBy(this.items, 'group');
    },
    resources() {
      return _.map(this.routesWithResource, (r) => _.get(r, 'resource'));
    },
    resourcesObject() {
      return _.zipObject(_.map(this.resources, 'id'), this.resources);
    },
    options() {
      return {
        editable: {
          add: true, // add new items by double tapping
          updateTime: true, // drag items horizontally
          updateGroup: false, // drag items from one group to another
          remove: false, // delete an item by tapping the delete button top right
          overrideItems: false, // allow these options to override item.editable
        },
        end: this.dateRangeFetched.to,
        groupTemplate: this.getGroupTemplate,
        template: this.getTemplate,
        locale: _.get(this.$i18n, 'locale', 'fr'),
        selectable: true,
        stack: false,
        snap: null,
        zoomMin: 600000,
        max: this.dateRangeFetched.to,
        min: this.dateRangeFetched.from,
        moment: (date) => m(date).locale(_.get(this.$i18n, 'locale', 'fr')),
        onAdd: (item) => {
          item.start = m(item.start);
          const shift = _.find(this.shiftItems, (s) =>
            item.start.isBetween(s.start, s.end)
          );
          if (shift) {
            item.start = shift.start.clone();
            item.end = shift.end.clone();
          } else {
            item.end = m(item.start).clone().add(2, 'h');
          }
          this.setItemBounds(item);
          const resource = this.resourcesObject[item.group];
          this.addRouteToQueue({
            id: `${item.id}_create`,
            name: `${resource.name} ${item.start.format('LL')}`,
            resource,
            planned_start: item.start,
            planned_end: item.end,
            deliveries: [],
          });
        },
        onMove: (item, callback) => {
          this.movedItem(item);
          callback(item);
        },
        onMoving: (item, callback) => {
          if (this.movingItem(item)) {
            callback(item);
          }
        },
        orientation: {
          axis: 'top',
          item: 'bottom',
        },
        start: this.dateRangeFetched.from,
      };
    },
  },
  watch: {
    dateRangeFetched(v) {
      if (this.$refs.timeline) {
        this.$refs.timeline.setOptions({
          start: v.from,
          end: v.to,
          min: v.from,
          max: v.to,
        });
      }
    },
  },
  beforeDestroy() {
    _.forEach(this.templates, (t) => {
      if (t) {
        t.$destroy();
      }
    });
    _.forEach(this.groupTemplates, (t) => {
      if (t) {
        t.$destroy();
      }
    });
  },
  methods: {
    ...mapActions(['clearSelection', 'queueAction']),
    addRouteToQueue(route) {
      this.queueAction({
        action: 'post',
        type: 'route',
        data: new Route(route),
      });
    },
    cleanTemplates(obj) {
      _.keys(obj).forEach((id) => {
        _.invoke(this.templates, `${id}.$destroy`);
        this.$delete(this.templates, id);
      });
    },
    getTemplate(item) {
      if (!item) {
        return false;
      }
      let component = '';
      if (this.templates[item.id]) {
        return this.templates[item.id].$el;
      }
      if (item.entity === 'route') {
        component = new GanttRoute({
          store: this.$store,
          parent: this,
          propsData: {
            routeId: item.id,
          },
        }).$once('delete-route', (route) => {
          this.queueAction({
            action: 'delete',
            type: 'route',
            data: route,
          });
          const idx = _.findIndex(this.routesWithResource, ['id', route.id]);
          this.routesWithResource.splice(idx, 1);
          this.$delete(this.templates, route.id);
          component.$destroy();
        });

        component.$mount();
        this.templates[item.id] = component;

        return component.$el;
      }
      return component;
    },
    getGroupTemplate(item) {
      if (!item) {
        return false;
      }
      if (this.groupTemplates[item.id]) {
        return this.groupTemplates[item.id].$el;
      }
      const component = new GanttLabel({
        store: this.$store,
        parent: this,
        propsData: {
          resourceId: item.id,
          name: item.content,
        },
      });
      component.$mount();
      this.groupTemplates[item.id] = component;

      return component.$el;
    },
    redraw() {
      this.$refs.timeline.redraw();
    },
    setItemBounds(i) {
      i.start = m(i.start);
      i.end = m(i.end);
      const duration = i.end.diff(i.start);
      const items = _.reject(
        _.sortBy(this.itemsByGroup[i.group], 'start'),
        (item) => item.id === i.id || item.entity === 'shift'
      );

      const index = _.findIndex(
        items,
        (item) => i.start.isSameOrBefore(item.end) && i.end.isAfter(item.start)
      );
      if (index > -1) {
        const getLeft = (leftId, start, isHole) => {
          const holeStart = isHole ? start : items[leftId].start;
          if (leftId === 0) {
            return items[leftId].start;
          }
          const prev = items[leftId - 1].end;
          const holeDuration = holeStart.diff(prev);
          if (holeDuration >= duration) {
            return isHole ? prev : holeStart;
          }
          return getLeft(leftId - 1, prev.clone(), true);
        };

        const getRight = (rightId, start, isHole) => {
          const holeStart = isHole ? start : items[rightId].end;
          if (rightId + 1 === items.length) {
            return items[rightId].end;
          }
          const next = items[rightId + 1].start;
          const holeDuration = next.diff(holeStart);
          if (holeDuration >= duration) {
            return isHole ? next : holeStart;
          }
          return getRight(rightId + 1, next.clone(), true);
        };

        const left = getLeft(index, i.start);
        const right = getRight(index, i.start);

        if (i.start.diff(left) <= right.diff(i.start)) {
          i.start = left.clone().subtract(duration);
          i.end = left;
        } else {
          i.start = right;
          i.end = right.clone().add(duration);
        }
      }
    },
    getRouteLimits(route) {
      if (route.deliveries.length > 0) {
        return {
          min: _.get(
            _.minBy(route.deliveries, 'planned_start'),
            'planned_start'
          ),
          max: _.get(_.maxBy(route.deliveries, 'planned_end'), 'planned_end'),
        };
      }
      return null;
    },
    getResourceGroup(resource) {
      return {
        id: resource.id,
        content: resource.name,
        type: 'group',
      };
    },
    getRouteItems(route) {
      return {
        id: route.id,
        content: route.name,
        start: route.planned_start,
        end: route.planned_end,
        group: route.resource.id,
        subgroup: route.id,
        className: 'route-planning shadow-border',
        entity: 'route',
        limits: this.getRouteLimits(route),
      };
    },
    getTimeoffItems(route) {
      return _.map(route.timeoffs, (b, idx) => ({
        id: `${route.id}_timeoff_${idx}`,
        start: b.start,
        end: b.end,
        group: _.get(route, 'resource.id', route.id),
        subgroup: route.id,
        className: 'timeoff-item-planning',
        entity: 'timeoff',
        routeId: route.id,
        index: idx,
      }));
    },
    movingItem({ entity, subgroup, start, end, index, limits }) {
      const route = this.$store.getters.routes.items[subgroup];
      if (entity === 'route') {
        if (limits && (start > limits.min || end < limits.max)) {
          return false;
        }
        const deltaStart = differenceInSeconds(start, route.planned_start);
        const deltaEnd = differenceInSeconds(end, route.planned_end);
        const delta = deltaStart === deltaEnd ? deltaStart : 0;
        route.timeoffs.forEach((b) => {
          const startTimeoff = addSeconds(b.start, delta);
          const endTimeoff = addSeconds(b.end, delta);
          const lengthTimeoff = differenceInSeconds(b.start, b.end);
          if (endTimeoff >= end) {
            b.end = end;
            b.start = subSeconds(end, lengthTimeoff);
          } else {
            b.end = endTimeoff;
          }
          if (startTimeoff <= start) {
            b.start = start;
            b.end = addSeconds(start, lengthTimeoff);
          } else {
            b.start = startTimeoff;
          }
        });
        route.planned_start = start;
        route.planned_end = end;
      }
      if (entity === 'timeoff') {
        const timeoff = route.timeoffs[index];
        if (route.planned_start > start || route.planned_end < end) {
          return false;
        } else {
          timeoff.start = start;
          timeoff.end = end;
        }
      }
      return true;
    },
    movedItem({ entity, id, routeId }) {
      // this.setItemBounds(item);
      const idRoute = entity === 'route' ? id : routeId;
      const route = this.$store.getters.routes.items[idRoute];
      if (route) {
        this.queueAction({
          action: 'patch',
          type: 'route',
          data: route,
        });
      } else {
        this.queueAction({
          action: 'post',
          type: 'route',
          data: route,
        });
      }
      this.selected = null;
    },
    select(v) {
      if (v.items.length === 0) {
        this.clearSelection();
      } else {
        [this.selected] = v.items;
      }
    },
  },
};
</script>
