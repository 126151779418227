<template>
  <el-container>
    <el-main>
      <div class="dock-wrapper">
        <dock v-for="dock in value" :key="dock.id" :dock="dock" />
        <cs-button
          v-show="value.length"
          square
          icon-type="material"
          icon="remove_circle"
          class="spot-btn-sec"
          @click="deleteDock"
        />
        <cs-button
          square
          icon-type="material"
          icon="add_circle"
          class="spot-btn-sec"
          @click="newDock"
        />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { isEmpty, upperFirst, last } from 'lodash';
import Dock from './Dock';
import { toAlphaNum } from '@/utils/helpers';

export default {
  name: 'EditDocks',
  components: {
    Dock,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    placeNumericId: {
      type: String,
      required: true,
    },
  },
  methods: {
    newDock() {
      const dockNumber = this.value.length + 1;
      if (dockNumber < 99) {
        this.addDock(`D${toAlphaNum(dockNumber, 1)}P${this.placeNumericId}`);
      } else {
        this.$message({
          type: 'error',
          message: upperFirst(this.$t('pages.place.spot.error.dockLimit')),
          showClose: true,
        });
      }
    },
    deleteDock() {
      if (isEmpty(last(this.value.items))) {
        const docks = this.value.splice(0, this.value.length - 1);
        this.$emit('input', docks);
      } else {
        this.$message({
          type: 'error',
          message: upperFirst(this.$t('pages.place.spot.error.spotNotEmpty')),
          showClose: true,
        });
      }
    },
    addDock(barcode) {
      this.$emit('input', [...this.value, { barcode, type: 'loading_dock' }]);
    },
  },
};
</script>

<style>
.dock-wrapper {
  max-width: 350px;
  margin: 10px 0;
  text-align: center;
}
</style>
