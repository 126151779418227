<template lang="html">
  <div class="gantt-label">
    <div class="gantt-label-content">
      <route-visibility :routes="routes" />
      <div class="upperFirst label-name">{{ name | truncate(33) }}</div>
    </div>
  </div>
</template>

<script>
import RouteVisibility from './RouteVisibility';

export default {
  components: {
    RouteVisibility,
  },
  props: {
    resourceId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    routes() {
      return this.$parent.resourceRoutes[this.resourceId];
    },
  },
};
</script>
