<template lang="html">
  <div class="gantt-label">
    <div class="gantt-label-route-content">
      <div class="upperFirst label-name">{{ route.name | truncate(33) }}</div>
      <el-select
        v-if="editable"
        v-model="route.resource"
        size="mini"
        value-key="id"
        :placeholder="$t('sentence.selectResource')"
        @change="change"
      >
        <el-option-group
          v-for="group in resourcesByPlace"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="resource in group.options"
            :key="resource.id"
            :disabled="!resource.shifts"
            :label="resource.name"
            :value="resource"
          />
        </el-option-group>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { values, reduce, reverse, upperFirst } from 'lodash';

export default {
  props: {
    routeId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    route() {
      return (
        this.$parent.routesObject[this.routeId] ||
        this.$store.getters.routes.items[this.routeId]
      );
    },
    resourcesByPlace() {
      return reverse(
        values(
          reduce(
            this.$store.getters.resources.items,
            (acc, resource) => {
              const place = resource.start_place;
              if (place) {
                if (!acc[place.id]) {
                  acc[place.id] = { label: place.name, options: [] };
                }
                acc[place.id].options.push(resource);
              } else {
                acc['#no_place#'].options.push(resource);
              }

              return acc;
            },
            {
              '#no_place#': {
                label: upperFirst(this.$t('commons.none.male')),
                options: [],
              },
            }
          )
        )
      );
    },
  },
  watch: {
    route(v) {
      if (!v) {
        this.$destroy();
      }
    },
  },
  methods: {
    ...mapActions(['queueAction']),
    change() {
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
  },
};
</script>
