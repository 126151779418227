<template>
  <list-layout :load="loading">
    <h3 slot="title">{{ $t('pages.vehicle.IndexPage.vehicleList') }}</h3>
    <el-table :data="paginatedList" stripe>
      <el-table-column prop="name" sortable :label="$t('commons.name')">
      </el-table-column>

      <el-table-column :label="$t('commons.capacity.plural')" min-width="200">
        <template slot-scope="scope">
          <constraint-list
            v-model="scope.row.capacities"
            no-title
            name="capacities"
          ></constraint-list>
        </template>
      </el-table-column>

      <el-table-column :label="$t('commons.tool.plural')" min-width="200">
        <template slot-scope="scope">
          <constraint-list
            v-model="scope.row.tools"
            no-title
            name="tools"
          ></constraint-list>
        </template>
      </el-table-column>

      <el-table-column min-width="150">
        <template slot-scope="scope">
          <div class="pull-right btn-group">
            <cs-button
              square
              icon="edit"
              icon-type="material"
              type="primary"
              @click.stop="
                $router.push({
                  name: 'editVehicle',
                  params: { id: scope.row.id },
                })
              "
            >
            </cs-button>
            <cs-button
              square
              icon="delete"
              icon-type="material"
              type="danger"
              @click="deleteVehicle(scope.row)"
            >
            </cs-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :page-size="pageSize"
      :current-page="current"
      layout="total, prev, pager, next"
      :total="vehicles.length"
      @current-change="setPage"
    >
    </el-pagination>

    <cs-button
      slot="footer"
      icon="add"
      icon-type="material"
      type="primary"
      @click.stop="$router.push({ name: 'createVehicle' })"
    >
      {{ $t('pages.resource.addVehicle') }}
    </cs-button>
  </list-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ListLayout from '@/layouts/ListLayout';
import ConstraintList from '@/components/ConstraintList';

export default {
  components: {
    ListLayout,
    ConstraintList,
  },
  data: () => ({
    loading: true,
    current: 1,
    pageSize: 10,
    dialogVisible: false,
  }),
  computed: {
    ...mapGetters(['vehicles']),
    paginatedList() {
      return Object.values(this.vehicles.items).slice(
        this.pageSize * (this.current - 1),
        this.pageSize * this.current
      );
    },
  },
  created() {
    this.get({ type: 'vehicle', id: 'all' }).then(() => {
      this.loading = false;
      this.setPage();
    });
  },
  methods: {
    ...mapActions(['get', 'delete']),
    deleteVehicle(vehicle) {
      this.$confirm(
        `${this.$t('pages.vehicle.IndexPage.removeVehicle')} ${vehicle.name}`
      )
        .then(() => {
          this.loading = true;
          this.delete({ type: 'vehicle', id: vehicle.id }).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setPage(v) {
      if (v) {
        this.current = v;
      }
    },
  },
};
</script>

<style scoped></style>
