<template lang="html">
  <sidebar-layout>
    <div class="sidebar-container">
      <el-row
        v-for="(o, idx) in optimizations.items"
        :key="idx"
        class="sidebar-head-bg"
      >
        <optim-list-item class="" :optimization="o" />
      </el-row>
      <div v-if="optimizationCount === 0" class="no-optim">
        <em class="upperFirst">{{ $t('sentence.noOptimization') }}</em>
      </div>
    </div>

    <!-- <optim-create
      :visible="displayCreate"
      :routes="routes"
      :resources="resources"
      :unplanned-route="unplannedRoute"
      @close="displayCreate = false"
    />

    <optim-result
      v-if="selectedOptimization"
      :optimization="selectedOptimization"
      @close="clearSelectedOptimization"
    /> -->

    <span slot="footer">
      <cs-button
        type="primary"
        placement="top"
        @click="$router.push({ name: 'createOptimization' })"
      >
        {{ $t('sentence.createOptimization') }}
      </cs-button>
    </span>
  </sidebar-layout>
</template>

<script>
import { some, size } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import OptimListItem from './OptimListItem';
import SidebarLayout from '../SidebarLayout';

export default {
  components: {
    OptimListItem,
    SidebarLayout,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    resources: {
      type: Object,
      default: () => ({}),
    },
    unplannedRoute: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    displayCreate: false,
  }),
  computed: {
    ...mapGetters(['selectedOptimization', 'optimizations']),
    hasRunningOptim() {
      return some(
        this.optimizations.items,
        (o) => !['resolved', 'failed'].includes(o.state)
      );
    },
    optimizationCount() {
      return size(this.optimizations.items);
    },
  },
  methods: {
    ...mapActions(['clearSelectedOptimization']),
  },
};
</script>

<style lang="scss">
.optim-button-list {
  display: flex;
  flex-direction: column;
}
.no-optim {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
