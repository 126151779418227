<template lang="html">
  <base-layout class="optim-create">
    <optim-title
      slot="title"
      :name="name | empty($t('sentence.createOptimization'))"
    >
      <el-input
        v-if="editName"
        v-model="name"
        slot="title"
        :placeholder="$t('sentence.addName')"
        @blur="editName = false"
      />
      <cs-button
        v-else
        slot="actions-left"
        icon-type="material"
        icon="edit"
        class="btn-invisible-highlight optim-title__btn"
        @click="editName = true"
      />
    </optim-title>

    <el-row
      class="optim-create__main"
      type="flex"
      justify="space-between"
      :gutter="20"
    >
      <el-col :span="12">
        <optim-create-map
          ref="map"
          class="optim-create-map"
          display-tools
          :style="mapStyle"
          :filtered-data="allRoutes"
          :routes="allRoutes"
        />
      </el-col>

      <el-col :span="12">
        <el-row type="flex" justify="space-between" class="optim-create__left">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-collapse
                v-model="visibleFilters"
                class="optim-create-collapse"
              >
                <el-collapse-item :title="$t('commons.batch')" name="lots">
                  <h5>
                    <i class="material-icons">room</i>
                    {{ $t('commons.activity.plural') }}
                  </h5>

                  <batch-checkbox
                    :value="checked.all.planned"
                    @change="toggleActivities(planned, $event)"
                  >
                    <span slot="label">
                      {{ $t('commons.planned.female.plural') }}
                    </span>
                    <span slot="count">{{ planned.length }}</span>
                  </batch-checkbox>

                  <br />

                  <batch-checkbox
                    :value="checked.all.unplanned"
                    @change="toggleActivities(unplanned, $event)"
                  >
                    <span slot="label">
                      {{ $t('commons.unplanned.female.plural') }}
                    </span>
                    <span slot="count">{{ unplanned.length }}</span>
                  </batch-checkbox>

                  <h5>
                    <i class="material-icons">show_chart</i>
                    {{ $t('commons.route.plural') }}
                  </h5>
                  <batch-checkbox
                    :value="checked.all.used"
                    @change="toggleRoutes(routeUsage.used, $event)"
                  >
                    <span slot="label">
                      {{ $t('commons.used.female.plural') }}
                    </span>
                    <span slot="count">{{ routeUsage.used.length }}</span>
                  </batch-checkbox>

                  <br />

                  <batch-checkbox
                    :value="checked.all.unused"
                    @change="toggleRoutes(routeUsage.unused, $event)"
                  >
                    <span slot="label">
                      {{ $t('commons.unused.female.plural') }}
                    </span>
                    <span slot="count">{{ routeUsage.unused.length }}</span>
                  </batch-checkbox>
                </el-collapse-item>

                <el-collapse-item :title="$t('commons.details')" name="details">
                  <div style="display: flex">
                    <section class="optim-create-collapse__column">
                      <h6>
                        <i class="material-icons">room</i>
                        {{ $t('commons.route.plural') }}
                      </h6>

                      <template v-for="route in routes">
                        <el-checkbox
                          :key="route.id"
                          :value="checked.routes[route.id].checked"
                          @change="toggleRoute(route, $event)"
                        >
                          <route-tag :route="route" />
                        </el-checkbox>
                      </template>
                    </section>

                    <section class="optim-create-collapse__column">
                      <h6>
                        <i class="material-icons">show_chart</i>
                        {{ $t('commons.activity.plural') }}
                      </h6>

                      <template v-for="route in routes">
                        <el-checkbox
                          :key="route.id"
                          :value="checked.routes[route.id].activitiyChecked"
                          :disabled="route.deliveries.length === 0"
                          @change="toggleRouteActivities(route, $event)"
                        >
                          <route-activity-tag :route="route" />
                        </el-checkbox>
                      </template>
                    </section>
                  </div>
                </el-collapse-item>

                <el-collapse-item
                  :title="$t('commons.option.plural')"
                  name="options"
                >
                  <ul class="optim-option">
                    <li v-if="isGraphhopper">
                      <batch-checkbox v-model="traffic">
                        <span slot="label">
                          {{
                            traffic
                              ? $t('pages.optimization.traffic.with')
                              : $t('pages.optimization.traffic.without')
                          }}
                        </span>
                        <!-- <span slot="count">{{ routeUsage.unused.length }}</span> -->
                      </batch-checkbox>
                      <!-- <el-checkbox v-model="traffic">
                        <span slot="label">
                          {{
                            traffic
                              ? $t('pages.optimization.traffic.with')
                              : $t('pages.optimization.traffic.without')
                          }}
                        </span>
                      </el-checkbox> -->
                    </li>
                  </ul>
                </el-collapse-item>

                <el-collapse-item
                  :title="$tc('commons.alert.choice', alerts.length)"
                  name="alerts"
                >
                  <ul class="optim-create-alerts">
                    <li v-for="(alert, idx) in alerts" :key="idx">
                      <!-- {{ alert }} -->
                      <el-alert
                        show-icon
                        :title="alert.message"
                        :type="alert.type"
                        :closable="false"
                      />
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-col>

            <el-col :span="12">
              <el-card shadow="never" class="optim-create-selection">
                <div slot="header">
                  <h4>{{ $t('sentence.toOptimize') }}</h4>
                  <el-button
                    type="primary"
                    size="small"
                    class="btn-highlight-inverted"
                    @click="clear"
                  >
                    {{ $t('commons.toEmpty') }}
                  </el-button>
                </div>

                <div class="optim-create-selection__body">
                  <ul>
                    <li
                      v-for="route in payload.routes"
                      class="pointer"
                      :key="route.id"
                      @click="removeRoutes([route])"
                    >
                      <route-tag :route="route" />
                    </li>
                    <li
                      v-for="activity in payload.deliveries"
                      :key="activity.id"
                    >
                      <activity-icon
                        font-size="0.8em"
                        line-height="22px"
                        class="pointer"
                        :text="activity.order | orderString"
                        :color="activity.color"
                        :svg="activity.svg"
                        @click="removeActivities([activity])"
                      />
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-card class="optim-create-actions">
                <div class="optim-create-actions__stat">
                  <i class="material-icons">show_chart</i>
                  {{ optimRoutes.length }}
                </div>

                <div class="optim-create-actions__stat">
                  <i class="material-icons">room</i>
                  {{ optimActivities.length }}
                </div>

                <div class="optim-create-actions__cta">
                  <el-button
                    type="primary"
                    class="btn-highlight upperFirst"
                    :loading="saving"
                    @click="send"
                    >{{ $t('commons.optimize') }}</el-button
                  >
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
  </base-layout>
</template>

<script>
import { difference, some, flatMap, groupBy, toString } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ActivityIcon from '@/components/ActivityIcon';
import OptimCreateMap from '@/components/Map';
import Route from '@/models/Route';
import BatchCheckbox from './create/BatchCheckbox';
import RouteActivityTag from './create/RouteActivityTag';
import RouteTag from './create/RouteTag';
import OptimTitle from './OptimTitle';

export default {
  name: 'OptimCreate',
  components: {
    ActivityIcon,
    BatchCheckbox,
    RouteActivityTag,
    RouteTag,
    OptimCreateMap,
    OptimTitle,
  },
  data: () => ({
    name: undefined,
    saving: false,
    traffic: false,
    mapStyle: {
      height: '76vh',
      width: '100%',
      float: 'left',
    },
    baseUnplanned: {
      id: null,
      state: '#unplanned',
      name: '',
      deliveries: [],
      displayMarkers: true,
    },
    optimRoutes: [],
    optimActivities: [],
    visibleFilters: ['lots', 'details', 'options', 'alerts'],
    editName: false,
  }),
  filters: {
    orderString: toString,
  },
  computed: {
    ...mapGetters(['selection', 'dateRange', 'routesByDate']),
    routes() {
      return [...this.$store.getters.routesByDate].filter(
        (r) => r.state === 'created'
      );
    },
    isGraphhopper() {
      return this.$store.getters.organization.engine === 'graphhopper';
    },
    maxActivities() {
      return this.$store.getters.organization.max_engine_activities;
    },
    allRoutes() {
      return [...this.routes, this.unplannedRoute];
    },
    planned() {
      return flatMap(this.routes, 'deliveries');
    },
    unplanned() {
      return this.unplannedRoute.deliveries;
    },
    activities() {
      return this.planned.concat(this.unplanned);
    },
    routeUsage() {
      const usage = groupBy(this.routes, ({ deliveries }) =>
        !deliveries.length ? 'unused' : 'used'
      );
      usage.used = usage.used || [];
      usage.unused = usage.unused || [];

      return usage;
    },
    checked() {
      const routes = this.routes.reduce((acc, { id, deliveries }) => {
        acc[id] = {
          checked: some(this.optimRoutes, (i) => i === id),
          activitiyChecked:
            deliveries.length > 0 &&
            difference(
              deliveries.map((d) => d.id),
              this.optimActivities
            ).length === 0,
        };
        return acc;
      }, {});

      const used = this.routeUsage.used || [];
      const unused = this.routeUsage.unused || [];
      return {
        routes,
        all: {
          used:
            used.length > 0 &&
            difference(
              used.map((d) => d.id),
              this.optimRoutes
            ).length === 0,
          unused:
            unused.length > 0 &&
            difference(
              unused.map((d) => d.id),
              this.optimRoutes
            ).length === 0,
          planned:
            this.planned.length > 0 &&
            difference(
              this.planned.map((d) => d.id),
              this.optimActivities
            ).length === 0,
          unplanned:
            this.unplanned.length > 0 &&
            difference(
              this.unplanned.map((d) => d.id),
              this.optimActivities
            ).length === 0,
        },
      };
    },
    alerts() {
      const alerts = [];
      if (!this.name) {
        alerts.push({
          message: this.$t('error.optim.nameEmpty'),
          type: 'error',
        });
      }
      if (!this.payload.deliveries.length) {
        alerts.push({
          message: this.$t('error.optim.noActivity'),
          type: 'error',
        });
      }
      if (!this.payload.routes.length) {
        alerts.push({
          message: this.$t('error.optim.noRoute'),
          type: 'error',
        });
      }
      if (this.payload.deliveries.length > this.maxActivities) {
        alerts.push({
          message: this.$t('error.optim.tooManyActivities', [
            this.maxActivities,
          ]),
          type: 'error',
        });
      }
      this.routes.forEach((r) => {
        if (!r.start_place && !r.end_place) {
          alerts.push({
            message: this.$t('error.optim.routeNoStartPlace', [r.name]),
            type: 'error',
          });
        }
      });

      return alerts;
    },
    unplannedRoute() {
      const route = this.baseUnplanned;
      route.deliveries = this.$store.getters.unplannedByDate;

      return route;
    },
    payload() {
      return {
        name: this.name,
        routes: this.routes
          .filter(({ id }) => this.optimRoutes.includes(id))
          .map((r) => {
            r.invalidateGeometry();
            return r;
          }),
        deliveries: this.activities.filter(({ id }) =>
          this.optimActivities.includes(id)
        ),
        range: {
          from: this.dateRange.from.toISOString(),
          to: this.dateRange.to.toISOString(),
        },
        options: {
          traffic: this.traffic,
        },
      };
    },
  },
  mounted() {
    this.$store.dispatch('setDatePicker', {
      clickHandle: this.fetchData,
      display: true,
    });
  },
  created() {
    this.fetchData();
    this.baseUnplanned = new Route(this.baseUnplanned);
    this.traffic = this.isGraphhopper;
  },
  beforeDestroy() {
    this.$store.dispatch('setDisplayDatePicker', false);
  },
  watch: {
    selection: {
      deep: true,
      handler({ type, data }) {
        if (type === 'activity') {
          const groups = groupBy(data, (activity) =>
            some(this.optimActivities, (id) => activity.id === id)
              ? 'remove'
              : 'add'
          );
          if (groups.add) {
            this.addActivities(groups.add);
          }

          if (groups.remove) {
            this.removeActivities(groups.remove);
          }
          this.clearSelection();
        }
        if (type === 'route') {
          const groups = groupBy(data, (routes) =>
            some(this.optimRoutes, (id) => routes.id === id) ? 'remove' : 'add'
          );
          if (groups.add) {
            this.addRoutes(groups.add);
          }
          if (groups.remove) {
            this.removeRoutes(groups.remove);
          }
          this.clearSelection();
        }
      },
    },
  },
  methods: {
    ...mapActions(['clearSelection', 'post', 'get']),
    clear() {
      this.clearSelection();
      this.optimRoutes = [];
      this.payload.deliveries.forEach((a) => (a.isOptimMode = false));
      this.optimActivities = [];
    },
    toggleRoutes(routes, checked) {
      if (checked) {
        this.addRoutes(routes);
      } else {
        this.removeRoutes(routes);
      }
    },
    toggleRoute({ id }, checked) {
      if (checked) {
        this.optimRoutes.push(id);
      } else {
        const idx = this.optimRoutes.findIndex((i) => i === id);
        if (idx !== -1) {
          this.optimRoutes.splice(idx, 1);
        }
      }
    },
    toggleRouteActivities({ deliveries }, checked) {
      this.toggleActivities(deliveries, checked);
    },
    toggleActivities(deliveries, checked) {
      if (checked) {
        this.addActivities(deliveries);
      } else {
        this.removeActivities(deliveries);
      }
    },
    addActivities(activities) {
      const ids = activities.map((activity) => {
        activity.isOptimMode = true;
        return activity.id;
      });
      this.optimActivities = this.optimActivities.concat(ids);
    },
    removeActivities(activities) {
      const ids = activities.map((activity) => {
        activity.isOptimMode = false;
        return activity.id;
      });
      this.optimActivities = difference(this.optimActivities, ids);
    },
    addRoutes(routes) {
      const ids = routes.map((d) => d.id);
      this.optimRoutes = this.optimRoutes.concat(ids);
    },
    removeRoutes(routes) {
      const ids = routes.map((d) => d.id);
      this.optimRoutes = difference(this.optimRoutes, ids);
    },
    async send() {
      if (this.alerts.length) {
        this.alerts.forEach(({ message, type }) => {
          this.$message({
            showClose: true,
            duration: 6000,
            message,
            type,
          });
        });
      } else {
        this.saving = true;

        await this.post({
          type: 'optimization',
          data: this.payload,
        });
        this.removeActivities(this.payload.deliveries);
        this.saving = false;
        this.$router.back();
      }
    },
    fetchData() {
      this.$store.dispatch('setDatePickerLoading', true);
      const getRoute = this.get({
        type: 'route',
        id: 'all',
        query: {
          from: this.dateRange.from,
          to: this.dateRange.to,
          include: 'deliveries',
        },
      });

      const getDeliveries = this.get({
        type: 'delivery',
        id: 'all',
        query: {
          from: this.dateRange.from,
          to: this.dateRange.to,
          unrouted: true,
        },
      });

      Promise.all([getRoute, getDeliveries]).then(() => {
        this.$store.dispatch('setDatePickerLoading', false);
        this.$refs.map.refreshBounds();
      });
    },
  },
};
</script>

<style lang="scss">
.optim-create {
  .layout-head {
    padding: 0 !important;
  }
  .layout-main {
    padding: 10px !important;
    overflow-y: hidden !important;
  }

  &__main {
    height: 100%;
  }
  &__left {
    flex-direction: column;
    height: 100%;
  }
}

.optim-create-map {
  height: 100% !important;
  .map-overlap-left {
    width: auto;
    min-width: auto;

    .map-toolbox {
      padding-right: 5px;
    }
  }
}

.optim-create-collapse,
.optim-create-selection > .el-card__body {
  overflow-y: auto !important;
  overflow-x: hidden;

  > * {
    padding-right: 5px;
  }
}

.optim-create-collapse {
  height: calc(80vh - 80px);
  margin-bottom: 10px;

  &.el-collapse {
    border-top: none;
    border-bottom: none;
  }

  .el-collapse-item__header {
    color: $accent-color;
    text-transform: uppercase;
    border-bottom-color: $accent-color;
    background-color: transparent;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
    background-color: transparent;
  }

  .el-collapse-item__content:last-of-type {
    padding-bottom: 10px;
  }

  h5 {
    margin: 25px 0;
  }

  h6 {
    margin: 20px 0;
  }

  h5,
  h6 {
    text-transform: uppercase;

    .material-icons {
      margin-right: 6px;
      font-size: 16px;
      vertical-align: -3px;
    }
  }

  .el-checkbox {
    display: block;
    text-transform: none;

    &__label {
      width: calc(100% - 25px);
      vertical-align: middle;
    }
    & + .el-checkbox {
      margin-left: 0;
    }
  }

  &__column {
    flex: 0 0 48%;
    overflow: hidden;

    & + & {
      margin-left: auto;
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
  }
}

.optim-create-alerts {
  .el-alert {
    padding: 5px 5px 0;
  }
}

.optim-create-selection {
  &.el-card {
    box-shadow: none;
    border: 2px dashed #979797;
  }
  .el-card__body {
    height: calc(80vh - 195px);
  }
  .el-card__header {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__body {
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-bottom: 1em;
      }
    }

    &__route {
      display: inline-block;
      padding: 5px;
      border-bottom: 2px solid $primary-color-text;
      color: white;
      vertical-align: middle;

      .material-icons {
        vertical-align: -2px;
      }
    }
  }
}

.optim-create-actions {
  $actions: &;
  height: 80px;
  // margin-top: -80px;

  &.el-card {
    box-shadow: none;
    border: none;
  }

  .el-card__body {
    display: flex;
    align-items: center;

    #{$actions}__stat {
      flex-basis: 25%;
      text-align: center;
      font-weight: 100;
      font-size: 1.4em;
    }

    #{$actions}__cta {
      margin-left: auto;
      flex-basis: calc(50% - 10px);

      .el-button {
        display: block;
        width: 100%;
      }
    }
  }
}

.optim-create-dialog {
  .el-dialog__body {
    background-color: rgb(240, 243, 242);
  }
}

.optim-create-left {
  display: flex;
  flex-direction: column;

  .optim-name {
    align-items: center;
    button {
      margin-left: 5px;
    }
  }

  .optim-route-list {
    flex-grow: 1;
    overflow-y: scroll;
    height: 70vh;
    ul {
      width: 100%;
      list-style-type: none;
      padding: 0;
      .not-selected {
        opacity: 0.5;
      }
    }
  }
  .optim-route-item {
    padding-left: 10px;
    margin: 5px 0;
    display: flex;
    .content {
      padding: 5px;
      width: 100%;
      background-color: white;
      cursor: pointer;
      .title {
      }
      ul {
        padding-bottom: 7px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .optim-activity-item {
    display: inline-block;
    list-style: none;
    color: #1a1a1a;
    background-color: white;
    border: 2px solid grey;
    border-radius: 2px;
    margin: 0 0 2px 2px;
    padding: 3px;
    height: 36px;
    min-width: 27px;
    vertical-align: middle;
    div {
      text-align: center;
      line-height: 2em;
      cursor: pointer;
      span {
        font-size: 1.5em;
        font-weight: bold;
      }
      i {
        width: 1.4em;
        margin: auto;
        font-size: 1.5em;
      }
    }
  }
}
</style>
