<template>
  <section>
    <el-row type="flex" align="middle">
      <slot v-if="!noTitle" name="title">
        <label>{{ $t(`components.ConstraintList.${name}.title`) }}</label>
      </slot>
      <slot v-if="editable" name="add">
        <cs-button
          outline
          icon="add"
          icon-type="material"
          :tooltip="$t('commons.add')"
          @click="dialogVisible = true"
        />
      </slot>
    </el-row>
    <el-row>
      <el-tag
        v-for="(item, index) in list"
        :key="item.type"
        :closable="editable"
        @close="deleteItem(index)"
      >
        {{ item.type }}<span v-if="keyValue">: {{ item.value }}</span>
      </el-tag>
    </el-row>

    <el-dialog
      width="30%"
      :title="$t(`components.ConstraintList.${name}.create`) | upperFirst"
      :visible.sync="dialogVisible"
    >
      <el-form
        ref="form"
        label-position="top"
        :model="form"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item prop="type" :label="$t('commons.type')">
          <el-select
            v-if="!repository"
            v-model="type"
            clearable
            filterable
            allow-create
            :placeholder="$t('commons.type')"
            :no-data-text="$t(`components.ConstraintList.${name}.create`)"
            @keyup.enter.native="add"
            @change.native="newTag"
          >
            <el-option
              v-for="item in repositoryList"
              :key="item.type"
              :value="item.type"
              :label="item.type"
            >
            </el-option>
          </el-select>
          <el-input
            v-else
            v-model="type"
            :placeholder="$t(`components.ConstraintList.${name}.labelType`)"
            :value="type"
            :label="type"
            @keyup.enter.native="add"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="!repository && keyValue"
          prop="value"
          :label="$t(`components.ConstraintList.${name}.labelValue`)"
        >
          <el-input
            v-model.number="value"
            type="number"
            step="any"
            @keyup.enter.native="add"
          />
        </el-form-item>
      </el-form>
      <cs-button
        slot="footer"
        icon="add"
        type="success"
        :tooltip="$t('commons.add')"
        icon-type="material"
        @click.stop="add"
      >
        {{ $t('commons.add') }}
      </cs-button>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isNil, some, get } from 'lodash';
import { getRequireRule } from './../validation';

export default {
  model: {
    prop: 'list',
    event: 'change',
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
      validator: (v) => ['capacities', 'consist_of', 'tools'].includes(v),
    },
    keyValue: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    repository: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    type: null,
    value: null,
    dialogVisible: false,
  }),
  computed: {
    ...mapGetters(['organization']),
    rules() {
      return {
        type: [
          getRequireRule('commons.type'),
          {
            validator: this.validatorType,
            trigger: 'blur',
          },
        ],
        value: [
          {
            validator: this.validatorValue,
            trigger: 'blur',
          },
        ],
      };
    },
    form() {
      return {
        type: this.type,
        value: this.value,
      };
    },
    repositoryList() {
      return get(this.organization, this.name, []);
    },
    validationList() {
      return !this.repository ? this.list : this.repositoryList;
    },
  },
  methods: {
    ...mapActions(['patch']),
    add() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const item = this.keyValue
            ? { type: this.type, value: this.value }
            : { type: this.type };
          this.list.push(item);
          if (
            !this.repository &&
            !some(this.repositoryList, {
              type: this.type,
            })
          ) {
            this.patch({
              type: 'organization',
              id: this.organization.id,
              url: `organization/${this.organization.id}/${this.name}`,
              data: { type: this.type },
            });
          }

          this.dialogVisible = false;
          this.$emit('change', this.list);
        }
      });
      this.type = null;
      this.value = null;
    },
    deleteItem(idx) {
      this.list.splice(idx, 1);
      this.$emit('change', this.list);
    },
    newTag(event) {
      if (event.target.value) {
        this.type = event.target.value;
      }
    },
    validatorType(rule, value, callback) {
      return some(this.validationList, { type: value })
        ? callback(new Error(this.$t('components.ConstraintList.error')))
        : callback();
    },
    validatorValue(rule, value, callback) {
      return this.keyValue && isNil(value)
        ? callback(new Error('required'))
        : callback();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 15px;
}
.el-tag {
  margin-right: 1em;
}
</style>
