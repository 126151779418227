<template>
  <div>
    <el-form
      ref="form"
      :rules="rules"
      autocomplete="off"
      :model="vehicle"
      @submit.prevent
    >
      <el-row>
        <el-col :lg="{ span: 15, offset: 6 }">
          <el-form-item :label="$t('commons.name')" prop="name">
            <el-input v-model="vehicle.name" name="name" type="text" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="{ span: 16, offset: 6 }">
          <constraint-list
            v-model="vehicle.capacities"
            editable
            key-value
            name="capacities"
          ></constraint-list>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="{ span: 16, offset: 6 }">
          <constraint-list
            v-model="vehicle.tools"
            editable
            name="tools"
          ></constraint-list>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getRequireRule } from './../../../validation';
import ConstraintList from './../../../components/ConstraintList';

export default {
  name: 'VehicleForm',
  components: {
    ConstraintList,
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    rules() {
      return {
        name: [getRequireRule('commons.name')],
      };
    },
  },
  methods: {
    setTools(v) {
      this.vehicle.tools = v;
    },
    setCapacities(v) {
      this.vehicle.capacities = v;
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => (valid ? resolve() : reject()));
      });
    },
  },
};
</script>
