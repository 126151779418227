import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Element from 'element-ui';
import messages from './lang/all';

Vue.use(VueI18n);

const userLang =
  localStorage.getItem('lang') ||
  window.navigator.userLanguage ||
  window.navigator.language;
Vue.config.lang = userLang.substr(0, 2);

const i18n = new VueI18n({
  locale: Vue.config.lang,
  fallbackLocale: 'en',
  silentTranslationWarn: false,
  messages,
});

Vue.use(Element, {
  i18n: (path, options) => i18n.t(path, options),
});

export default i18n;
