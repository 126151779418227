<template lang="html">
  <cs-button
    outline
    :style="{ fontSize, color }"
    :icon="value ? iconOff : iconOn"
    :tooltip="undefined"
    icon-type="material"
    @click.stop="toggle"
  >
  </cs-button>
</template>

<script>
export default {
  name: 'EditToggle',
  props: {
    value: {
      type: Boolean,
    },
    small: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: undefined,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#50E3C2',
    },
    iconOn: {
      type: String,
      default: 'mode_edit',
    },
    iconOff: {
      type: String,
      default: 'close',
    },
  },
  computed: {
    fontSize() {
      return this.small ? '1em' : 'initial';
    },
    tooltipText() {
      if (this.disableTooltip) {
        return undefined;
      }

      return this.tooltip
        ? this.tooltip
        : `${this.$t('commons.edit')} ${this.name}`;
    },
  },
  methods: {
    toggle() {
      this.$emit(this.value ? 'close' : 'open');
      this.$emit('input', !this.value);
    },
  },
};
</script>
