import { upperFirst } from 'lodash';
import i18n from './locales';

const getRequireRule = (transKey) => ({
  required: true,
  message: i18n.t('commons.validateField', { field: i18n.t(transKey) }),
  trigger: 'blur',
});

const getTypeRule = (checkedType) => ({
  type: checkedType,
  message: (checkedType, i18n.t('commons.fieldError')),
  trigger: 'blur',
});

const passwordPromptRule = (password) => {
  const error = [];
  if (!password.match(/.{6,64}$/)) {
    error.push(i18n.t('error.password.length'));
  }
  if (!password.match(/^(?=.*[0-9]).*$/)) {
    error.push(i18n.t('error.password.number'));
  }
  if (!password.match(/^(?=.*[A-Z]).*$/)) {
    error.push(i18n.t('error.password.upper'));
  }
  if (!password.match(/^(?=.*[a-z]).*$/)) {
    error.push(i18n.t('error.password.lower'));
  }

  if (error.length) {
    return upperFirst(`${i18n.t('commons.password')} ${error.join(', ')}.`);
  } else {
    return true;
  }
};
const getPasswordRule = {
  validator: (rule, value, callback) => {
    const res = passwordPromptRule(value);
    if (typeof res === 'string') {
      callback(new Error(res));
    } else {
      callback();
    }
  },
  trigger: 'change',
};

const getPasswordConfirmRule = (password) => ({
  validator: (rule, value, callback) => {
    if (password !== value) {
      callback(new Error());
    }
    callback();
  },
  message: i18n.t('pages.password.noMatch'),
  trigger: 'blur',
});

const coordValidator = (value, min, max, name) => {
  if (value > max) {
    return new Error(i18n.t('error.validator.coord.greater', { name, max }));
  } else if (value < min) {
    return new Error(i18n.t('error.validator.coord.lower', { name, min }));
  }
};

const getLatitudeRule = () => ({
  validator: (rule, value, callback) => {
    callback(coordValidator(value, -90, 90, 'latitude'));
  },
  trigger: 'blur',
});

const getLongitudeRule = () => ({
  validator: (rule, value, callback) => {
    callback(coordValidator(value, -180, 180, 'longitude'));
  },
  trigger: 'blur',
});

export {
  getRequireRule,
  getTypeRule,
  getLatitudeRule,
  getLongitudeRule,
  getPasswordRule,
  getPasswordConfirmRule,
  passwordPromptRule,
};
