import { getRandomColor } from './color';

// const trackAddedProperties = {
//   position: null,
//   icon: ''
// };

// function addTrackingProperties(route) {
//   if (route.tracking && route.tracking.length > 0) {
//     const trackingPoints = [];
//
//     forEach(route.tracking, track => {
//       forEach(trackAddedProperties, (value, key) => {
//         Vue.set(track, key, value);
//       });
//
//       track.position = pushLatLng(trackingPoints, track);
//       track.route_id = route.id;
//     });
//
//     route.trackingPolyline = trackingPoints;
//   }
// }

export function getRouteColor(o) {
  return getRandomColor(o.route_id, '#F00', -0.2);
}
