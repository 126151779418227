<template>
  <div class="back">
    <section id="login">
      <h1 class="title">{{ $t('pages.password.resetLink') }}</h1>

      <el-form
        ref="form"
        label-position="top"
        :model="form"
        label-width="120px"
        :rules="rules"
      >
        <div v-if="error" class="alert alert-danger">
          <p class="upperFirst">erreur, veuillez réassayer</p>
        </div>

        <el-form-item :label="$t('commons.mail')" prop="email">
          <el-input
            v-model="form.email"
            name="email"
            type="email"
            :placeholder="$t('commons.mail')"
          />
        </el-form-item>
        <el-form-item :label="$t('pages.password.newPassword')" prop="password">
          <el-input
            v-model="form.password"
            name="password"
            type="password"
            :placeholder="$t('pages.user.UserForm.password')"
          />
        </el-form-item>

        <el-form-item
          :label="$t('pages.password.passwordConfirm')"
          prop="passwordConfirm"
        >
          <el-input
            v-model="form.passwordConfirm"
            name="password_confirmation"
            type="password"
            :placeholder="$t('pages.user.UserForm.password')"
            @keyup.enter.native="resetPassword"
          />
        </el-form-item>

        <el-form-item>
          <cs-button
            class="pull-right"
            type="primary"
            icon-type="material"
            icon="save"
            :loading="loading"
            @click="resetPassword"
          >
            {{ $t('commons.save') }}
          </cs-button>
        </el-form-item>
      </el-form>
    </section>
  </div>
</template>

<script>
import {
  getRequireRule,
  getTypeRule,
  getPasswordRule,
  getPasswordConfirmRule,
} from '@/validation';

export default {
  data: () => ({
    form: {
      email: null,
    },
    success: false,
    error: false,
    loading: false,
    token: '',
  }),
  computed: {
    rules() {
      return {
        email: [getRequireRule('commons.mail'), getTypeRule('email')],
        passwordConfirm: [
          getPasswordConfirmRule(this.form.password),
          getRequireRule('pages.user.UserForm.password'),
        ],
        password: [
          getRequireRule('pages.user.UserForm.password'),
          getPasswordRule,
        ],
      };
    },
  },
  mounted() {
    this.form.email = this.$route.query.email;
    this.token = this.$route.params.token;
  },
  methods: {
    resetPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.axios
            .post('password/reset', {
              token: this.token,
              email: this.form.email,
              password: this.form.password,
              password_confirmation: this.form.passwordConfirm,
            })
            .then(
              () => {
                this.loading = true;
                this.$router.push('/login');
              },
              () => {
                this.error = true;
                this.loading = false;
              }
            );
        }
      });
    },
    checkPasswordConfirm(rule, value, callback) {
      if (this.form.passwordConfirm !== this.form.password) {
        callback(new Error());
      }
      callback();
    },
    checkPasswordLength(rule, value, callback) {
      if (this.form.password.length < 6) {
        callback(new Error());
      }
      callback();
    },
  },
};
</script>
