<template lang="html">
  <section>
    <el-row class="content-section" :style="backgroundStyle">
      <el-row type="flex" align="bottom">
        <el-col :span="21">
          <h4 :style="titleStyle">{{ title }}</h4>
        </el-col>
        <el-col :span="3">
          <slot name="activator">
            <edit-toggle v-show="isEditable" v-model="edit" :name="tooltip" />
          </slot>
        </el-col>
      </el-row>

      <el-row :style="backgroundStyle" class="edit-element">
        <slot v-if="!edit" />
        <slot v-else name="editContent" :edit="edit" />
      </el-row>
    </el-row>
    <hr v-if="!noSeparator" class="activity-content-separator" />
  </section>
</template>

<script>
import { has } from 'lodash';
import EditToggle from '@/components/EditToggle';

export default {
  components: {
    EditToggle,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    activatorName: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      required: true,
    },
    noSeparator: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    edit: false,
  }),
  computed: {
    isEditable() {
      return (
        has(this.$slots, 'editContent') ||
        has(this.$scopedSlots, 'editContent') ||
        this.editable
      );
    },
    tooltip() {
      return this.activatorName || this.title;
    },
    backgroundStyle() {
      return { backgroundColor: this.edit ? '#32363B' : '#EBEDF0' };
    },
    titleStyle() {
      return { color: this.edit ? '#50E3C2' : 'black' };
    },
  },
  methods: {
    close() {
      this.edit = false;
    },
  },
};
</script>

<style lang="scss">
.edit-element {
  width: 100%;
}
</style>
