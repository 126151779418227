<template>
  <list-layout :load="loading">
    <h3 slot="title">{{ $t('pages.user.IndexPage.userList') }}</h3>
    <el-table :data="paginatedList" stripe style="width: 100%">
      <el-table-column
        prop="name"
        sortable
        :label="$t('commons.name')"
        min-width="150"
      >
      </el-table-column>
      <el-table-column prop="email" :label="$t('commons.mail')" min-width="150">
      </el-table-column>
      <el-table-column
        prop="role.name"
        sortable
        :label="$t('commons.role.single')"
        width="100"
      >
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <div class="pull-right btn-group">
            <cs-button
              square
              icon="edit"
              icon-type="material"
              type="primary"
              @click.stop="
                $router.push({
                  name: 'editUser',
                  params: { id: scope.row.id },
                })
              "
            >
            </cs-button>
            <cs-button
              square
              icon="delete"
              icon-type="material"
              type="danger"
              @click="deleteUser(scope.row)"
            >
            </cs-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :page-size="pageSize"
      :current-page="current"
      layout="total, prev, pager, next"
      :total="users.length"
      @current-change="setPage"
    >
    </el-pagination>

    <cs-button
      slot="footer"
      icon="add"
      icon-type="material"
      type="primary"
      @click.stop="$router.push({ name: 'createUser' })"
    >
      {{ $t('pages.user.IndexPage.addUser') }}
    </cs-button>
  </list-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ListLayout from '@/layouts/ListLayout';

export default {
  components: { ListLayout },
  data: () => ({
    loading: true,
    pageIndex: 1,
    pageSize: 10,
    current: 1,
  }),
  computed: {
    ...mapGetters(['users']),
    paginatedList() {
      return Object.values(this.users.items).slice(
        this.pageSize * (this.pageIndex - 1),
        this.pageSize * this.pageIndex
      );
    },
  },
  created() {
    this.get({ type: 'user', id: 'all' })
      .then(() => {
        this.setPage();
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['get', 'delete']),
    deleteUser(user) {
      this.$confirm(
        `${this.$t('pages.user.IndexPage.removeUser')} ${user.name}`
      ).then(
        () => {
          this.loading = true;
          this.delete({ type: 'user', id: user.id })
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        },
        () => null
      );
    },
    setPage(v) {
      if (v) {
        this.pageIndex = v;
      }
    },
  },
};
</script>
