<template lang="html">
  <li
    draggable="true"
    class="drag-item"
    :class="{ selected: activity.isSelected }"
    @mousedown="toggleSelect"
    @dragstart="handleDragStart($event)"
    @dragend="handleDragStop"
    @mouseenter="handleOver"
  >
    <activity-icon
      ref="icon"
      :text="activity.icon.options.text"
      :color="color"
      :svg="activity.icon.options.svg"
    >
    </activity-icon>
  </li>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import ActivityIcon from '../../ActivityIcon';

export default {
  components: {
    ActivityIcon,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    content: {
      type: [String, Number],
      default: () => '',
    },
    overId: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapGetters(['routes']),
    over() {
      return this.activity.id === this.overId;
    },
    text() {
      return this.content || this.activity.external_id;
    },
    color() {
      return get(this.routes.items[this.activity.route_id], 'color');
    },
  },
  methods: {
    handleDragStart(event) {
      event.dataTransfer.effectAllowed = 'move';
      const item = {
        id: this.activity.id,
        content: 'activity',
      };
      event.dataTransfer.setData('text', JSON.stringify(item));
    },
    handleDragStop() {
      this.$emit('unselected', this.activity);
    },
    handleOver() {
      this.$emit('over', this.activity.id);
    },
    toggleSelect() {
      this.$emit(
        this.activity.isSelected ? 'unselected' : 'selected',
        this.activity
      );
    },
  },
};
</script>
