import { isValid } from 'date-fns';
import { isArray, isNil } from 'lodash';

export default class ApiEntity {
  #attributes;

  toJSON() {
    return this.#attributes.reduce((acc, attr) => {
      acc[attr] = this[attr];
      return acc;
    }, {});
  }

  fill(apiObject) {
    Object.keys(apiObject).forEach((key) => {
      if (this.hasOwnProperty(key) && apiObject[key]) {
        this[key] = apiObject[key];
      }
    });
    this.#attributes = Object.getOwnPropertyNames(this);
  }

  castDate(date) {
    if (date) {
      const d = new Date(date);
      return isValid(d) ? d : date;
    }

    return undefined;
  }

  static createInstance(data) {
    if (isNil(data)) {
      return data;
    }

    return isArray(data)
      ? data.reduce((acc, d) => {
          if (d) {
            acc.push(new this(d));
          }
          return acc;
        }, [])
      : new this(data);
  }
}
