<template>
  <base-list :data="clients">
    <el-table-column
      sortable
      :label="$t('commons.name') | upperFirst"
      prop="name"
    >
    </el-table-column>

    <el-table-column
      prop="import_format"
      :label="$t('pages.client.import') | upperFirst"
      width="100"
      :filters="[
        { text: 'Api', value: 'api' },
        { text: 'Csv', value: 'csv' },
        { text: 'Dispor', value: 'dispor' },
      ]"
      :filter-method="filterTag"
      filter-placement="bottom-end"
    >
    </el-table-column>

    <slot name="columns"></slot>

    <el-table-column>
      <template slot-scope="scope">
        <div class="pull-right btn-group">
          <cs-button
            v-if="scope.row.import_format === 'api' && scope.row.api_key"
            v-clipboard="scope.row.api_key"
            square
            icon="content_copy"
            icon-type="material"
            type="primary"
            @success="handleCopySuccess"
            @error="handleCopyError"
          />
          <slot name="edit-action" :client="scope.row">
            <cs-button
              square
              icon="edit"
              icon-type="material"
              type="primary"
              @click="
                $router.push({
                  name: 'editClient',
                  params: { id: scope.row.id },
                })
              "
            />
          </slot>

          <cs-button
            square
            icon="delete"
            icon-type="material"
            type="danger"
            @click="deleteClient(scope.row)"
          />
          <slot name="actions"></slot>
        </div>
      </template>
    </el-table-column>
  </base-list>
</template>

<script>
import { mapActions } from 'vuex';
import BaseList from './BaseList';

export default {
  components: { BaseList },
  props: {
    clients: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions(['delete']),
    deleteClient(client) {
      this.$confirm(
        `${this.$t('pages.client.IndexPage.wantedToDeleteCustomer')} ${
          client.name
        }`
      ).then(
        () => {
          this.loading = true;
          this.delete({ type: 'client', id: client.id })
            .then((this.loading = false))
            .catch((this.loading = false));
        },
        () => null
      );
    },
    handleCopySuccess() {
      this.$notify({
        title: this.$t('pages.client.notifTitleDone'),
        message: this.$t('pages.client.notifTexteDone'),
        type: 'success',
        iconClass: this.$t('commons.cancel'),
        duration: 2000,
      });
    },
    handleCopyError() {
      this.$notify({
        title: this.$t('commons.error'),
        message: this.$t('pages.client.notifTexteError'),
        type: 'success',
        iconClass: this.$t('commons.cancel'),
        duration: 2000,
      });
    },
    filterTag(value, row) {
      return row.import_format === value;
    },
  },
};
</script>

<style lang="css" scoped></style>
