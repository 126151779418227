<template>
  <base-layout>
    <h3 slot="title">{{ $t('pages.user.create') }}</h3>
    <user-form is-create ref="form" :user="user" :saving="saving"></user-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexUser' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveUser"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapActions } from 'vuex';
import UserForm from './forms/UserForm';

export default {
  name: 'CreateUser',
  components: { UserForm },
  data: () => ({
    saving: false,
    user: {
      first_name: null,
      last_name: null,
      email: null,
      role: null,
      password: null,
    },
  }),
  methods: {
    ...mapActions(['post']),
    saveUser() {
      this.$refs.form.validate().then(
        () => {
          this.saving = true;
          this.post({ type: 'user', data: this.user }).then(() => {
            this.saving = false;
            this.$router.push({ name: 'indexUser' });
          });
        },
        () => {
          this.saving = false;
        }
      );
    },
  },
};
</script>
