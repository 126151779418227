<template>
  <el-select
    v-model="currentValue"
    filterable
    placeholder="Select"
    @change="emitCountryCode"
  >
    <el-option
      v-for="(country, code) in countries"
      :key="code"
      :value="code"
      :label="country"
    >
    </el-option>
  </el-select>
</template>

<script>
import countryList from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';

countryList.registerLocale(fr);
countryList.registerLocale(en);

export default {
  model: {
    prop: 'countryCode',
    event: 'change',
  },
  props: {
    countryCode: {
      type: String,
      default: 'FR',
    },
  },
  data: () => ({
    currentValue: null,
    countryList,
  }),
  computed: {
    countries() {
      return countryList.getNames(this.$i18n.locale);
    },
  },
  mounted() {
    this.currentValue = this.countryCode;
    this.emitCountryCode(this.currentValue);
  },
  methods: {
    emitCountryCode(v) {
      this.$emit('change', v);
    },
  },
};
</script>
