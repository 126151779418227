<template lang="html">
  <el-table :data="data">
    <el-table-column prop="name" min-width="100" :label="$t('commons.name')">
      <template slot-scope="scope">
        {{ $t(`role.ability.${scope.row.name}`) }}
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      min-width="100"
      :label="$t('role.abilityHeader.read')"
    >
      <template slot-scope="scope">
        <el-checkbox
          v-model="scope.row['read']"
          :disabled="isDisabled(scope.row.name, 'read')"
          @change="$emit('change', abilitiesFromData)"
        />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      min-width="100"
      :label="$t('role.abilityHeader.create')"
    >
      <template slot-scope="scope">
        <el-checkbox
          v-model="scope.row['create']"
          :disabled="isDisabled(scope.row.name, 'create')"
          @change="$emit('change', abilitiesFromData)"
        />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      min-width="100"
      :label="$t('role.abilityHeader.update')"
    >
      <template slot-scope="scope">
        <el-checkbox
          v-model="scope.row['update']"
          :disabled="isDisabled(scope.row.name, 'update')"
          @change="$emit('change', abilitiesFromData)"
        />
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      header-align="center"
      min-width="100"
      :label="$t('role.abilityHeader.destroy')"
    >
      <template slot-scope="scope">
        <el-checkbox
          v-model="scope.row['destroy']"
          :disabled="isDisabled(scope.row.name, 'destroy')"
          @change="$emit('change', abilitiesFromData)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, map, clone, zipObject } from 'lodash';

export default {
  model: {
    prop: 'abilities',
    event: 'change',
  },
  props: {
    abilities: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    disabled: {
      organization: ['read', 'create', 'destroy'],
      delivery: ['destroy'],
      item: ['destroy'],
    },
  }),
  computed: {
    data() {
      return map(this.abilities, (abilities, name) => ({ name, ...abilities }));
    },
    abilitiesFromData() {
      const abilities = this.data.map((d) => {
        const a = clone(d);
        delete a.name;
        return a;
      });

      return zipObject(map(this.data, 'name'), abilities);
    },
  },
  methods: {
    isDisabled(name, ability) {
      return get(this.disabled, name, []).includes(ability);
    },
  },
};
</script>

<style lang="scss"></style>
