<template>
  <section class="excel-uploader">
    <div class="uploader" v-if="rows.length === 0">
      <el-upload
        class="upload-field"
        drag
        action=""
        :on-change="onFileChange"
        :accept="accept"
        :auto-upload="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ $t('components.XlsxImporter.dragNdrop') }}
          <em>{{ $t('components.XlsxImporter.upload') }}</em>
        </div>
        <div slot="tip" class="el-upload__tip">
          <p>{{ $t('components.XlsxImporter.accepted') }} : {{ accept }}</p>
          <p>{{ $t('components.XlsxImporter.maxrow') }} : {{ limit }}</p>
        </div>
      </el-upload>
      <sample />
    </div>

    <el-table v-else height="72vh" :data="mapping">
      <el-table-column
        :label="$t('components.XlsxImporter.fields')"
        width="200"
      >
        <template slot-scope="scope">
          <label>
            {{ scope.row.label }}
            <span v-if="scope.row.isArray"> </span>
          </label>
          <p class="excel-uploader__desc" v-if="scope.row.desc">
            {{ scope.row.desc }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('components.XlsxImporter.columnMatch')"
        width="300"
      >
        <template slot-scope="scope">
          <column-mapper
            :data="scope.row"
            :options="select"
            @change="handleColumnMapChange"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('components.XlsxImporter.preview')"
        min-width="200"
      >
        <template slot-scope="scope" class="column-preview">
          <Preview :preview="scope.row.preview" />
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import {
  some,
  isEmpty,
  map,
  get,
  last,
  isNil,
  find,
  set,
  forEach,
} from 'lodash';
import XLSX from 'xlsx';
import ColumnMapper from './ColumnMapper';
import Preview from './Preview';
import Sample from './Sample';

export default {
  name: 'XlsxImporter',
  components: { ColumnMapper, Preview, Sample },
  props: {
    model: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    dictionary: {
      type: Object,
      default: () => ({}),
    },
    limit: {
      type: Number,
      default: 300,
    },
  },
  data: () => ({
    accept: 'xlsx, xlsb, xlsm, xls',
    rows: [],
    select: [],
    mapping: [],
  }),
  computed: {
    hasError() {
      return some(this.mapping, (m) => m.error);
    },
    mappedList() {
      return this.mapping.filter(
        (i) =>
          (!isNil(i.value) && !isEmpty(i.value)) ||
          !isNil(i.defaultValue) ||
          !isEmpty(i.itemsMapValue)
      );
    },
    firstRow() {
      return this.rows[0];
    },
  },
  methods: {
    initMapping() {
      this.mapping = map(this.model, (value, name) => {
        const data = {
          name,
          label: get(value, 'label'),
          desc: get(value, 'desc'),
          default_field: get(value, 'default_field'),
          isArray: get(value, 'model_type') === Array,
          itemsModel: get(value, 'items'),
          itemsMapValue: [],
          value: this.getValue(name),
          defaultValue: undefined,
          error: null,
        };
        data.preview = this.getPreview(data);
        return data;
      });
      this.$emit('inited');
      this.$emit('input', this.parseData());
    },
    clearData() {
      this.rows = [];
    },
    getValue(name) {
      const key = name.includes('.') ? last(name.split('.')) : name;
      const res = find(
        this.select,
        (word) =>
          get(this.dictionary, key) === word.toLowerCase() ||
          key === word.toLowerCase()
      );
      return res;
    },
    updateMapping(mappingEntry) {
      const idx = this.mapping.findIndex((m) => m.name === mappingEntry.name);
      mappingEntry.preview = this.getPreview(mappingEntry);
      this.$emit('mapped', mappingEntry);
      this.$set(this.mapping, idx, mappingEntry);
    },
    getPreview(mapping) {
      return this.firstRow
        ? this.getFormattedData(
            mapping,
            this.firstRow[mapping.value],
            this.model[mapping.name],
            this.firstRow
          )
        : undefined;
    },
    handleColumnMapChange(mappingEntry) {
      this.updateMapping(mappingEntry);
      this.$emit('input', this.parseData());
    },
    parseData() {
      return this.rows.map((row) => {
        const item = {};
        this.mappedList.forEach((mapping) => {
          set(
            item,
            mapping.name,
            this.getFormattedData(
              mapping,
              row[mapping.value],
              this.model[mapping.name],
              row
            )
          );
        });
        return item;
      });
    },
    getFormattedData(mapping, data, model, row) {
      if (model.model_type === Array) {
        return map(mapping.itemsMapValue, (item) => {
          const res = {};
          forEach(item.map, (v, k) => {
            set(
              res,
              k,
              this.getFormattedData(mapping, row[v], mapping.itemsModel[k])
            );
          });
          return res;
        });
      }
      return model.format(data);
    },
    populateSelect() {
      const max = 0;
      let maxSelect = [];
      this.rows.forEach((object) => {
        if (Object.keys(object).length >= max) {
          maxSelect = Object.keys(object);
        }
      });
      forEach(maxSelect, (selection) => {
        this.select.push(selection);
      });
    },
    onFileChange(evt) {
      const file = evt.raw;
      const reader = new FileReader();

      reader.onload = (e) => {
        // pre-process data
        let binary = '';
        const bytes = new Uint8Array(e.target.result);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i += 1) {
          binary += String.fromCharCode(bytes[i]);
        }
        /* read workbook */
        const wb = XLSX.read(binary, {
          type: 'binary',
          cellDates: true,
        });

        /* grab first sheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const range = XLSX.utils.decode_range(ws['!ref']);
        range.e.r = this.limit;
        ws['!ref'] = XLSX.utils.encode_range(range);
        this.rows = XLSX.utils.sheet_to_json(ws, {
          defval: '',
        });
        this.populateSelect();
        this.initMapping();
      };

      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style lang="scss">
.excel-uploader {
  width: 100%;
  .upload-field {
    margin-bottom: 10px;
  }
  .el-upload {
    margin: 1;
    width: 100%;
  }
  &__desc {
    font-style: italic;
    font-size: 0.8em;
  }
}

td {
  vertical-align: top !important;
}

.cell {
  overflow: auto !important;
}
</style>
