<template>
  <el-dialog
    width="75%"
    :modal-append-to-body="false"
    :title="$t('sentence.createRoute') | upperFirst"
    :visible="visible"
    @open="setDefaultDate"
    @close="$emit('change', false)"
  >
    <section v-loading="loading">
      <span v-show="!loading">
        <el-form
          ref="batchCreateForm"
          label-position="top"
          inline
          :model="form"
        >
          <el-form-item
            prop="range"
            :label="$t('components.RouteBatchCreator.periodGenerateRoute')"
            :rules="[
              {
                required: true,
                message: $t('commons.validateField', {
                  field: $t('commons.range'),
                }),
              },
            ]"
          >
            <el-date-picker
              v-model="range"
              size="large"
              type="daterange"
              :clearable="false"
              :placeholder="
                $t('components.RouteBatchCreator.datePlaceholder') | upperFirst
              "
              @keydown.enter.prevent.native
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <label
          class="
            col-xs-12
            title title__highlight
            th--primary
            title--center title--sm-margin
          "
        >
          {{ $t('components.RouteBatchCreator.resourceForRouteGen') }}
        </label>

        <el-table
          :data="resourceList"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :selectable="(r) => r.start_place">
          </el-table-column>
          <el-table-column property="name" sortable :label="$t('commons.name')">
          </el-table-column>
          <el-table-column :label="$t('commons.planning')">
            <template slot-scope="scope">
              <shift-popover
                v-if="visible"
                :shifts="scope.row.shifts"
                trigger="click"
              />
            </template>
          </el-table-column>
          <el-table-column
            property="depot.name"
            sortable
            :label="$t('commons.depot.single')"
          >
          </el-table-column>
        </el-table>
      </span>
    </section>

    <cs-button
      slot="footer"
      icon="add_circle"
      type="success"
      :loading="saving"
      :disabled="routes.length === 0"
      @click="saveRoutes"
    >
      {{ $t('commons.create') }} {{ routes.length }}
      {{ $t('commons.route.undetermined') }}
    </cs-button>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { values, filter, forEach } from 'lodash';
import { eachDayOfInterval, getDay, format } from 'date-fns';
import ShiftPopover from './shift-type/ShiftPopover';
import { getShift } from './../utils';

export default {
  name: 'RouteBatchCreator',
  components: { ShiftPopover },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
  },
  data: () => ({
    loading: true,
    saving: false,
    selectedResources: [],
    range: [],
  }),
  computed: {
    ...mapGetters(['resources', 'organization', 'dateRange']),
    resourceList() {
      return values(filter(this.resources.items, 'start_place'));
    },
    form() {
      return {
        range: this.range,
      };
    },
    routes() {
      return this.selectedResources.reduce((routeList, resource) => {
        if (Array.isArray(resource.shifts) && this.range.length === 2) {
          eachDayOfInterval({
            start: this.range[0],
            end: this.range[1],
          }).forEach((d) => {
            forEach(resource.shifts[getDay(d)], (s) => {
              const shift = getShift(d, s);
              routeList.push({
                name: `${resource.name} - ${format(d, 'dd/MMM', {
                  locale: this.$t('dateFns'),
                })}`,
                planned_start: shift.start,
                planned_end: shift.end,
                resource,
                start_place: resource.start_place,
                end_place: resource.end_place,
              });
            });
          });
        }

        return routeList;
      }, []);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['get', 'post']),
    handleSelectionChange(val) {
      this.selectedResources = val;
    },
    saveRoutes() {
      this.$refs.batchCreateForm.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.post({ type: 'route', id: 'all', data: this.routes })
            .then(() => {
              this.saving = false;
              this.$emit('created');
            })
            .then(() => {
              this.$emit('change', false);
            });
        }
      });
    },
    setDefaultDate() {
      this.range = [];
      this.range[0] = new Date(this.dateRange.from);
      this.range[1] = new Date(this.dateRange.to);
    },
    fetchData() {
      this.get({ type: 'resource', id: 'all' }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
