<template lang="html">
  <el-form auto-complete="off" :model="address" @submit.native.prevent>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="10" :md="8" :lg="6" :xl="4">
        <el-form-item :label="$t('commons.streetNumber')" prop="street_number">
          <el-input
            v-model="address.street_number"
            name="street_number"
            type="text"
            @change="$emit('input', address)"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="14" :md="16" :lg="18" :xl="20">
        <el-form-item :label="$t('commons.streetName')" prop="street_name">
          <el-input
            v-model="address.street_name"
            name="street_name"
            type="text"
            @change="$emit('input', address)"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item :label="$t('commons.secondaryAddress')" prop="secondary">
      <el-input
        v-model="address.secondary"
        name="secondary"
        type="text"
        @change="$emit('input', address)"
      />
    </el-form-item>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="9" :md="7" :lg="6" :xl="4">
        <el-form-item :label="$t('commons.postcode')" prop="postcode">
          <el-input
            v-model="address.postcode"
            name="postcode"
            type="text"
            @change="$emit('input', address)"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="15" :md="17" :lg="12" :xl="16">
        <el-form-item :label="$t('commons.city')" prop="city">
          <el-input
            v-model="address.city"
            name="city"
            type="text"
            @change="$emit('input', address)"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :lg="6" :xl="4">
        <el-form-item :label="$tc('commons.country.choice', 1)" prop="country">
          <country-select
            v-model="address.country_code"
            @change="$emit('input', address)"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { merge } from 'lodash';
import CountrySelect from './CountrySelect';

export default {
  components: { CountrySelect },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    address: {
      street_number: null,
      street_name: null,
      secondary: null,
      postcode: null,
      city: null,
      country_code: null,
    },
  }),
  mounted() {
    this.address = merge(this.address, this.value);
  },
};
</script>
