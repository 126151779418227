import enElementLocale from 'element-ui/lib/locale/lang/en';
import { enGB } from 'date-fns/locale';

export default {
  ...enElementLocale,
  dateFns: enGB,
  longDateFormat: 'EEEE, d MMM y HH:mm',
  languages: {
    fr: 'french',
    en: 'english',
  },
  commons: {
    action: {
      choice: 'action | action | actions',
      plural: 'actions',
      single: 'action',
    },
    activity: {
      choice: 'delivery | delivery | deliveries',
      plural: 'deliveries',
      single: 'delivery',
      undetermined: 'delivery(ies)',
    },
    add: 'add',
    address: {
      plural: 'addresses',
      single: 'address',
      undetermined: 'address(es)',
    },
    affected: {
      female: 'affected',
      male: 'affected',
    },
    alert: {
      choice: 'no alert | alert | alerts',
      plural: 'alerts',
      single: 'alert',
      undetermined: 'alert(s)',
    },
    all: 'all',
    agree: 'ok',
    and: 'and',
    appointment: {
      plural: 'appointments',
      single: 'appointment',
    },
    at: 'at',
    barcode: 'barcode',
    batch: 'batch',
    before: 'before',
    begin: 'start',
    beginDate: '@:commons.begin @:commons.date',
    cancel: 'cancel',
    capacity: {
      plural: 'capacities',
      single: 'capacity',
      undetermined: 'capacity(ies)',
    },
    city: 'city',
    client: {
      plural: 'clients',
      single: 'client',
    },
    close: 'close',
    companyName: 'company name',
    collection: {
      plural: 'collections',
      single: 'collection',
    },
    color: {
      single: 'color',
      plural: 'colors',
    },
    country: {
      choice: 'country | countries',
      plural: 'countries',
      single: 'country',
    },
    countryCode: 'country code',
    create: 'create',
    created_at: 'created at',
    current: {
      female: 'current',
      male: 'current',
    },
    customer: {
      plural: 'customers',
      single: 'customer',
    },
    dashboard: 'dashboard',
    dataOfficerEmail: "data protection officer's email",
    date: 'date',
    datePlanned: 'planned date',
    dateSla: 'SLA date',
    delete: 'delete',
    depot: {
      plural: 'depots',
      single: 'depot',
    },
    destination: 'destination',
    details: 'details',
    disable: 'disable',
    dispatch: 'dispatch',
    distance: 'distance',
    driver: 'driver',
    duration: 'duration',
    edit: 'edit',
    editName: '@:commons.edit the @:commons.name',
    editState: '@:commons.edit the @:commons.state',
    enable: 'enable',
    end: 'end',
    endDate: '@:commons.end @:commons.date',
    engine: 'optimization engine',
    error: 'error',
    externalId: 'external @:commons.id',
    externalData: 'additional info',
    newNote: 'new note',
    editNote: 'edit note',
    field: {
      plural: 'fields',
      single: 'field',
    },
    fieldError: '@:commons.field.single @:commons.error, please correct',
    fieldEmpty: '@:commons.field.single empty',
    first_name: 'first name',
    french: 'french',
    from: 'from',
    gantt: 'gantt',
    generalInfos: 'general @:commons.infos',
    geocoder: 'geocode',
    hide: 'hide',
    hotlineEmail: 'hotline',
    hotlinePhone: 'hotline phone',
    hour: {
      plural: 'hours',
      single: 'hour',
    },
    id: 'id',
    image: 'image',
    import: 'import',
    infos: 'informations',
    invalideName: 'invalid @:commons.name',
    item: {
      choice: '@:commons.item.single | @:commons.item.plural',
      plural: 'parcels',
      single: 'parcel',
      undetermined: 'parcel(s)',
    },
    item_count: 'number of @:commons.item.undetermined',
    km: 'km',
    languages: 'languages',
    last_name: 'last name',
    list: 'list',
    loading: 'loading',
    locked: {
      female: {
        plural: 'locked',
        single: 'locked',
      },
      male: {
        plural: 'locked',
        single: 'locked',
      },
    },
    logo: 'logo',
    mail: 'email',
    map: 'map',
    minutes: 'minutes',
    mobilityId: 'mobility @:commons.id',
    module: {
      plural: 'modules',
      single: 'module',
    },
    name: 'name',
    need: {
      plural: 'needs',
      single: 'need',
    },
    new: {
      female: {
        plural: 'new',
        single: 'new',
      },
      male: {
        plural: 'new',
        single: 'new',
      },
    },
    newTab: 'oopen in new tab (Beta)',
    no: {
      female: {
        plural: 'no',
        single: 'no',
      },
      male: {
        plural: 'no',
        single: 'no',
      },
    },
    noData: '@:commons.none.female data',
    none: {
      female: 'none',
      male: 'none',
    },
    not: 'not',
    note: 'note',
    optimize: 'optimize',
    optimization: {
      plural: 'optimizations',
      single: 'optimization',
    },
    option: {
      plural: 'options',
      single: 'option',
    },
    order: 'order',
    organization: 'organization',
    overtime: 'overtime',
    param: {
      plural: 'parameters',
      single: 'parameter',
    },
    password: 'password',
    phone: 'phone',
    place: {
      choice: 'place | places',
      plural: 'places',
      single: 'place',
      start: 'starts',
      end: 'ends',
    },
    planned: {
      choice: {
        female: 'planned | planned',
        male: 'planned | planned',
      },
      female: {
        plural: 'planned',
        single: 'planned',
      },
      male: {
        plural: 'planned',
        single: 'planned',
      },
      undetermined: 'planned',
    },
    plannedActivity:
      '@:commons.activity.plural @:commons.planned.female.plural',
    planned_end: 'end date',
    planned_start: 'start date',
    planning: 'planning',
    planningDate: 'planification @:commons.date',
    pod: 'delivery proof',
    points: 'markers',
    postcode: 'zipcode',
    quantity: 'quantity',
    range: 'range',
    reason: 'reason',
    reasons: {
      away: 'away',
      closed: 'closed',
      damaged: 'damaged',
      holidays: 'holidays',
      moved: 'moved',
      new_place: 'new place',
      partial: 'partial @:commons.activity.single',
      refused_msg: 'refused',
      too_late: 'too late',
      too_long: 'too long',
      unknown: 'unknown',
      wait_proof: 'wait proof',
      weekend: 'weekend',
      wrong_place: 'wrong place',
    },
    recenter: 'recenter',
    recipient: 'recipient',
    remove: 'delete',
    reorganize: 'reorganize',
    replan: 'replan',
    repository: 'repository',
    required: {
      female: 'required',
      male: 'required',
    },
    resource: {
      plural: 'resources',
      single: 'resource',
    },
    resourcedetails: "@:commons.resource.single's @:commons.details",
    return: 'return',
    role: {
      plural: 'roles',
      single: 'role',
    },
    route: {
      choice: 'route | route | routes',
      plural: 'routes',
      single: 'route',
      undetermined: 'route(s)',
    },
    routedetails: "@:commons.route.single's @:commons.details",
    save: 'save',
    saveProgress: 'saving',
    search: 'search',
    secondaryAddress: 'secondary @:commons.address.single',
    seconds: 'seconds',
    select: 'select',
    selectAll: 'select all',
    selected: 'selected',
    selectResource: '@:commons.select the @:commons.resource.single',
    selection: 'selection',
    settings: 'settings',
    show: 'show',
    showChild: 'show replaned',
    showParent: 'show origin',
    showHide: '@:commons.show/@:commons.hide',
    siret: 'siret',
    sla: {
      plural: 'SLAS',
      single: 'SLA',
    },
    start: 'start',
    start_place: 'start location',
    end_place: 'end location',
    startWarehouse: '@:commons.start @:commons.warehouse.single',
    state: 'status',
    state_history: 'state history',
    states: {
      delivery: {
        awaiting: 'awaiting',
        cleared: 'cleared',
        done: 'done',
        en_route: 'en route',
        failed: 'failed',
        finished: 'finished',
        incoming: 'incoming',
        loaded: 'loaded',
        loading: 'loading',
        not_received: 'not received',
        on_site: 'on site',
        prepared: 'prepared',
        preparing: 'preparing',
        ready: 'ready',
        received: 'received',
        reception_started: 'reception started',
        refused: 'refused',
        returned: 'returned',
        replaned: 'replaned',
      },
      intake: {
        arrived: 'arrived',
        completed: 'completed',
        incoming: 'incoming',
        started: 'started',
        uploading: 'uploading',
      },
      item: {
        awaiting: 'awaiting',
        cleared: 'cleared',
        done: 'done',
        failed: 'failed',
        finished: 'finished',
        incoming: 'incoming',
        loaded: 'loaded',
        lost: 'lost',
        not_received: 'not received',
        prepared: 'prepared',
        ready: 'ready',
        received: 'received',
        refused: 'refused',
        returned: 'returned',
      },
      route: {
        created: 'created',
        done: 'done',
        finished: 'finished',
        in_progress: 'in progress',
        loaded: 'loaded',
        loading: 'loading',
        paused: 'paused',
        prepared: 'prepared',
        preparing: 'preparing',
        ready: 'ready',
      },
      optimization: {
        new: 'new',
        canceled: 'canceled',
        created: 'created',
        ended: 'ended',
        failed: 'failed',
        resolved: 'resolved',
        running: 'running',
      },
    },
    street: 'street',
    streetName: '@:commons.street @:commons.name',
    streetNumber: '@:commons.street number',
    street_name: 'street name',
    street_number: 'street number',
    summary: 'summary',
    survey: 'survey',
    time: {
      days: 'day | days',
      hours: 'hour | hours',
      minutes: 'minute | minutes',
      seconds: 'second | seconds',
    },
    to: 'to',
    toEmpty: 'empty',
    tool: {
      plural: 'tools',
      single: 'tool',
    },
    total: 'total',
    transitions: {
      delivery: {
        arrive: 'on site',
        await: 'awaiting',
        clear: 'cleared',
        do: 'done',
        end_loading: 'loaded',
        end_prepare: 'prepared',
        fail: 'failed',
        finish: 'finished',
        go: 'en route',
        not_receive: 'not received',
        receive: 'received',
        receiving: 'reception started',
        refuse: 'refused',
        return: 'returned',
        start_loading: 'loading',
        start_prepare: 'preparing',
      },
      intake: {
        arrive: 'arrivée',
        end: 'terminée',
        start: 'démarrée',
        uploaded: 'en attente de réception',
      },
      parcel: {
        clear: 'cleared',
        do: 'done',
        fail: 'failed',
        finish: 'finished',
        loade: 'loaded',
        not_receive: 'not received',
        prepare: 'prepared',
        receive: 'received',
        refus: 'refused',
        return: 'returned',
        set_lost: 'lost',
      },
      route: {
        do: 'done',
        end_loading: 'loaded',
        end_prepare: 'prepared',
        finish: 'finished',
        pause: 'pause',
        resume: 'resume',
        set_created: 'created',
        set_ready: 'ready',
        start: 'started',
        start_loading: 'loading',
        start_prepare: 'preparing',
      },
    },
    type: 'type',
    unassigned: '@:commons.not @:commons.affected.female',
    unplanned: {
      choice: {
        female: 'not planned | not planned',
        male: 'not planned | not planned',
      },
      female: {
        plural: 'not planned',
        single: 'not planned',
      },
      male: {
        plural: 'not planned',
        single: 'not planned',
      },
      undetermined: 'not planned',
    },
    unselect: 'unselect',
    unselectAll: 'unselect all',
    upload: 'upload',
    used: {
      choice: {
        female: 'used | used',
        male: 'used | used',
      },
      female: {
        plural: 'used',
        single: 'used',
      },
      male: {
        plural: 'used',
        single: 'used',
      },
      undetermined: 'used',
    },
    unused: {
      choice: {
        female: 'unused | unused',
        male: 'unused | unused',
      },
      female: {
        plural: 'unused',
        single: 'unused',
      },
      male: {
        plural: 'unused',
        single: 'unused',
      },
      undetermined: 'unused',
    },
    user: {
      plural: 'users',
      single: 'user',
    },
    validate: 'validate',
    validateField: '@:commons.field.single {field} @:commons.required.male',
    value: {
      plural: 'values',
      single: 'value',
    },
    vehicle: {
      plural: 'vehicles',
      single: 'vehicle',
    },
    view: 'visualize',
    volume: 'volume',
    wantedTo: 'do you want to',
    warehouse: {
      plural: 'warehouses',
      single: 'warehouse',
    },
    warning: 'warning',
    week_days: {
      fri: 'friday',
      mon: 'monday',
      sat: 'satururday',
      sun: 'sunday',
      thur: 'thursday',
      tue: 'tuesday',
      wed: 'wednesday',
    },
    weight: 'weight',
    when: 'when',
    where: 'where',
    workflow: 'workflow',
    yes: 'yes',
    zoom_in: 'zoom in',
    zoom_out: 'zoom out',
  },
  sentence: {
    addressFound: '@:commons.address.single found',
    addressOrigin: '@:commons.address.single given',
    addName: '@:commons.add a name',
    changeResource: 'modify the @:commons.resource.single',
    changeResourceForRoute:
      'please select a @:commons.resource.single for the @:commons.route.single {0}',
    createRoute: '@:commons.create @:commons.route.plural',
    createOptimization:
      '@:commons.new.female.single @:commons.optimization.single',
    noOptimization: '@:commons.none.female @:commons.optimization.single',
    editDuration: '@:commons.edit @:commons.duration',
    geocodeSuccess: '@:commons.address.single founded successfully',
    goToActivity: 'go to @:commons.activity.single',
    goToRoute: 'go to @:commons.route.single',
    modifyPassword: 'modify password',
    manualGeocode: 'manually @:commons.edit geocoding',
    noRouteCreated: 'no @:commons.route.single has been created yet.',
    noTransition: '@:commons.no.female.single transition available',
    organizationList: 'organization list',
    rdvDate: '@:commons.appointment.single date',
    rdvTime: '@:commons.appointment.single time',
    receiveAll: 'receive all',
    replanDate: 'replanning date',
    replanDesc:
      'please select the date at which the activity should be replan to.',
    replanWarn: 'all items will be transfered the the new delivery.',
    savePaper: 'save paper',
    selectDriver: 'select a driver',
    selectResource: 'select a @:commons.resource.single',
    selectClientUpload:
      '@:commons.select a @:commons.customer.single to upload data to',
    endPlaceNotStartPlace: 'end location differs from start location',
    toOptimize: 'List to optimize',
    wantedToDeleteOrg:
      '@:commons.wantedTo @:commons.delete the @:commons.organization {name}?',
  },
  role: {
    advise: 'advised @:role.abilityTitle',
    abilityTitle: 'autorisations',
    abilityHeader: {
      create: 'create',
      destroy: 'delete',
      read: 'read',
      update: 'update',
    },
    ability: {
      client: '@:commons.client.single',
      delivery: '@:commons.activity.single',
      intake: '@:commons.collection.single',
      item: '@:commons.item.single',
      organization: '@:commons.organization',
      place: '@:commons.depot.single',
      resource: '@:commons.resource.single',
      role: '@:commons.role.single',
      route: '@:commons.route.single',
      user: '@:commons.user.single',
      vehicle: '@:commons.vehicle.single',
    },
    create: 'create a new role',
    moduleTitle: 'Modules',
    module: {
      client: {
        title: 'module @:commons.client.single',
        description: 'allow access to the manage @:commons.client.plural page',
      },
      intake: {
        title: 'module @:commons.collection.single',
        description: 'allow access to the @:commons.collection.plural page',
      },
      mobile: {
        title: 'module mobile app',
        description: 'allow access to the mobile app',
      },
      organization: {
        title: 'module @:commons.organization',
        description: 'allow access to the manage @:commons.organization page',
      },
      place: {
        title: 'module @:commons.depot.single',
        description: 'allow access to the manage @:commons.depot.plural page',
      },
      resource: {
        title: 'module @:commons.resource.single',
        description:
          'allow access to the manage @:commons.resource.plural page',
      },
      role: {
        title: 'module @:commons.role.single',
        description: 'allow access to the manage @:commons.role.plural page',
      },
      route: {
        title: 'module @:commons.route.single',
        description: 'allow access to the @:commons.route.plural page',
      },
      search: {
        title: 'module search',
        description:
          'allow access to the search @:commons.activity.plural page',
      },
      statistics: {
        title: 'module statistics',
        description: 'allow access to the statistics page',
      },
      tracking: {
        title: 'module tracking',
        description: 'allow access to the tracking page',
      },
      upload: {
        title: 'module upload',
        description: 'allow access to the activity upload page',
      },
      user: {
        title: 'module @:commons.user.single',
        description: 'allow access to the manage @:commons.user.plural page',
      },
      vehicle: {
        title: 'module @:commons.vehicle.single',
        description: 'allow access to the manage @:commons.vehicle.plural page',
      },
    },
    name: 'enter role name',
    ownRoleWarning: {
      description:
        'make change with extra care when modifying your own @:commons.role.single as you could lock yourself out.',
      title: 'this is your own @:commons.role.single',
    },
  },
  components: {
    BarcodeScanner: {
      activate: 'activate barcode scanner',
      transfer: 'transfer in progress',
    },
    ConstraintList: {
      capacities: {
        title: '@:commons.capacity.plural',
        create:
          '@:commons.create a @:commons.new.female.single @:commons.capacity.single',
        labelType: '@:commons.name of @:commons.capacity.single',
        labelValue: '@:commons.value.single @:commons.capacity.single',
      },
      consist_of: {
        title: '@:commons.need.plural',
        create:
          '@:commons.create a @:commons.new.male.single @:commons.need.single',
        labelType: '@:commons.name of @:commons.need.plural',
        labelValue: '@:commons.value.single @:commons.need.single',
      },
      tools: {
        title: '@:commons.tool.plural',
        create:
          '@:commons.create a @:commons.new.male.single @:commons.tool.single',
        labelType: '@:commons.name of @:commons.tool.single',
      },
      error: 'input already exists',
    },
    DatePicker: {
      currentWeek: 'current week',
      refresh: 'refresh',
      today: 'today',
      tomorrow: 'tomorrow',
    },
    DeliveryDetails: {
      activityAddress: '@:commons.activity.single @:commons.address.single',
      activityTerm: 'term of @:commons.activity',
      orderRoute: '@:commons.order in the @:commons.route.single',
      slots: 'SLAs',
    },
    Filter: {
      caseSensitiveOff: 'case-insensitive',
      caseSensitiveOn: 'case sensitive',
      filter: 'filter',
      filterError: '@:commons.error in the @:components.Filter.filter',
      filterErrorText: 'please fill in a value',
      items: {
        activity: '@:commons.activity.single',
        all: 'all',
        capacities: '@:commons.capacity.plural',
        city: '@:commons.city',
        consist_of: '@:commons.need.plural',
        country: '@:commons.country.single',
        country_code: '@:commons.countryCode',
        deliveries: '@:commons.activity.plural',
        depot: '@:commons.depot.single',
        distance: '@:commons.distance',
        duration: '@:commons.duration',
        email: '@:commons.mail',
        external_id: '@:commons.externalId',
        first_name: '@:commons.first_name',
        item_count: '@:commons.item_count',
        last_name: '@:commons.last_name',
        name: '@:commons.name',
        order: '@:commons.order',
        place: '@:commons.place.single',
        planned_end: '@:commons.planned_end',
        planned_start: '@:commons.planned_start',
        postcode: '@:commons.postcode',
        resource: '@:commons.resource.single',
        route: '@:commons.route.single',
        slas: '@:commons.sla.plural',
        state: '@:commons.state',
        street_name: '@:commons.street_name',
        street_number: '@:commons.street_number',
        tools: '@:commons.tool.plural',
        type: '@:commons.type',
        user: '@:commons.user.single',
        value: '@:commons.value.single',
        vehicle: '@:commons.vehicle.single',
      },
      operators: {
        EQ: 'equals',
        GT: 'greater than',
        GTE: 'greater or equals than',
        LT: 'less than',
        LTE: 'less or equals than',
        NEQ: 'not equals',
        between: 'between',
        endsWith: 'ends with',
        equals: 'equals',
        includes: 'includes',
        intersect: 'intersect',
        notBetween: 'not between',
        notEndsWith: 'not ends with',
        notEquals: 'not equals',
        notIncludes: 'not includes',
        notIntersect: 'not intersect',
        notStartsWith: 'not starts with',
        notWithin: 'not within',
        startsWith: 'starts with',
        within: 'within',
      },
    },
    GeocodeGate: {
      addressFound: '@:commons.address.undetermined found',
      addressList: '@:commons.list of @:commons.address.plural',
      addressProvided: 'provided @:commons.address.undetermined',
      confidence: 'confidence score',
      defectiveAddress: '{0} @:commons.address.plural are defective.',
      editAddress: 'edit the @:commons.address.single',
      editPointList:
        'please @:commons.edit the @:commons.list of @:commons.address.single below',
      geocode: 'geocode now',
      locate: 'locate',
      noMatchFound: 'no matching address found',
      noResult: '@:commons.no.male.single result',
      notFound: 'no matching address found',
      resultTitle: 'geocoding result',
      validateAddress: '@:commons.validate this @:commons.address.single',
      validateAll: '@:commons.validate all addresses',
    },
    Menu: {
      search: '@:commons.search',
      follow: 'tracking',
      logout: 'logout',
      notification: 'notifications',
    },
    RouteBatchCreator: {
      datePlaceholder: 'select a range',
      periodGenerateRoute: '@:commons.route.plural generation period',
      resourceForRouteGen:
        '@:commons.resource.plural for which @:commons.route.plural will be generated',
      selectStartDepot:
        '@:commons.selected a @:commons.start @:commons.warehouse.single',
      selectWarehouse: '@:commons.selected a @:commons.warehouse.single',
    },
    RouteBatchValidator: {
      resourceForValidation:
        'select @:commons.route.plural that will be validated',
    },
    selection: {
      remove:
        'remove the @:commons.activity.single from the @:commons.route.single | remove all {count} @:commons.activity.plural from the @:commons.route.single',
    },
    calendar: {
      addShift: 'add a shift',
      selectDay: 'select a weekday',
      selectShift: 'select a shift @:commons.range',
    },
    XlsxImporter: {
      label: {
        external_id: 'unique id',
        duration: 'duration',
        item_count: 'quantity',
        date: 'date of @:commons.activity.single',
        slas: 'slas',
        street_number: 'street number',
        street_name: 'street name',
        secondary: 'secondary address',
        city: 'city',
        postcode: 'postcode',
        country: 'country',
        country_code: 'country code',
        default_country_code: 'default country code',
        latitude: 'latitude',
        longitude: 'longitude',
        consist_of: 'constraints',
        tools: 'tools',
        receiver_name: 'receiver',
        receiver_phone: 'phone',
        email: 'email',
      },
      desc: {
        external_id:
          'Unique reference for the @:commons.activity.single . It allows to easly find the @:commons.activity.single and avoid duplicates.',
        duration: 'Duration of the @:commons.activity.single',
        item_count:
          'Quantity of object/parcels associated with this @:commons.activity.single',
        date: 'date at which the @:commons.activity.single must be done',
        slas: 'Time range between which this @:commons.activity.single can be placed. This will override the date field.',
        street_number: 'street number',
        street_name: 'street name',
        secondary: 'Additional info about the address',
        city: 'city name',
        postcode: 'postcode',
        country: 'country name',
        country_code: 'Two digits country code',
        default_country_code: 'Default country code when column is empty',
        latitude: 'GPS latitude',
        longitude: 'GPS longitude',
        consist_of: 'Constraints associated with the @:commons.activity.single',
        tools: 'Tools associated with the @:commons.activity.single',
        receiver_name: 'receiver name',
        receiver_phone: 'phone of the local contact',
        email: 'email of the receiver',
      },
      sample: 'sample file',
      addFile: 'add a spreadsheet file',
      successCount: '{count} @:commons.activity.plural imported successfully',
      errorCount: '{count} @:commons.activity.plural with errors',
      uploading: 'uploading your data',
      new: 'new import',
      title: 'data import',
      mappingTitle: 'map the spreadsheet columns',
      accepted: 'accepted files',
      dragNdrop: 'drop file here or',
      upload: 'click to upload',
      done: 'file edited',
      fileUpload: 'upload file',
      require: 'required',
      columnMatch: 'matching column',
      parsers: 'how many parsers ?',
      proposition: 'parsing order, editable',
      fields: 'fields',
      preview: 'preview',
      maxrow: 'Max rows',
    },
    entities: {
      Constraints: {
        toolsPerk: '@:commons.tool.plural & skills',
      },
      ResourceDetails: {
        createResource: '@:commons.create a @:commons.resource.single',
        selectDriver: '@:commons.selected @:commons.driver',
        selectVehicle: '@:commons.selected @:commons.vehicle.single',
        selectPlace: '@:commons.selected @:commons.depot.single',
      },
      activityDetails: {
        description: 'description',
        moreInfos: '@:commons.infos',
        recipientName: '@:commons.recipient @:commons.name',
        recipientPhone: '@:commons.recipient phone',
      },
    },
    optim: {
      activityPlanned:
        '@:commons.activity.plural @:commons.planned.female.plural',
      addToOptim: '@:commons.add selected items to optimization',
      applyOptim: 'apply @:commons.optimization.single',
      currentUsedRoutes:
        'average @:commons.activity.plural / @:commons.route.plural',
      deleteOptim:
        '@:commons.wantedTo @:commons.remove this @:commons.optimization.single',
      delta: 'delta',
      errorOptim: '@:commons.optimization.single @:commons.error',
      globalUsage: 'used route rate',
      inSla: '@:commons.sla.single hit rate',
      inAppointment: '@:commons.appointment.single hit rate',
      minOneActivity:
        'you must @:commons.select at least a @:commons.activity.single',
      minOneRoute:
        'you must @:commons.select at least a @:commons.route.single',
      nameNotEmpty: 'you must enter a name',
      optimSolved: '@:commons.optimization.single solved',
      optimView: 'view @:commons.optimization.single',
      outDeplanned:
        'unplanned @:commons.activity.undetermined from @:commons.current',
      outReplanned:
        '@:commons.planned.undetermined @:commons.activity.undetermined remaining',
      removeFromOptim: 'remove selected items from optimization',
      removeOptim: 'delete @:commons.optimization.single',
      routeList: '@:commons.list of @:commons.route.plural',
      routeUsage: 'average utilization rate',
      routeUsed: 'used @:commons.route.plural',
      sended: 'sent',
      totalDist: 'total @:commons.distance (approx.)',
      totalDuration: 'total @:commons.duration (approx.)',
      totalDurationWork: 'total work @:commons.duration ',
      totalTravelTime: 'total travel @:commons.duration (approx.)',
      unPlanned: 'not @:commons.planned.undetermined',
    },
    shift: {
      assignPlanning: 'assign a @:commons.planning',
      assignResource: 'assign to @:commons.resource.plural',
      loadPlanning: 'load a @:commons.planning',
    },
  },
  error: {
    invalid_credentials: 'invalid email or password',
    unauthorized: 'This action is unauthorized.',
    moduleUnauthorized:
      "You don't have the right to access the module {0}. Please contact your administrator.",
    optim: {
      nameEmpty: 'You must add a name for your optimization',
      noActivity: 'Please add al least one @:commons.activity.single',
      tooManyActivities:
        'Maximum number of @:commons.activity.plural exceeded (maximum {0})',
      noRoute: 'Please add al least one @:commons.route.single',
      routeNoStartPlace:
        'The @:commons.route.single {0} must have a @:commons.start_place and a @:commons.end_place.',
    },
    password: {
      length: 'must be between 6 and 64 characters',
      number: 'must contains 1 number',
      upper: 'must contains 1 upper case',
      lower: 'must contains 1 lower case',
    },
    route: {
      locked: 'the route {0} is locked',
      noResource:
        "the route {0} doesn't have a @:commons.resource.single attached",
      notSaved: 'the route {0} should be saved first',
      someNotReceived: 'some @:commons.activity.single are not yet received',
      thisNoResource:
        "this route doesn't have a @:commons.resource.single attached",
    },
    search: {
      unavailable: {
        title: 'search unavailable',
        description:
          'search is currently unavailable. Please contact your administrator.',
      },
    },
    shouldBeAfter: 'the date should be after the inital date',
    shouldBeFuture: 'the date should be in the future',
    validator: {
      coord: {
        greater: '{name} cannot be greater than {max}',
        lower: '{name} cannot be lower than {min}',
      },
    },
  },
  pages: {
    HomePage: {
      generalKPI: 'general KPI',
      incoming: 'incoming',
      started: 'started',
      summaryFrom: '@:commons.summary from',
      summaryOf: '@:commons.summary of',
      to: 'to',
      welcomeMessage: 'welcome to your dashboard',
    },
    LoginPage: {
      errorMessage:
        'an error occured, unable to log you in with these identification informations',
      loading: 'loading',
      logIn: 'login',
      welcome: 'welcome',
    },
    Notifications: {
      notificationNotFound: 'notification panel is empty',
    },
    client: {
      create: '@:commons.create a @:commons.customer.single',
      ClientForm: {
        apiKey: 'API key',
        config: 'config',
        configMobile: 'mobile configuration',
        configOther: 'other configuration',
        configTech: 'technical configuration',
        mobile: 'mobile',
        optionnal: 'optionnal',
        autoStatus: 'automatic state during delivery',
        mandatory: 'mandatory',
        scanItems: 'scan items',
        geolocation: 'geolocation',
        distance:
          'minimum distance from the activity to trigger geolocation actions',
        report: 'report',
        deliveryProof: 'proof of delivery',
        defaultActivityPolicy:
          'default @:commons.activity.single policy at D+{0}',
        defaultDurationActivity:
          'default @:commons.activity.single @:commons.duration (in minutes)',
        itemReasons: 'activate item reasons in mobile app',
        activityReasons: 'activate activity reasons in mobile app',
        delayReason: 'activate delay reasons in mobile app',
        infosFtpConnect: 'sFTP connection @:commons.infos',
        lambdaName: '@:commons.name of custom Lambda',
        reportType: 'report type',
        sftpAddress: 'sFTP host',
        sftpPass: 'fill in sFTP password',
        sftpPort: 'sFTP port',
        sftpUser: 'fill in sFTP user@:commons.name',
        sftpPath: 'sFTP path',
        ftpSsl: 'SSL',
        ftpSslImplicit: 'SSL implicit',
        ftps: 'FTPS mode',
        same_day: 'same day',
        next_day: 'next day',
        two_days: '2 days',
        three_days: '3 days',
        scanForPrepare:
          'Scan @:commons.item.plural while @:commons.states.delivery.preparing @:commons.route.single',
        scanForLoad:
          'Scan @:commons.item.plural while @:commons.transitions.route.start_loading @:commons.route.single',
        scanForDeliver: 'Scan @:commons.item.plural while delivering',
        signatureProof: 'Signature proof required while delivering',
        photoProof: 'Photo proof required while delivering',
      },
      IndexPage: {
        contact: 'contact',
        customerAdd: '@:commons.add a @:commons.customer.single',
        customerList: '@:commons.list of @:commons.customer.plural',
        wantedToDeleteCustomer:
          '@:commons.wantedTo @:commons.delete this @:commons.customer.single',
      },
      import: 'import',
      importerLimit: 'importer activity limit',
      importCanal: 'importation channel',
      notifTexteDone: 'the key have been copied to you clipboard',
      notifTexteError: 'the key have not been copied to you clipboard',
      notifTitleDone: 'copied successfully',
      report: 'report',
      reportCanal: 'report channel',
    },
    intake: {
      EditIntake: {
        destination: 'destination',
      },
      IndexPage: {
        collectionNotFound:
          '@:commons.no.female.single @:commons.collection.single found',
        from: 'from',
        of: 'of',
        selectAnotherDateRange:
          'please @:commons.select another @:commons.range of @:commons.date',
        to: 'to',
      },
      wantedToDeleteIntake:
        '@:commons.wantedTo really @:commons.delete this intake {0}',
    },
    organization: {
      tabs: {
        references: {
          title: '@:commons.repository',
        },
        details: {
          title: '@:commons.generalInfos',
        },
        planning: {
          title: '@:commons.planning',
        },
      },
      OrganizationShifts: {
        addShift:
          'please fill the @:commons.name of @:commons.planning @:commons.type',
        infoError: 'an @:commons.errror occured, please try again',
        invalideName: '@:commons.name not valid',
      },
      myOrganization: 'my organization',
    },
    optimization: {
      traffic: {
        with: 'with traffic',
        without: 'without traffic',
      },
    },
    password: {
      passwordForgotten: 'forgot your password ?',
      passwordReset: 'password reset',
      resetLink: 'reset',
      successLink:
        'an email has been sent to you, please, follow instructions.',
      errorLink: 'this email does not match any user, please correct.',
      noMatch: 'the two passwords must match',
      notLongEnough: 'password must be at least 6 characters.',
      newPassword: 'new password',
      passwordConfirm: 'confirm new password',
    },
    place: {
      create: '@:commons.create a @:commons.warehouse.single',
      IndexPage: {
        wantedToDeleteWarehouse:
          '@:commons.wantedTo @:commons.delete this @:commons.warehouse.single',
        warehouseAdd: '@:commons.add a @:commons.warehouse.single',
        warehouseList: '@:commons.list of @:commons.warehouse.plural',
      },
      PlaceForm: {
        number: 'number',
        secondaryAddress: 'secondary @:commons.address.single',
      },
      addressGeocoding: '@:commons.address.undetermined not geocoded',
      spot: {
        type: {
          storage: 'Storage',
          dock: 'Dock',
        },
        edit: 'Edit spots',
        level: {
          plural: 'levels',
          single: 'level',
          choice:
            '@:pages.place.spot.level.single | @:pages.place.spot.level.plural',
        },
        plural: 'spots',
        single: 'spot',
        undetermined: 'spot(s)',
        dock: {
          single: 'loading dock',
          plural: 'loading docks',
        },
        print: {
          all: 'Print all barcodes',
          loading_dock: 'Print @:pages.place.spot.dock.plural',
          storage: 'Print @:pages.place.spot.plural',
        },
        error: {
          spotNotEmpty:
            'There are @:commons.item.plural in this @:pages.place.spot.single',
          columnLimit: "Can't create more that 999 columns.",
          levelsLimit:
            "Can't create more than 99 @:pages.place.spot.level.plural",
          dockLimit: "Can't create more than 99 @:pages.place.spot.dock.plural",
        },
      },
    },
    resource: {
      addResource: '@:commons.add a @:commons.resource.single',
      addVehicle: '@:commons.add a @:commons.vehicle.single',
      generateManyRoutes: 'generate @:commons.route.plural',
      resourceList: '@:commons.list of @:commons.resource.plural',
      wantedToDeleteResource:
        '@:commons.wantedTo @:commons.delete this @:commons.resource.single',
    },
    role: {
      Client: 'client',
      Delivery: 'delivery',
      Intake: 'intake',
      Organization: 'organization',
      Place: 'place',
      Resource: 'resource',
      Role: 'role',
      Route: 'route',
      User: 'user',
      Vehicle: 'vehicle',
      addRole: '@:commons.add @:commons.role.single',
      create: '@:commons.create',
      editRole: 'edit the @:commons.role.single',
      manage: 'manage',
      manageClient: 'manage clients',
      manageDashboard: 'manage dashboard',
      manageDelivery: 'manage deliveries',
      manageIntake: 'manage intakes',
      managePlace: 'manage places',
      manageResource: 'manage resources',
      manageRole: 'manage roles',
      manageRoute: 'manage routes',
      manageOrganization: 'manage organization',
      manageOutstanding: 'manage outstanding',
      manageUser: 'manage users',
      manageVehicle: 'manage vehicles',

      noPermission: '@:commons.no.female.single permission',
      roleList: '@:commons.list of @:commons.role.plural',
      uploadCsv: 'upload csv',
      wantedToDeleteRole:
        '@:commons.wantedTo @:commons.delete this @:commons.role.single',
    },
    routes: {
      RoutePrint: {
        note: 'note',
        print: 'print',
        proof: 'proof of delivery',
        required: 'required',
      },
      activityRemaining: 'remaining @:commons.activity.plural',
      components: {
        addRoute: '@:commons.add to @:commons.route.single',
        associateToRoute: 'link to @:commons.route',
        confirmChoice: 'validate your choice',
        currentSelection: '@:commons.selection @:commons.current.female',
        plannedFrom: '@:commons.planned.female.single from',
        removeFromSelect: 'remove from @:commons.selection',
        saveAllRoute: '@:commons.save definitivement la @:commons.route',
        saveCurrentChange: '@:commons.save current modifications',
        showPointOnMap:
          '@:commons.show @:commons.points on the @:pages.routes.map',
        showTraceOnMap: '@:commons.show the trace on the @:pages.routes.map',
        to: 'to',
        wantedToDeleteRoute:
          '@:commons.wantedTo @:commons.delete this @:commons.route.single',
      },
      map: 'map',
      removeSelection: 'clear the @:commons.selection',
    },
    track: {
      DashboardPage: {},
      RouteList: {
        toggleMarkers: 'display all @:commons.points',
        togglePolyline: 'display all @:commons.road.plurals',
        lassoSelection: 'lasso @:commons.selection',
        clusterView: 'cluster view',
        selectAll:
          '@:commons.add all @:commons.route.plural to @:commons.optimization.single + Alt for @:commons.activity.plural',
        unselectAll:
          '@:commons.remove all @:commons.route.plural to @:commons.optimization.single + Alt for @:commons.activity.plural',
        saveCurrentTheChange: 'save current modifications',
      },
      TrackDetail: {
        followRoute: '@:components.Menu.follow',
      },
      TrackingPage: {
        checkInfoShowActivity:
          'check @:commons.infos you want displayed for the @:commons.activity.plural',
        checkInfoShowRoute:
          'check @:commons.infos you want displayed for the @:commons.route.plural',
        infosActivity: '@:commons.activity.plural @:commons.infos',
        infosRoute: '@:commons.route.plural @:commons.infos',
        recapRoute: '@:commons.route.plural summary',
        showAllRoute: 'display @:commons.route.plural',
        showAllTrace:
          'display @:commons.points.plural of the @:commons.route.plural',
        stateActivity: '@:commons.state of @:commons.activity.plural',
        totalActivity: '@:commons.activity.single count',
      },
      anomaly: 'anomalies',
      closed: 'closed',
      hourReal: 'actual',
      hourValued: 'planned',
      late: 'late',
      slaEnd: '@:commons.end',
      slaStart: '@:commons.begin',
      outOfSla: 'out of @:commons.sla.single',
      outOfAppointment: 'out of @:commons.appointment.single',
      notes: 'notes',
    },
    user: {
      create: '@:commons.create a @:commons.user.single',
      IndexPage: {
        addUser: '@:commons.add a @:commons.user.single',
        removeUser:
          '@:commons.wantedTo @:commons.remove this @:commons.user.single',
        userList: '@:commons.list of @:commons.user.plural',
      },
      UserForm: {
        firstName: 'first name',
        password: 'password',
      },
    },
    vehicle: {
      create: '@:commons.create a @:commons.vehicle.single',
      IndexPage: {
        addVehicle: '@:commons.add a @:commons.vehicle.single',
        removeVehicle:
          '@:commons.wantedTo @:commons.remove this @:commons.vehicle.single',
        vehicleList: '@:commons.list of @:commons.vehicle.plural',
      },
      VehicleForm: {
        addCapacity: '@:commons.add a @:commons.capacity.single',
        addTool: '@:commons.add a @:commons.need.single',
        value: 'value',
      },
    },
  },
};
