<template>
  <list-layout :load="loading">
    <h3 slot="title">{{ $t('pages.resource.resourceList') }}</h3>
    <el-table :data="paginatedList" stripe style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <p class="upperFirst">
            {{ $t('commons.depot.single') }}:
            {{
              scope.row.start_place
                ? scope.row.start_place.name
                : $t('commons.fieldEmpty')
            }}
          </p>
          <p class="upperFirst">
            {{ $t('commons.externalId') }}:
            {{ scope.row.external_id | empty($t('commons.fieldEmpty')) }}
          </p>
          <p class="upperFirst">
            {{ $t('commons.mobilityId') }}:
            {{ scope.row.mobility_id | empty($t('commons.fieldEmpty')) }}
          </p>

          <span v-if="scope.row.vehicle">
            <el-row v-if="scope.row.vehicle.capacities">
              <p class="upperFirst">
                {{ $t('commons.capacity.single') }}
                {{ $t('commons.vehicle.single') }}:
              </p>
              <constraint-list
                v-model="scope.row.vehicle.capacities"
                no-title
                name="capacities"
              ></constraint-list>
            </el-row>
            <el-row v-if="scope.row.vehicle.tools">
              <p class="upperFirst">
                {{ $t('commons.tool.single') }}
                {{ $t('commons.vehicle.single') }}:
              </p>
              <constraint-list
                v-model="scope.row.vehicle.tools"
                no-title
                name="tools"
              ></constraint-list>
            </el-row>
          </span>

          <el-row v-if="scope.row.user && scope.row.user.tools">
            <p class="upperFirst">
              {{ $t('commons.tool.single') }} {{ $t('commons.user.single') }}:
            </p>
            <constraint-list
              v-model="scope.row.user.tools"
              name="tools"
            ></constraint-list>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        sortable
        :label="$t('commons.name') | upperFirst"
      >
      </el-table-column>
      <el-table-column :label="$t('commons.planning') | upperFirst" width="98">
        <template slot-scope="scope">
          <shift-popover :shifts="scope.row.shifts" />
        </template>
      </el-table-column>
      <el-table-column
        prop="vehicle.name"
        :label="$t('commons.vehicle.single') | upperFirst"
        sortable
      >
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.vehicle"
            :to="{
              name: 'editVehicle',
              params: { id: scope.row.vehicle.id },
            }"
          >
            {{ scope.row.vehicle.name }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column
        prop="user.name"
        sortable
        :label="$t('commons.user.single') | upperFirst"
      >
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.user"
            :to="{ name: 'editUser', params: { id: scope.row.user.id } }"
          >
            {{ scope.row.user.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <div class="pull-right btn-group">
            <cs-button
              square
              icon="edit"
              icon-type="material"
              type="primary"
              @click.stop="
                $router.push({
                  name: 'editResource',
                  params: { id: scope.row.id },
                })
              "
            >
            </cs-button>
            <cs-button
              square
              icon="delete"
              icon-type="material"
              type="danger"
              @click="deleteResource(scope.row)"
            >
            </cs-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="pageSize"
      :current-page="current"
      layout="total, prev, pager, next"
      :total="resources.length"
      @current-change="setPage"
    >
    </el-pagination>

    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="assignment_ind"
      @click="$router.push({ name: 'createResource' })"
    >
      {{ $t('pages.resource.addResource') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="local_shipping"
      @click="$router.push({ name: 'createVehicle' })"
    >
      {{ $t('pages.resource.addVehicle') }}
    </cs-button>
  </list-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { upperFirst } from 'lodash';
import ListLayout from '@/layouts/ListLayout';
import ShiftPopover from '@/components/shift-type/ShiftPopover';
import ConstraintList from '@/components/ConstraintList';

export default {
  name: 'Resources',
  components: {
    ListLayout,
    ShiftPopover,
    ConstraintList,
  },
  data: () => ({
    loading: true,
    current: 1,
    pageSize: 10,
    searchData: '',
  }),
  computed: {
    ...mapGetters(['resources']),
    paginatedList() {
      return Object.values(this.resources.items).slice(
        this.pageSize * (this.current - 1),
        this.pageSize * this.current
      );
    },
  },
  created() {
    this.get({ type: 'resource', id: 'all' }).then(() => {
      this.loading = false;
      this.setPage();
    });
  },
  methods: {
    ...mapActions(['get', 'delete']),
    deleteResource(resource) {
      this.$confirm(
        `${upperFirst(this.$t('pages.resource.wantedToDeleteResource'))} ${
          resource.name
        }`
      )
        .then(
          () => {
            this.loading = true;
            return this.delete({ type: 'resource', id: resource.id });
          },
          () => null
        )
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setPage(v) {
      if (v) {
        this.current = v;
      }
    },
  },
};
</script>
