<template>
  <base-layout>
    <h2 slot="title">
      {{ $t('components.GeocodeGate.defectiveAddress', [list.length]) }}
    </h2>
    <el-row :gutter="20">
      <el-col :span="8">
        <h4 class="subtitle">{{ $t('components.GeocodeGate.addressList') }}</h4>
        <ul class="address-list">
          <li
            v-for="delivery in list"
            :key="delivery.id"
            class="item"
            :style="colorFromPercent(delivery)"
            :class="{ selected: delivery === selection }"
            @click="select(delivery)"
          >
            <strong>{{ delivery.external_id }}</strong>
            <p>
              <span v-if="delivery.place.street_number">{{
                delivery.place.street_number
              }}</span>
              <span v-if="delivery.place.street_name">{{
                delivery.place.street_name
              }}</span>
              <span v-if="delivery.place.postcode">{{
                delivery.place.postcode
              }}</span>
              <span v-if="delivery.place.city">{{ delivery.place.city }}</span>
            </p>
          </li>
        </ul>
      </el-col>
      <el-col :span="16">
        <!-- <place v-if="selection" :entity="selection"/> -->
        <geocode-gate-editor
          v-model="selection.place_geocoded"
          edit-mode
          :entity-id="selection.id"
          :place="selection.place"
        />
      </el-col>
    </el-row>
  </base-layout>
</template>

<script>
import _ from 'lodash';
import GeocodeGateEditor from './GeocodeGateEditor';
import { getColorForPercentage } from '@/utils/color';

export default {
  components: {
    GeocodeGateEditor,
  },
  props: {
    toGeocode: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    saving: false,
    selection: null,
  }),
  computed: {
    list() {
      const list = _.sortBy(this.toGeocode, (o) =>
        o.place_geocoded ? o.place_geocoded.score : 0
      );
      [this.selection] = list;

      return list;
    },
  },
  methods: {
    select(delivery) {
      this.selection = delivery;
    },
    colorFromPercent(item) {
      if (item && item.place_geocoded && item.place_geocoded.score) {
        return `border-left:4px solid ${getColorForPercentage(
          item.place_geocoded.score
        )};`;
      }
      return '';
    },
    removeFromList(d) {
      const list = this.toGeocode;
      const index = _.findIndex(list, (e) => e.id === d.id);

      if (index > -1) {
        list.splice(index, 1);
      }

      this.selection = {};
    },
    close() {
      this.removeFromList(this.selection);
    },
  },
};
</script>
