import { isNil } from 'lodash';
import { addHours, startOfDay, isValid } from 'date-fns';
import i18n from '@/locales';

const formatString = (v) => (isNil(v) ? v : String(v));
const formatDateTime = (v) => {
  try {
    let d = new Date(v);
    if (!isValid(d)) {
      d = undefined;
    }
    return d;
  } catch (e) {
    return undefined;
  }
};
const formatDate = (v) => {
  let d = formatDateTime(v);
  if (d) {
    d = startOfDay(addHours(d, 3));
  }
  return d;
};

const formatArray = (v) => {
  if (v instanceof Array) {
    return Array(v);
  }
  return v;
};
const formatNumber = (v) => {
  const val = Number(v);
  return Number.isNaN(val) ? undefined : val;
};

export default {
  external_id: {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.external_id'),
    desc: i18n.t('components.XlsxImporter.desc.external_id'),
  },
  duration: {
    model_type: Number,
    format: formatNumber,
    label: i18n.t('components.XlsxImporter.label.duration'),
    desc: i18n.t('components.XlsxImporter.desc.duration'),
  },
  item_count: {
    model_type: Number,
    format: formatNumber,
    label: i18n.t('components.XlsxImporter.label.item_count'),
    desc: i18n.t('components.XlsxImporter.desc.item_count'),
  },
  date: {
    model_type: Date,
    format: formatDate,
    label: i18n.t('components.XlsxImporter.label.date'),
    desc: i18n.t('components.XlsxImporter.desc.date'),
  },
  slas: {
    model_type: Array,
    format: formatArray,
    label: i18n.t('components.XlsxImporter.label.slas'),
    desc: i18n.t('components.XlsxImporter.desc.slas'),
    items: {
      start: {
        model_type: Date,
        format: formatDateTime,
      },
      end: {
        model_type: Date,
        format: formatDateTime,
      },
    },
  },
  receiver_name: {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.receiver_name'),
    desc: i18n.t('components.XlsxImporter.desc.receiver_name'),
  },
  receiver_phone: {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.receiver_phone'),
    desc: i18n.t('components.XlsxImporter.desc.receiver_phone'),
  },
  email: {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.email'),
    desc: i18n.t('components.XlsxImporter.desc.email'),
  },
  'place.street_number': {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.street_number'),
    desc: i18n.t('components.XlsxImporter.desc.street_number'),
  },
  'place.street_name': {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.street_name'),
    desc: i18n.t('components.XlsxImporter.desc.street_name'),
  },
  'place.secondary': {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.secondary'),
    desc: i18n.t('components.XlsxImporter.desc.secondary'),
  },
  'place.city': {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.city'),
    desc: i18n.t('components.XlsxImporter.desc.city'),
  },
  'place.postcode': {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.postcode'),
    desc: i18n.t('components.XlsxImporter.desc.postcode'),
  },
  'place.country': {
    model_type: String,
    format: formatString,
    label: i18n.t('components.XlsxImporter.label.country'),
    desc: i18n.t('components.XlsxImporter.desc.country'),
  },
  'place.country_code': {
    model_type: String,
    format: formatString,
    default_field: {
      label: i18n.t('components.XlsxImporter.label.default_country_code'),
      desc: i18n.t('components.XlsxImporter.desc.default_country_code'),
    },
    label: i18n.t('components.XlsxImporter.label.country_code'),
    desc: i18n.t('components.XlsxImporter.desc.country_code'),
  },
  'place.latitude': {
    model_type: Number,
    format: formatNumber,
    label: i18n.t('components.XlsxImporter.label.latitude'),
    desc: i18n.t('components.XlsxImporter.desc.latitude'),
  },
  'place.longitude': {
    model_type: Number,
    format: formatNumber,
    label: i18n.t('components.XlsxImporter.label.longitude'),
    desc: i18n.t('components.XlsxImporter.desc.longitude'),
  },
  consist_of: {
    model_type: Array,
    format: formatArray,
    label: i18n.t('components.XlsxImporter.label.consist_of'),
    desc: i18n.t('components.XlsxImporter.desc.consist_of'),
    items: {
      type: {
        model_type: String,
        format: formatString,
      },
      value: {
        model_type: Number,
        format: formatNumber,
      },
    },
  },
  tools: {
    model_type: Array,
    format: formatArray,
    label: i18n.t('components.XlsxImporter.label.tools'),
    desc: i18n.t('components.XlsxImporter.desc.tools'),
    items: {
      type: {
        model_type: String,
        format: formatString,
      },
    },
  },
};
