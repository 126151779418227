<template lang="html">
  <el-select
    value-key="id"
    :value="value"
    :placeholder="$t('components.RouteBatchCreator.selectStartDepot')"
    :size="size"
    @change="update"
  >
    <el-option
      v-for="depot in depots"
      :key="depot.id"
      :label="depot.name"
      :value="depot"
    >
      <span class="select-place">
        <span class="place-name">{{ depot.name }}</span>
        <span class="place-type">{{ depot.type }}</span>
      </span>
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: () => 'normal',
    },
  },
  computed: {
    depots() {
      return this.$store.getters.places.items;
    },
  },
  created() {
    this.$store.dispatch('get', { type: 'place', id: 'all' });
  },
  methods: {
    update(v) {
      this.$emit('input', v);
    },
  },
};
</script>

<style lang="scss">
.select-place {
  .place-name {
    float: left;
  }
  .place-type {
    margin-left: 5px;
    float: right;
    color: #8492a6;
    font-size: 13px;
  }
}
</style>
