<template>
  <section>
    <el-row :gutter="20">
      <el-form
        ref="form"
        :model="organization"
        autocomplete="off"
        :rules="formRules"
        @submit.prevent
      >
        <el-col :span="8" :offset="4">
          <el-form-item :label="$t('commons.name')" prop="name">
            <el-input
              name="name"
              type="text"
              :value="organization.name"
              @input="updateField('name', $event)"
            />
          </el-form-item>

          <el-form-item :label="$t('commons.siret')" prop="siret">
            <el-input
              name="siret"
              type="text"
              :value="organization.siret"
              @input="updateField('siret', $event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item :label="$t('commons.logo')" prop="logo">
            <el-input
              name="image"
              type="text"
              :value="organization.image"
              @input="updateField('image', $event)"
            />
          </el-form-item>
          <div :class="$style.divLogo">
            <div>
              <img :class="$style.logo" :src="logo" alt="organization logo" />
            </div>
          </div>
        </el-col>
        <slot />
      </el-form>
    </el-row>
  </section>
</template>
<script>
import { getRequireRule, getTypeRule } from '@/validation';
import logo from '@/images/logitricks_logo.png';

export default {
  model: {
    prop: 'organization',
    event: 'change',
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    formRules() {
      return {
        name: [getRequireRule('commons.name')],
        email: [getTypeRule('email')],
        ...this.rules,
      };
    },
    logo() {
      return this.organization.image || logo;
    },
  },
  methods: {
    updateField(name, value) {
      this.$emit('change', { ...this.organization, [name]: value });
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => (valid ? resolve() : reject()));
      });
    },
  },
};
</script>
<style module>
.divLogo {
  margin: auto;
}
.logo {
  max-height: 100px;
  object-fit: contain;
}
</style>
