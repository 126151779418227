<script>
import {
  camelCase,
  reduce,
  indexOf,
  isArray,
  isNil,
  isEmpty,
  isPlainObject,
  isObject,
  sortBy,
} from 'lodash';
import i18n from '../locales';

export default {
  functional: true,
  props: {
    data: {
      type: Object,
      required: true,
    },
    only: {
      type: [Object, Array],
      default: undefined,
    },
    i18nPrefix: {
      type: String,
      default: 'commons.',
    },
    i18n: {
      type: Boolean,
      default: true,
    },
    colors: {
      type: Object,
      default: () => ({
        left: '#606266',
        right: '#32363B',
      }),
    },
    sort: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: null,
    },
  },
  render(createElement, context) {
    const shouldFilter = !isEmpty(context.props.only);
    let hasi18n = false;
    let listOnly = [];
    if (isArray(context.props.only)) {
      listOnly = context.props.only;
    }
    if (isPlainObject(context.props.only)) {
      listOnly = Object.keys(context.props.only);
      hasi18n = true;
    }

    const children = reduce(
      context.props.data,
      (acc, value, key) => {
        if (value !== '' && !isNil(value) && !isObject(value)) {
          let indexOfKey = -1;
          let i18nKey = `${context.props.i18nPrefix}${camelCase(key)}`;

          if (shouldFilter) {
            if (!listOnly.includes(key)) {
              return acc;
            }

            if (hasi18n) {
              i18nKey = context.props.only[key];
            }

            if (context.props.sort) {
              indexOfKey = indexOf(listOnly, key);
            }
          }
          const innerHTML = context.props.i18n ? i18n.t(i18nKey) : key;
          acc.push(
            createElement('tr', { key: `${indexOfKey} ${key}` }, [
              createElement('th', {
                domProps: { innerHTML },
                class: `row-key ${key}`,
                style: {
                  color: context.props.colors.left,
                  width: context.props.width || 'auto',
                },
              }),
              createElement('td', {
                domProps: { innerHTML: value },
                class: `row-value ${key}`,
                style: { color: context.props.colors.right },
              }),
            ])
          );
        }

        return acc;
      },
      []
    );

    return createElement(
      'table',
      { class: 'object-display' },
      sortBy(children, 'key')
    );
  },
};
</script>

<style lang="scss" scoped>
.object-display {
  width: 100%;
  .row-key,
  .row-value {
    text-transform: capitalize;
  }
  .column {
    word-break: break-word;
    &.left {
      margin-right: 10px;
    }
  }
}
</style>
