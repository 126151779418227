<template lang="html">
  <print-template>
    <spots-print :spots="spots" />
  </print-template>
</template>

<script>
import { mapActions } from 'vuex';

import { isEmpty, sortBy, values, filter } from 'lodash';

import PrintTemplate from '@/components/print/PrintTemplate';
import SpotsPrint from '@/components/print/SpotsPrint';

export default {
  components: {
    SpotsPrint,
    PrintTemplate,
  },
  computed: {
    spots() {
      if (isEmpty(this.$store.getters.spots.items)) {
        return [];
      }
      let spots;
      if (this.$route.params.type === 'all') {
        spots = this.$store.getters.spots.items;
      } else if (
        ['storage', 'loading_dock'].includes(this.$route.params.type)
      ) {
        spots = values(
          filter(this.$store.getters.spots.items, {
            type: this.$route.params.type,
          })
        );
      } else {
        spots = values(this.$store.getters.spots.items).filter((spot) => {
          return spot.barcode.slice(0, 5) === this.$route.params.type;
        });
      }
      return sortBy(spots, 'barcode');
    },
  },
  created() {
    if (isEmpty(this.$store.getters.spots.items)) {
      this.loading = true;
      this.get({
        type: 'place',
        id: this.$route.params.id,
        query: 'include=spots',
      }).then(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    ...mapActions(['get']),
  },
};
</script>

<style lang="scss"></style>
