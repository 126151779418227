import { pad } from 'lodash';

export function invertColor(h, bw) {
  if (!h) {
    return false;
  }
  let hex = h.indexOf('#') === 0 ? h.slice(1) : h;
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return `#${pad(r, 2, 0)}${pad(g, 2, 0)}${pad(b, 2, 0)}`;
}

export function hexToRgbA(hex, alpha) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    /* eslint-disable no-bitwise, no-unneeded-ternary */
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${
      alpha ? alpha : 1
    })`;
    /* eslint-enable no-bitwise, no-unneeded-ternary */
  }
  throw new Error('Bad Hex');
}

export function getColor(hash, luminance) {
  /* eslint-disable no-bitwise */
  const lum = luminance || 0;
  let colour = '#';
  for (let i = 0; i < 3; i += 1) {
    let value = (hash >> (i * 8)) & 0xff;
    value = Math.round(
      Math.min(Math.max(0, value + value * lum), 255)
    ).toString(16);
    colour += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return colour;
}

export function getColorHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    /* eslint-disable no-bitwise */
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    /* eslint-enable no-bitwise */
  }

  return hash;
}

export function getRandomColor(str, defaultColor, luminance) {
  if (!str) {
    return defaultColor;
  }
  return getColor(getColorHash(str), luminance);
}

export function shadeColor(color, percent) {
  /* eslint-disable no-bitwise */
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  const hex = (
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1);
  /* eslint-enable no-bitwise */
  return `#${hex}`;
}

export function getColorForPercentage(percent) {
  const r = Math.floor(255 - (255 * percent) / 100);
  const g = Math.floor((255 * percent) / 100);
  return `rgb(${r},${g},0)`;
}
