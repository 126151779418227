var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-cal',{attrs:{"hide-view-selector":"","hide-title-bar":"","start-week-on-sunday":"","locale":_vm.$i18n.locale,"editable-events":{
    title: false,
    drag: _vm.editable,
    resize: _vm.editable,
    delete: false,
    create: _vm.editable,
  },"disable-views":['years', 'year', 'month', 'day'],"events":_vm.events,"snap-to-time":5},on:{"event-drop":_vm.onDrop,"event-duration-change":_vm.onUpdate,"event-drag-create":_vm.onCreate,"cell-dblclick":_vm.onCreateDblClick},scopedSlots:_vm._u([{key:"event",fn:function(ref){
  var event = ref.event;
return [_c('div',{staticClass:"vuecal__event-content"},[_c('div',[_vm._v(_vm._s(_vm._f("format")(event.start,'HH:mm')))]),(_vm.editable)?_c('cs-button',{attrs:{"square":"","icon":"delete","icon-type":"material","type":"danger"},on:{"click":function($event){return _vm.onDelete(event)}}}):_vm._e(),_c('div',[_vm._v(_vm._s(_vm._f("format")(event.end,'HH:mm')))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }