<template lang="html">
  <span>
    <array-mapper
      v-if="data.isArray && data.itemsModel"
      :default-field="data.default_field"
      :array-models="data.itemsModel"
      :preview="data.preview"
      :options="options"
      @change="updateMap"
    />
    <select-column
      v-else
      :default-field="data.default_field"
      :value="data.value"
      :options="options"
      @change="updateValue"
    />
  </span>
</template>

<script>
import SelectColumn from './SelectColumn';
import ArrayMapper from './ArrayMapper';

export default {
  name: 'ColumnMapper',
  components: { SelectColumn, ArrayMapper },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateValue({ value, defaultValue }) {
      this.$emit('change', { ...this.data, value, defaultValue });
    },
    updateMap(value) {
      const itemsMapValue = value;
      this.$emit('change', { ...this.data, value, itemsMapValue });
    },
  },
};
</script>
