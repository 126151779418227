<template>
  <base-layout :load="loading">
    <h3 v-if="place" slot="title">
      {{ $t('commons.warehouse.single') }} : {{ place.name }}
    </h3>

    <place-form
      v-if="place"
      ref="form"
      v-model="isValid"
      :place="place"
    ></place-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexPlace' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      :disabled="!isValid"
      @click="savePlace"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapActions } from 'vuex';
import PlaceForm from './forms/PlaceForm';

export default {
  name: 'PlaceEdit',
  components: { PlaceForm },
  data: () => ({
    loading: false,
    saving: false,
    isValid: true,
  }),
  computed: {
    place() {
      const pl = this.$store.getters.places.items[this.$route.params.id];
      if (!pl) return {};
      return pl;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.isValid = true;
  },
  methods: {
    ...mapActions(['get', 'patch']),
    fetchData() {
      this.loading = true;
      this.get({ type: 'place', id: this.$route.params.id }).then(() => {
        this.loading = false;
      });
    },
    savePlace() {
      this.saving = true;
      this.patch({ type: 'place', id: this.place.id, data: this.place })
        .then(() => {
          this.saving = false;
          this.$router.push({ name: 'indexPlace' });
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
