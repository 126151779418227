<template lang="html">
  <l-polygon
    ref="polygon"
    :lat-lngs="selectionPolygon"
    @click="clearPolygon()"
  />
</template>

<script>
import { forEach, get, filter } from 'lodash';
import FreeDraw from 'leaflet-freedraw';
import { mapActions } from 'vuex';
import { LPolygon } from 'vue2-leaflet';

export default {
  components: {
    LPolygon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    freeDraw: undefined,
    selectionPolygon: [],
  }),
  watch: {
    value(v) {
      if (!v) {
        this.freeDraw.mode(FreeDraw.NONE);
      } else {
        this.freeDraw.mode(FreeDraw.CREATE);
      }
    },
  },
  mounted() {
    this.freeDraw = new FreeDraw({ mode: FreeDraw.NONE });
    this.freeDraw.on('markers', (event) => this.updateSelection(event));
    this.$emit('setFreeDraw', this.freeDraw);
  },
  beforeDestroy() {
    this.$emit('removeFreeDraw', this.freeDraw);
    this.freeDraw = undefined;
  },
  methods: {
    ...mapActions(['setSelectedActivities']),
    updateSelection(event) {
      this.freeDraw.mode(FreeDraw.NONE);
      this.$emit('input', false);
      if (event.latLngs.length > 0) {
        this.freeDraw.clear();
        [this.selectionPolygon] = event.latLngs;
        let selection = [];
        forEach(this.data, (route) => {
          if (route.displayMarkers) {
            forEach(route.deliveries, (delivery) => {
              if (get(delivery, 'matchFilter', true)) {
                selection.push(delivery);
              }
            });
          }
        });
        selection = filter(selection, (delivery) =>
          this.findDeliveryInSelection(delivery, this.selectionPolygon)
        );
        this.setSelectedActivities({ data: selection });
      }
    },
    findDeliveryInSelection(delivery, polygon) {
      let crossings = 0;
      for (let i = 0; i < polygon.length; i += 1) {
        const a = polygon[i];
        let j = i + 1;
        if (j >= polygon.length) {
          j = 0;
        }
        const b = polygon[j];
        if (this.isSegmentCross(delivery.position, a, b)) {
          crossings += 1;
        }
      }
      return crossings % 2 === 1;
    },
    /* eslint-disable no-param-reassign */
    isSegmentCross(point, a, b) {
      if (a.lat > b.lat) {
        const tempa = a;
        a = b;
        b = tempa;
      }
      const px = point.lng + 180;
      const ax = a.lng + 180;
      const bx = b.lng + 180;
      let py = point.lat;
      const ay = a.lat;
      const by = b.lat;

      if (py === ay || py === by) py += 0.00000001;
      // Ray is over, under or too east
      if (py > by || py < ay || px > Math.max(ax, bx)) {
        return false;
      }
      // way too west, intersect !
      if (px < Math.min(ax, bx)) return true;
      // comparaison required
      const ab = ax !== bx ? (by - ay) / (bx - ax) : Infinity;
      const rayFromPoint = ax !== px ? (py - ay) / (px - ax) : Infinity;
      return rayFromPoint >= ab;
    },
    /* eslint-disable no-param-reassign */
    clearPolygon() {
      this.selectionPolygon = [];
    },
  },
};
</script>

<style lang="scss">
.mode-create {
  cursor: crosshair !important;
}
.leaflet-edge {
  background-color: #95bc59;
  box-shadow: 0 0 0 2px white, 0 0 10px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  cursor: move;
  outline: none;
  transition: background-color 0.25s;
}
.free-draw {
  position: absolute;
}
.leaflet-polygon {
  fill: #b4cd8a;
  stroke: #50622b;
  stroke-width: 2;
  fill-opacity: 0.75;
}
</style>
