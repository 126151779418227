const state = {
  maps: [],
};

const mutations = {
  REGISTER_MAP: (myState, namespace) => {
    if (!myState.maps.find((f) => f.namespace === namespace)) {
      myState.maps.push({
        namespace,
        maps: [],
      });
    }
  },
  STORE_MAP: (myState, o) => {
    const map = myState.maps.find((f) => f.namespace === o.namespace);
    map.maps.push(o.map);
  },
};

const getters = {
  maps: (myState) => myState.maps,
  getMapsByNamespace: (myState, myGetters) => (namespace) => {
    const map = myGetters.maps.find((m) => m.namespace === namespace);
    if (map) {
      return map.maps;
    }
    return null;
  },
};

const actions = {
  registerMap({ commit }, namespace) {
    commit('REGISTER_MAP', namespace);
  },
  storeMap({ commit }, o) {
    commit('STORE_MAP', o);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
