<template>
  <base-layout v-if="activity">
    <h3 slot="title">
      {{ $t('commons.activity.single') }} : {{ activity.external_id }}
    </h3>

    <activity-summary :value="activity" @save="save" @change="saveToQueue" />

    <el-row type="flex" justify="space-between" :gutter="20">
      <el-col :span="8">
        <l-map class="map-tracking" :bounds="bounds">
          <l-tile-layer :url="url" :attribution="attribution"> </l-tile-layer>
          <l-layer-group>
            <l-marker :lat-lng="activity.position" :icon="activity.icon">
            </l-marker>
          </l-layer-group>
          <l-circle-marker
            v-if="selectedState && selectedState.position"
            :lat-lng="selectedState.position"
            :class-name="`state-${selectedState.to}`"
            :radius="7"
          >
            <l-popup :lat-lng="selectedState.position">
              {{ $t(`commons.states.delivery.${selectedState.to}`) }}
              {{ $t('commons.at') }}
              {{ selectedState.datetime | moment('HH:mm') }}
              <em>{{ selectedState.message }}</em>
            </l-popup>
          </l-circle-marker>
        </l-map>
      </el-col>

      <el-col :span="16">
        <el-row type="flex" justify="space-between" :gutter="20">
          <el-col :span="11">
            <h4>{{ $tc('commons.item.choice', activity.item_count) }}</h4>

            <el-table :data="activity.items" style="width: 100%">
              <el-table-column
                prop="state"
                width="80"
                :label="$t('commons.state')"
                sortable
              >
                <template slot-scope="scope">
                  <state type="delivery" :value="scope.row.state" />
                </template>
              </el-table-column>
              <el-table-column
                prop="barcode"
                :label="$t('commons.barcode')"
                sortable
              >
              </el-table-column>
              <el-table-column
                prop="quantity"
                :label="$t('commons.quantity')"
                sortable
              >
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="8">
            <section style="display: inline-flex">
              <h4>{{ $t('commons.infos') }}</h4>
              <cs-button
                icon="add"
                icon-type="material"
                class="btn_add"
                @click="newNote"
              >
              </cs-button>
            </section>
            <section class="notes">
              <activity-external-data
                v-for="(data, idx) in activity.external_data"
                :key="idx"
                :index="idx"
                :data="activity"
                @save="save"
                @change="saveToQueue"
                @delete="deleteItem"
                @notif="notificationOn"
              >
              </activity-external-data>
            </section>
            <h4 style="margin-top: 10px">{{ $t('commons.pod') }}</h4>

            <activity-proof :value="activity.proof" />
          </el-col>
          <el-col :span="5">
            <h4>{{ $t('commons.state_history') }}</h4>

            <state-history
              :history="activity.state_history"
              entity-type="delivery"
              @selected="setSelectedState"
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <replan slot="footer" :activity="activity" />
    <cs-button
      slot="footer"
      type="primary"
      :disabled="!hasChanged"
      @click="save"
    >
      {{ $t('commons.save') }}
    </cs-button>
    <cs-button slot="footer" :disabled="!hasChanged" @click="cancel">
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      icon-type="material"
      icon="print"
      target="_blank"
      :to="{ name: 'printActivity', params: { id: activity.id } }"
    />
    <cs-button
      v-show="activity.route_id && $can('route', 'read')"
      slot="footer"
      @click="
        $router.push({
          name: 'routeDetail',
          params: { id: activity.route_id },
        })
      "
    >
      {{ $t('sentence.goToRoute') }}
    </cs-button>
    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.back()"
    >
      {{ $t('commons.return') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import {
  LMap,
  LTileLayer,
  LLayerGroup,
  LMarker,
  LPopup,
  LCircleMarker,
} from 'vue2-leaflet';
import { compact, isNil } from 'lodash';
import moment from 'moment';
import { setBounds } from '@/utils';
import StateHistory from '@/components/StateHistory';
import ActivityProof from '@/components/ActivityProof';
import State from '@/components/State';
import Replan from '@/components/Replan';
import ActivitySummary from './ActivitySummary';
import ActivityExternalData from '../../components/ActivityExternalData.vue';

export default {
  components: {
    LCircleMarker,
    LMap,
    LTileLayer,
    LLayerGroup,
    LMarker,
    LPopup,
    StateHistory,
    ActivityProof,
    ActivitySummary,
    Replan,
    State,
    ActivityExternalData,
  },
  filters: {
    formatName: (v) => v.replace('_', ' '),
  },
  data: () => ({
    hasChanged: false,
    selectedState: null,
    loading: true,
    url: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER}`,
    attribution:
      '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
  }),
  computed: {
    activity() {
      return this.$store.getters.deliveries.items[this.$route.params.id];
    },
    bounds() {
      return setBounds(compact([this.selectedState, this.activity]));
    },
    delay() {
      return this.activity.completed_at - this.activity.planned_end;
    },
    delayHuman() {
      return moment.duration(this.delay).humanize();
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  watch: {
    activity(v) {
      if (isNil(v) || (v.state === 'replaned' && isNil(v.child))) {
        this.loading = true;
        this.fetchData();
      }
    },
    currentLanguage: function () {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'get',
      'queueAction',
      'processActionQueue',
      'cancelActionQueue',
    ]),
    setSelectedState(state) {
      this.selectedState = state;
      // Fix for leaflet rerender
      if (this.$refs.selectedMarker) {
        this.$refs.map.mapObject.removeLayer(
          this.$refs.selectedMarker.mapObject
        );
        this.$refs.selectedMarker.setClassName(
          `state-${this.selectedState.to}`
        );
        this.$refs.map.mapObject.addLayer(this.$refs.selectedMarker.mapObject);
      }
    },
    fetchData() {
      this.get({
        type: 'delivery',
        id: this.$route.params.id,
        query: {
          include: 'parent,child',
        },
        forceFetch: true,
      }).then(() => {
        this.loading = false;
      });
    },
    save() {
      this.processActionQueue(['delivery']);
      this.hasChanged = false;
    },
    cancel() {
      this.cancelActionQueue(['delivery']);
      this.fetchData();
      this.hasChanged = false;
    },
    saveToQueue() {
      this.queueAction({
        action: 'patch',
        type: 'delivery',
        data: this.activity,
      });
      this.hasChanged = true;
    },
    deleteItem(idx) {
      this.activity.external_data.splice(idx, 1);
      this.queueAction({
        action: 'patch',
        type: 'delivery',
        data: this.activity,
      });
      this.hasChanged = true;
    },
    notificationOn(idx) {
      if (this.activity.notification == idx + 1) {
        this.activity.notification = null;
      } else {
        this.activity.notification = idx + 1;
      }
      this.queueAction({
        action: 'patch',
        type: 'delivery',
        data: this.activity,
      });
      this.hasChanged = true;
    },
    newNote() {
      this.activity.external_data.push({
        name: this.$t('commons.newNote'),
        value: '',
      });
      this.queueAction({
        action: 'patch',
        type: 'delivery',
        data: this.activity,
      });
      this.hasChanged = true;
    },
  },
};
</script>

<style lang="scss">
.btn_add {
  height: auto !important;
  line-height: inherit !important;
  min-width: auto !important;
  padding: 0px 8px;
}
</style>
