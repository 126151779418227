<template>
  <div>
    <div class="row-spot row-label">
      <p class="spot-name">{{ barcodePrefix }}</p>
      <br />
      <p class="level-label">
        {{ $tc('pages.place.spot.level.choice', spots.length).toUpperCase() }}
      </p>
      <div class="menu-btn">
        <el-popover
          v-model="visible"
          placement="right"
          title=""
          width="5"
          trigger="hover"
        >
          <h5 v-for="(level, id) in spots" :key="id">
            {{ level.barcode }}
          </h5>
          <cs-button
            slot="reference"
            class="spot-btn spot-btn-sec"
            :class="{ 'activate-btn': visible }"
            square
            icon="menu"
            icon-type="material"
            @click="visible = !visible"
          >
          </cs-button>
        </el-popover>
      </div>
    </div>
    <div class="row-spot level-row">
      <div class="icon-wrapper">
        <cs-button
          class="spot-btn"
          square
          icon-type="material"
          icon="remove"
          @click="removeLevel"
        />
      </div>
      <p class="nb-level">{{ spots.length }}</p>
      <div class="icon-wrapper">
        <cs-button
          class="spot-btn"
          square
          icon-type="material"
          icon="add"
          @click="addLevel"
        />
      </div>
    </div>
    <div class="row-spot btn-row">
      <div class="icon-wrapper">
        <cs-button
          class="spot-btn spot-btn-sec"
          square
          icon-type="material"
          icon="delete"
          @click="deleteSpot"
        />
      </div>
      <div class="icon-wrapper">
        <cs-button
          class="spot-btn spot-btn-sec"
          square
          icon-type="material"
          icon="print"
          target="_blank"
          :to="printOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { upperFirst, last } from 'lodash';
import { getBarcode } from '@/utils/spotUtils';

const LEVEL_LIMIT = 99;

export default {
  name: 'Spot',
  props: {
    spots: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    barcodePrefix() {
      return this.spots[0].barcode.slice(0, 5);
    },
    printOptions() {
      return {
        name: 'printSpots',
        params: {
          id: this.$route.params.id,
          type: this.barcodePrefix,
        },
      };
    },
  },
  methods: {
    removeLevel() {
      this.$emit('delete', last(this.spots));
    },
    addLevel() {
      const lastSpot = last(this.spots);
      const row = lastSpot.barcode.slice(0, 2);
      const col = lastSpot.barcode.slice(2, 5);
      const place = lastSpot.barcode.slice(9);
      const newLevel = parseInt(lastSpot.barcode.slice(6, 8)) + 1;

      if (newLevel <= LEVEL_LIMIT) {
        this.$emit('addSpot', {
          barcode: getBarcode(row, col, place, newLevel),
          type: 'storage',
        });
      } else {
        this.$message({
          type: 'error',
          message: upperFirst(this.$t('pages.place.spot.error.levelsLimit')),
          showClose: true,
        });
      }
    },
    deleteSpot() {
      this.$emit('delete', this.spots);
    },
  },
};
</script>

<style lang="scss">
.spot-name {
  font-size: 10px;
  color: #999;
  margin: 0;
  position: relative;
}

.row-spot * {
  display: inline-block;
}

.row-label {
  background-color: #f6f7f9;
}

.level-label {
  margin-top: 6px;
}

.menu-btn {
  float: right;
}

.activate-btn {
  font-size: 15px;
}

.level-row * {
  vertical-align: middle;
}

.spot-btn {
  font-size: 18px !important;
  border-radius: 50% !important;
  height: 30px !important;
  min-width: 30px !important;
  width: 30px !important;
  margin: auto 5px;
  line-height: 0 !important; // safari
}

.spot-btn-sec {
  background-color: inherit !important;
  font-size: 16px !important;
}

.spot-btn-sec i {
  color: #a8abb4;
}

.btn-row .spot-btn {
  margin: 0 9px !important;
}
</style>
