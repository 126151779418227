<template lang="html">
  <div class="appointment-component">
    <slot></slot>

    <el-row v-if="!edit" type="flex" align="middle" :style="{ width: '100%' }">
      <el-col :span="18" :offset="1" :style="{ display: 'flex' }">
        <span
          v-if="!appointment || !appointment.isValid()"
          class="right-key-display"
        >
          {{ $t('commons.none.male') }}
        </span>
        <div v-else>
          <span>
            {{ $t('commons.from') | upperFirst }}
            {{ appointment.start | format(SHORT_DATETIME_FORMAT) }}
            {{ $t('commons.to') }}
            {{ appointment.end | format(SHORT_DATETIME_FORMAT) }}
          </span>
        </div>
      </el-col>
    </el-row>

    <div v-else>
      <el-row type="flex" align="middle" :gutter="1">
        <el-col :span="10">
          <span class="left-key-display">
            {{ $t('sentence.rdvDate') }}
          </span>
        </el-col>
        <el-col :span="14">
          <el-date-picker
            v-model="date"
            type="date"
            format="dd MMM"
            :size="size"
            placeholder="_________________"
            @change="change"
          />
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" :gutter="1">
        <el-col :span="10">
          <span class="left-key-display">
            {{ $t('sentence.rdvTime') }}
          </span>
        </el-col>
        <el-col :span="14">
          <el-time-picker
            is-range
            v-model="time"
            placeholder="_________________"
            @change="change"
          >
          </el-time-picker>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Appointment from '@/models/Appointment';
import { SHORT_DATETIME_FORMAT } from '@/utils/date';

function mergeDateWithTime(date, time) {
  const merged = new Date(date.valueOf());
  merged.setHours(time.getHours());
  merged.setMinutes(time.getMinutes());
  merged.setSeconds(time.getSeconds());
  return merged;
}

export default {
  model: {
    prop: 'appointment',
    event: 'change',
  },
  props: {
    appointment: {
      type: Appointment,
    },
    defaultDate: {
      type: Date,
      default: () => new Date(),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: () => '',
    },
    step: {
      type: String,
      default: '00:15',
    },
  },
  data() {
    return {
      date: new Date(this.defaultDate.valueOf()),
      time: [
        new Date(this.defaultDate.valueOf()),
        new Date(this.defaultDate.valueOf()),
      ],
      SHORT_DATETIME_FORMAT: SHORT_DATETIME_FORMAT,
    };
  },
  computed: {
    height() {
      return this.size === 'small' ? '32px' : '100%';
    },
  },
  mounted() {
    if (this.appointment && this.appointment.isValid()) {
      this.date = this.appointment.start;
      this.time = [this.appointment.start, this.appointment.end];
    }
  },
  methods: {
    change() {
      let appointment = undefined;
      if (this.time && this.date) {
        appointment = new Appointment({
          start: mergeDateWithTime(this.date, this.time[0]),
          end: mergeDateWithTime(this.date, this.time[1]),
        });
      }

      this.$emit('change', appointment);
    },
  },
};
</script>
<style lang="scss">
.appointment-component {
  width: 100%;
}
</style>
