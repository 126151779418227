<template lang="html">
  <div class="sidebar-head optim-head">
    <el-row>
      <el-col :span="24">
        <h5>{{ optimization.name }}</h5>
      </el-col>
      <el-col :span="24">
        <i class="material-icons">info</i>
        {{ $t(`commons.states.optimization.${optimization.state}`) }}
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
      <cs-button
        class="optim-button-item"
        square
        type="primary"
        :icon="icon"
        :disabled="disabled"
        :rotate="rotate"
        @click="
          $router.push({
            name: 'editOptimization',
            params: { id: optimization.id },
          })
        "
      >
      </cs-button>
      <cs-button
        square
        class="optim-button-item"
        type="danger"
        icon="trash"
        @click="deleteOptim(optimization)"
      />
    </el-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    optimization: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disabled() {
      return this.optimization.state !== 'resolved';
    },
    rotate() {
      return !['resolved', 'failed'].includes(this.optimization.state);
    },
    icon() {
      switch (this.optimization.state) {
        case 'resolved':
          return 'eye';
        case 'failed':
          return 'exclamation-circle';
        default:
          return 'compass';
      }
    },
  },
  methods: {
    ...mapActions(['delete']),
    deleteOptim(optim) {
      this.$confirm(
        `${this.$t('components.optim.deleteOptim')} ${optim.name}`
      ).then(
        () => {
          this.delete({ type: 'optimization', id: optim.id });
        },
        () => null
      );
    },
  },
};
</script>

<style lang="scss">
.optim-button-item {
  margin-left: 2px;
  display: flex;
}
</style>
