<template>
  <base-layout :load="loading">
    <h3 slot="title">{{ $t('commons.create') }} {{ organization.name }}</h3>
    <organization-form ref="form" v-model="organization" :rules="rules">
      <el-col :span="16" :offset="4">
        <h3>{{ $t('commons.engine') }}</h3>
      </el-col>

      <el-col :span="8" :offset="4">
        <el-form-item>
          <el-switch
            v-model="organization.engine"
            active-text="Graphhopper"
            active-value="graphhopper"
            inactive-text="Redplanr"
            inactive-value="redplanr"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8" :offset="0">
        <el-form-item>
          <el-select v-model="organization.reason_prefix">
            <el-option
              v-for="(prefix, idx) in prefixes"
              :key="idx"
              :value="prefix"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="16" :offset="4">
        <h3>Admin</h3>
      </el-col>

      <el-col :span="8" :offset="4">
        <el-form-item :label="$t('commons.first_name')" prop="first_name">
          <el-input
            name="first_name"
            type="text"
            v-model="organization.first_name"
          />
        </el-form-item>
        <el-form-item :label="$t('commons.last_name')" prop="last_name">
          <el-input
            name="last_name"
            type="text"
            v-model="organization.last_name"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8" :offset="0">
        <el-form-item :label="$t('commons.mail')" prop="email">
          <el-input
            autocomplete="off"
            name="email"
            type="email"
            v-model="organization.email"
          />
        </el-form-item>
        <el-form-item :label="$t('commons.password')" prop="password">
          <el-input
            autocomplete="off"
            name="password"
            type="password"
            v-model="organization.password"
          />
        </el-form-item>
      </el-col>
      <el-col :span="16" :offset="4">
        <h3>Role</h3>
      </el-col>
      <el-col :span="8" :offset="4">
        <el-form-item :label="$t('commons.driver')" prop="driver_name">
          <el-input
            name="driver_name"
            type="text"
            v-model="organization.driver_name"
          />
        </el-form-item>
      </el-col>
    </organization-form>

    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      @click="createOrg"
    >
      {{ $t('commons.create') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import OrganizationForm from '@/pages/organization/forms/OrganizationForm';
import { getRequireRule, getTypeRule, getPasswordRule } from '@/validation';

export default {
  components: { OrganizationForm },
  data: () => ({
    loading: true,
    initData: {},
    organization: {
      name: undefined,
      image: undefined,
      siret: '',
      engine: 'graphhopper',
      first_name: undefined,
      last_name: undefined,
      reason_prefix: undefined,
      driver_name: 'livreur',
      email: undefined,
      password: undefined,
    },
  }),
  computed: {
    prefixes() {
      return get(this.initData, 'options.prefix', []);
    },
    rules() {
      return {
        first_name: [getRequireRule('commons.first_name')],
        last_name: [getRequireRule('commons.last_name')],
        driver_name: [getRequireRule('commons.driver')],
        password: [getRequireRule('commons.password'), getPasswordRule],
        email: [getTypeRule('email'), getRequireRule('commons.mail')],
      };
    },
  },
  async created() {
    const { data } = await this.axios.get('admin/organization/create');
    this.initData = data;
    this.loading = false;
  },
  methods: {
    ...mapActions(['post']),
    async createOrg() {
      try {
        await this.$refs.form.validate();
        const { id } = await this.post({
          type: 'organization',
          url: `admin/organization`,
          data: this.organization,
        });
        this.$router.push({ name: 'adminEditOrganization', params: { id } });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
