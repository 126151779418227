<template>
  <section class="d-flex flex-wrap shift-container">
    <shift-type
      v-for="(shift, idx) in shifts"
      :key="idx"
      :editable="true"
      :planning="shift.shifts"
      :shift-name="shift.name"
      :render-calendar="renderCalendar"
      @change="updateShift($event, idx)"
    >
      <cs-button
        slot="footer"
        square
        type="danger"
        icon-type="material"
        icon="delete"
        @click="deleteShift(shift)"
      />
      <shift-to-resources
        slot="footer"
        :shift="shift.shifts"
        :name="shift.name"
      />
    </shift-type>
  </section>
</template>
<style lang="scss" scoped>
.shift-container {
  justify-content: center;
}
</style>
<script>
import { findIndex } from 'lodash';
import ShiftType from '@/components/shift-type/ShiftType';
import ShiftToResources from '@/components/shift-type/ShiftToResources';

export default {
  components: { ShiftType, ShiftToResources },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    renderCalendar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shifts: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    deleteShift(shift) {
      const idx = findIndex(this.shifts, (s) => s === shift);
      if (idx > -1) {
        this.shifts.splice(idx, 1);
      }
    },
    updateShift(shift, idx) {
      this.shifts[idx].shifts = shift;
    },
  },
};
</script>
