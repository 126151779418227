<template>
  <base-layout :load="loading">
    <h3 v-if="vehicle" slot="title">
      {{ $t('commons.vehicle.single') }}: {{ vehicle.name }}
    </h3>
    <vehicle-form v-if="vehicle" ref="form" :vehicle="vehicle"></vehicle-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexVehicle' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveVehicle"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import VehicleForm from './forms/VehicleForm';

export default {
  name: 'VehicleEdit',
  components: { VehicleForm },
  data: () => ({
    loading: true,
    saving: false,
  }),
  computed: {
    ...mapGetters(['vehicles']),
    vehicle() {
      return this.vehicles.items[this.$route.params.id];
    },
  },
  created() {
    this.get({ type: 'vehicle', id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['get', 'patch']),
    saveVehicle() {
      this.$refs.form.validate().then(() => {
        this.saving = true;
        this.patch({ type: 'vehicle', id: this.vehicle.id, data: this.vehicle })
          .then(() => {
            this.saving = false;
            this.$router.push({ name: 'indexVehicle' });
          })
          .catch(() => {
            this.saving = false;
          });
      });
    },
  },
};
</script>
