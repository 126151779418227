<template>
  <section class="barcodes-wrapper">
    <VueBarcode
      v-for="spot in spots"
      :key="spot.id"
      :value="spot.barcode"
      :element-tag="'img'"
      :font="'Roboto'"
      :margin="30"
      class="barcode-item"
    />
  </section>
</template>

<script>
import VueBarcode from 'vue-barcode';

export default {
  components: {
    VueBarcode,
  },
  props: {
    spots: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.barcodes-wrapper {
  text-align: center;
}
.barcode-item {
  display: inline-block;
}
</style>
