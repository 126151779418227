<template lang="html">
  <section class="route-content primary-color-dark edit-element">
    <el-row
      class="content-section"
      :style="{ backgroundColor: edit.infos ? editColor : '#EBEDF0' }"
    >
      <el-row type="flex" justify="space-between" align="bottom">
        <el-col :span="21">
          <h4 :style="{ color: edit.infos ? tonic : 'black' }">
            {{ $t('commons.infos') }}
          </h4>
        </el-col>
        <el-col :span="3">
          <edit-toggle v-model="edit.infos" :name="$t('commons.infos')" />
        </el-col>
      </el-row>

      <el-row v-if="!edit.infos && route.external_id" type="flex">
        <el-col :span="2">
          <el-tooltip :content="$t('commons.externalId')">
            <label><i class="material-icons">info</i></label>
          </el-tooltip>
        </el-col>
        <el-col>
          <span>{{ route.external_id | empty('N/A') }}</span>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="6">
          <el-tooltip :content="$t('commons.name')">
            <span class="left-key-display">{{ $t('commons.name') }}</span>
          </el-tooltip>
        </el-col>
        <el-col :span="18" class="right-key-display">
          <span v-if="!edit.infos">{{ route.name | empty('N/A') }}</span>
          <el-input
            v-else
            v-model="route.name"
            required="required"
            type="text"
            @change="saveToQueue"
          />
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="6">
          <el-tooltip :content="$t('commons.color.single')">
            <span class="left-key-display">{{
              $t('commons.color.single')
            }}</span>
          </el-tooltip>
        </el-col>
        <el-col :span="18" class="right-key-display">
          <!-- <span v-if="!edit.infos">{{ route.color | empty('N/A') }}</span> -->
          <el-color-picker
            v-model="route.color"
            :disabled="!edit.infos"
            :show-alpha="false"
            color-format="hex"
            @change="saveToQueue"
          ></el-color-picker>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="6">
          <el-tooltip :content="$t('commons.place.start')">
            <span class="left-key-display">{{
              $t('commons.place.start')
            }}</span>
          </el-tooltip>
        </el-col>
        <el-col :span="18" class="right-key-display">
          <span v-if="!edit.infos">{{
            route.start_place.name | empty('N/A')
          }}</span>
          <span
            v-if="!edit.infos && route.start_place"
            class="display_place_type"
          >
            ({{ route.start_place.type }})
          </span>
          <select-depot
            v-else
            v-model="route.start_place"
            size="mini"
            @input="handleChangePlace"
          />
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="6">
          <el-tooltip :content="$t('commons.place.end')">
            <span class="left-key-display">{{ $t('commons.place.end') }}</span>
          </el-tooltip>
        </el-col>
        <el-col :span="18" class="right-key-display">
          <span v-if="!edit.infos">{{
            route.end_place.name | empty('N/A')
          }}</span>
          <span
            v-if="!edit.infos && route.end_place"
            class="display_place_type"
          >
            ({{ route.end_place.type }})
          </span>
          <select-depot
            v-else
            v-model="route.end_place"
            size="mini"
            @input="handleChangePlace"
          />
        </el-col>
      </el-row>
    </el-row>

    <hr class="activity-content-separator" />

    <el-row
      class="content-section"
      :style="{ backgroundColor: edit.time ? editColor : '#EBEDF0' }"
    >
      <el-row type="flex" justify="space-between" align="bottom">
        <el-col :span="21">
          <h4 :style="{ color: edit.time ? tonic : 'black' }">
            {{ $t('commons.when') }}
          </h4>
        </el-col>
        <el-col :span="3">
          <edit-toggle v-model="edit.time" :name="$t('commons.when')" />
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="6">
          <el-tooltip :content="$t('commons.planned_start')">
            <span class="left-key-display">{{
              $t('commons.planned_start')
            }}</span>
          </el-tooltip>
        </el-col>
        <el-col v-if="!edit.time" :span="18" class="right-key-display">
          <span v-if="!edit.time">{{
            route.planned_start | format | empty('N/A')
          }}</span>
        </el-col>
        <el-col v-else :span="18">
          <el-date-picker
            v-model="route.planned_start"
            placeholder="__________"
            type="datetime"
            @change="saveToQueue"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="6">
          <el-tooltip :content="$t('commons.planned_end')">
            <span class="left-key-display">{{
              $t('commons.planned_end')
            }}</span>
          </el-tooltip>
        </el-col>
        <el-col :span="18" class="right-key-display">
          <span v-if="!edit.time">{{
            route.planned_end | format | empty('N/A')
          }}</span>
          <el-date-picker
            v-else
            v-model="route.planned_end"
            placeholder="__________"
            type="datetime"
            @change="saveToQueue"
          >
          </el-date-picker>
        </el-col>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import EditToggle from '@/components/EditToggle';
import SelectDepot from '@/components/SelectDepot';

export default {
  components: {
    EditToggle,
    SelectDepot,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    edit: {
      infos: false,
      time: false,
    },
    editColor: '#32363B',
    tonic: '#50E3C2',
  }),
  methods: {
    ...mapActions(['queueAction']),
    handleChangePlace() {
      this.route.updatePolyline();
      this.saveToQueue();
    },
    saveToQueue() {
      this.queueAction({
        action: 'patch',
        type: 'route',
        data: this.route,
      });
    },
  },
};
</script>
<style lang="scss">
.display_place_type {
  font-size: 9px;
  margin-left: 5px;
}
.el-color-picker__trigger {
  border: 1px solid transparent !important;
}
</style>
