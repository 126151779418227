import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import i18n from '@/locales';

Echo.install = (Vue) => {
  const disabledTransports =
    process.env.VUE_APP_ENV === 'development' ? ['wss'] : [];
  const client = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    authEndpoint: process.env.VUE_APP_BROADCAST_AUTH,
    cluster: 'eu',
    httpHost: process.env.VUE_APP_BROADCAST_HOST,
    httpPath: '/app/pusher',
    wsHost: process.env.VUE_APP_BROADCAST_HOST,
    wsPort: 6001,
    wssPort: 6001,
    enabledTransports: ['ws', 'wss'],
    disabledTransports,
    disableStats: true,
    auth: {
      headers: {
        'Accept-Language': i18n.locale,
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    },
  });

  Vue.prototype.$ws = {
    setToken: (token) => {
      Vue.prototype.$ws.echo.options.client.config.auth.headers.Authorization = `Bearer ${
        token || localStorage.getItem('id_token')
      }`;
    },
    echo: new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      client,
    }),
  };
};

export default Echo;
