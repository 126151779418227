<template lang="html">
  <section class="external-data-item">
    <el-row :style="backgroundStyle">
      <el-row type="flex" align="bottom" :style="titleStyle">
        <el-col :span="17">
          <div v-if="!edit">{{ note.name }}</div>
          <div v-else>Edit Note</div>
        </el-col>
        <el-col :span="4">
          <cs-button
            class="notification-btn"
            :icon="icon_style"
            icon-type="material"
            @click="notificationOn(index)"
          >
          </cs-button>
        </el-col>
        <el-col :span="3">
          <slot name="activator">
            <edit-toggle
              class="toogle"
              v-model="edit"
              :name="tooltip"
              :small="true"
            />
          </slot>
        </el-col>
      </el-row>
      <el-row :style="backgroundStyle" class="edit-external-element">
        <div v-if="!edit">
          <el-col style="margin-bottom: 10px">
            <span>{{ note.value | empty('N/A') }}</span>
          </el-col>
        </div>
        <div v-else class="edit-external-element" :edit="edit">
          <el-col>
            <span style="margin-right: 20px">{{ $t('commons.name') }}</span>
            <el-input
              clearable
              v-model="note.name"
              @change="hasChange"
              :style="inputStyle(note.name)"
            ></el-input>
          </el-col>
          <el-col>
            <el-input
              class="el-input-textarea"
              type="textarea"
              @change="hasChange"
              autosize
              v-model="note.value"
              :style="inputStyle(note.value)"
            ></el-input>
          </el-col>
          <el-col style="padding: 0px">
            <cs-button
              icon="delete"
              icon-type="material"
              @click="
                deleteItem(index);
                hasChange;
              "
            >
            </cs-button>
            <cs-button icon="save" icon-type="material" @click="save">
            </cs-button>
          </el-col>
        </div>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import EditToggle from '@/components/EditToggle';
import { mapActions } from 'vuex';
export default {
  components: {
    EditToggle,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => ({
        name: '',
        value: '',
      }),
    },
    activatorName: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    edit: false,
  }),
  computed: {
    tooltip() {
      return this.activatorName || this.title;
    },
    backgroundStyle() {
      return { backgroundColor: this.edit ? '#32363B' : '#FFF', width: '95%' };
    },
    note() {
      return this.data.external_data[this.index];
    },
    titleStyle() {
      return {
        color: this.edit ? '#50E3C2' : 'black',
        padding: '10px',
        borderBottom: '1px solid #ebeef5',
        fontSize: '15px',
      };
    },
    icon_style() {
      return this.data.notification - 1 == this.index
        ? 'notifications_active'
        : 'notifications_none';
    },
  },
  methods: {
    ...mapActions(['queueAction', 'processActionQueue', 'cancelActionQueue']),
    close() {
      this.edit = false;
    },
    inputStyle(data) {
      return {
        borderBottom:
          !data || !data.length ? '1px solid #ebeef5 !important' : '',
      };
    },
    save() {
      this.$emit('save');
      this.close();
    },
    deleteItem(idx) {
      this.$emit('delete', idx);
      this.close();
    },
    hasChange() {
      this.$emit('change');
    },
    notificationOn(idx) {
      this.$emit('notif', idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-btn {
  height: auto !important;
  line-height: inherit !important;
  min-width: auto !important;
  padding: 0px;
  background-color: transparent;
  color: #50e3c2;
}

.toogle {
  height: auto !important;
  line-height: inherit !important;
  min-width: auto !important;
  padding: 0px;
}
</style>

<style lang="scss">
.edit-external-element {
  .el-col {
    display: flex;
    align-items: center;
    padding: 15px 10px 0px 10px;
    span {
      color: #6d737f;
    }
  }
  .el-input__inner {
    text-align: left;
    background-color: transparent;
    border: none;
    color: white;
    padding: 0 !important;
    height: auto !important;
    font-size: 12px;
  }
  .el-textarea__inner {
    text-align: left;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 12px;
    padding: 0px;
    resize: none;
  }
  .el-input-textarea {
    margin-bottom: 10px;
  }
  .btn {
    background-color: transparent;
  }
  i {
    color: #50e3c2;
    &:hover {
      cursor: pointer;
    }
  }
}
.external-data-item {
  display: inline-flex;
  width: 50%;
  margin-bottom: 10px;
}
.notes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
