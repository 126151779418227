<template lang="html">
  <section
    class="sidebar-head-bg primary-color-dark"
    :style="{ backgroundColor: color }"
    @click="$emit('click')"
  >
    <el-row class="activity-head" type="flex">
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="2">
          <activity-icon
            :text="activity.icon.options.text"
            :color="color"
            :svg="activity.icon.options.svg"
          >
          </activity-icon>
        </el-col>
        <el-col :span="11" :offset="2">
          <span v-if="activity.external_id" style="font-size: 14px">
            {{ activity.external_id }}
          </span>
        </el-col>

        <el-col :span="3" :offset="2">
          <span v-if="route" class="activity-head-order"
            >{{ activity.order }} / {{ route.deliveries.length }}</span
          >
        </el-col>
        <el-col :span="2">
          <el-tooltip :content="$t('commons.externalData')">
            <router-link
              :to="{ name: 'deliveryDetail', params: { id: activity.id } }"
            >
              <cs-button
                icon="info_outline"
                outline
                style="width: 100%"
                icon-type="material"
              ></cs-button>
            </router-link>
          </el-tooltip>
        </el-col>
        <el-col :span="2">
          <cs-button
            :style="{ width: '100%' }"
            outline
            icon="close"
            icon-type="material"
            @click.stop="close"
          />
        </el-col>
      </el-row>

      <el-row @click="$emit('click')">
        <el-col :span="18">
          <span class="activity-head-state">{{
            $t(`commons.states.delivery.${activity.state}`) | upperFirst
          }}</span>
        </el-col>
      </el-row>

      <el-row v-if="route" type="flex" align="middle">
        <el-col :span="3">
          <el-tooltip :content="$tc('components.selection.remove', 1, 1)">
            <cs-button
              circle
              class="activity-head-linkBtn"
              icon="link"
              icon-type="material"
              @click.stop="$emit('unplan')"
            ></cs-button>
          </el-tooltip>
        </el-col>
        <el-col :span="13">
          <a
            class="activity-head-link"
            :style="{ textDecoration: 'solid' }"
            @click.stop="goToRoute"
          >
            {{ route.name }}
          </a>
        </el-col>
        <el-col :span="8">
          <strong>{{ activity.planned_start | format('dd MMM HH:mm') }}</strong>
        </el-col>
      </el-row>

      <el-row v-else>
        <el-col :span="24">
          <span>
            Cette activitée
            <span v-show="activity.planned_start">
              prévu le
              <strong>{{ activity.planned_start | format('Do MMM') }}</strong> à
              <strong
                >{{ activity.planned_start | format('h:mm') }}
              </strong> </span
            >n'est pas liée a une route.
          </span>
        </el-col>
      </el-row>

      <div class="activity-head-plusBtn">
        <i v-if="!showDetails" class="el-icon-plus"></i>
        <i v-else class="el-icon-minus"></i>
      </div>
    </el-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import ActivityIcon from '@/components/ActivityIcon';

export default {
  components: {
    ActivityIcon,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    unplanning: false,
  }),
  computed: {
    ...mapGetters(['routes']),
    route() {
      return this.routes.items[this.activity.route_id];
    },
    color() {
      return get(this.route, 'color');
    },
  },
  methods: {
    ...mapActions([
      'setSelectedRoutes',
      'removeActivitiesFromSelection',
      'setSelectionSidebar',
    ]),
    goToRoute() {
      this.setSelectedRoutes({
        data: [this.route],
      });
      this.setSelectionSidebar(true);
    },
    close() {
      this.removeActivitiesFromSelection({
        data: [this.activity],
      });
    },
  },
};
</script>
