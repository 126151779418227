<template>
  <base-layout :load="loading">
    <h3 slot="title">{{ $t('commons.user.single') }} : {{ user.name }}</h3>
    <user-form v-if="user" ref="form" :user="user" :saving="saving"></user-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexUser' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button slot="footer" @click="changePassword">
      {{ $t('sentence.modifyPassword') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveUser"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { upperFirst } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import UserForm from './forms/UserForm';
import { passwordPromptRule } from '@/validation';

export default {
  name: 'UserEdit',
  components: { UserForm },
  data: () => ({
    loading: false,
    saving: false,
  }),
  computed: {
    ...mapGetters(['users']),
    user() {
      return this.users.items[this.$route.params.id];
    },
  },
  created() {
    this.loading = true;
    this.get({ type: 'user', id: this.$route.params.id })
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['get', 'patch']),
    async changePassword() {
      try {
        const { value } = await this.$prompt(
          this.$t('commons.password').toUpperCase(),
          upperFirst(this.$t('sentence.modifyPassword')),
          {
            confirmButtonText: this.$t('commons.agree'),
            cancelButtonText: this.$t('commons.cancel'),
            inputValidator: passwordPromptRule,
            inputType: 'password',
          }
        );
        this.saving = true;
        await this.patch({
          type: 'user',
          id: this.user.id,
          data: { password: value, ...this.user },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.saving = false;
    },
    saveUser() {
      this.$refs.form.validate().then(() => {
        this.saving = true;
        this.patch({ type: 'user', id: this.user.id, data: this.user })
          .then(() => {
            this.saving = false;
            this.$router.push({ name: 'indexUser' });
          })
          .catch(() => {
            this.saving = false;
          });
      });
    },
  },
};
</script>
