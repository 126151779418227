<template>
  <base-layout :load="!intake.state">
    <h3 slot="title">
      {{ $t('commons.collection.single') }} {{ intake.external_id }}
    </h3>

    <h4 v-if="intake" slot="subtitle" class="upperFirst">
      {{ $t('commons.state') }}:
      <em>{{ $t('commons.states.intake.' + intake.state) }}</em> |
      <span v-if="deliveries"
        >{{ $t('commons.activity.plural') | upperFirst }}:
        <em>{{ deliveries.length }}</em> |</span
      >
      <span v-if="items"
        >{{ $t('commons.item.plural') | upperFirst }}:
        <em>{{ items.length }}</em></span
      >
    </h4>

    <div v-if="deliveries" class="intake-container">
      <div
        v-for="delivery in deliveries"
        :key="delivery.id"
        class="card intake-card"
        :class="'card-' + delivery.state"
      >
        <div class="card-body intake-card-body">
          <p class="card-title">
            <el-popover
              placement="top"
              :title="
                $t('commons.states.delivery.' + delivery.state) | upperFirst
              "
              width="300"
              trigger="click"
            >
              <button
                slot="reference"
                class="fa fa-info-circle btn btn-xs"
              ></button>
              <span>
                <ul class="list-group">
                  <li v-if="delivery.destination_name" class="list-group-item">
                    {{ $t('pages.intake.EditIntake.destination') }}:
                    {{ delivery.destination_name }}
                  </li>
                  <li
                    v-for="(consist, idx) in delivery.consist_of"
                    :key="idx"
                    class="list-group-item"
                  >
                    {{ consist.type }}: {{ consist.value }}
                  </li>
                </ul>
              </span>
            </el-popover>
            {{ delivery.external_id | truncate(20) }}
            <span class="badge badge-info pull-right">
              {{ $t('commons.item.plural') }} : {{ delivery.item_count }}
            </span>
          </p>
        </div>
        <div class="intake-item-list">
          <item-status
            v-for="i in delivery.items"
            :key="i.id"
            :item="i"
            :available-transitions="['receive', 'not_receive']"
          />
        </div>
      </div>
    </div>

    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveIntake"
    >
      {{ $t('commons.save') }}
    </cs-button>

    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      @click="switchAll('receive')"
    >
      {{ $t('sentence.receiveAll') }}
    </cs-button>

    <barcode-scanner
      slot="footer"
      :items="items"
      :transition-success="transitionSuccess"
      :transition-error="transitionError"
    />

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexIntake' })"
    >
      {{ $t('commons.return') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, forEach, map, sortBy, get } from 'lodash';
import BarcodeScanner from './../../components/BarcodeScanner';
import ItemStatus from './../../components/ItemStatus';

export default {
  components: {
    BarcodeScanner,
    ItemStatus,
  },
  data: () => ({
    loading: false,
    saving: false,
    transitionSuccess: 'receive',
    transitionError: 'damage',
  }),
  computed: {
    ...mapGetters(['intakes']),
    intake() {
      return get(this.intakes.items, this.$route.params.id, {});
    },
    items() {
      let items = [];

      if (!isEmpty(this.intake)) {
        forEach(this.intake.deliveries, (delivery) => {
          map(delivery.items, (i) => {
            this.$set(i, 'transition', null);
          });

          items = items.concat(delivery.items);
        });
      }

      return items;
    },
    deliveries() {
      return sortBy(this.intake.deliveries, 'external_id');
    },
  },
  created() {
    this.loading = true;
    const getIntake = this.get({ type: 'intake', id: this.$route.params.id });
    Promise.all([getIntake]).then(
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  },
  methods: {
    ...mapActions(['get', 'patch']),
    saveIntake() {
      this.saving = true;
      this.patch({
        type: 'intake',
        id: this.intake.id,
        data: this.intake,
      }).then(
        () => {
          this.loading = false;
          this.saving = false;
        },
        () => {
          this.loading = false;
          this.saving = false;
        }
      );
    },
    switchAll(transition) {
      this.items.forEach((i) => {
        i.transition = transition;
      });
    },
  },
};
</script>

<style lang="scss">
.intake-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .intake-item-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 25vh;
  }
}
.intake-card {
  margin: 2px;
  width: 150px;
  display: block;
  &.hightlight {
    background: $brand-success;
    border-color: darken($brand-success, 10%) darken($brand-success, 10%)
      darken($brand-success, 10%);
  }

  &.selected {
    background: $brand-info;
    border-color: darken($brand-info, 10%) darken($brand-info, 10%)
      darken($brand-info, 10%);
  }

  &.card-incoming {
    border-left: 4px solid $incoming;
  }
  &.card-not_received {
    border-left: 4px solid $not_received;
  }
  &.card-reception_started {
    border-left: 4px solid $reception_started;
  }
  &.card-received {
    border-left: 4px solid $received;
  }
  &.card-awaiting {
    border-left: 4px solid $awaiting;
  }
  &.card-ready {
    border-left: 4px solid $ready;
  }
  &.card-preparing {
    border-left: 4px solid $preparing;
  }
  &.card-prepared {
    border-left: 4px solid $prepared;
  }
  &.card-loading {
    border-left: 4px solid $loading;
  }
  &.card-loaded {
    border-left: 4px solid $loaded;
  }
  &.card-en_route {
    border-left: 4px solid $en_route;
  }
  &.card-onsite {
    border-left: 4px solid $on_site;
  }
  &.card-lost {
    border-left: 4px solid $lost;
  }
  &.card-done {
    border-left: 4px solid $done;
  }
  &.card-failed {
    border-left: 4px solid $failed;
  }
  &.card-refused {
    border-left: 4px solid $refused;
  }
  &.card-returned {
    border-left: 4px solid $returned;
  }
  &.card-cleared {
    border-left: 4px solid $cleared;
  }
  &.card-finished {
    border-left: 4px solid $finished;
  }
}
.intake-card-body {
  padding: 10px;
}
.container {
  &.card-incoming {
    .heading {
      background-color: $incoming;
    }
  }
  &.card-not_received {
    .heading {
      background-color: $not_received;
    }
  }
  &.card-reception_started {
    .heading {
      background-color: $reception_started;
    }
  }
  &.card-received {
    .heading {
      background-color: $received;
    }
  }
  &.card-awaiting {
    .heading {
      background-color: $awaiting;
    }
  }
  &.card-ready {
    .heading {
      background-color: $ready;
    }
  }
  &.card-preparing {
    .heading {
      background-color: $preparing;
    }
  }
  &.card-prepared {
    .heading {
      background-color: $prepared;
    }
  }
  &.card-loading {
    .heading {
      background-color: $loading;
    }
  }
  &.card-loaded {
    .heading {
      background-color: $loaded;
    }
  }
  &.card-en_route {
    .heading {
      background-color: $en_route;
    }
  }
  &.card-on_site {
    .heading {
      background-color: $on_site;
    }
  }
  &.card-lost {
    .heading {
      background-color: $lost;
    }
  }
  &.card-done {
    .heading {
      background-color: $done;
    }
  }
  &.card-failed {
    .heading {
      background-color: $failed;
    }
  }
  &.card-refused {
    .heading {
      background-color: $refused;
    }
  }
  &.card-finished {
    .heading {
      background-color: $finished;
    }
  }
  &.card-refused {
    .heading {
      background-color: $refused;
    }
  }
  &.card-cleared {
    .heading {
      background-color: $cleared;
    }
  }
}
</style>
