<template lang="html">
  <l-map v-if="position" :zoom="zoomData" :center="center" :style="customStyle">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker
      :lat-lng="position"
      :icon="markerIcon"
      :draggable="draggable"
      @l-dragend="dragEvent"
    />
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { getIconSelected } from './../utils';

export default {
  components: { LMap, LTileLayer, LMarker },
  props: {
    position: {
      type: Object,
      default: undefined,
    },
    attribution: {
      type: String,
      default:
        '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    },
    url: {
      type: String,
      default: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER}`,
    },
    zoom: {
      type: Number,
      default: 16,
    },
    customStyle: {
      type: String,
      default: 'height: 256px; width: 100%;',
    },
    icon: {
      type: Object,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    center: null,
    zoomData: null,
  }),
  computed: {
    markerIcon() {
      return this.icon ? this.icon : getIconSelected();
    },
  },
  watch: {
    position: {
      deep: true,
      handler() {
        this.center = this.position.clone();
        this.zoomData = this.zoom;
      },
    },
  },
  created() {
    if (this.position) {
      this.center = this.position.clone();
    }
    this.zoomData = this.zoom;
  },
  methods: {
    dragEvent(e) {
      // eslint-disable-next-line no-underscore-dangle
      this.$emit('dragged', e.target._latlng);
    },
  },
};
</script>
