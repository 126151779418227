<template>
  <base-layout :load="loading">
    <slot slot="title" name="title"></slot>
    <el-col
      :xs="{ span: 24, offset: 0 }"
      :sm="{ span: 18, offset: 3 }"
      :md="{ span: 18, offset: 3 }"
      :lg="{ span: 16, offset: 4 }"
    >
      <slot></slot>
    </el-col>

    <slot name="footer" slot="footer"></slot>
  </base-layout>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
  },
};
</script>
