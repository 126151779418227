<template lang="html">
  <sidebar-layout>
    <timeoff-sidebar-item class="sidebar-container" :timeoff="entity" />

    <timeoff-footer slot="footer" :index="entity.index" :route="route" />
  </sidebar-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { first, isEmpty } from 'lodash';
import SidebarLayout from '../../SidebarLayout';
import TimeoffSidebarItem from './TimeoffSidebarItem';
import TimeoffFooter from './TimeoffFooter';

export default {
  components: {
    TimeoffSidebarItem,
    TimeoffFooter,
    SidebarLayout,
  },
  computed: {
    ...mapGetters(['selection']),
    entity() {
      return first(this.selection.data);
    },
    route() {
      return this.$store.getters.routes.items[this.entity.routeId];
    },
  },
  watch: {
    entity(v) {
      if (isEmpty(v)) {
        this.setSelectionSidebar(false);
      }
    },
  },
  methods: {
    ...mapActions(['setSelectionSidebar']),
  },
};
</script>
