<template lang="html">
  <base-layout>
    <portal to="search">
      <el-input
        v-model="query"
        prefix-icon="el-icon-search"
        type="search"
        :placeholder="$t('commons.search')"
      ></el-input>
    </portal>
    <ais-instant-search
      v-if="searchClient"
      :index-name="indexName"
      :search-client="searchClient"
      :class-names="{
        'ais-InstantSearch': 'search-page',
      }"
    >
      <ais-configure
        :query="query"
        :filters="filters"
        :hits-per-page.camel="hitsPerPage"
      />

      <div class="result-row">
        <div class="search-controls">
          <label class="label" for="datepicker">trier par date</label>
          <el-date-picker
            v-model="range"
            name="datepicker"
            type="daterange"
            align="right"
          />
          <el-switch
            class="switch"
            v-model="dateSwitch"
            :active-text="$t('commons.datePlanned') | upperFirst"
            :inactive-text="$t('commons.dateSla') | upperFirst"
          />

          <label class="label" for="statesort">trier par état</label>
          <ais-refinement-list name="statesort" attribute="state">
            <div slot-scope="{ items, refine }">
              <div
                v-for="item in items"
                :key="item.value"
                class="item-refinement-list"
              >
                <el-checkbox
                  :value="item.isRefined"
                  @change="refine(item.value)"
                >
                  <activity-state type="delivery" :value="item.label">
                    {{ $t(`commons.states.delivery.${item.value}`) }} ({{
                      item.count.toLocaleString()
                    }})
                  </activity-state>
                </el-checkbox>
              </div>
            </div>
          </ais-refinement-list>
        </div>

        <section class="result-left">
          <ais-state-results class="table-search-results">
            <template slot-scope="{ hits }">
              <el-table fit :data="hits" @row-click="focus">
                <el-table-column :label="$t('commons.state')" width="120">
                  <template slot-scope="{ row }">
                    <activity-state type="delivery" :value="row" />
                  </template>
                </el-table-column>
                <el-table-column type="expand">
                  <template slot-scope="{ row }">
                    <p>
                      <label class="label" for="recipient">
                        {{ $t('commons.recipient') }}:
                      </label>
                      {{ row.receiver_name | empty('N/A') }}
                    </p>
                    <p>
                      <item-status
                        v-for="item in row.items"
                        :key="item.id"
                        :item="item"
                      />
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('commons.address.single')"
                  width="400"
                >
                  <template slot-scope="{ row }">
                    <activity-address
                      :address="row.address_geocode || row.address_origin"
                      :full="false"
                      no-label
                    />
                  </template>
                </el-table-column>

                <el-table-column
                  :label="$t('commons.action.single')"
                  min-width="200"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div class="btn-group btn-column">
                      <cs-button
                        v-if="row.objectID"
                        @click="
                          $router.push({
                            name: 'deliveryDetail',
                            params: { id: row.objectID },
                          })
                        "
                      >
                        {{ $t('sentence.goToActivity') }}
                      </cs-button>
                      <cs-button
                        v-if="row.route_id && $can('route', 'read')"
                        @click="
                          $router.push({
                            name: 'routeDetail',
                            params: { id: row.route_id },
                          })
                        "
                      >
                        {{ $t('sentence.goToRoute') }}
                      </cs-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <ais-pagination>
                <span slot-scope="{ currentRefinement, nbHits, refine }">
                  <el-pagination
                    class="pagination"
                    background
                    layout="prev, pager, next, sizes"
                    :total="nbHits"
                    :current-page="currentRefinement + 1"
                    :page-size.sync="hitsPerPage"
                    @current-change="(p) => refine(p - 1)"
                  />
                </span>
              </ais-pagination>
            </template>
          </ais-state-results>
        </section>

        <section class="result-right">
          <ais-state-results>
            <template slot-scope="{ hits }">
              <search-map
                ref="map"
                latitude-key="_geoloc.lat"
                longitude-key="_geoloc.lng"
                custom-style="height: 50vh"
                :zoom="2"
                :hits="hits"
              />
            </template>
          </ais-state-results>
          <ais-stats />
        </section>
      </div>
    </ais-instant-search>
    <el-alert
      v-else
      type="warning"
      show-icon
      :title="$t('error.search.unavailable.title') | upperFirst"
      :description="$t('error.search.unavailable.description') | upperFirst"
      :closable="false"
    >
    </el-alert>
  </base-layout>
</template>

<script>
import {
  AisInstantSearch,
  AisConfigure,
  AisStats,
  AisStateResults,
  AisRefinementList,
  AisPagination,
} from 'vue-instantsearch';
import { endOfDay } from 'date-fns';
import { mapGetters } from 'vuex';
import { Portal } from 'portal-vue';
import algoliasearch from 'algoliasearch/lite';
import ActivityState from '@/components/State';
import ActivityAddress from '@/components/Address';
import ItemStatus from '@/components/ItemStatus';
import SearchMap from '@/components/search/SearchMap';

export default {
  components: {
    SearchMap,
    ActivityAddress,
    ActivityState,
    AisStats,
    AisInstantSearch,
    AisConfigure,
    AisStateResults,
    AisRefinementList,
    AisPagination,
    Portal,
    ItemStatus,
  },
  data: () => ({
    searchClient: undefined,
    indexName: 'activities',
    dateSwitch: false,
    query: null,
    hitsPerPage: 10,
    range: [],
  }),
  mounted() {
    try {
      this.searchClient = algoliasearch(
        process.env.VUE_APP_ALGOLIA_ID,
        process.env.VUE_APP_ALGOLIA_KEY,
        { _useRequestCache: true }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e.message);
    }
  },
  computed: {
    ...mapGetters(['organization']),
    filters() {
      let filters = `organization_id:${this.organization.id}`;
      if (this.range && this.range.length > 0) {
        const epochStart = Math.floor(this.range[0].getTime() / 1000);
        const epochEnd = Math.floor(endOfDay(this.range[1]).getTime() / 1000);
        if (!this.dateSwitch) {
          filters += ` AND min_sla_timestamp < ${epochEnd} AND max_sla_timestamp > ${epochStart}`;
        } else {
          filters += ` AND planned_start_timestamp < ${epochEnd} AND planned_end_timestamp > ${epochStart}`;
        }
      }
      return filters;
    },
  },
  methods: {
    focus(item) {
      this.$refs.map.setCenter([item._geoloc.lat, item._geoloc.lng], 16);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-page {
  .table-search-results {
    display: flex;
    flex-direction: column;
  }
  .ais-Hits {
    width: 50%;
  }
  .search-controls {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    > label {
      margin-top: 15px;
    }
    .switch {
      margin: 10px 0;
    }
  }
  .item-refinement-list {
    margin-bottom: 3px;
  }
  .result-row {
    display: flex;
    justify-content: space-between;
  }
  .result-left {
    margin-right: 10px;
  }
  .result-right {
    width: 500px;
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
  .btn-group {
    align-items: flex-end;
  }
}
</style>
