const selected = {
  planned:
    '<g><path d="M15.035,4.5C9.244,4.507,4.533,9.241,4.533,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.027,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.496,5.604,17.855,4.5,15.035,4.5L15.035,4.5z"/><path fill="#FFFFFF" d="M14.99,28.544l-4.672-4.648c-3.344-3.377-4.83-6.111-4.824-8.868c0-5.26,4.264-9.544,9.504-9.548c2.547,0,4.939,0.998,6.736,2.81c1.78,1.801,2.76,4.192,2.76,6.733c-0.002,2.741-1.501,5.475-4.861,8.865L14.99,28.544z M14.998,6.48c-4.688,0.004-8.504,3.839-8.504,8.549c-0.005,2.473,1.393,4.99,4.531,8.161l3.962,3.941l3.937-3.948c3.159-3.187,4.568-5.704,4.57-8.16c0-2.276-0.877-4.417-2.471-6.029C19.417,7.374,17.277,6.48,14.998,6.48z"/></g>',
  plannedWithWarnings:
    '<g><path d="M22.446,7.606C20.465,5.604,17.823,4.5,15.004,4.5c-0.637,0.001-1.257,0.068-1.862,0.178c0.102,0.312,0.185,0.631,0.247,0.958c0.537-0.096,1.086-0.155,1.649-0.155c2.549,0,4.941,0.998,6.736,2.81c1.781,1.802,2.762,4.193,2.762,6.733c-0.004,2.742-1.504,5.475-4.861,8.865l-4.644,4.656l-4.672-4.648c-3.343-3.377-4.829-6.111-4.823-8.868c0-0.556,0.058-1.098,0.149-1.629c-0.343-0.062-0.677-0.151-1.003-0.258c-0.113,0.622-0.182,1.261-0.182,1.915c-0.006,3.051,1.675,6.113,5.457,9.931L14.993,30l5.006-5.02c3.803-3.838,5.499-6.9,5.501-9.93C25.503,12.239,24.416,9.597,22.446,7.606z"/><path d="M6.751,13.5c-0.023,0-0.046-0.006-0.069-0.006c-0.091,0.499-0.146,1.011-0.146,1.536c-0.006,2.473,1.392,4.99,4.531,8.161l3.962,3.941l3.936-3.948c3.157-3.187,4.567-5.704,4.571-8.161c0-2.274-0.878-4.416-2.473-6.028c-1.605-1.621-3.745-2.514-6.025-2.514c-0.529,0-1.046,0.056-1.548,0.15c0,0.041,0.011,0.079,0.011,0.12C13.501,10.473,10.474,13.5,6.751,13.5z"/><path fill="#FFFFFF" d="M10.359,23.896l4.672,4.648l4.644-4.656c3.357-3.39,4.857-6.123,4.861-8.865c0-2.54-0.98-4.931-2.762-6.733c-1.795-1.812-4.188-2.81-6.736-2.81c-0.563,0-1.112,0.06-1.649,0.155c0.062,0.323,0.094,0.655,0.102,0.995c0.502-0.094,1.019-0.149,1.548-0.15c2.28,0,4.42,0.893,6.025,2.514c1.595,1.613,2.473,3.754,2.473,6.028c-0.004,2.457-1.414,4.974-4.571,8.161l-3.936,3.948l-3.962-3.941c-3.14-3.171-4.537-5.688-4.531-8.161c0-0.525,0.055-1.037,0.146-1.536c-0.34-0.004-0.673-0.035-0.996-0.094c-0.092,0.531-0.149,1.073-0.149,1.629C5.53,17.785,7.017,20.52,10.359,23.896z"/><path d="M7.001,12.5c-3.032,0-5.5-2.468-5.5-5.5s2.468-5.5,5.5-5.5s5.5,2.468,5.5,5.5S10.033,12.5,7.001,12.5z"/><rect x="6.563" y="8.906" fill="#FFFFFF" width="1" height="1"/><rect x="6.563" y="4.271" fill="#FFFFFF" width="1" height="3.656"/></g>',
  unplanned:
    '<g><path d="M15.069,7.5c2.008,0.001,3.895,0.788,5.307,2.216s2.189,3.321,2.188,5.332c-0.002,1.352-0.484,3.633-4.627,7.814l-2.887,2.896l-2.898-2.883c-4.117-4.157-4.592-6.45-4.588-7.819C7.563,10.895,10.931,7.505,15.069,7.5 M15.065,4.5C9.274,4.507,4.563,9.241,4.563,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.058,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.526,5.604,17.886,4.5,15.065,4.5L15.065,4.5z"/><path fill="#FFFFFF" d="M15.02,28.544l-4.672-4.648c-3.343-3.377-4.83-6.111-4.823-8.868c0-5.26,4.263-9.544,9.503-9.548c2.548,0,4.94,0.998,6.736,2.81c1.781,1.801,2.761,4.192,2.761,6.733c-0.002,2.741-1.501,5.475-4.862,8.865L15.02,28.544z M15.028,6.48c-4.688,0.004-8.504,3.839-8.504,8.549c-0.005,2.473,1.392,4.99,4.531,8.161l3.962,3.941l3.936-3.948c3.16-3.187,4.569-5.704,4.571-8.16c0-2.276-0.878-4.417-2.471-6.029C19.447,7.374,17.307,6.48,15.028,6.48z"/><path fill="#FFFFFF" d="M15.069,7.5c2.008,0.001,3.895,0.788,5.307,2.216s2.189,3.321,2.188,5.332c-0.002,1.352-0.484,3.633-4.627,7.814l-2.887,2.896l-2.898-2.883c-4.117-4.157-4.592-6.45-4.588-7.819C7.563,10.895,10.931,7.505,15.069,7.5"/></g>',
  rdv: {
    planned:
      '<g><path d="M15.087,4.5C9.296,4.507,4.585,9.241,4.585,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.079,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.548,5.604,17.907,4.5,15.087,4.5L15.087,4.5z"/><path fill="#FFFFFF" d="M15.042,28.544l-4.672-4.648c-3.343-3.377-4.83-6.111-4.823-8.868c0-5.26,4.263-9.544,9.503-9.548c2.547,0,4.94,0.998,6.736,2.81c1.78,1.801,2.76,4.192,2.76,6.733c-0.002,2.741-1.501,5.475-4.862,8.865L15.042,28.544z M15.05,6.48c-4.689,0.004-8.504,3.839-8.504,8.549c-0.005,2.473,1.392,4.99,4.531,8.161l3.961,3.941l3.937-3.948c3.159-3.187,4.569-5.704,4.571-8.16c0-2.276-0.877-4.417-2.471-6.029C19.468,7.374,17.328,6.48,15.05,6.48z"/><path d="M30.054,15.002l-3-0.004c0.002-3.192-1.236-6.199-3.49-8.467C21.298,4.254,18.276,3,15.054,3V0c4.027,0,7.805,1.568,10.637,4.416C28.509,7.251,30.058,11.01,30.054,15.002z"/></g>',
    plannedWithWarnings:
      '<g><path d="M6.75,13.5c-0.024,0-0.046-0.006-0.07-0.006c-0.091,0.499-0.145,1.011-0.145,1.536c-0.005,2.473,1.392,4.99,4.531,8.161l3.961,3.941l3.937-3.948c3.157-3.187,4.567-5.704,4.571-8.161c0-2.274-0.878-4.416-2.473-6.028c-1.605-1.621-3.745-2.514-6.025-2.514c-0.529,0-1.045,0.056-1.548,0.15C13.49,6.671,13.5,6.709,13.5,6.75C13.5,10.473,10.473,13.5,6.75,13.5z"/><path d="M22.446,7.606C20.464,5.604,17.823,4.5,15.003,4.5c-0.637,0.001-1.257,0.068-1.862,0.178c0.102,0.312,0.185,0.631,0.247,0.958c0.537-0.095,1.086-0.154,1.649-0.155c2.55,0,4.942,0.998,6.737,2.81c1.781,1.802,2.762,4.193,2.762,6.733c-0.004,2.742-1.503,5.475-4.862,8.865l-4.643,4.656l-4.672-4.648c-3.343-3.377-4.83-6.111-4.823-8.868c0-0.556,0.058-1.098,0.149-1.629c-0.342-0.062-0.676-0.151-1.002-0.258C4.569,13.764,4.5,14.402,4.5,15.057c-0.006,3.051,1.675,6.113,5.457,9.931L14.993,30l5.006-5.02c3.803-3.838,5.499-6.9,5.501-9.93C25.502,12.239,24.416,9.597,22.446,7.606z"/><path fill="#FFFFFF" d="M10.359,23.896l4.672,4.648l4.643-4.656c3.358-3.39,4.858-6.123,4.862-8.865c0-2.54-0.981-4.931-2.762-6.733c-1.795-1.812-4.187-2.81-6.737-2.81c-0.563,0-1.112,0.06-1.649,0.155c0.062,0.323,0.094,0.656,0.102,0.995c0.503-0.094,1.019-0.149,1.548-0.15c2.28,0,4.42,0.893,6.025,2.514c1.594,1.613,2.473,3.754,2.473,6.028c-0.004,2.457-1.414,4.974-4.571,8.161l-3.937,3.948l-3.961-3.941c-3.139-3.171-4.537-5.688-4.531-8.161c0-0.525,0.054-1.037,0.145-1.536c-0.34-0.004-0.672-0.035-0.996-0.094c-0.091,0.531-0.149,1.073-0.149,1.629C5.529,17.785,7.016,20.52,10.359,23.896z"/><path d="M30,15.002l-3-0.004c0.002-3.192-1.236-6.199-3.49-8.467C21.245,4.254,18.223,3,15,3V0c4.027,0,7.805,1.568,10.637,4.416C28.456,7.251,30.004,11.01,30,15.002z"/><path d="M7,12.5c-3.032,0-5.5-2.468-5.5-5.5S3.968,1.5,7,1.5s5.5,2.468,5.5,5.5S10.033,12.5,7,12.5z"/><rect x="6.563" y="8.906" fill="#FFFFFF" width="1" height="1"/><rect x="6.563" y="4.271" fill="#FFFFFF" width="1" height="3.656"/></g>',
    unplanned:
      '<g><path fill="#FFFFFF" d="M15.09,7.484h-0.002c2.012,0,3.898,0.788,5.312,2.217c1.41,1.425,2.186,3.315,2.184,5.324c-0.004,2.173-1.322,4.473-4.277,7.455l-3.232,3.241l-3.246-3.231c-2.938-2.968-4.248-5.268-4.244-7.458C7.584,10.874,10.95,7.488,15.09,7.484"/><path d="M15.086,4.5C9.295,4.507,4.584,9.241,4.584,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.079,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.547,5.604,17.907,4.5,15.086,4.5L15.086,4.5z"/><path fill="#FFFFFF" d="M15.09,7.5c2.008,0.001,3.895,0.788,5.307,2.216s2.189,3.321,2.188,5.332c-0.002,1.352-0.484,3.633-4.627,7.814l-2.887,2.896l-2.898-2.883c-4.117-4.157-4.592-6.45-4.588-7.819C7.584,10.895,10.952,7.505,15.09,7.5"/><path d="M30.053,15.002l-3-0.004c0.002-3.192-1.236-6.199-3.49-8.467C21.297,4.254,18.276,3,15.053,3V0c4.027,0,7.805,1.568,10.637,4.416C28.508,7.251,30.057,11.01,30.053,15.002z"/></g>',
  },
  timeoff: {
    planned:
      '<g><polygon points="5.119,4.982 5.119,24.982 9.953,24.973 14.975,30 19.979,24.982 25.119,24.982 25.119,4.982"/><path fill="#FFFFFF" d="M23.119,7.108v16h-3.627h-0.415l-0.293,0.294l-3.796,3.807l-3.811-3.815l-0.294-0.294L10.468,23.1l-3.349,0.006V7.108H23.119 M24.119,6.108h-18v18L10.47,24.1l4.519,4.524l4.504-4.516h4.627V6.108L24.119,6.108z"/></g>',
    plannedWithWarnings:
      '<g><g><path d="M12.065,4.857C11.229,2.886,9.273,1.5,7,1.5C3.969,1.5,1.5,3.968,1.5,7c0,2.323,1.451,4.311,3.492,5.115C5.615,12.36,6.291,12.5,7,12.5c3.033,0,5.5-2.468,5.5-5.5C12.5,6.24,12.345,5.516,12.065,4.857z"/><g><path d="M10.278,23.1l0.416-0.001l0.294,0.294l3.811,3.815l3.796-3.807l0.293-0.294h0.415h3.627v-16h-9.438c-0.08,3.515-2.959,6.351-6.492,6.351c-0.023,0-0.047-0.003-0.07-0.003v9.651L10.278,23.1z"/><path d="M13.19,4.982c0.117,0.368,0.197,0.744,0.247,1.126H23.93v18h-4.627l-4.504,4.516L10.28,24.1L5.93,24.108V13.363c-0.338-0.057-0.671-0.144-1-0.256v11.875l4.834-0.009L14.785,30l5.004-5.018h5.141v-20H13.19z"/><path fill="#FFFFFF" d="M13.492,7.108h9.438v16h-3.627h-0.415l-0.293,0.294l-3.796,3.807l-3.811-3.815l-0.294-0.294L10.278,23.1L6.93,23.106v-9.651c-0.336-0.004-0.67-0.036-1-0.092v10.745L10.28,24.1l4.519,4.524l4.504-4.516h4.627v-18H13.438C13.475,6.389,13.5,6.672,13.5,6.958C13.5,7.009,13.493,7.058,13.492,7.108z"/></g></g><g><rect x="6.562" y="8.906" fill="#FFFFFF" width="1" height="1"/><rect x="6.562" y="4.271" fill="#FFFFFF" width="1" height="3.656"/></g></g>',
  },
};
const normal = {
  planned:
    '<path d="M15.087,4.5C9.295,4.507,4.584,9.241,4.584,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.079,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.548,5.604,17.907,4.5,15.087,4.5L15.087,4.5z"/>',
  plannedWithWarnings:
    '<g><path d="M22.446,7.606C20.464,5.604,17.823,4.5,15.003,4.5c-0.637,0.001-1.256,0.068-1.861,0.178c0.215,0.657,0.359,1.345,0.359,2.072c0,3.723-3.028,6.75-6.751,6.75c-0.725,0-1.412-0.145-2.068-0.358C4.568,13.764,4.5,14.402,4.5,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L14.993,30l5.006-5.02c3.803-3.838,5.498-6.9,5.5-9.93C25.503,12.239,24.415,9.597,22.446,7.606z"/><path d="M7,12.5c-3.031,0-5.5-2.468-5.5-5.5S3.969,1.5,7,1.5c3.033,0,5.5,2.468,5.5,5.5S10.033,12.5,7,12.5z"/><rect x="6.562" y="8.906" fill="#FFFFFF" width="1" height="1"/><rect x="6.562" y="4.271" fill="#FFFFFF" width="1" height="3.656"/></g>',
  unplanned:
    '<g><g><path d="M15.091,7.5c2.008,0.001,3.895,0.788,5.307,2.216s2.189,3.321,2.188,5.332c-0.002,1.352-0.484,3.633-4.627,7.814l-2.887,2.896l-2.898-2.883c-4.117-4.157-4.592-6.45-4.588-7.819C7.585,10.895,10.952,7.505,15.091,7.5 M15.087,4.5C9.296,4.507,4.585,9.241,4.585,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.079,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.548,5.604,17.907,4.5,15.087,4.5L15.087,4.5z"/></g><path fill="#FFFFFF" d="M15.091,7.5c2.008,0.001,3.895,0.788,5.307,2.216s2.189,3.321,2.188,5.332c-0.002,1.352-0.484,3.633-4.627,7.814l-2.887,2.896l-2.898-2.883c-4.117-4.157-4.592-6.45-4.588-7.819C7.585,10.895,10.952,7.505,15.091,7.5"/></g>',
  rdv: {
    planned:
      '<g><path d="M15.087,4.5C9.295,4.507,4.584,9.241,4.584,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.079,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.548,5.604,17.907,4.5,15.087,4.5L15.087,4.5z"/><path d="M30.054,15.002l-3-0.004c0.002-3.192-1.236-6.199-3.49-8.467C21.298,4.254,18.276,3,15.054,3V0c4.027,0,7.805,1.568,10.637,4.416C28.509,7.251,30.058,11.01,30.054,15.002z"/></g>',
    plannedWithWarnings:
      '<g><path d="M22.446,7.606C20.464,5.604,17.823,4.5,15.003,4.5c-0.637,0.001-1.256,0.068-1.861,0.178c0.215,0.657,0.359,1.345,0.359,2.072c0,3.723-3.028,6.75-6.751,6.75c-0.725,0-1.412-0.145-2.068-0.358C4.568,13.764,4.5,14.402,4.5,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L14.993,30l5.006-5.02c3.803-3.838,5.498-6.9,5.5-9.93C25.503,12.239,24.415,9.597,22.446,7.606z"/><path d="M30,15.002l-3-0.004c0.003-3.192-1.236-6.199-3.49-8.467C21.245,4.254,18.223,3,15.001,3V0c4.027,0,7.804,1.568,10.636,4.416C28.455,7.251,30.005,11.01,30,15.002z"/><path d="M7,12.5c-3.031,0-5.5-2.468-5.5-5.5S3.969,1.5,7,1.5c3.033,0,5.5,2.468,5.5,5.5S10.033,12.5,7,12.5z"/><rect x="6.562" y="8.906" fill="#FFFFFF" width="1" height="1"/><rect x="6.562" y="4.271" fill="#FFFFFF" width="1" height="3.656"/></g>',
    unplanned:
      '<g><path fill="#FFFFFF" d="M15.09,7.484h-0.002c2.012,0,3.898,0.788,5.312,2.217c1.41,1.425,2.186,3.315,2.184,5.324c-0.004,2.173-1.322,4.473-4.277,7.455l-3.232,3.241l-3.246-3.231c-2.938-2.968-4.248-5.268-4.244-7.458C7.584,10.874,10.95,7.488,15.09,7.484"/><path d="M15.086,4.5C9.295,4.507,4.584,9.241,4.584,15.057c-0.006,3.051,1.676,6.113,5.457,9.931L15.079,30l5.004-5.02c3.803-3.838,5.498-6.9,5.502-9.93c0.002-2.811-1.084-5.453-3.053-7.444C20.547,5.604,17.907,4.5,15.086,4.5L15.086,4.5z"/><path fill="#FFFFFF" d="M15.09,7.5c2.008,0.001,3.895,0.788,5.307,2.216s2.189,3.321,2.188,5.332c-0.002,1.352-0.484,3.633-4.627,7.814l-2.887,2.896l-2.898-2.883c-4.117-4.157-4.592-6.45-4.588-7.819C7.584,10.895,10.952,7.505,15.09,7.5"/><path d="M30.053,15.002l-3-0.004c0.002-3.192-1.236-6.199-3.49-8.467C21.297,4.254,18.276,3,15.053,3V0c4.027,0,7.805,1.568,10.637,4.416C28.508,7.251,30.057,11.01,30.053,15.002z"/></g>',
  },
  timeoff: {
    planned:
      '<g><g><path fill="#FFFFFF" d="M15.091,7.484h-0.002c2.012,0,3.898,0.788,5.312,2.217c1.41,1.425,2.186,3.315,2.184,5.324c-0.004,2.173-1.322,4.473-4.277,7.455l-3.232,3.241l-3.247-3.231c-2.938-2.968-4.248-5.268-4.244-7.458C7.584,10.874,10.949,7.488,15.091,7.484 M15.087,5.984c-4.964,0.004-9.003,4.064-9.003,9.048c-0.004,2.616,1.438,5.24,4.678,8.513l4.317,4.297l4.291-4.303c3.258-3.289,4.711-5.914,4.715-8.512c0.002-2.41-0.93-4.674-2.617-6.381C19.769,6.928,17.505,5.984,15.087,5.984L15.087,5.984z"/></g><polygon points="4.994,4.982 4.994,24.982 9.828,24.973 14.85,30 19.854,24.982 24.994,24.982 24.994,4.982"/></g>',
    plannedWithWarnings:
      '<g><g><path d="M13.129,4.857C13.365,5.529,13.5,6.248,13.5,7c0,3.584-2.916,6.5-6.5,6.5c-0.701,0-1.375-0.115-2.008-0.321v11.678l4.834-0.009l5.021,5.027l5.004-5.018h5.141v-20H13.129z"/><path d="M12.065,4.857C11.229,2.886,9.273,1.5,7,1.5C3.969,1.5,1.5,3.968,1.5,7c0,2.323,1.451,4.311,3.492,5.115C5.615,12.36,6.291,12.5,7,12.5c3.033,0,5.5-2.468,5.5-5.5C12.5,6.24,12.345,5.516,12.065,4.857z"/></g><g><rect x="6.562" y="8.906" fill="#FFFFFF" width="1" height="1"/><rect x="6.562" y="4.271" fill="#FFFFFF" width="1" height="3.656"/></g></g>',
  },
};

export { selected, normal };
