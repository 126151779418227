<template lang="html">
  <sidebar-layout>
    <div
      v-for="(entity, index) in entities"
      :key="index"
      class="sidebar-container"
    >
      <activity-sidebar-item
        :activity="entity"
        @unplan="unplanActivity(entity)"
      />
    </div>
  </sidebar-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { findIndex, isEmpty } from 'lodash';
import SidebarLayout from '../../SidebarLayout';
import ActivitySidebarItem from './ActivitySidebarItem';

export default {
  components: {
    ActivitySidebarItem,
    SidebarLayout,
  },
  computed: {
    ...mapGetters(['selection', 'routes']),
    entities() {
      return this.selection.data;
    },
  },
  watch: {
    entities(v) {
      if (isEmpty(v)) {
        this.setSelectionSidebar(false);
      }
    },
  },
  methods: {
    ...mapActions(['setSelectionSidebar', 'queueAction']),
    unplanActivity(a) {
      const r = this.routes.items[a.route_id];
      r.deliveries.splice(
        findIndex(r.deliveries, (d) => d.id === a.id),
        1
      );
      r.reorder().then(() => {
        a.route_id = null;
        a.resetIcon();
        this.queueAction({
          action: 'patch',
          type: 'delivery',
          data: a,
        });
        this.queueAction({
          action: 'patch',
          type: 'route',
          data: r,
        });
      });
    },
  },
};
</script>
