<template lang="html">
  <button
    v-shortkey="shortkeys"
    style="display: none"
    @shortkey="action"
  ></button>
</template>

<script>
export default {
  name: 'ShortKey',
  props: {
    shortkeys: {
      type: Array,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },
};
</script>
