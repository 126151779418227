var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"activity-content"},[_c('edit-details',{attrs:{"title":_vm.$t('commons.infos')}},[_c('div',[_c('ObjectDisplay',{attrs:{"width":'98px',"data":_vm.activity,"only":{
          receiver_name: 'commons.name',
          receiver_phone: 'commons.phone',
          text: 'commons.note',
          delivery_term: 'components.entities.activityDetails.moreInfos',
        },"sort":true}}),_c('external-data',{attrs:{"widthTable":'98px',"value":_vm.activity.external_data,"without":_vm.without}})],1),_c('div',{staticClass:"edit-element",style:({ width: '100%' }),attrs:{"slot":"editContent"},slot:"editContent"},[_vm._l(({
          receiver_name: 'commons.name',
          receiver_phone: 'commons.phone',
          text: 'commons.note',
          delivery_term: 'components.entities.activityDetails.moreInfos',
        }),function(val,key){return _c('el-row',{key:val,style:({ marginBottom: '0px' }),attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"left-key-display"},[_vm._v(_vm._s(_vm.$t(val)))])]),_c('el-col',{attrs:{"span":18}},[_c('el-input',{style:(_vm.inputStyle(key)),attrs:{"required":"required","type":"text","clearable":""},on:{"change":_vm.saveToQueue},model:{value:(_vm.activity[key]),callback:function ($$v) {_vm.$set(_vm.activity, key, $$v)},expression:"activity[key]"}})],1)],1)}),_vm._l((_vm.activity.external_data),function(data,idx){return _c('el-row',{key:idx,style:({ marginBottom: '0px' }),attrs:{"type":"flex","align":"middle"}},[(
            data.value && data.value.length && !_vm.without.includes(data.name)
          )?_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"left-key-display"},[_vm._v(_vm._s(data.name))])]):_vm._e(),(data.value != '' && !_vm.without.includes(data.name))?_c('el-col',{attrs:{"span":18}},[_c('el-input',{attrs:{"required":"required","type":"text","clearable":""},on:{"change":_vm.saveToQueue},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}})],1):_vm._e()],1)})],2)]),(_vm.activity.items)?_c('edit-details',{attrs:{"title":_vm.$t('commons.item.plural')}},_vm._l((_vm.activity.items),function(item){return _c('item-status',{key:item.id,attrs:{"item":item}})}),1):_vm._e(),_c('edit-details',{attrs:{"activator-name":_vm.$t('commons.appointment.single'),"title":_vm.$t('commons.when')},scopedSlots:_vm._u([{key:"editContent",fn:function(ref){
          var edit = ref.edit;
return [_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"left-key-display"},[_vm._v(" "+_vm._s(_vm.$t('commons.duration'))+" ")])]),_c('el-col',{attrs:{"span":18}},[_c('el-input-number',{attrs:{"step":60,"placeholder":"__________","controls-position":"right","size":"mini","min":0,"controls":true},on:{"change":_vm.saveToQueue},model:{value:(_vm.activity.duration),callback:function ($$v) {_vm.$set(_vm.activity, "duration", $$v)},expression:"activity.duration"}}),_c('span',[_vm._v("sec")])],1)],1),_c('el-row',[_c('appointment',{attrs:{"size":"mini","default-date":_vm.activity.planned_start,"edit":edit},on:{"change":_vm.saveToQueue},model:{value:(_vm.activity.appointment),callback:function ($$v) {_vm.$set(_vm.activity, "appointment", $$v)},expression:"activity.appointment"}})],1)]}}])},[_c('div',{style:({ width: '100%' })},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"left-key-display"},[_vm._v(" "+_vm._s(_vm.$t('commons.duration'))+" ")])]),_c('el-col',{attrs:{"span":18}},[_c('span',{staticClass:"right-key-display"},[_vm._v(" "+_vm._s(_vm._f("seconds")(_vm.activity.duration))+" ")])])],1),_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":7}},[_c('span',{staticClass:"left-key-display"},[_vm._v(" "+_vm._s(_vm.$t('commons.appointment.single'))+" ")])]),_c('appointment',{attrs:{"size":"mini","appointment":_vm.activity.appointment}})],1)],1)]),_c('edit-details',{attrs:{"title":_vm.$t('commons.sla.plural')},scopedSlots:_vm._u([{key:"editContent",fn:function(ref){
          var edit = ref.edit;
return [_c('sla',{attrs:{"edit":edit},on:{"change":_vm.saveToQueue},model:{value:(_vm.activity.slas),callback:function ($$v) {_vm.$set(_vm.activity, "slas", $$v)},expression:"activity.slas"}})]}}])},[_c('div',{style:({ width: '100%' })},[_c('sla',{model:{value:(_vm.activity.slas),callback:function ($$v) {_vm.$set(_vm.activity, "slas", $$v)},expression:"activity.slas"}})],1)]),_c('edit-details',{ref:"where",attrs:{"activator-name":_vm.$t('commons.address.single'),"title":_vm.$t('commons.where')},scopedSlots:_vm._u([{key:"editContent",fn:function(ref){
          var edit = ref.edit;
return _c('geocode',{style:({ width: '100%' }),attrs:{"visible":edit,"entity-id":_vm.activity.id,"place":_vm.activity.place},on:{"close":_vm.$refs.where.close},model:{value:(_vm.activity.place_geocoded),callback:function ($$v) {_vm.$set(_vm.activity, "place_geocoded", $$v)},expression:"activity.place_geocoded"}})}}])},[_c('div',{style:({ width: '100%' })},[_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"left-key-display"},[_vm._v(" "+_vm._s(_vm.$t('commons.address.single'))+" ")])]),_c('el-col',{attrs:{"span":18}},[_c('place',{attrs:{"small":"","entity":_vm.activity}},[_c('el-tooltip',{attrs:{"content":_vm.$t('commons.address.single')}})],1)],1)],1)],1)]),_c('constraints',{attrs:{"type":"activity","entity":_vm.activity},on:{"change":_vm.saveToQueue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }