import { find, findLast, isArray } from 'lodash';
import { makeReactive } from '@/utils/helpers';
class StateHistoryItem {
  event;
  reason;
  message;
  position;
  from;
  to;
  datetime;
  constructor(o) {
    this.event = o.event;
    this.reason = o.reason;
    this.position = o.position;
    this.message = o.message;
    this.from = o.from;
    this.to = o.to;
    if (o.datetime) {
      this.datetime = new Date(o.datetime);
    }
  }
}

export default class StateHistory extends Array {
  constructor(args) {
    const list = isArray(args) ? args.map((i) => new StateHistoryItem(i)) : [];
    super(...list);

    makeReactive(this);
  }

  findItem(needle, param = 'to') {
    return find(this, (s) =>
      isArray(needle) ? needle.includes(s[param]) : s[param] === needle
    );
  }

  findLastItem(needle, param = 'to') {
    return findLast(this, (s) =>
      isArray(needle) ? needle.includes(s[param]) : s[param] === needle
    );
  }

  last() {
    return this[this.length - 1];
  }
}
