<template lang="html">
  <div
    v-if="selection.draggable"
    class="drag-selection"
    @mouseleave="overId = null"
  >
    <div class="drag-controls drag-cursor">
      <el-tag
        v-show="activitySelection.length"
        closable
        disable-transitions
        type="info"
        class="upperFirst"
        :draggable="activitySelection.length !== 0"
        @dragstart.native="handleDragStart($event)"
        @close="clear"
      >
        {{ $t('commons.all') }} ({{ activitySelection.length }})
      </el-tag>
    </div>
    <div class="drag-list">
      <ul ref="dragList">
        <drag-item
          v-for="(activity, idx) in activitySelection"
          :key="activity.id"
          class="selected-item drag-cursor"
          :activity="activity"
          :content="idx + 1"
          :over-id="overId"
          @over="handleOver"
        >
          <i class="material-icons closing" @click="unselect(activity)"
            >close</i
          >
        </drag-item>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { flatMap, uniqBy } from 'lodash';
import DragItem from './DragItem';

export default {
  name: 'DragSelected',
  components: {
    DragItem,
  },
  data: () => ({
    open: false,
    overId: null,
  }),
  computed: {
    ...mapGetters(['selection']),
    activitySelection() {
      if (this.selection.type === 'route') {
        return uniqBy(flatMap(this.selection.data, 'deliveries'), 'id');
      }

      return this.selection.data;
    },
  },
  methods: {
    ...mapActions(['removeActivitiesFromSelection', 'clearSelection']),
    clear() {
      this.open = false;
      this.clearSelection();
    },
    handleDragStart(event) {
      event.dataTransfer.effectAllowed = 'move';
      const item = {
        content: 'selection',
      };
      event.dataTransfer.setData('text', JSON.stringify(item));
      event.dataTransfer.setDragImage(this.$refs.dragList, 0, 0);
    },
    handleOver(id) {
      this.overId = id;
    },
    unselect(activity) {
      this.removeActivitiesFromSelection({ data: [activity] });
    },
  },
};
</script>
