<template lang="html">
  <div class="sla-component">
    <div v-if="!edit">
      <el-row v-for="(item, idx) in list" :key="idx" type="flex" align="middle">
        <el-col :el-row="24">
          <a class="activity-head-link">
            {{ $t('commons.from') | upperFirst }}
            {{ item.sla.start | format(SHORT_DATETIME_FORMAT) }}
            {{ $t('commons.to') }}
            {{ item.sla.end | format(SHORT_DATETIME_FORMAT) }}
          </a>
        </el-col>
      </el-row>
    </div>

    <div v-else type="flex" align="middle" class="edit-element">
      <el-row v-if="!isSingle" type="flex" align="middle">
        <el-col :span="6">
          <span class="left-key-display" @click="addSla"
            >{{ $t('commons.add') }}&nbsp;</span
          >
          <el-tooltip :content="$t('commons.add')">
            <i class="el-icon-plus" @click="addSla"></i>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row v-for="(item, idx) in list" :key="idx" type="flex" align="middle">
        <el-col :span="6">
          <span class="left-key-display">{{ $t('commons.date') }}</span>
        </el-col>
        <el-col :span="18">
          <el-date-picker
            v-model="pickerData[idx]"
            class="activity-sla-datePicker"
            type="datetimerange"
            size="mini"
            format="dd/MM HH:mm"
            :clearable="!isSingle"
            :start-placeholder="$t('commons.planned_start')"
            :end-placeholder="$t('commons.planned_end')"
            @change="updateSla(idx)"
            @close="updateSla(idx)"
          >
          </el-date-picker>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { isArray, isNil, get, map } from 'lodash';
import { SHORT_DATETIME_FORMAT } from '@/utils/date';

export default {
  model: {
    prop: 'slas',
    event: 'change',
  },
  props: {
    slas: {
      type: [Array, Object],
      default: () => [],
    },
    edit: Boolean,
  },
  data: () => ({
    list: [],
    pickerData: [],
    display: false,
    SHORT_DATETIME_FORMAT: SHORT_DATETIME_FORMAT,
  }),
  computed: {
    isSingle() {
      return !isArray(this.slas);
    },
  },
  watch: {
    slas() {
      this.setList();
    },
    list(v) {
      this.pickerData = map(v, (i) => [i.sla.start, i.sla.end]);
    },
  },
  mounted() {
    this.setList();
  },
  methods: {
    setList() {
      this.list = this.isSingle
        ? [{ sla: this.slas, edit: false }]
        : this.slas.map((sla) => ({ sla, edit: false }));
    },
    deleteSla(idx) {
      this.slas.splice(idx, 1);
    },
    updateSla(idx) {
      if (
        !isArray(this.pickerData[idx]) ||
        isNil(this.pickerData[idx][0]) ||
        isNil(this.pickerData[idx][1])
      ) {
        this.slas.splice(idx, 1);
      } else {
        if (this.isSingle) {
          this.$emit('change', {
            start: this.pickerData[idx][0],
            end: this.pickerData[idx][1],
          });
        } else {
          this.slas.splice(idx, 1, {
            start: this.pickerData[idx][0],
            end: this.pickerData[idx][1],
            priority: get(this.slas[idx], 'priority', 1),
          });
        }
      }
    },
    addSla() {
      this.list.push({
        sla: { start: new Date(), end: new Date() },
        edit: true,
      });
      this.display = true;
    },
  },
};
</script>

<style lang="scss">
.sla-component {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 260px !important;
  }
  .el-range-separator {
    width: 3% !important;
    padding: 0 !important;
    margin-right: 10px !important;
  }
  ul {
    list-style-type: none;
    padding-left: 1em;
    margin: 0;
  }
}
</style>
