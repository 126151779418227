<template lang="html" functional>
  <el-checkbox
    :value="props.value"
    @change="listeners.change ? listeners.change($event) : undefined"
  >
    <div class="batch-checkbox__option">
      <div class="batch-checkbox__option__label">
        <slot name="label" />
      </div>

      <div class="batch-checkbox__option__count">
        <slot name="count" />
      </div>
    </div>
  </el-checkbox>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-checkbox {
  &__option {
    display: flex;

    &__label {
      flex: 1 0 auto;
    }

    &__count {
      flex: 0 1 auto;
    }
  }
}
</style>
