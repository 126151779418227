<template lang="html">
  <section class="state-history">
    <el-steps direction="vertical" finish-status="success">
      <el-step
        v-for="(s, idx) in orderedHistory"
        :key="idx"
        :title="s.to"
        :status="state === s ? 'process' : 'wait'"
      >
        <state
          slot="title"
          :type="entityType"
          :value="s.to"
          @click.native="toggleState(s)"
        >
          {{ $t(`commons.states.${entityType}.${s.to}`) }}
          <span v-show="s.position" class="fa fa-map-marker"></span>
        </state>
        <span v-show="state === s" slot="description">
          {{ s.datetime | format('dd/MM/yy HH:mm') }}
          <p v-if="s.reason">
            {{ s.reason }}
            <br />
            <em>{{ s.message }}</em>
          </p>
        </span>
      </el-step>
    </el-steps>
  </section>
</template>

<script>
import { orderBy } from 'lodash';
import State from '@/components/State';

export default {
  components: { State },
  props: {
    history: {
      type: Array,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
      validator: (value) => ['route', 'delivery'].includes(value),
    },
  },
  data: () => ({
    state: null,
  }),
  computed: {
    orderedHistory() {
      return orderBy(this.history, 'datetime', 'asc');
    },
  },
  methods: {
    toggleState(s) {
      this.state = s !== this.state ? s : null;
      this.$emit('selected', this.state);
    },
  },
};
</script>

<style lang="scss">
.state-history {
  .tag {
    cursor: pointer;
  }
  .el-steps--vertical {
    flex-flow: column-reverse;
  }
  .el-step:last-of-type .el-step__line {
    display: block;
  }
  .el-step:first-of-type .el-step__line {
    display: none;
  }
}
</style>
