<template>
  <div class="back">
    <section id="login">
      <h1 class="title">{{ $t('pages.password.passwordReset') }}</h1>

      <el-form
        ref="form"
        label-position="top"
        :model="form"
        label-width="120px"
        :rules="rules"
        @submit.native.prevent
      >
        <div v-if="success" class="alert alert-success">
          <p class="upperFirst">{{ $t('pages.password.successLink') }}</p>
        </div>

        <div v-if="error" class="alert alert-danger">
          <p class="upperFirst">{{ $t('pages.password.errorLink') }}</p>
        </div>

        <el-form-item :label="$t('commons.mail')" prop="email">
          <el-input
            v-model="form.email"
            name="email"
            type="email"
            :placeholder="$t('commons.mail')"
            @keyup.enter.native="sendEmail"
          />
        </el-form-item>

        <el-form-item>
          <cs-button
            class="pull-right"
            type="primary"
            icon-type="material"
            icon="email"
            :loading="loading"
            @click="sendEmail"
          >
            {{ $t('pages.password.resetLink') }}
          </cs-button>
        </el-form-item>
      </el-form>
    </section>
  </div>
</template>

<script>
import { getRequireRule, getTypeRule } from '@/validation';

export default {
  data: () => ({
    form: {
      email: null,
    },
    success: false,
    error: false,
    loading: false,
  }),
  computed: {
    rules() {
      return {
        email: [getRequireRule('commons.mail'), getTypeRule('email')],
      };
    },
  },
  methods: {
    sendEmail() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.error = false;
          this.success = false;
          this.axios.post('password/email', this.form).then(
            () => {
              this.success = true;
              this.loading = false;
            },
            () => {
              this.error = true;
              this.loading = false;
            }
          );
        }
      });
    },
  },
};
</script>
