<template>
  <section>
    <div class="header">
      <el-input
        v-if="search !== undefined"
        class="search"
        size="mini"
        :value="search"
        :placeholder="$t('commons.search')"
        @input="$emit('input', $event)"
      />
    </div>

    <el-table :data="paginatedList">
      <slot></slot>
    </el-table>

    <el-pagination
      :page-size="pageSize"
      :current-page="current"
      layout="total, prev, pager, next"
      :total="data.length"
      @current-change="setPage"
    />
  </section>
</template>

<script>
export default {
  model: {
    prop: 'search',
    event: 'input',
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    search: String,
  },
  data: () => ({
    pageIndex: 1,
    current: 1,
  }),
  computed: {
    paginatedList() {
      return this.data.slice(
        this.pageSize * (this.pageIndex - 1),
        this.pageSize * this.pageIndex
      );
    },
  },
  methods: {
    setPage(v) {
      if (v) {
        this.pageIndex = v;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  padding: 1rem;
  background: white;
}
.search {
  max-width: 30%;
}
</style>
