<template>
  <base-layout>
    <h3 slot="title">{{ $t('components.XlsxImporter.title') }}</h3>
    <h4 slot="subtitle">{{ $t(subtitle) }}</h4>

    <div class="importer">
      <xlsx-importer
        v-if="!uploading && client"
        v-model="dataset"
        :model="activityModel"
        :dictionary="dictionary"
        :limit="client.importerLimit"
        @mapped="updateDictionary"
        @inited="inited = true"
      />

      <div v-else class="summary">
        <el-progress type="circle" :width="250" :percentage="percent" />
        <h5>
          {{
            $t('components.XlsxImporter.successCount', {
              count: response.length,
            })
          }}
        </h5>
        <span v-show="errors.length > 0">
          <h5>
            {{
              $t('components.XlsxImporter.errorCount', { count: errors.length })
            }}
          </h5>
          <p v-for="(error, idx) in errors" :key="idx">{{ error }}</p>
        </span>
      </div>
    </div>

    <div slot="footer" class="btn-group">
      <cs-button v-show="!response.length" @click="reset">
        {{ $t('commons.cancel') }}
      </cs-button>
      <cs-button
        v-if="!response.length"
        type="success"
        :disabled="!dataset.length"
        @click="upload"
      >
        {{ $t('commons.upload') }}
      </cs-button>
      <cs-button v-else @click="reset">
        {{ $t('components.XlsxImporter.new') }}
      </cs-button>
    </div>
  </base-layout>
</template>

<script>
import { chunk, round, last } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { startOfDay, endOfDay } from 'date-fns';
import XlsxImporter from '@/components/importer/XlsxImporter';
import activityModel from '@/components/importer/activityModel';
import Activity from '@/models/Activity';

export default {
  components: {
    XlsxImporter,
  },
  data: () => ({
    state: 1,
    loading: true,
    inited: false,
    uploading: false,
    chunksize: 1,
    chunkpointer: 0,
    dataset: [],
    errors: [],
    response: [],
    activityModel,
  }),
  computed: {
    ...mapGetters(['clients']),
    dictionary() {
      try {
        const dic = JSON.parse(
          localStorage.getItem(`impdic_${this.$route.params.id}`)
        );
        return dic;
      } catch (e) {
        return undefined;
      }
    },
    client() {
      return this.clients.items[this.$route.params.id];
    },
    sanitizeData() {
      // we transform date in sla
      return this.dataset.map((d) => {
        if (d.date && !d.slas) {
          d.slas = [{ start: startOfDay(d.date), end: endOfDay(d.date) }];
        }
        return new Activity(d);
      });
    },
    percent() {
      return round((this.chunkpointer / this.chunksize) * 100);
    },
    subtitle() {
      if (this.uploading) {
        return 'components.XlsxImporter.uploading';
      }
      if (!this.inited) {
        return 'components.XlsxImporter.addFile';
      } else {
        return 'components.XlsxImporter.mappingTitle';
      }
    },
  },
  async created() {
    await this.get({ type: 'client', id: this.$route.params.id });
    this.loading = false;
  },
  methods: {
    ...mapActions(['get']),
    updateDictionary(mapping) {
      if (mapping) {
        const key = mapping.name.includes('.')
          ? last(mapping.name.split('.'))
          : mapping.name;
        const mapped = mapping.value;
        let dictionary = this.dictionary;

        if (!mapping.isArray) {
          if (!dictionary) {
            dictionary = { [key]: mapped.toLowerCase() };
          } else {
            dictionary[key] = mapped.toLowerCase();
          }
        }

        localStorage.setItem(
          `impdic_${this.$route.params.id}`,
          JSON.stringify(dictionary)
        );
      } else {
        localStorage.removeItem(`impdic_${this.$route.params.id}`);
      }
    },
    upload() {
      const chunkData = chunk(this.sanitizeData, 50);
      this.chunksize = chunkData.length;
      this.uploading = true;
      chunkData.forEach((data) =>
        this.axios
          .post(`/client/${this.client.id}/delivery/batch`, data)
          .then((res) => {
            this.chunkpointer += 1;
            if (res.errors) {
              this.errors = this.errors.concat(res.errors);
            }
            this.response = this.response.concat(res.data.data);
          })
      );
    },
    reset() {
      this.$router.push({ name: 'selectClient' });
    },
  },
};
</script>
