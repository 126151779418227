import _ from 'lodash';
import Vue from 'vue';

const simpleFilters = {
  Comparable: {
    EQ: (v, fv) => v === fv,
    NEQ: (v, fv) => v !== fv,
    LT: (v, fv) => v < fv,
    LTE: (v, fv) => v <= fv,
    GT: (v, fv) => v > fv,
    GTE: (v, fv) => v >= fv,
    between: (v, f1v, f2v) => v >= f1v && v <= f2v,
    notBetween: (v, f1v, f2v) => v < f1v || v > f2v,
  },
  String: {
    includes: (v, fv) => _.includes(v, fv),
    in: (v, fv) => _.includes(fv, v),
    startsWith: (v, fv) => _.startsWith(v, fv),
    endsWith: (v, fv) => _.endsWith(v, fv),
    notIncludes: (v, fv) => !_.includes(v, fv),
    notStartsWith: (v, fv) => !_.startsWith(v, fv),
    notEndsWith: (v, fv) => !_.endsWith(v, fv),
    equals: (v, fv) => v === fv,
    notEquals: (v, fv) => v !== fv,
  },
  Range: {
    within: (v1, v2, fv1, fv2) => v1 >= fv1 && v2 <= fv2,
    notWithin: (v1, v2, fv1, fv2) => v1 < fv1 || v2 > fv2,
    intersect: (v1, v2, fv1, fv2) => v1 <= fv2 && v2 >= fv1,
    notIntersect: (v1, v2, fv1, fv2) => v1 > fv2 || v2 < fv1,
    overlapping: (v1, v2, fv1, fv2) => v1 < fv2 && fv1 < v2,
  },
};

/**
 * This function return property of an object at some path.
 * @param {Object} obj an object to look into for the property
 * @param {string} path of the requeried property
 * @returns {Array|string|number|boolean|undefined} the resolved property
 */
function resolvePath(obj, path) {
  if (path === '.') return obj;
  /* eslint no-restricted-globals: 1 */
  return path
    .split(/[.\][]+/)
    .reduce((prev, curr) => (prev ? prev[curr] : undefined), obj);
}

/* eslint-disable no-use-before-define */

// Filter Validation

/**
 * This function check if a filter is valid or not. It will throw an exception
if something is wrong
 * @param {Object} filter to validate
 * @returns {undefined} Returns nothing
 */
function validateFilter(filter) {
  const exception = {
    type: 'filterValidityException',
    filter,
    message: '',
  };
  if (filter.operator === 'AND' || filter.operator === 'OR') {
    if (
      !filter.filters &&
      Object.prototype.toString.call(filter.filters) !== '[object Array]'
    ) {
      exception.message = 'Filter filters not provided';
      throw exception;
    }
    for (let i = 0; i < filter.filters.length; i += 1) {
      validateFilter(filter.filters[i]);
    }
  } else if (filter.path && _.split(filter.path, '[*].').length > 1) {
    validateArrayFilter(filter);
  } else {
    if (!filter.path) {
      exception.message = 'Filter path not provided';
      throw exception;
    }
    if (
      !Object.prototype.hasOwnProperty.call(filter, 'value') ||
      filter.value === undefined
    ) {
      exception.message = 'Filter value not provided';
      throw exception;
    }
    if (!filter.type) {
      exception.message = 'Type of Object to compare not provided';
      throw exception;
    }
    if (!simpleFilters[filter.type]) {
      exception.message = "Type of object to compare doesn't exists";
      throw exception;
    }
    if (!filter.operator) {
      exception.message = 'Type of operator not provided';
      throw exception;
    }
    if (!simpleFilters[filter.type][filter.operator]) {
      exception.message = `Operator ${filter.operator} doesn't exists for ${filter.type}`;
      throw exception;
    }
    if (
      filter.type === 'Comparable' &&
      (filter.operator === 'between' || filter.operator === 'notBetween') &&
      (!Object.prototype.hasOwnProperty.call(filter, 'value2') ||
        filter.value2 === null)
    ) {
      exception.message = `Filter ${filter.operator} with value2 not provided`;
      throw exception;
    }
    if (filter.type === 'Range') {
      if (!filter.path2) {
        exception.message = 'Filter path2 not provided';
        throw exception;
      }
      if (
        !Object.prototype.hasOwnProperty.call(filter, 'value2') ||
        filter.value2 === null
      ) {
        exception.message = `Filter ${filter.operator} with value2 not provided`;
        throw exception;
      }
      if (filter.value2 < filter.value) {
        exception.message = `Filter ${filter.type} must have value >= value2`;
        throw exception;
      }
    }
  }
}

/**
 * This function extract path from an filter on array and call validateFilter to
check remaining subfilters
 * @param {Object} filter for an Array to validate
 * @returns {undefined} Returns nothing
 */
function validateArrayFilter(filter) {
  const subFilter = _.clone(filter);
  const paths = _.split(filter.path, '[*].');
  subFilter.path = _.join(_.slice(paths, 1), '[*].');
  validateFilter(subFilter);
}

// Simplify Filter //

/**
 * This function group logic filter from filters if they have the same operator as filter
ORs in OR filter can be grouped together as ANDs in AND filter can be grouped together
 * @param {Object} filter to simplify
 * @returns {boolean} returns true if a simplification have been made
 */
function groupLogicFilter(filter) {
  let modified = false;
  const logicFilters = _.filter(filter.filters, { operator: filter.operator });
  let filters = [];
  if (logicFilters.length > 1) {
    modified = true;
    for (let i = 0; i < logicFilters.length; i += 1) {
      filters = _.concat(filters, logicFilters[i].filters);
    }
    let first = true;
    for (let i = 0; i < filter.filters.length; i += 1) {
      const currentFilter = filter.filters[i];
      if (
        currentFilter.operator &&
        currentFilter.operator === filter.operator
      ) {
        if (first) {
          currentFilter.filters = filters;
          first = false;
        } else {
          filter.filters.splice(i, 1);
        }
      }
    }
  }
  return modified;
}

/**
 * This function group filters on array from filters in one single filter
This makes filtering faster as array will be iterated over less times
 * @param {Object} filter to simplify
 * @returns {boolean} returns true if a simplification have been made
 */
function groupFiltersOnSameArray(filter) {
  let modified = false;
  for (let i = 0; i < filter.filters.length; i += 1) {
    const currentFilter = filter.filters[i];
    if (currentFilter.path && currentFilter.path.includes('[*]')) {
      // Find full path up to the last [*]
      const path = currentFilter.path.substring(
        0,
        currentFilter.path.lastIndexOf('[*]') + 3
      );
      // Lets find match path in other filters
      const matchingFilters = _.filter(filter.filters, (f) => {
        if (f.path && f.path.includes('[*]')) {
          return f.path.substring(0, f.path.lastIndexOf('[*]') + 3) === path;
        }
        return false;
      });

      if (matchingFilters.length > 1) {
        modified = true;
        const newFilter = {};
        newFilter.operator = filter.operator;
        newFilter.path = `${path}.`;
        newFilter.filters = [];
        for (let j = 0; j < matchingFilters.length; j += 1) {
          matchingFilters[j].path = matchingFilters[j].path.replace(
            newFilter.path,
            ''
          );
          newFilter.filters.push(matchingFilters[j]);
          _.pull(filter.filters, matchingFilters[j]);
        }
        // Remove matching fitlers from filters
        filter.filters.push(newFilter);
      }
    }
  }
  return modified;
}

/**
 * This function simplify filter by merging filters and filters on array
 * @param {Object} filter to simplify
 * @returns {undefined} returns nothing
 */
function simplifyFilterRecursive(filter) {
  let { operator } = filter;
  let isSimplified = operator && (operator === 'AND' || operator === 'OR');
  let resultFilter = filter;
  while (
    isSimplified &&
    operator &&
    (operator === 'AND' || operator === 'OR')
  ) {
    isSimplified = false;
    for (let i = 0; i < resultFilter.filters.length; i += 1) {
      const currentFilter = resultFilter.filters[i];
      const result = simplifyFilterRecursive(currentFilter);
      resultFilter.filters[i] = result.resultFilter;
      if (result.isSimplified) {
        isSimplified = true;
      }
    }
    if (groupFiltersOnSameArray(resultFilter)) {
      isSimplified = true;
    }
    if (groupLogicFilter(resultFilter, operator)) {
      isSimplified = true;
    }
    if (_.size(resultFilter.filters) === 1) {
      isSimplified = true;
      const path = [filter.path, filter.filters[0].path]
        .filter((val) => val)
        .join('.');
      [resultFilter] = filter.filters;
      filter.path = path;
      ({ operator } = filter);
    }
  }
  return { resultFilter, isSimplified };
}

/**
 * This function simplify filter by merging filters and filters on array
 * @param {Object} originalFilter filter to simplify
 * @returns {Object} returns a simplified filter
 */
function simplifyFilter(originalFilter) {
  const result = simplifyFilterRecursive(_.cloneDeep(originalFilter));
  return result.resultFilter;
}

// Filter Dataset //

/**
 * This function filters a dataset according to a filter.
 * @param {Array} dataset any dataset to filter
 * @param {Object} filter any filter object
 * @param {number} [removeValues=true] Whether or not to remove not matching
data in resulting dataset
 * @param {string} [matchProperty='matchFilter'] name of property to inject in
data to mark if data is matching or not
 * @returns {Array} filtered dataset
 */
function filterDataset(dataset, filter, removeValues, matchProperty, isFirst) {
  if (_.isEmpty(filter)) return dataset;
  let simpleFilter;

  if (_.isNil(isFirst)) {
    validateFilter(filter);
    simpleFilter = simplifyFilter(filter);
    initOptionsOnFilter(simpleFilter);
  } else {
    simpleFilter = filter;
  }
  const match =
    typeof matchProperty === 'undefined' ? 'matchFilter' : matchProperty;
  const subset = _.filter(dataset, (data) =>
    isDataMatch(data, simpleFilter, match)
  );
  const shouldRemove =
    typeof removeValues === 'undefined' ? 'true' : removeValues;
  return shouldRemove ? subset : dataset;
}

/**
 * This function filter array according to a filter on array.
 * @param {Object} data an object containing an Array to be filered
 * @param {Object} filter any filter object
 * @param {string} matchProperty name of property to inject in data to mark if it
matches filter or not
 * @returns {boolean} Returns true if any of data in array match the filter,
 this behavior can be modified with the option mustAllBeTrue which will make
 this function return true if all of data in array match the filter
 */
function filterArray(data, filter, matchProperty) {
  const paths = _.split(filter.path, '[*].');
  // Get Array to filter
  const array = resolvePath(data, paths[0]);
  if (typeof array === 'undefined') {
    return false;
  }
  // Make clone of filter for array and adapt
  const subFilter = _.clone(filter);
  subFilter.path = _.join(_.slice(paths, 1), '[*].');
  if (filter.path2) {
    subFilter.path2 = _.join(_.slice(_.split(filter.path2, '[*].'), 1), '[*].');
  }

  const result = filterDataset(array, subFilter, false, matchProperty, false);
  let match = false;

  if (filter.options.mustAllBeTrue) {
    match = _.filter(result, [matchProperty, true]).length === result.length;
  } else {
    match = _.filter(result, [matchProperty, true]).length > 0;
  }
  if (
    typeof data === 'object' &&
    !Object.prototype.hasOwnProperty.call(data, matchProperty) &&
    match
  ) {
    Vue.set(data, matchProperty, match);
  }
  return match;
}

/**
 * This function filter data according to a set of filter and apply OR between them.
 * @param {Object} data an object to be filered
 * @param {Array} filters an array of filter to apply with an OR rule between them
 * @param {string} matchProperty name of property to inject in data to mark if it
matches filter or not
 * @returns {boolean} return true if any filter matches data
 */
function filterOR(data, filters, matchProperty) {
  for (let i = 0; i < filters.length; i += 1) {
    if (isDataMatch(data, filters[i], matchProperty)) {
      return true;
    }
  }
  return false;
}

/**
 * This function filter data according to a set of filter and apply AND between them.
 * @param {Object} data an object to be filered
 * @param {Array} filters an array of filter to apply with an AND rule between them
 * @param {string} matchProperty name of property to inject in data to mark if it
matches filter or not
 * @returns {boolean} return true if all filters matches data
 */
function filterAND(data, filters, matchProperty) {
  for (let i = 0; i < filters.length; i += 1) {
    if (!isDataMatch(data, filters[i], matchProperty)) {
      return false;
    }
  }
  return true;
}

/**
 * This function filter data according to a filter.
 * @param {Object} data an object to be filered
 * @param {Object} filter a filter to apply
 * @param {string} matchProperty name of property to inject in data to mark if it
matches filter or not
 * @returns {boolean} return true if data matches the filter
 */
function isDataMatch(data, filter, matchProperty) {
  if (filter.path && _.split(filter.path, '[*].').length > 1) {
    return filterArray(data, filter, matchProperty);
  } else if (filter.operator === 'AND') {
    return filterAND(data, filter.filters, matchProperty);
  } else if (filter.operator === 'OR') {
    return filterOR(data, filter.filters, matchProperty);
  }
  let value = resolvePath(data, filter.path);
  if (value === undefined) {
    return false;
  }
  let fv = filter.value;
  let fv2;
  if (filter.type === 'String' && !filter.options.caseSensitive) {
    fv = _.toLower(fv);
    value = _.toLower(value);
  }
  if (
    filter.type === 'Comparable' &&
    (filter.operator === 'between' || filter.operator === 'notBetween')
  ) {
    fv2 = filter.value2;
  }
  let match;
  if (typeof value.toISOString === 'function') {
    value = value.toISOString();
  }
  if (filter.type === 'Range') {
    let value2 = resolvePath(data, filter.path2);
    if (value2 === null) {
      return false;
    }
    if (typeof value2.toISOString === 'function') {
      value2 = value2.toISOString();
    }
    fv2 = filter.value2;
    match = simpleFilters.Range[filter.operator](value, value2, fv, fv2);
  } else {
    match = simpleFilters[filter.type][filter.operator](value, fv, fv2);
  }
  if (typeof data === 'object') {
    Vue.set(data, matchProperty, match);
  }
  return match;
}

/**
 * This function set options on filter recursively.
 * @param {Object} input any filter object
 * @returns {undefined} returns nothing.
 */
function initOptionsOnFilter(filter) {
  filter.options = Object.prototype.hasOwnProperty.call(filter, 'options')
    ? filter.options
    : {};
  if (filter.filters) {
    _.forEach(filter.filters, (o) => {
      initOptionsOnFilter(o);
    });
  }
}

// Removal FilterMatch property //

/**
 * This function remove match property on a dataset according to a filter.
 * @param {Array} dataset any dataset where match property should be removed
 * @param {Object} filter used previously to filter dataset
 * @param {string} [matchProperty='matchFilter'] name of property injected in
data to mark if data is matching or not
 * @returns {undefined} Returns nothing
 */
function removeMatchProperty(dataset, filter, matchProperty) {
  if (_.isEmpty(filter)) return;
  validateFilter(filter);
  const f = simplifyFilter(filter);
  const match =
    typeof matchProperty === 'undefined' ? 'matchFilter' : matchProperty;
  _.forEach(dataset, (data) => removeMatchPropertyOnData(data, f, match));
}

/**
 * This function remove match property recursively on data according to a filter.
 * @param {Array} data any data where match property should be removed
 * @param {Object} filter used previously to filter dataset
 * @param {string} matchProperty name of property injected in data to mark if
data is matching or not
 * @returns {undefined} Returns nothing
 */
function removeMatchPropertyOnData(data, filter, matchProperty) {
  if (filter.path && _.split(filter.path, '[*].').length > 1) {
    removeMatchPropertyOnArray(data, filter, matchProperty);
  } else if (filter.operator === 'AND' || filter.operator === 'OR') {
    for (let i = 0; i < filter.filters.length; i += 1) {
      removeMatchPropertyOnData(data, filter.filters[i], matchProperty);
    }
  } else if (data && typeof data === 'object') {
    Vue.delete(data, matchProperty);
  }
}

/**
 * This function remove match property recursively on data according to an array filter.
 * @param {Array} data any data where match property should be removed
 * @param {Object} filter used previously to filter dataset
 * @param {string} matchProperty name of property injected in data to mark if
data is matching or not
 * @returns {undefined} Returns nothing
 */
function removeMatchPropertyOnArray(data, filter, matchProperty) {
  const paths = _.split(filter.path, '[*].');
  // Get Array to filter
  const array = resolvePath(data, paths[0]);
  // Make clone of filter for array and adapt
  const subFilter = _.clone(filter);
  subFilter.path = _.join(_.slice(paths, 1), '[*].');
  removeMatchProperty(array, subFilter, matchProperty);
}

/* eslint-enable no-use-before-define */
export default {
  filterDataset,
  removeMatchProperty,
};
