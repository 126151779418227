<template>
  <div style="height: 100%">
    <menu-nav v-show="displayHeader && !printLayout" />
    <router-view></router-view>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import MenuNav from '@/components/AppMenu';

export default {
  components: { MenuNav },
  data: () => ({
    sockets: {
      user: false,
      organization: false,
    },
  }),
  computed: {
    ...mapGetters(['loggedUser', 'organization', 'displayHeader']),
    printLayout() {
      return this.$route.meta.layout === 'print-layout';
    },
  },
  watch: {
    loggedUser(val, old) {
      this.$ws.setToken();
      if (!isEmpty(val)) {
        this.subscribeUserSockets();
        this.subscribeOrganizationSockets();
      } else {
        this.unsubscribeUserSockets(old.id);
      }
    },
    organization(v, o) {
      if (v.id) {
        this.subscribeOrganizationSockets();
      } else {
        this.unsubscribeOrganizationSockets(o.id);
      }
    },
  },
  mounted() {
    if (this.loggedUser.id) {
      this.$ws.setToken();
      this.subscribeUserSockets();
      this.subscribeOrganizationSockets();
    }
  },
  methods: {
    subscribeUserSockets() {
      // User only channel
      const optimizationUpdateWS = async ({ data }) => {
        this.$store.dispatch('add', { type: 'optimization', data });

        if (data.state === 'resolved') {
          this.$store.dispatch('addNotification', {
            title: this.$t('components.optim.optimSolved'),
            text: this.$t('components.optim.optimSolved', {
              name: data.name,
            }),
            action: {
              title: this.$t('components.optim.optimView'),
              routerLink: {
                name: 'editOptimization',
                params: { id: data.id },
              },
            },
            delay: 6000,
            type: 'success',
          });
        }
      };

      this.$ws.echo
        .private(`user.${this.loggedUser.id}`)
        .listen('OptimizationUpdate', optimizationUpdateWS);
      this.sockets.user = true;
    },
    subscribeOrganizationSockets() {
      const addEntity = ({ type, data }) => {
        const formated = type.match(/[^\\]*$/)[0];
        this.$store.dispatch('add', { type: formated.toLowerCase(), data });
      };

      const routeSpliceWS = (data) => {
        this.$store.commit(`DELETE_ROUTE`, data);
        this.$store.dispatch('add', { type: 'route', data });
      };

      this.$ws.echo
        .private(`organization.${this.organization.id}`)
        .listen('StatusChanged', addEntity)
        .listen('ActivityCreated', (data) =>
          this.$store.dispatch('add', { type: 'delivery', data })
        )
        .listen('RouteCreated', (data) =>
          this.$store.dispatch('add', { type: 'route', data })
        )
        .listen('RoutesSplice', routeSpliceWS);
      this.sockets.organization = true;
    },
    unsubscribeUserSockets(id) {
      if (this.$ws.echo) {
        this.$ws.echo.leave(`user.${id}`);
        this.sockets.user = false;
      }
    },
    unsubscribeOrganizationSockets(id) {
      if (this.$ws.echo) {
        this.$ws.echo.leave(`organization.${id}`);
        this.sockets.organization = false;
      }
    },
  },
};
</script>

<style lang="scss">
// Don't use this class
// Don't do this at home
// This only to trick webpack to copy shadow image and retina marker...
.leaflet-fake-icon-image-2x {
  background-image: url(~leaflet/dist/images/marker-icon-2x.png);
}
.leaflet-fake-icon-shadow {
  background-image: url(~leaflet/dist/images/marker-shadow.png);
}
// End of trick

@import '~leaflet/dist/leaflet.css';
@import '~vis-timeline/styles/vis-timeline-graph2d.css';
</style>
