<template lang="html">
  <div class="info-tabs">
    <div class="info-toolbar">
      <cs-button
        icon="place"
        icon-type="material"
        :class="{ active: tab === 'activity' }"
        @click="tab = 'activity'"
      >
        {{ $t('commons.activity.single') }}
      </cs-button>
      <cs-button
        icon="timeline"
        icon-type="material"
        :class="{ active: tab === 'route' }"
        @click="tab = 'route'"
      >
        {{ $t('commons.route.single') }}
      </cs-button>
    </div>
    <activity-info
      v-show="tab === 'activity'"
      :routes="routes"
      :unplanned-route="unplannedRoute"
    />
    <route-info
      v-show="tab === 'route'"
      :routes="routes"
      :unplanned-route="unplannedRoute"
    />
  </div>
</template>

<script>
import ActivityInfo from './ActivityInfo';
import RouteInfo from './RouteInfo';

export default {
  components: {
    ActivityInfo,
    RouteInfo,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    unplannedRoute: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    tab: 'activity',
  }),
};
</script>

<style lang="scss"></style>
