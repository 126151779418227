<template lang="html">
  <div
    v-if="display"
    class="notification"
    :class="`notification-${notification.type}`"
  >
    <div class="notification-start">
      <h4 v-if="notification.title">
        <strong>{{ notification.title }}</strong>
      </h4>
      <p>{{ notification.text }}</p>
      <cs-button
        v-if="notification.action"
        :type="notification.type"
        @click.native="clickHandle"
      >
        {{ notification.action.title }}
      </cs-button>
    </div>

    <div class="notification-end">
      <cs-button
        outline
        square
        icon="close"
        icon-type="material"
        :type="notification.type"
        @click="closeHandle"
      />
      <em v-if="notification.datetime">
        {{ notification.datetime | moment('L') }}
        {{ notification.datetime | moment('LT') }}
      </em>
    </div>
  </div>
</template>

<script>
import { isFunction } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'Notification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    timeout: {
      type: Boolean,
      default: true,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timer: null,
    display: true,
  }),
  mounted() {
    if (this.timeout) {
      this.timer = setTimeout(() => {
        this.hide();
      }, this.notification.delay || 1000);
    }
  },
  methods: {
    ...mapActions(['removeNotification']),
    clickHandle() {
      if (isFunction(this.notification.action.click)) {
        this.notification.action.click();
      }
      if (this.notification.action.routerLink) {
        this.$router.push(this.notification.action.routerLink);
      }
      this.hide();
    },
    closeHandle() {
      if (this.remove) {
        this.$emit('remove', this.notification);
        this.removeNotification();
      } else {
        this.hide();
      }
    },
    hide() {
      this.display = false;
      this.$emit('hide', this.notification);
    },
  },
};
</script>

<style lang="scss">
.notification {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  overflow: hidden;
  background-color: rgba(var(--color), 0.7);
  .notification-start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .notification-end {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  p {
    // opacity: 1;
    margin-right: 20px;
    margin-bottom: 20px !important;
  }
  h4 {
    // opacity: 1;
    margin-top: 10px;
  }
}
</style>
