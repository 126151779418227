<template lang="html">
  <div class="route-menu">
    <cs-button
      class="activator"
      type="primary"
      icon-type="material"
      :icon="activatorText"
      @click.stop.prevent="toggleMenu"
      @dblclick.prevent.self
    />
    <span v-show="isActive">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    active: false,
  }),
  computed: {
    isActive() {
      return this.value || this.active;
    },
    activatorText() {
      return this.isActive ? 'remove' : 'add';
    },
  },
  methods: {
    toggleMenu() {
      this.active = !this.isActive;
      this.$emit('input', this.active);
    },
  },
};
</script>

<style lang="scss">
$size: 15px;
.route-menu {
  padding: 2px;
  .activator {
    background-color: #32363b;
  }
  button {
    height: $size !important;
    min-width: $size !important;
    width: $size !important;
    line-height: $size !important;
    padding: unset;
    font-size: smaller;
    margin-right: 2px;
    background-color: #a8b7c7;
    &:hover:not(.activator) {
      background-color: #50e3c2;
    }
  }
}
</style>
