<template>
  <div class="datepicker">
    <el-date-picker
      v-model="range"
      type="daterange"
      size="medium"
      :picker-options="pickerOptions"
      :clearable="false"
      :start-placeholder="$t('commons.beginDate') | upperFirst"
      :end-placeholder="$t('commons.endDate') | upperFirst"
      @keydown.enter.prevent.native
      @change="click"
    />
    <cs-button
      square
      type="primary"
      icon="search"
      :loading="datePickerLoading"
      @click="click"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { every } from 'lodash';
import {
  parse,
  format,
  isBefore,
  isValid,
  startOfDay,
  endOfDay,
  addDays,
  startOfISOWeek,
  endOfISOWeek,
} from 'date-fns';
import { QUERY_DATE_FORMAT } from '@/utils/date';

export default {
  name: 'DatePicker',
  props: {
    click: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    range: [],
  }),
  computed: {
    ...mapGetters(['dateRange', 'datePickerLoading']),
    pickerOptions() {
      return {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: this.$i18n.t('components.DatePicker.today'),
            onClick(picker) {
              picker.$emit('pick', [
                startOfDay(new Date()),
                endOfDay(new Date()),
              ]);
            },
          },
          {
            text: this.$i18n.t('components.DatePicker.tomorrow'),
            onClick(picker) {
              picker.$emit('pick', [
                addDays(startOfDay(new Date()), 1),
                addDays(endOfDay(new Date()), 1),
              ]);
            },
          },
          {
            text: this.$i18n.t('components.DatePicker.currentWeek'),
            onClick(picker) {
              picker.$emit('pick', [
                startOfISOWeek(new Date()),
                endOfISOWeek(new Date()),
              ]);
            },
          },
        ],
      };
    },
  },
  watch: {
    range(v) {
      if (every(v, isValid)) {
        this.setDateRange({
          from: v[0],
          to: v[1],
        });
        const query = {
          from: format(v[0], QUERY_DATE_FORMAT, new Date()),
          to: format(v[1], QUERY_DATE_FORMAT, new Date()),
        };
        this.$router.replace({ path: this.$route.path, query });
      }
    },
  },
  created() {
    const from = parse(this.$route.query.from, QUERY_DATE_FORMAT, new Date());
    let to = endOfDay(
      parse(this.$route.query.to, QUERY_DATE_FORMAT, new Date())
    );
    if (isValid(from) && isValid(to)) {
      if (isBefore(to, from)) {
        to = endOfDay(from);
      }
      this.setDateRange({ from, to });
    }

    this.range = [this.dateRange.from, this.dateRange.to];
  },
  methods: {
    ...mapActions(['setDateRange']),
  },
};
</script>
<style lang="scss">
.datepicker {
  display: flex;
  align-items: center;
  .el-date-editor {
    margin-right: 0.5em;
  }

  .el-range-separator {
    width: 10px !important;
  }
  .el-date-editor {
    .el-range__close-icon {
      display: none;
    }
    .el-range-input {
      width: 25%;
      min-width: 80px;
    }
    &.el-input__inner {
      width: min-content;
    }
  }
}
</style>
