<template lang="html">
  <div class="workflow-step">
    <el-tooltip
      :disabled="!required"
      class="item"
      effect="light"
      content="this  is required"
      placement="top"
    >
      <div
        class="item"
        :style="enabled ? enableStep : disableStep"
        @click="changeState"
      >
        <p>
          <span v-if="required" class="required">* </span>
          <span class="upperFirst"> {{ name }} </span>
        </p>
      </div>
    </el-tooltip>
    <i
      v-if="!end"
      class="material-icons arrow"
      :style="{
        color: enabled
          ? enableStep.backgroundColor
          : disableStep.backgroundColor,
      }"
    >
      arrow_forward
    </i>
  </div>
</template>

<script>
export default {
  name: 'Step',
  model: {
    prop: 'step',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    end: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    disableStep: {
      backgroundColor: 'grey',
      boxShadow: '0px 0px 0px 0px #7bba4a',
    },
    enableStep: {
      backgroundColor: '#7bba4a',
      boxShadow: '0px 0px 15px 2px #7bba4a',
    },
  }),
  computed: {},
  methods: {
    changeState() {
      if (!this.required) {
        this.$emit('change', {
          step: this.name,
          enable: !this.enabled,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.workflow-step {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
  }
  span {
    display: inline-block;
  }
  .item {
    width: 90px;
    height: 70px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .arrow {
    font-size: 25px;
    font-weight: bolder;
  }
  .required {
    color: red;
  }
}
</style>
