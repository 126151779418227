import { latLng } from 'leaflet';
import { ApiState } from 'vuex-rest-plugin';
import Activity from '@/models/Activity';
import Route from '@/models/Route';
import Client from '@/models/Client';
import Optimization from '@/models/Optimization';
import Organization from '@/models/Organization';

export default {
  client: {
    name: 'CLIENT',
    plural: 'CLIENTS',
    type: new ApiState(),
    afterGet: Client.create,
    timeout: 60,
  },
  delivery: {
    name: 'DELIVERY',
    plural: 'DELIVERIES',
    type: new ApiState(),
    afterGet: Activity.create,
    references: {
      child: 'delivery',
      parent: 'delivery',
    },
  },
  item: {
    name: 'ITEM',
    plural: 'ITEMS',
    type: new ApiState(),
  },
  intake: {
    name: 'INTAKE',
    plural: 'INTAKES',
    type: new ApiState(),
  },
  optimization: {
    name: 'OPTIMIZATION',
    plural: 'OPTIMIZATIONS',
    afterGet: Optimization.create,
    type: new ApiState(),
  },
  organization: {
    name: 'ORGANIZATION',
    plural: 'ORGANIZATIONS',
    type: new ApiState(),
    afterGet: Organization.create,
  },
  place: {
    name: 'PLACE',
    plural: 'PLACES',
    type: new ApiState(),
    afterGet: (place) => {
      if (place && place.latitude && place.longitude) {
        place.position = latLng(place.latitude, place.longitude);
      }
      return place;
    },
    references: {
      spots: 'spot',
    },
  },
  spot: {
    name: 'SPOT',
    plural: 'SPOTS',
    type: new ApiState(),
    references: {
      // depot: 'place' // ?
    },
  },
  resource: {
    name: 'RESOURCE',
    plural: 'RESOURCES',
    type: new ApiState(),
    references: {
      depot: 'place',
      user: 'user',
      vehicle: 'vehicle',
    },
  },
  role: {
    name: 'ROLE',
    plural: 'ROLES',
    type: new ApiState(),
  },
  route: {
    name: 'ROUTE',
    plural: 'ROUTES',
    type: new ApiState(),
    afterGet: Route.create,
    references: {
      start_place: 'place',
      end_place: 'place',
      resource: 'resource',
      deliveries: 'delivery',
    },
  },
  user: {
    name: 'USER',
    plural: 'USERS',
    type: new ApiState(),
    references: {
      role: 'role',
    },
  },
  vehicle: {
    name: 'VEHICLE',
    plural: 'VEHICLES',
    type: new ApiState(),
  },
};
