<template>
  <list-layout>
    <h3 slot="title">{{ $t('sentence.organizationList') }}</h3>
    <base-list :data="tableData" v-model="search">
      <el-table-column :label="$t('commons.name') | upperFirst" prop="name">
      </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <div class="pull-right btn-group">
            <cs-button
              square
              icon="edit"
              icon-type="material"
              type="primary"
              @click="
                $router.push({
                  name: 'adminEditOrganization',
                  params: { id: scope.row.id },
                })
              "
            >
            </cs-button>
          </div>
        </template>
      </el-table-column>
    </base-list>
    <cs-button
      slot="footer"
      type="highlight"
      icon-type="material"
      icon="add"
      @click="$router.push({ name: 'adminCreateOrganization' })"
    >
      {{ $t('commons.create') }}
    </cs-button>
  </list-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseList from '@/components/lists/BaseList';
import ListLayout from '@/layouts/ListLayout';

export default {
  components: { BaseList, ListLayout },
  data: () => ({
    loading: true,
    search: '',
  }),
  computed: {
    ...mapGetters(['organizations']),
    tableData() {
      return Object.values(this.organizations.items).filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  async created() {
    await this.get({ type: 'organization', url: 'admin/organization' });
    this.loading = false;
  },
  methods: {
    ...mapActions(['get']),
    createOrg() {},
  },
};
</script>

<style lang="scss" scoped></style>
