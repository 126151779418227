<template>
  <section>
    <div class="print-actions">
      <cs-button
        icon-type="material"
        icon="print"
        class="print-icon"
        @click="print"
      >
      </cs-button>
    </div>
    <div id="pagePrint" ref="toPrint" class="page" size="A4">
      <slot></slot>
      <div class="print-hideDetails"></div>
      <div class="print-footer">
        <div>
          <img
            class="print-footer-logo"
            src="@/images/logitricks_logo_ligne.png"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
