import Vue from 'vue';
import { isNil, get, isEmpty, isArray, some } from 'lodash';
import jwt_decode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import router from '@/router';
import store from '@/store';

const isAllowed = (name) =>
  get(store.getters, `loggedUser.role.modules.${name}`, false);

const can = (name, list) => {
  const check = (ability) =>
    get(store.getters, `loggedUser.role.abilities.${name}.${ability}`, false);
  return isArray(list) ? some(check) : check(list);
};

const authenticate = (email, password) => {
  return Vue.axios.post('authenticate', { email, password });
};

const setUserData = (user) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
  store.commit('SET_LOGGED_USER', user);
  store.dispatch('setIsLogged', !isNil(user));
  store.dispatch('setInit', !isNil(user));
};

const loadBoot = (withUser) => {
  const promises = [];
  if (withUser) {
    promises.push(
      store.dispatch('get', { type: 'user', id: 'me' }).then((user) => {
        setUserData(user);
      })
    );
  }
  promises.push(store.dispatch('get', { type: 'organization', id: 'me' }));

  return Promise.all(promises);
};

const getToken = () => localStorage.getItem('id_token');

const hasToken = () => !isEmpty(getToken());

const setToken = (token) => {
  if (!isNil(token)) {
    localStorage.setItem('id_token', token);
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return getToken();
};

const clearToken = () => {
  localStorage.removeItem('id_token');
  Vue.axios.defaults.headers.common.Authorization = undefined;
};

const checkLogin = () => isLogged() || hasToken();

const isLogged = () => {
  return store.getters.logged && store.getters.inited;
};

const hasUser = () => store.getters.loggedUser.id;

const isAdmin = () => {
  try {
    const payload = jwt_decode(getToken());
    return get(payload, 'adm');
  } catch (e) {
    return false;
  }
};

const getNextRoute = (next) => {
  const defaultNext = isAdmin() ? '/admin' : '/';

  return next || defaultNext;
};

const login = (email, password, next) => {
  if (isLogged()) {
    router.push(getNextRoute(next));
    return Promise.resolve(true);
  }
  store.dispatch('reset');
  if (hasToken()) {
    return loadBoot(true).then(() => router.push(getNextRoute(next)));
  }
  return authenticate(email, password).then((response) => {
    setToken(response.data.token);
    setUserData(response.data.user);
    loadBoot(false).then(() => router.push(getNextRoute(next)));
  });
};

const loginAs = async (id) => {
  const { data } = await Vue.axios.get(`admin/loginas/${id}`);
  store.dispatch('reset');
  setToken(data.token);
  setUserData(data.user);
  await loadBoot(false);
  router.push('/');
};

const logout = () => {
  setUserData();
  clearToken();
  store.dispatch('reset');
  store.dispatch('setIsLogged', false);
  store.dispatch('setInit', false);
  router.push({ name: 'login' });
};

const AuthPlugin = {
  install: (vm) => {
    vm.prototype.$can = can;
    vm.prototype.$isAllowed = isAllowed;
    vm.prototype.$login = login;
    vm.prototype.$logout = logout;
  },
};

export {
  AuthPlugin,
  can,
  setToken,
  getToken,
  isAllowed,
  isLogged,
  isAdmin,
  checkLogin,
  login,
  loginAs,
  logout,
  loadBoot,
  hasUser,
};
