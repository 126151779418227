<template lang="html">
  <el-aside class="map-aside" :width="collapse ? '64px' : '150px'">
    <el-menu
      id="map-menu"
      active-text-color="#158CBA"
      :collapse="collapse"
      :collapse-transition="false"
    >
      <el-menu-item
        id="menu-tool-button"
        index="0"
        :class="{ 'force-active': displayTools }"
        @click="updateTools"
      >
        <i class="material-icons">build</i>
        <span slot="title"> {{ $t('commons.tool.plural') | upperFirst }} </span>
      </el-menu-item>

      <el-menu-item index="1" @click="toggleSidebar('info')">
        <i class="material-icons">info</i>
        <span slot="title"> {{ $t('commons.infos') | upperFirst }} </span>
      </el-menu-item>

      <el-menu-item index="2" @click="toggleSidebar('selection')">
        <el-badge
          class="mini-badge"
          :value="selectionCount"
          :hidden="selectionCount === 0"
        >
          <i class="material-icons">format_list_numbered</i>
        </el-badge>
        <span slot="title"> {{ $t('commons.selection') | upperFirst }} </span>
      </el-menu-item>

      <el-menu-item index="3" @click="$router.push({ name: 'planning' })">
        <i class="material-icons">local_shipping</i>
        <span slot="title"> {{ $t('commons.planning') | upperFirst }} </span>
      </el-menu-item>

      <el-menu-item index="4" @click="toggleSidebar('optimization')">
        <i class="fa fa-compass"></i>
        <span slot="title">
          {{ $t('commons.optimization.single') | upperFirst }}
        </span>
      </el-menu-item>
    </el-menu>

    <cs-button
      id="expand-button"
      icon-type="material"
      :icon="collapse ? 'chevron_right' : 'chevron_left'"
      @click="collapse = !collapse"
    >
    </cs-button>
  </el-aside>
</template>

<script>
import { size } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    collapse: false,
    displayTools: true,
  }),
  computed: {
    ...mapGetters(['selection']),
    selectionCount() {
      return size(this.selection.data);
    },
  },
  methods: {
    ...mapActions(['toggleSidebar']),
    updateTools() {
      this.displayTools = !this.displayTools;
      this.$emit('updateTools', this.displayTools);
    },
  },
};
</script>

<style lang="scss">
.mini-badge {
  .el-badge__content.is-fixed {
    top: 20px;
  }
  .el-badge__content {
    font-size: 9px;
    height: 13px;
    line-height: 1.3em;
    padding: 0 4px;
    border: none;
  }
}

.map-aside {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  border-right: solid 1px #e6e6e6;
  .el-menu-item {
    .material-icons,
    .fa {
      width: 24px;
      text-align: center;
      font-size: 18px;
      vertical-align: middle;
      color: inherit !important;
    }
  }
  #map-menu {
    overflow-x: hidden;
    background-color: #f0f3f2;
    border: none;
  }
  #expand-button {
    color: #909399;
    background-color: #f0f3f2;
    width: 100%;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
  }
  #menu-tool-button {
    &:not(.force-active) {
      color: #909399 !important;
    }
    &.force-active {
      color: #158cba !important;
    }
  }
}
</style>
