<template lang="html">
  <span>
    <span class="route-title">{{ route.name }}</span>
    <div class="timeline-route extended" style="width: 100%"></div>
  </span>
</template>

<script>
export default {
  props: {
    routeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    route() {
      return this.$parent.routesObject[this.routeId];
    },
  },
};
</script>
