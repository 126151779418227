<template>
  <print-template>
    <activity-print v-if="activity" :activity="activity" />
  </print-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import { printElement } from 'print-html-element';
import ActivityPrint from '@/components/print/ActivityPrint';
import PrintTemplate from '@/components/print/PrintTemplate';

export default {
  name: 'Print',
  components: {
    ActivityPrint,
    PrintTemplate,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['organization']),
    activity() {
      return get(this.$store.getters.deliveries.items, this.$route.params.id);
    },
  },
  created() {
    this.fetchActivity();
  },
  methods: {
    ...mapActions(['get']),
    async fetchActivity() {
      try {
        await this.get({
          type: 'delivery',
          id: this.$route.params.id,
          url: `delivery/${this.$route.params.id}/pdf`,
          query: this.$route.query,
        });
        this.loading = false;
      } catch (error) {
        this.$router.push('/');
      }
    },
    print() {
      printElement(this.$refs.toPrint);
    },
  },
};
</script>
