<template lang="html" functional>
  <div
    class="route-check"
    :style="`background-color: ${props.route.color}`"
    @change="listeners.change ? listeners.click($event) : undefined"
  >
    <i class="material-icons">show_chart</i>
    {{ props.route.name }}
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.route-check {
  overflow: hidden;
  border-bottom: 2px solid $primary-color-text;
  text-overflow: ellipsis;
  color: white;
  font-size: 12px;

  .material-icons {
    vertical-align: -3px;
  }
}
</style>
