import { isNumber } from 'lodash';
import { numberToLetter, toAlphaNum } from '@/utils/helpers';

export function getBarcode(row, col, place, level = 1) {
  const rowName = isNumber(row) ? numberToLetter(parseInt(row)) : row;
  return `${rowName.toUpperCase()}${toAlphaNum(parseInt(col), 2)}L${toAlphaNum(
    level,
    1
  )}P${place}`;
}
