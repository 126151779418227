<script>
import { invertColor } from '@/utils/color';

export default {
  functional: true,
  props: {
    text: {
      type: String,
      default: '',
    },
    svg: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#1a4e95',
    },
    material: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: 'inherit',
    },
    lineHeight: {
      type: String,
      default: '30px',
    },
    extraClass: {
      type: String,
      default: '',
    },
    ganttCentered: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, context) {
    context.data.class = {
      ...context.data.class,
      'activity-icon': true,
      'gantt-centered': context.props.ganttCentered,
    };
    const svg = createElement('svg', {
      class: 'svg-icon',
      attrs: {
        version: '1.1',
        xmlns: 'http://www.w3.org/2000/svg',
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        width: '30px',
        height: '30px',
        lineHeight: context.props.lineHeight,
        viewBox: '0 0 30 30',
        fill: context.props.color,
      },
      domProps: {
        innerHTML: context.props.svg,
      },
    });
    const ctx = {
      class: {
        'activity-icon-i': true,
        'material-icons': context.props.material,
        'text-icon': !context.props.material,
      },
      style: {
        color:
          context.props.color === '#1a4e95'
            ? '#1a4e95'
            : invertColor(context.props.color, true),
        fontSize: context.props.fontSize,
        lineHeight: context.props.lineHeight,
      },
      domProps: {
        innerHTML: context.props.text,
      },
    };
    ctx.class[context.props.extraClass] = true;
    const i = createElement('i', ctx);
    return createElement('div', context.data, [svg, i]);
  },
};
</script>
