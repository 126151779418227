import Vue from 'vue';
import Vuex from 'vuex';
import { ApiStorePlugin } from 'vuex-rest-plugin';
import app from './modules/app';
import filter from './modules/filter';
import map from './modules/map';
import selection from './modules/selection';
import sidebar from './modules/sidebar';
import models from '@/models/api-models';
import axios from '@/services/axios';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    filter,
    selection,
    sidebar,
    map,
  },
  plugins: [
    ApiStorePlugin({
      axios,
      namespaced: false,
      models,
    }),
  ],
});
