import ApiEntity from './ApiEntity';
import Sla from './Sla';

export default class Timeoff extends ApiEntity {
  start;
  end;
  sla;
  constructor(o) {
    super();
    this.start = this.castDate(o.start);
    this.end = this.castDate(o.end);
    this.sla = new Sla(o.sla);
  }

  static create(data) {
    return Timeoff.createInstance(data);
  }
}
