<template lang="html">
  <section class="activity-content">
    <!-- informations component -->

    <edit-details :title="$t('commons.infos')">
      <div>
        <ObjectDisplay
          :width="'98px'"
          :data="activity"
          :only="{
            receiver_name: 'commons.name',
            receiver_phone: 'commons.phone',
            text: 'commons.note',
            delivery_term: 'components.entities.activityDetails.moreInfos',
          }"
          :sort="true"
        />
        <external-data
          :widthTable="'98px'"
          :value="activity.external_data"
          :without="without"
        />
      </div>
      <div slot="editContent" :style="{ width: '100%' }" class="edit-element">
        <el-row
          v-for="(val, key) in {
            receiver_name: 'commons.name',
            receiver_phone: 'commons.phone',
            text: 'commons.note',
            delivery_term: 'components.entities.activityDetails.moreInfos',
          }"
          :key="val"
          :style="{ marginBottom: '0px' }"
          type="flex"
          align="middle"
        >
          <el-col :span="6">
            <span class="left-key-display">{{ $t(val) }}</span>
          </el-col>
          <el-col :span="18">
            <el-input
              :style="inputStyle(key)"
              v-model="activity[key]"
              required="required"
              type="text"
              @change="saveToQueue"
              clearable
            />
          </el-col>
        </el-row>
        <el-row
          v-for="(data, idx) in activity.external_data"
          :key="idx"
          :style="{ marginBottom: '0px' }"
          type="flex"
          align="middle"
        >
          <el-col
            v-if="
              data.value && data.value.length && !without.includes(data.name)
            "
            :span="6"
          >
            <span class="left-key-display">{{ data.name }}</span>
          </el-col>
          <el-col
            v-if="data.value != '' && !without.includes(data.name)"
            :span="18"
          >
            <el-input
              v-model="data.value"
              required="required"
              type="text"
              @change="saveToQueue"
              clearable
            />
          </el-col>
        </el-row>
      </div>
    </edit-details>

    <!-- parcel component -->

    <edit-details v-if="activity.items" :title="$t('commons.item.plural')">
      <item-status v-for="item in activity.items" :key="item.id" :item="item" />
    </edit-details>
    <!-- when component -->

    <edit-details
      :activator-name="$t('commons.appointment.single')"
      :title="$t('commons.when')"
    >
      <div :style="{ width: '100%' }">
        <el-row type="flex">
          <el-col :span="6">
            <span class="left-key-display"> {{ $t('commons.duration') }} </span>
          </el-col>
          <el-col :span="18">
            <span class="right-key-display">
              {{ activity.duration | seconds }}
            </span>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="7">
            <span class="left-key-display">
              {{ $t('commons.appointment.single') }}
            </span>
          </el-col>
          <appointment size="mini" :appointment="activity.appointment" />
        </el-row>
      </div>
      <template slot="editContent" slot-scope="{ edit }" class="test">
        <el-row>
          <el-col :span="6">
            <span class="left-key-display"> {{ $t('commons.duration') }} </span>
          </el-col>
          <el-col :span="18">
            <el-input-number
              v-model="activity.duration"
              :step="60"
              placeholder="__________"
              controls-position="right"
              size="mini"
              :min="0"
              :controls="true"
              @change="saveToQueue"
            />
            <span>sec</span>
          </el-col>
        </el-row>
        <el-row>
          <appointment
            size="mini"
            v-model="activity.appointment"
            :default-date="activity.planned_start"
            :edit="edit"
            @change="saveToQueue"
          />
        </el-row>
      </template>
    </edit-details>

    <!-- sla component -->

    <edit-details :title="$t('commons.sla.plural')">
      <div :style="{ width: '100%' }">
        <sla v-model="activity.slas" />
      </div>
      <template
        slot="editContent"
        slot-scope="{ edit }"
        :style="{ width: '100%' }"
      >
        <sla v-model="activity.slas" :edit="edit" @change="saveToQueue"> </sla>
      </template>
    </edit-details>

    <edit-details
      ref="where"
      :activator-name="$t('commons.address.single')"
      :title="$t('commons.where')"
    >
      <div :style="{ width: '100%' }">
        <el-row type="flex" align="middle">
          <el-col :span="6">
            <span class="left-key-display">
              {{ $t('commons.address.single') }}
            </span>
          </el-col>
          <el-col :span="18">
            <place small :entity="activity">
              <el-tooltip :content="$t('commons.address.single')"> </el-tooltip>
            </place>
          </el-col>
        </el-row>
      </div>
      <!-- <template
        slot="editContent"
        slot-scope="{ edit }"
        :style="{ width: '100%' }"
      > -->
      <geocode
        v-model="activity.place_geocoded"
        slot="editContent"
        slot-scope="{ edit }"
        :style="{ width: '100%' }"
        :visible="edit"
        :entity-id="activity.id"
        :place="activity.place"
        @close="$refs.where.close"
      />
      <!-- </template> -->
    </edit-details>

    <!-- constraints component -->

    <constraints type="activity" :entity="activity" @change="saveToQueue" />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import Appointment from './Appointment';
import Constraints from './Constraints';
import EditDetails from '../EditDetails';
import Geocode from '@/components/Geocode';
import ObjectDisplay from '@/components/ObjectDisplay';
import Place from '@/components/Place';
import ItemStatus from '@/components/ItemStatus';
import ExternalData from '@/components/ExternalData';
import Sla from '../Sla';

export default {
  components: {
    Appointment,
    Constraints,
    EditDetails,
    Geocode,
    ItemStatus,
    ObjectDisplay,
    Place,
    ExternalData,
    Sla,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  watch: {
    activity: {
      deep: true,
      handler() {
        this.fetchItems();
      },
    },
  },
  mounted() {
    this.fetchItems();
  },
  data: () => ({
    without: ['text', 'delivery_term'],
  }),
  methods: {
    ...mapActions(['queueAction']),
    async fetchItems() {
      if (!this.activity.items) {
        const { data } = await this.axios.get(`delivery/${this.activity.id}`);
        this.activity.items = data.items;
      }
    },
    saveToQueue() {
      this.queueAction({
        action: 'patch',
        type: 'delivery',
        data: this.activity,
      });
    },
    inputStyle(key) {
      return {
        borderBottom:
          !this.activity[key] || !this.activity[key].length
            ? '1px solid #ebeef5 !important'
            : '',
      };
    },
  },
};
</script>

<style lang="scss">
.content-section,
.activity-constraints {
  min-width: 359px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 20px;
}
</style>
