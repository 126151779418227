<template>
  <base-layout>
    <h3 slot="title">{{ $t('components.XlsxImporter.title') }}</h3>
    <h4 slot="subtitle">
      {{ $t('sentence.selectClientUpload') }}
    </h4>

    <el-select
      v-model="client"
      :placeholder="$t('commons.select')"
      @change="$router.push({ name: 'mapper', params: { id: client.id } })"
    >
      <el-option
        v-for="(item, id) in clients.items"
        :key="id"
        :label="item.name"
        :value="item"
      >
      </el-option>
    </el-select>
  </base-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    client: undefined,
    loading: true,
  }),
  computed: {
    ...mapGetters(['clients']),
  },
  async created() {
    await this.get({ type: 'client', id: 'all' });
    this.loading = false;
  },
  methods: {
    ...mapActions(['get']),
  },
};
</script>
