<template lang="html">
  <div class="gantt-travel" :class="[hiddenClass]"></div>
</template>

<script>
import { get } from 'lodash';

export default {
  props: {
    routeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    hiddenClass() {
      const route = this.$store.getters.routes.items[this.routeId];
      return get(route, 'displayPolyline') || get(route, 'displayMarkers')
        ? ''
        : 'gantt-hidden';
    },
  },
};
</script>

<style lang="css"></style>
