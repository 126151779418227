<template>
  <div class="optim-title">
    <div class="optim-title__left">
      <i class="material-icons btn-dark-background optim-title__icon">
        settings
      </i>
      <span class="optim-title__name">
        <slot name="title">
          <h3>{{ name | upperFirst }}</h3>
        </slot>
      </span>

      <slot name="actions-left" />
    </div>
    <div class="optim-title__right">
      <state class="optim-title__status" type="optimization" :value="state" />

      <slot name="actions-right" />

      <cs-button
        icon-type="material"
        icon="close"
        class="btn-invisible optim-title__btn"
        @click="$router.back()"
      />
    </div>
  </div>
</template>

<script>
import State from '@/components/State';

export default {
  components: { State },
  props: {
    name: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: 'new',
    },
  },
};
</script>

<style lang="scss">
.optim-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left,
  &__right {
    display: flex;
  }

  &__left {
    > * {
      margin-right: 20px;
    }
  }
  &__right {
    justify-content: flex-end;
    > * {
      margin-left: 20px;
    }
  }

  &__name,
  &__edit-name {
    align-items: center;
    display: flex;
    line-height: 60px;
  }

  &__name {
    margin-bottom: 0;
  }

  &__status {
    align-items: center;
  }

  &__icon,
  &__btn {
    height: 60px !important;
    width: 60px;
    text-align: center;

    &.material-icons,
    .material-icons {
      font-size: 24px;
    }
  }
  &__icon {
    line-height: 60px;
  }
}
</style>
