<template>
  <el-col
    :xs="{ span: 24, offset: 0 }"
    :sm="{ span: 18, offset: 3 }"
    :md="{ span: 12, offset: 6 }"
    :lg="{ span: 6, offset: 9 }"
  >
    <el-form
      ref="form"
      :model="user"
      :rules="rules"
      autocomplete="off"
      @submit.prevent
    >
      <el-form-item
        :label="$t('pages.user.UserForm.firstName')"
        prop="first_name"
      >
        <el-input
          id="first_name"
          v-model="user.first_name"
          name="first_name"
          type="text"
        />
      </el-form-item>
      <el-form-item :label="$t('commons.name')" prop="last_name">
        <el-input
          id="last_name"
          v-model="user.last_name"
          name="last_name"
          type="text"
        />
      </el-form-item>
      <el-form-item :label="$t('commons.mail')" prop="email">
        <el-input
          id="email"
          v-model="user.email"
          name="email"
          type="email"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        v-if="isCreate"
        :label="$t('pages.user.UserForm.password')"
        :required="!user.id"
        class="form-password"
        prop="password"
      >
        <el-input
          id="password"
          v-model="user.password"
          :placeholder="$t('sentence.modifyPassword')"
          name="password"
          type="password"
          autocomplete="off"
        />
      </el-form-item>

      <el-form-item :label="$t('commons.role.single')" prop="role">
        <el-select
          id="role"
          v-model="user.role"
          clearable
          value-key="id"
          name="role"
          :placeholder="$t('commons.selected')"
        >
          <el-option
            v-for="role in roles.items"
            :key="role.id"
            :value="role"
            :label="role.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getRequireRule, getTypeRule, getPasswordRule } from '@/validation';

export default {
  name: 'UserForm',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['roles']),
    rules() {
      return {
        first_name: [getRequireRule('pages.user.UserForm.firstName')],
        last_name: [getRequireRule('commons.name')],
        email: [getRequireRule('commons.mail'), getTypeRule('email')],
        password: [
          getRequireRule('pages.user.UserForm.password'),
          getPasswordRule,
        ],
        role: [getRequireRule('commons.role.single')],
      };
    },
  },
  created() {
    this.get({ type: 'role', id: 'all' });
  },
  methods: {
    ...mapActions(['get']),
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => (valid ? resolve() : reject()));
      });
    },
  },
};
</script>
<style lang="scss">
.form-password {
  margin-bottom: 40px;
}
</style>
