<template lang="html">
  <section class="info-route">
    <div class="info-head">
      <span class="info-highlight">
        {{ sortedRoutes.used.length }} <sup>/{{ realRoutes.length }}</sup>
      </span>
      <el-progress
        type="circle"
        color="#32353b"
        :stroke-width="4"
        :percentage="percent"
        :show-text="false"
      />
    </div>
    <div class="info-content">
      <div class="info-row">
        <div>
          <el-checkbox
            :disabled="sortedRoutes.used.length === 0"
            :value="selectUsed"
            @change="toggleUsedSelection"
          />
          <cs-button
            outline
            icon-type="material"
            :disabled="sortedRoutes.used.length === 0"
            :icon="displayUsed ? 'visibility' : 'visibility_off'"
            @click="toggleUsedDisplay"
          />
          <span>{{
            $tc('commons.used.choice.female', sortedRoutes.used.length)
          }}</span>
        </div>
        <div class="info-count">{{ sortedRoutes.used.length }}</div>
      </div>

      <div class="info-row">
        <div class="no-action">
          <span
            >{{ $t('commons.not') }}
            {{
              $tc('commons.used.choice.female', sortedRoutes.used.length)
            }}</span
          >
        </div>
        <div class="info-count">{{ sortedRoutes.unused.length }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  differenceBy,
  filter,
  get,
  groupBy,
  round,
  some,
  toSafeInteger,
} from 'lodash';

export default {
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    unplannedRoute: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['selection']),
    sortedRoutes() {
      const groups = groupBy(this.realRoutes, (r) => r.deliveries.length === 0);
      return {
        unused: get(groups, true, []),
        used: get(groups, false, []),
      };
    },
    realRoutes() {
      return filter(this.routes, 'id');
    },
    percent() {
      const percent =
        (this.sortedRoutes.used.length / this.realRoutes.length) * 100;
      return toSafeInteger(round(percent));
    },
    selectUsed() {
      return this.sortedRoutes.used.length > 0 && this.diffUsed.length === 0;
    },
    diffUsed() {
      return differenceBy(this.sortedRoutes.used, this.selection.data, 'id');
    },
    displayUsed() {
      return !some(this.realRoutes, ['displayMarkers', false]);
    },
  },
  methods: {
    ...mapActions(['addRoutesToSelection', 'removeRoutesFromSelection']),
    toggleUsedSelection(v) {
      if (v) {
        this.addRoutesToSelection({ data: this.diffUsed });
      } else {
        this.removeRoutesFromSelection({ data: this.sortedRoutes.used });
      }
    },
    toggleUsedDisplay() {
      const display = !this.displayUsed;
      this.realRoutes.forEach((r) => {
        r.displayMarkers = display;
        r.displayPolyline = display;
      });
    },
  },
};
</script>

<style lang="scss"></style>
