<template>
  <base-layout>
    <h3 slot="title">{{ $t('pages.vehicle.create') }}</h3>
    <vehicle-form ref="form" :vehicle="vehicle" :saving="saving"></vehicle-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexVehicle' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveVehicle"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';
import VehicleForm from './forms/VehicleForm';

export default {
  name: 'CreateVehicle',
  components: {
    VehicleForm,
  },
  data: () => ({
    saving: false,
    vehicle: {
      capacities: [],
      tools: [],
    },
  }),
  methods: {
    ...mapActions(['post']),
    saveVehicle() {
      this.$refs.form.validate().then(() => {
        this.saving = true;
        this.post({ type: 'vehicle', data: this.vehicle })
          .then(() => {
            this.saving = false;
            this.$router.push({ name: 'indexVehicle' });
          })
          .catch(() => {
            this.saving = false;
          });
      });
    },
  },
};
</script>
