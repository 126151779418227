<template>
  <vue-cal
    hide-view-selector
    hide-title-bar
    start-week-on-sunday
    :locale="$i18n.locale"
    :editable-events="{
      title: false,
      drag: editable,
      resize: editable,
      delete: false,
      create: editable,
    }"
    :disable-views="['years', 'year', 'month', 'day']"
    :events="events"
    :snap-to-time="5"
    @event-drop="onDrop"
    @event-duration-change="onUpdate"
    @event-drag-create="onCreate"
    @cell-dblclick="onCreateDblClick"
  >
    <template v-slot:event="{ event }">
      <div class="vuecal__event-content">
        <div>{{ event.start | format('HH:mm') }}</div>

        <cs-button
          v-if="editable"
          square
          icon="delete"
          icon-type="material"
          type="danger"
          @click="onDelete(event)"
        />

        <div>{{ event.end | format('HH:mm') }}</div>
      </div>
    </template>
  </vue-cal>
</template>

<script>
import VueCal from 'vue-cal';
import { setDay, isEqual, addHours, isWithinInterval } from 'date-fns';
import { sortBy } from 'lodash';
import 'vue-cal/dist/drag-and-drop.js';
import 'vue-cal/dist/i18n/fr.js';
import 'vue-cal/dist/vuecal.css';

function eventToDate(day, time) {
  const timeArray = time.split(':');
  const date = setDay(new Date(), day);
  date.setHours(timeArray[0], timeArray[1]);

  return date;
}

function getPlanningEvent(event) {
  return {
    start: event.start.format('H:mm'),
    end: event.end.format('H:mm'),
    idx: event.start.getDay(),
  };
}

function toPlanning(events) {
  return events.reduce(
    (acc, event) => {
      const planningEvent = getPlanningEvent(event);
      acc[planningEvent['idx']].push(planningEvent);

      return acc;
    },
    [[], [], [], [], [], [], []]
  );
}

function findEventIndex(events, event) {
  return events.findIndex(
    ({ start, end }) =>
      isEqual(start, new Date(event.start)) && isEqual(end, new Date(event.end))
  );
}

function noOverlap(events) {
  return sortBy(events, 'start').map((event, idx, list) => {
    const prevIdx = idx > 0 ? idx - 1 : undefined;
    if (prevIdx > -1) {
      const prevEvent = list[prevIdx];
      if (isWithinInterval(event.start, prevEvent)) {
        event.start = new Date(prevEvent.end);
      }
    }
    return event;
  });
}

export default {
  components: { VueCal },
  props: {
    planning: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    events() {
      return this.planning.reduce((list, events, day) => {
        events.forEach(({ start, end }) => {
          list.push({
            start: eventToDate(day, start),
            end: eventToDate(day, end),
          });
        });
        return list;
      }, []);
    },
  },
  methods: {
    onCreateDblClick(start) {
      const event = {
        start,
        end: addHours(start, 1),
      };
      this.onCreate(event);
    },
    onCreate(event) {
      this.change([...this.events, event]);
    },
    onDelete(event) {
      this.spliceEvent(event);
    },
    onUpdate() {
      this.change(this.events);
    },
    onDrop({ event, originalEvent }) {
      this.spliceEvent(originalEvent, event);
    },
    change(events) {
      this.$emit('change', toPlanning(noOverlap(events)));
    },
    spliceEvent(originalEvent, newEvent) {
      const events = [...this.events];
      if (newEvent) {
        events.splice(findEventIndex(events, originalEvent), 1, newEvent);
      } else {
        events.splice(findEventIndex(events, originalEvent), 1);
      }

      this.change(events);
    },
  },
};
</script>

<style lang="scss">
.vuecal__event {
  cursor: pointer;
  color: #606266;
  border: solid 1px;
}

.vuecal__event-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.weekday-label {
  span:last-child {
    display: none;
  }
}
</style>
