<template lang="html">
  <section class="state-editor">
    <div
      class="state outlined tag"
      v-if="display === 'tag'"
      :class="`state-${state}`"
    >
      <slot>
        {{ $t(`commons.states.${type}.${state}`) }}
      </slot>
    </div>

    <h2 v-else class="state editable outlined" :class="`state-${state}`">
      {{ $t(`commons.states.${type}.${state}`) }}
    </h2>

    <edit-toggle v-if="editable" v-model="edit" />

    <el-dialog
      v-if="editable"
      :visible.sync="edit"
      :title="$t('commons.editState')"
      @close="cancel"
    >
      <slot name="transtions">
        <el-radio-group
          v-if="transitionList.length > 0"
          v-model="transitTo"
          class="transition-group"
          :disabled="!$can(type, 'update')"
        >
          <el-radio-button
            v-for="(transition, idx) in transitionList"
            :key="idx"
            :label="transition"
          >
            {{ $t(`commons.transitions.${type}.${transition}`) }}
          </el-radio-button>
        </el-radio-group>
        <el-alert
          v-else
          type="info"
          show-icon
          :description="$t('sentence.noTransition') | upperFirst"
          :closable="false"
        >
        </el-alert>
      </slot>

      <div slot="footer" class="btn-group">
        <cs-button @click="cancel">
          {{ $t('commons.cancel') }}
        </cs-button>
        <cs-button type="primary" :disabled="!transitTo" @click="validate">
          {{ $t('commons.validate') }}
        </cs-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { isObject, difference } from 'lodash';
import EditToggle from '@/components/EditToggle';

export default {
  components: {
    EditToggle,
  },
  props: {
    display: {
      type: String,
      default: 'tag',
      validator: (d) => ['tag', 'title'].includes(d),
    },
    type: {
      type: String,
      required: true,
      validator: (t) =>
        ['item', 'route', 'delivery', 'optimization'].includes(t),
    },
    value: {
      type: [Object, String],
      required: true,
    },
    transitions: {
      type: Array,
      default: () => [],
    },
    excludeTransitions: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    edit: false,
    transitTo: undefined,
  }),
  computed: {
    state() {
      return isObject(this.value) ? this.value.state : this.value;
    },
    transitionList() {
      return difference(this.transitions, this.excludeTransitions);
    },
  },
  methods: {
    cancel() {
      this.transitTo = undefined;
      this.edit = false;
    },
    validate() {
      this.$emit('change', this.transitTo);
      this.cancel();
    },
  },
};
</script>

<style lang="scss">
.state {
  padding: 2px 5px;
  font-weight: 100;
  &:not(.editable) {
    border: 1px solid;
  }
}
.editable {
  color: inherit !important;
}
.state-editor {
  display: inline-flex;
  .transition-group {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    .el-radio-button__inner {
      border-left: 1px solid #dcdfe6;
    }
  }
}
</style>
