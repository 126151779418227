<template>
  <el-form
    ref="form"
    :model="place"
    :rules="rules"
    label-width="120px"
    label-position="top"
    auto-complete="off"
    @validate="validate"
  >
    <el-row :gutter="20">
      <el-col
        :xs="{ span: 10, offset: 1 }"
        :sm="{ span: 8, offset: 3 }"
        :md="{ span: 6, offset: 5 }"
        :lg="{ span: 4, offset: 7 }"
      >
        <el-form-item :label="$t('commons.name')" prop="name">
          <el-input v-model="place.name" name="name" type="text"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :sm="10" :md="8" :lg="6">
        <el-form-item :label="$t('commons.id')" prop="id">
          <el-input
            v-model="place.external_id"
            name="external_id"
            type="text"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col
        :xs="{ span: 8, offset: 1 }"
        :sm="{ span: 6, offset: 3 }"
        :md="{ span: 4, offset: 5 }"
        :lg="{ span: 2, offset: 7 }"
      >
        <el-form-item
          :label="$t('pages.place.PlaceForm.number')"
          prop="street_number"
        >
          <el-input
            v-model="place.street_number"
            name="street_number"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="14" :sm="12" :md="10" :lg="8">
        <el-form-item :label="$t('commons.street')" prop="street_name">
          <el-input
            v-model="place.street_name"
            name="street_name"
            type="text"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col
        :xs="{ span: 22, offset: 1 }"
        :sm="{ span: 18, offset: 3 }"
        :md="{ span: 14, offset: 5 }"
        :lg="{ span: 10, offset: 7 }"
      >
        <el-form-item
          :label="$t('pages.place.PlaceForm.secondaryAddress')"
          prop="secondaryAddress"
        >
          <el-input
            v-model="place.secondary"
            name="secondary"
            type="text"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="{ span: 8, offset: 1 }"
        :sm="{ span: 5, offset: 3 }"
        :md="{ span: 4, offset: 5 }"
        :lg="{ span: 3, offset: 7 }"
      >
        <el-form-item :label="$t('commons.postcode')" prop="postcode">
          <el-input v-model="place.postcode" name="postcode"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="8" :sm="7" :md="6" :lg="4">
        <el-form-item :label="$t('commons.city')" prop="city">
          <el-input v-model="place.city" name="city" type="text"></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="6" :sm="6" :md="4" :lg="3">
        <el-form-item
          :label="$tc('commons.country.choice', 1)"
          prop="country_code"
        >
          <country-select
            :country-code="place.country_code"
            @change="setCountryCode"
          ></country-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="{ span: 8, offset: 1 }"
        :sm="{ span: 5, offset: 3 }"
        :md="{ span: 4, offset: 5 }"
        :lg="{ span: 3, offset: 7 }"
      >
        <el-form-item :label="$t('commons.latitude')" prop="latitude">
          <el-input
            v-model="place.latitude"
            name="latitude"
            type="number"
            step="0.00001"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :xs="8" :sm="5" :md="4" :lg="3">
        <el-form-item :label="$t('commons.longitude')" prop="longitude">
          <el-input
            v-model="place.longitude"
            name="longitude"
            type="number"
            step="0.00001"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import CountrySelect from '@/components/CountrySelect';
import {
  getRequireRule,
  getLatitudeRule,
  getLongitudeRule,
} from '@/validation';

export default {
  name: 'PlaceForm',
  components: {
    CountrySelect,
  },
  props: {
    place: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    rules() {
      return {
        name: [getRequireRule('commons.name')],
        street_name: [getRequireRule('commons.street_name')],
        city: [getRequireRule('commons.city')],
        latitude: [getLatitudeRule()],
        longitude: [getLongitudeRule()],
      };
    },
  },
  methods: {
    setCountryCode(code) {
      this.place.country_code = code;
    },
    validate(name, valid) {
      this.$emit('input', valid);
    },
  },
};
</script>
