import _ from 'lodash';
import { numberToLetter } from '@/utils/helpers';

const state = {
  selection: {
    data: [],
    type: 'unknown',
    draggable: true,
  },
};

const mutations = {
  SET_SELECTED_OBJECT: (myState, o) => {
    _.forEach(o.data, (d, idx) => {
      if (d && !_.get(d, 'isSelected')) {
        d.isSelected = true;
        if (o.type === 'activity') {
          d.iconText = numberToLetter(idx + 1);
        }
      }
    });
    myState.selection.type = o.type;
    myState.selection.data = o.data;
    myState.selection.draggable = _.get(o, 'draggable', true);
  },
  ADD_OBJECT_TO_SELECTION: (myState, o) => {
    _.forEach(o.data, (d, idx) => {
      d.isSelected = true;
      if (o.type === 'activity') {
        d.iconText = numberToLetter(myState.selection.data.length + idx + 1);
      }
    });
    const toBeAdded = _.difference(o.data, myState.selection.data);
    myState.selection.data.push(...toBeAdded);
    myState.selection.type = o.type;
  },
  REMOVE_OBJECT_FROM_SELECTION: (myState, o) => {
    _.forEach(o.data, (toRemove) => {
      const index = _.findIndex(myState.selection.data, toRemove);
      if (index !== -1) {
        toRemove.isSelected = false;
        if (o.type === 'activity') {
          toRemove.resetIcon();
        }
        myState.selection.data.splice(index, 1);
      }
    });
    if (myState.selection.data.length === 0) {
      myState.selection.type = 'unknown';
    }
  },
  CLEAR_SELECTION: (myState) => {
    _.forEach(myState.selection.data, (d) => {
      if (d) {
        d.isSelected = false;
        if (myState.selection.type === 'activity' && !d.isOptimMode) {
          d.resetIcon();
        }
      }
    });
    myState.selection.data = [];
    myState.selection.type = 'unknown';
  },
};

const getters = {
  selection: (myState) => myState.selection,
};

const actions = {
  setSelected({ commit }, o) {
    commit('CLEAR_SELECTION');
    commit('SET_SELECTED_OBJECT', o);
  },
  setSelectedRoutes({ commit }, o) {
    commit('CLEAR_SELECTION');
    commit('SET_SELECTED_OBJECT', {
      type: 'route',
      data: o.data,
    });
  },
  addRoutesToSelection({ commit, state: myState }, o) {
    if (myState.selection.type !== 'activity' && !o.isSelected) {
      commit('ADD_OBJECT_TO_SELECTION', {
        type: 'route',
        data: o.data,
      });
    }
  },
  removeRoutesFromSelection({ commit, state: myState }, o) {
    if (myState.selection.type === 'route') {
      commit('REMOVE_OBJECT_FROM_SELECTION', {
        type: 'route',
        data: o.data,
      });
    }
  },
  setSelectedActivities({ commit }, o) {
    commit('CLEAR_SELECTION');
    commit('SET_SELECTED_OBJECT', {
      type: 'activity',
      data: o.data,
    });
  },
  addActivitiesToSelection({ commit, state: myState }, o) {
    if (myState.selection.type !== 'route' && !o.isSelected) {
      commit('ADD_OBJECT_TO_SELECTION', {
        type: 'activity',
        data: o.data,
      });
    }
  },
  removeActivitiesFromSelection({ commit, state: myState }, o) {
    if (myState.selection.type === 'activity') {
      commit('REMOVE_OBJECT_FROM_SELECTION', {
        type: 'activity',
        data: o.data,
      });
    }
  },
  clearSelection({ commit }) {
    commit('CLEAR_SELECTION');
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
