<template lang="html">
  <div>
    <activity-head
      :activity="activity"
      :show-details="showDetails"
      @unplan="$emit('unplan', activity)"
      @click="showDetails = !showDetails"
    />

    <activity-content v-show="showDetails" :activity="activity" />
  </div>
</template>

<script>
import ActivityHead from './ActivityHead';
import ActivityContent from './ActivityContent';

export default {
  components: {
    ActivityHead,
    ActivityContent,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showDetails: false,
  }),
};
</script>
