<template>
  <base-layout :load="loading">
    <h3 slot="title">{{ $t('pages.place.IndexPage.warehouseList') }}</h3>

    <el-row :gutter="20">
      <el-col
        v-for="(place, index) in places.items"
        :key="place.id"
        :xs="{ span: 20 }"
        :sm="{ span: 12 }"
        :md="{ span: 8 }"
        :lg="{ span: 6 }"
        :place="place"
        :index="index"
      >
        <el-card :body-style="{ padding: '0px' }">
          <div v-if="place.is_gecoded">
            <single-map :position="place.position" :icon="icon" />
          </div>
          <div v-else>
            <span class="fakeLoad">{{
              $t('pages.place.addressGeocoding')
            }}</span>
          </div>
          <div style="padding: 14px">
            <h3>{{ place.name }}</h3>
            <div>
              <p>
                <span v-if="place.external_id">
                  <dt class="upperFirst">{{ $t('commons.externalId') }}:</dt>
                  <dd>{{ place.external_id }}</dd>
                </span>
                <span v-if="place.display_address">
                  <dt class="upperFirst">
                    {{ $t('commons.address.single') }}:
                  </dt>
                  <dd>{{ place.display_address }}</dd>
                </span>
              </p>
              <div class="actions btn-group">
                <cs-button
                  type="primary"
                  @click.stop="
                    $router.push({
                      name: 'editSpot',
                      params: { id: place.id },
                    })
                  "
                >
                  {{ $t('pages.place.spot.edit') }}
                </cs-button>
                <cs-button
                  square
                  icon="edit"
                  icon-type="material"
                  type="primary"
                  @click.stop="
                    $router.push({
                      name: 'editPlace',
                      params: { id: place.id },
                    })
                  "
                >
                </cs-button>
                <cs-button
                  square
                  icon="delete"
                  icon-type="material"
                  type="danger"
                  @click="deletePlace(place)"
                >
                </cs-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <cs-button
      slot="footer"
      icon="add"
      icon-type="material"
      type="primary"
      @click.stop="$router.push({ name: 'createPlace' })"
    >
      {{ $t('pages.place.IndexPage.warehouseAdd') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SingleMap from './../../components/SingleMap';
import { getDepotIcon } from './../../utils';

export default {
  name: 'Places',
  components: { SingleMap },
  data: () => ({
    loading: true,
    position: null,
    icon: getDepotIcon(),
  }),
  computed: {
    ...mapGetters(['places']),
  },
  created() {
    this.loading = true;
    this.get({ type: 'place', id: 'all' }).then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(['get', 'delete']),
    deletePlace(place) {
      this.$confirm(
        `${this.$t('pages.place.IndexPage.wantedToDeleteWarehouse')} ${
          place.name
        }`
      )
        .then(
          () => {
            this.loading = true;
            return this.delete({ type: 'place', id: place.id });
          },
          () => null
        )
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.warehouseMap {
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  border-color: #a8abb5;
}
.warehouseInfos {
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  border-color: #a8abb5;
  border-top: none;
  padding: 5px;
  margin-bottom: 10px;
}
.el-row {
  display: flex;
  justify-content: center;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
.el-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  margin-bottom: 20px;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
