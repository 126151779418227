<template>
  <base-layout class="page-intake" :load="loading">
    <h3 slot="title">{{ $t('commons.collection.plural') }}</h3>
    <h4 slot="subtitle">
      <span v-if="isDateRange">
        {{ $t('pages.intake.IndexPage.from') }}
        {{ dateRange.from | format('EEEE, d MMMM y') }}
        {{ $t('pages.intake.IndexPage.to') }}
        {{ dateRange.to | format('EEEE, d MMMM y') }}
      </span>
      <span v-else
        >{{ $t('pages.intake.IndexPage.of') }}
        {{ dateRange.to | format('EEEE, d MMMM y') }}</span
      >
    </h4>

    <el-timeline>
      <el-timeline-item
        v-for="intake in sortedItems"
        :key="intake.id"
        :timestamp="new Date(intake.planned_start) | format"
        placement="top"
      >
        <div
          slot="dot"
          class="cd-timeline-img text-center"
          :class="`cd-${intake.state}`"
        >
          <span class="icon">
            <i class="material-icons">{{ intake.state | getIcon }}</i>
          </span>
        </div>

        <el-card>
          <h2>{{ intake.external_id }}</h2>
          <el-row class="summary" type="flex" justify="space-around">
            <el-col class="box">
              <el-row type="flex" justify="space-between">
                <el-col>
                  <p class="title">{{ $t('commons.created_at') }}</p>
                </el-col>
                <el-col>
                  <p class="data">
                    {{
                      new Date(intake.created_at) | format(LONG_DATETIME_FORMAT)
                    }}
                  </p>
                </el-col>
              </el-row>

              <el-row type="flex" justify="space-between">
                <el-col>
                  <p class="title">{{ $t('commons.planning') }}</p>
                </el-col>
                <el-col>
                  <p class="data">
                    {{ new Date(intake.created_at) | format(LONG_DATE_FORMAT) }}
                  </p>
                </el-col>
              </el-row>

              <el-row type="flex" justify="space-between">
                <el-col>
                  <p class="title">{{ $t('commons.state') }}</p>
                </el-col>
                <el-col>
                  <p class="data">
                    {{ $t('commons.states.intake.' + intake.state) }}
                  </p>
                </el-col>
              </el-row>
            </el-col>

            <el-col class="box">
              <el-row type="flex" justify="space-between">
                <el-col>
                  <p class="title">{{ $t('commons.activity.plural') }}</p>
                </el-col>
                <el-col>
                  <p class="data">{{ intake.deliveries.length }}</p>
                </el-col>
              </el-row>

              <el-row type="flex" justify="space-between">
                <el-col>
                  <p class="title">{{ $t('commons.item.plural') }}</p>
                </el-col>
                <el-col>
                  <p class="data">
                    {{ intake | itemCount }}
                  </p>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <div class="btn-group">
            <cs-button
              v-if="0 < 1"
              slot="footer"
              icon="delete"
              icon-type="material"
              type="danger"
              @click.stop="deleteIntake(intake)"
            >
              {{ $t('commons.remove') }}
            </cs-button>
            <cs-button
              v-if="intake.file"
              icon-type="material"
              icon="file_download"
              @click="download(intake.file)"
            >
              {{ getFilename(intake.file) }}
            </cs-button>
            <cs-button
              icon-type="material"
              icon="edit"
              @click="
                $router.push({
                  name: 'editIntake',
                  params: { id: intake.id },
                })
              "
            />
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <div v-show="!sortedItems.length">
      <el-alert
        type="info"
        show-icon
        :title="$t('pages.intake.IndexPage.collectionNotFound') | upperFirst"
        :description="
          $t('pages.intake.IndexPage.selectAnotherDateRange') | upperFirst
        "
        :closable="false"
      >
      </el-alert>
    </div>
  </base-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isSameDay, isWithinInterval } from 'date-fns';
import { filter } from 'lodash';
import { LONG_DATETIME_FORMAT, LONG_DATE_FORMAT } from '@/utils/date';
import * as Sentry from '@sentry/browser';

export default {
  name: 'Intake',
  filters: {
    getIcon: ({ state }) => {
      switch (state) {
        case 'arrived':
        case 'started':
          return 'cached';
        case 'completed':
          return 'playlist_add_check';
        case 'incoming':
        default:
          return 'schedule';
      }
    },
    itemCount: ({ deliveries }) =>
      deliveries.reduce((acc, { item_count }) => (acc += item_count), 0),
  },
  data: () => ({
    loading: false,
    LONG_DATETIME_FORMAT: LONG_DATETIME_FORMAT,
    LONG_DATE_FORMAT: LONG_DATE_FORMAT,
  }),
  computed: {
    ...mapGetters(['intakes', 'dateRange']),
    sortedItems() {
      return filter(this.intakes.items, (i) =>
        isWithinInterval(new Date(i.planned_start), {
          start: this.dateRange.from,
          end: this.dateRange.to,
        })
      ).sort(({ planned_start }) => planned_start);
    },
    isDateRange() {
      return (
        !this.dateRange.from ||
        !isSameDay(this.dateRange.from, this.dateRange.to)
      );
    },
  },
  mounted() {
    this.$store.dispatch('setDatePicker', {
      clickHandle: this.fetchData,
      display: true,
    });
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.$store.dispatch('setDisplayDatePicker', false);
  },
  methods: {
    ...mapActions(['get', 'delete']),
    getFilename(path) {
      return path?.split('/').pop();
    },
    async deleteIntake(intake) {
      try {
        await this.$confirm(
          `${this.$t('pages.intake.wantedToDeleteIntake', [
            intake.external_id,
          ])}`
        );
        await this.delete({ type: 'intake', id: intake.id });
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        this.loading = false;
      }
    },
    download(path) {
      const filename = this.getFilename(path);
      this.axios({
        url: 'download',
        params: {
          path,
        },
        method: 'GET',
        responseType: 'blob',
      }).then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    },
    fetchData() {
      this.loading = false;
      this.$store.dispatch('setDatePickerLoading', true);
      this.get({
        type: 'intake',
        id: 'all',
        query: { from: this.dateRange.from, to: this.dateRange.to },
      }).then(() => {
        this.loading = false;
        this.$store.dispatch('setDatePickerLoading', false);
      });
    },
  },
};
</script>

<style lang="scss">
.page-intake {
  .cd-timeline-img {
    border-radius: 50%;
    box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08),
      0 3px 0 4px rgba(0, 0, 0, 0.05);
    width: 60px;
    height: 60px;
    margin-left: -25px;
    text-align: center;
    font-size: 2em;
    .icon {
      color: #fff;
      i {
        line-height: 60px;
      }
    }
  }

  .cd-timeline-img.cd-incoming {
    background: $incoming;
  }
  .cd-timeline-img.cd-arrived {
    background: $arrived;
  }
  .cd-timeline-img.cd-started {
    background: $started;
  }
  .cd-timeline-img.cd-completed {
    background: $completed;
  }
  .el-timeline-item__wrapper {
    padding-left: 60px !important;
  }
  .el-timeline-item__tail {
    border-left-width: 4px;
  }
}
</style>
