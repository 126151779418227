<template>
  <section class="tracking">
    <tracking-summary :states="routeStates" :routes="filteredRoutes" />

    <route-list
      :route-states="routeStates"
      :delivery-states="deliveryStates"
      :routes="filteredRoutes"
    />
  </section>
</template>

<script>
import { filter, isEmpty } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import TrackingSummary from './TrackingSummary';
import RouteList from './RouteList';

export default {
  name: 'IndexTracking',
  components: {
    TrackingSummary,
    RouteList,
  },
  data: () => ({
    loading: true,
    routeStates: {
      ready: {
        id: 'ready',
        visible: true,
        icon: 'assistant_photo',
        translationKey: 'commons.states.route.ready',
      },
      sending: {
        id: 'sending',
        visible: false,
        groupWith: 'ready',
        icon: 'cached',
        translationKey: 'commons.states.route.sending',
      },
      prepared: {
        id: 'prepared',
        visible: false,
        groupWith: 'ready',
        icon: 'cached',
        translationKey: 'commons.states.route.prepared',
      },
      sent: {
        id: 'sent',
        visible: false,
        groupWith: 'ready',
        icon: 'inbox',
        translationKey: 'commons.states.route.sent',
      },
      in_progress: {
        id: 'in_progress',
        visible: true,
        icon: 'local_shipping',
        translationKey: 'commons.states.route.in_progress',
      },
      loading: {
        id: 'loading',
        visible: false,
        groupWith: 'in_progress',
        icon: 'transfer_within_a_station',
        translationKey: 'commons.states.route.loading',
      },
      loaded: {
        id: 'loaded',
        visible: false,
        groupWith: 'in_progress',
        icon: 'transfer_within_a_station',
        translationKey: 'commons.states.route.loaded',
      },
      paused: {
        id: 'paused',
        visible: true,
        icon: 'layers',
        translationKey: 'commons.states.route.paused',
      },
      done: {
        id: 'done',
        visible: false,
        groupWith: 'in_progress',
        icon: 'beenhere',
        translationKey: 'commons.states.route.finished',
      },
      finished: {
        id: 'finished',
        visible: true,
        icon: 'beenhere',
        translationKey: 'commons.states.route.finished',
      },
    },
    deliveryStates: {},
  }),
  computed: {
    ...mapGetters(['dateRange', 'routesByDate']),
    filteredRoutes() {
      return filter(this.routesByDate, (route) => route.state !== 'created');
    },
  },
  mounted() {
    this.$store.dispatch('setDatePicker', {
      clickHandle: this.fetchData,
      display: true,
    });
    if (isEmpty(this.filteredRoutes)) {
      this.fetchData();
    }
  },
  beforeDestroy() {
    this.$store.dispatch('setDisplayDatePicker', false);
  },
  methods: {
    ...mapActions(['get']),
    fetchData() {
      this.$store.dispatch('setDatePickerLoading', true);
      this.get({
        type: 'route',
        id: 'all',
        query: {
          from: this.dateRange.from,
          to: this.dateRange.to,
          include: 'tracking,transitions,deliveries',
          exclude: 'deliveries.items',
        },
      }).then(() => {
        this.$store.dispatch('setDatePickerLoading', false);
        this.loading = false;
      });
    },
  },
};
</script>
