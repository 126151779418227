<template lang="html">
  <cs-button icon-type="material" type="primary" @click="download">
    {{ $t('components.XlsxImporter.sample') }}
  </cs-button>
</template>

<script>
import XLSX from 'xlsx';
import { format } from 'date-fns';

const date = format(new Date(), 'ddMMyyyy');
const data = [
  {
    external_id: `${date}_1`,
    duration: 600,
    item_count: 1,
    date: new Date(),
    receiver_name: 'john doe',
    receiver_phone: '+3312345678',
    email: 'john@exemple.com',
    street_number: '2 bis',
    street_name: 'rue de la paix',
    postcode: '75002',
    city: 'paris',
    country: 'France',
    country_code: 'FR',
    latitude: 48.8684237,
    longitude: 2.3284768,
    c1_type: 'poids',
    c1_val: 100,
    c2_type: 'volume',
    c2_val: 25,
    tools: 'échelle',
  },
  {
    external_id: `${date}_2`,
    duration: 600,
    item_count: 2,
    date: new Date(),
    receiver_name: 'jane doe',
    receiver_phone: '+3387654321',
    email: 'jane@exemple.com',
    street_name: '23 Rue Clovis, 75005 Paris',
    country_code: 'FR',
    latitude: 48.8476407,
    longitude: 2.3484949,
    c1_type: 'poids',
    c1_val: 300,
    c2_type: 'volume',
    c2_val: 20,
    tools: 'échelle',
  },
];

export default {
  methods: {
    download() {
      /* create a new blank workbook */
      const wb = XLSX.utils.book_new();
      wb.SheetNames = ['logitricks_sample'];
      wb.Sheets['logitricks_sample'] = XLSX.utils.json_to_sheet(data);
      XLSX.writeFile(wb, 'logitricks_sample.xlsx');
    },
  },
};
</script>
