<template>
  <div class="">
    <div class="gantt-menu">
      <route-visibility v-show="groups.length > 0" :routes="routed" />
    </div>
    <timeline
      ref="timeline"
      :items="itemsWithSla"
      :groups="groups"
      :options="options"
      :selection="ganttSelection"
      :events="['drop', 'mouseDown']"
      @items-mounted="itemsMounted"
      @mouseDown.exact="mousedown"
      @drop="drop"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import {
  addSeconds,
  differenceInMilliseconds,
  isAfter,
  isBefore,
} from 'date-fns';
import m from 'moment';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Timeline } from '@vue2vis/timeline';
import { isWithin } from '@/utils';
import Activity from './routing/Activity';
import Timeoff from './routing/Timeoff';
import Route from './routing/Route';
import RouteExtended from './routing/RouteExtended';
import Travel from './routing/Travel';
import Label from './Label';
import LabelRoute from './LabelRoute';
import RouteVisibility from './RouteVisibility';

const GanttLabel = Vue.extend(Label);
const GanttLabelRoute = Vue.extend(LabelRoute);
const GanttRoute = Vue.extend(Route);
const GanttRouteExtended = Vue.extend(RouteExtended);
const GanttActivity = Vue.extend(Activity);
const GanttTimeoff = Vue.extend(Timeoff);
const GanttTravel = Vue.extend(Travel);

const templates = new Map();
const groupTemplates = new Map();

export default {
  components: {
    Timeline,
    RouteVisibility,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    gap: {
      type: Number,
      default: 43200000000,
    },
    offset: {
      type: Number,
      default: 3600000,
    },
    maxHeight: {
      type: [Number, String],
      default: '40vh',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    resourceEdit: {
      type: Boolean,
      default: true,
    },
    start: {
      type: Date,
      default: undefined,
    },
    end: {
      type: Date,
      default: undefined,
    },
  },
  data: () => ({
    routeExtended: [],
    boundaries: {},
    itemDataSet: {},
  }),
  computed: {
    ...mapGetters(['selection', 'dateRange']),
    groups() {
      return _.concat(
        _.map(this.resourcesObject, this.getResourceGroup),
        _.map(this.routeResource.without, this.getRouteGroup)
      );
    },
    items() {
      return this.routed
        .reduce(
          (acc, route) => [
            ...acc,
            this.getRouteItems(route),
            ...this.getTravelItems(route),
            ...this.getTimeoffItems(route),
          ],
          []
        )
        .concat(
          _.map(
            _.filter(this.activitiesObject, 'route_id'),
            this.getActivityItems
          )
        );
    },
    unrouted() {
      return _.first(this.routes, ['id', null]);
    },
    routed() {
      return _.filter(this.routes, 'id');
    },
    slas() {
      let slas = [];
      if (this.selection.type === 'activity') {
        const activity = _.last(this.selection.data);
        const appointment = _.get(activity, 'appointment');
        const groupId = this.itemDataSet.get(activity.id);
        slas = _.map(_.get(activity, 'slas'), (s, idx) =>
          this.getSla(s, `sla_${activity.id}_${idx}`, groupId)
        );
        if (appointment && !_.isNil(appointment.start)) {
          const appointmentGroup = this.getSla(
            appointment,
            `appointment_${activity.id}`,
            groupId
          );
          appointmentGroup.className = 'timeline-sla appointment';
          slas.push(appointmentGroup);
        }
      }

      if (this.selection.type === 'timeoff') {
        const itemTimeoff = _.last(this.selection.data);
        const route = _.get(this.routesObject, _.get(itemTimeoff, 'routeId'));
        const timeoff = _.get(route, `timeoffs[${itemTimeoff.index}]`);
        if (timeoff) {
          const groupId = _.get(route, 'resource.id', route.id);
          slas = [
            this.getSla(
              timeoff.sla,
              `sla_timeoff_${route.id}_${itemTimeoff.index}`,
              groupId,
              'timmeoff-sla'
            ),
          ];
        } else {
          this.clearSelection();
        }
      }

      return slas;
    },
    ganttSelection() {
      if (['activity', 'timeoff'].includes(this.selection.type)) {
        return _.map(this.selection.data, 'id');
      }

      return [];
    },
    routeResource() {
      return _.groupBy(this.routed, (r) => (r.resource ? 'with' : 'without'));
    },
    routesObject() {
      return _.zipObject(_.map(this.routed, 'id'), this.routed);
    },
    activities() {
      return _.flatMap(this.routes, 'deliveries');
    },
    activitiesObject() {
      return _.zipObject(_.map(this.activities, 'id'), this.activities);
    },
    resourceRoutes() {
      return _.groupBy(this.routeResource.with, (r) => _.get(r, 'resource.id'));
    },
    resourcesObject() {
      return _.zipObject(
        _.keys(this.resourceRoutes),
        _.map(this.resourceRoutes, (l) => _.first(l).resource)
      );
    },
    itemsWithSla() {
      return _.concat(this.items, this.slas, this.routeExtended);
    },
    options() {
      const editable = this.editable
        ? {
            add: false, // add new items by double tapping
            updateTime: true, // drag items horizontally
            updateGroup: true, // drag items from one group to another
            remove: false, // delete an item by tapping the delete button top right
            overrideItems: false, // allow these options to override item.editable
          }
        : false;
      return {
        editable,
        end: this.end || this.dateRange.to,
        groupTemplate: this.getGroupTemplate,
        template: this.getTemplate,
        locale: _.get(this.$i18n, 'locale', 'fr'),
        selectable: false,
        stack: false,
        snap: null,
        zoomMin: 600000,
        max: this.end || this.dateRange.to,
        maxHeight: this.maxHeight,
        min: this.start || this.dateRange.from,
        moment: (date) => m(date).locale(_.get(this.$i18n, 'locale', 'fr')),
        multiselect: true,
        onMove: this.moveItem,
        orientation: {
          axis: 'top',
          item: 'bottom',
        },
        start: this.start || this.dateRange.from,
      };
    },
  },
  watch: {
    dateRange(v) {
      this.clearTemplates();
      this.$refs.timeline.setOptions({
        start: v.from,
        end: v.to,
        min: v.from,
        max: v.to,
      });
    },
    maxHeight(v) {
      if (v) {
        this.$refs.timeline.setOptions({
          maxHeight: v,
        });
      }
    },
  },
  beforeDestroy() {
    this.clearTemplates();
  },
  methods: {
    ...mapActions([
      'removeActivitiesFromSelection',
      'setSelectedActivities',
      'clearSelection',
      'queueAction',
    ]),
    clearTemplates() {
      templates.forEach((c) => c.$destroy());
      templates.clear();
      groupTemplates.forEach((c) => c.$destroy());
      groupTemplates.clear();
    },
    getTemplate(item) {
      let component = '';
      if (templates.has(item.id)) {
        return templates.get(item.id).$el;
      }
      /* eslint-disable no-case-declarations */
      switch (item.entity) {
        case 'activity':
          component = new GanttActivity({
            store: this.$store,
            parent: this,
            propsData: {
              activityId: item.id,
            },
          });
          break;
        case 'route':
          component = new GanttRoute({
            store: this.$store,
            parent: this,
            propsData: {
              routeId: item.id,
              editable: this.editable,
            },
          });
          break;
        case 'travel':
          component = new GanttTravel({
            store: this.$store,
            parent: this,
            propsData: {
              routeId: item.routeId,
            },
          });
          break;
        case 'routeExtended':
          component = new GanttRouteExtended({
            store: this.$store,
            parent: this,
            propsData: {
              routeId: item.id.split('_')[0],
            },
          });
          break;
        case 'timeoff':
          component = new GanttTimeoff({
            store: this.$store,
            parent: this,
            propsData: {
              id: item.id,
              routeId: item.routeId,
              index: item.index,
            },
          });
          break;
        default:
          return component;
      }
      /* eslint-enable no-case-declarations */
      component.$mount();
      templates.set(item.id, component);

      return component.$el;
    },
    getGroupTemplate(item) {
      if (!item) {
        return false;
      }
      if (groupTemplates.has(item.id)) {
        return groupTemplates.get(item.id).$el;
      }

      let component = '';
      switch (item.entity) {
        case 'route':
          component = new GanttLabelRoute({
            store: this.$store,
            parent: this,
            propsData: {
              routeId: item.id,
              editable: this.resourceEdit,
            },
          });
          break;
        default:
          component = new GanttLabel({
            store: this.$store,
            parent: this,
            propsData: {
              resourceId: item.id,
              name: item.content,
            },
          });
          break;
      }
      component.$mount();
      groupTemplates.set(item.id, component);

      return component.$el;
    },
    itemsMounted(d) {
      this.itemDataSet = d;
    },
    clearDestroyedItem(id) {
      this.itemDataSet.remove(id);
      templates.delete(id);
    },
    mousedown(e) {
      if (
        _.has(this.activitiesObject, e.item) &&
        !e.event.shiftKey &&
        !e.event.altKey &&
        this.selection.data.length <= 1
      ) {
        this.setSelectedActivities({ data: [this.activitiesObject[e.item]] });
      }
    },
    closestRoute(start, list) {
      return _.first(
        _.sortBy(list, (r) =>
          Math.abs(differenceInMilliseconds(start, r.planned_start))
        )
      );
    },
    drop(v) {
      const data = JSON.parse(v.event.dataTransfer.getData('text'));

      if (data.content === 'activity') {
        const activity = _.get(this.activitiesObject, data.id);
        const newStart = new Date(v.snappedTime);
        const newEnd = addSeconds(newStart, activity.duration);
        const route = _.get(this.routesObject, activity.route_id);
        this.swapActivity(activity, newStart, newEnd, route, v.group).then(
          (routes) => {
            this.reorderRoutes(routes);
            this.removeActivitiesFromSelection({ data: [activity] });
          }
        );
      }
      if (data.content === 'selection') {
        const activitySelection =
          this.selection.type === 'activity'
            ? this.selection.data
            : _.flatMap(this.selection.data, 'deliveries');
        let delta = 0;
        const changedRoutes = _.map(activitySelection, (activity) => {
          const newStart = addSeconds(new Date(v.snappedTime), delta);
          const newEnd = addSeconds(newStart, activity.duration);
          const route = _.get(this.routesObject, activity.route_id);
          delta += activity.duration;

          return this.swapActivity(activity, newStart, newEnd, route, v.group);
        });

        Promise.all(changedRoutes).then((list) => {
          const uniqueRoutes = list.reduce((acc, set) => {
            set.forEach((r) => acc.add(r));
            return acc;
          }, new Set());
          this.reorderRoutes(uniqueRoutes);
          this.clearSelection();
        });
      }
    },
    extendRoute(r, start, end) {
      const extendedStart = isBefore(start, r.planned_start)
        ? start
        : r.planned_start;
      const extendedEnd = isAfter(end, r.planned_end) ? end : r.planned_end;

      if (isBefore(start, r.planned_start) || isAfter(end, r.planned_end)) {
        return this.$confirm(
          `This change does not fit in the route, do you want to extend it ?`,
          `extend route ${r.name}`,
          {
            confirmButtonText: this.$t('commons.agree'),
            cancelButtonText: this.$t('commons.cancel'),
          }
        ).then(() => {
          r.planned_start = extendedStart;
          r.planned_end = extendedEnd;
          this.updateItem({
            id: r.id,
            start: extendedStart,
            end: extendedEnd,
          });
          r.extended = true;
          this.setBoundaries(r.id, start, end);
        });
      }

      return Promise.resolve(r);
    },
    fit() {
      this.$refs.timeline.fit();
    },
    redraw() {
      this.clearTemplates();
      this.$refs.timeline.redraw();
    },
    getActivityItems(activity) {
      // const slaValues = _.flatMap(activity.slas, _.values);
      const route = _.get(this.routesObject, activity.route_id);
      if (!activity.planned_start) {
        activity.planned_start = new Date(route.planned_start);
        activity.planned_end = addSeconds(
          activity.planned_start,
          activity.duration
        );
      }
      this.setBoundaries(
        route.id,
        activity.planned_start,
        activity.planned_end
        // ...slaValues
      );

      return {
        id: activity.id,
        content: `${activity.order} ${activity.external_id}`,
        start: activity.planned_start,
        end: activity.planned_end,
        group: _.get(route, 'resource.id', route.id),
        className: 'activity-item',
        entity: 'activity',
      };
    },
    getResourceGroup(resource) {
      return {
        id: resource.id,
        content: resource.name,
        type: 'group',
      };
    },
    getRouteGroup(route) {
      return {
        id: route.id,
        content: route.name,
        type: 'group',
        entity: 'route',
      };
    },
    getRouteItems(route) {
      this.setBoundaries(route.id, route.planned_start, route.planned_end);
      return {
        id: route.id,
        content: route.name,
        start: route.planned_start,
        end: route.planned_end,
        group: _.get(route, 'resource.id', route.id),
        type: 'background',
        className: 'route-routing',
        entity: 'route',
      };
    },
    getTravelItems(route) {
      return _.map(route.travels, (travel, idx) => ({
        id: `${route.id}_travel_${idx}`,
        start: travel.start,
        end: travel.end,
        group: _.get(route, 'resource.id', route.id),
        type: 'background',
        className: 'route-travel',
        entity: 'travel',
        routeId: route.id,
      }));
    },
    getTimeoffItems(route) {
      return _.map(route.timeoffs, (b, idx) => ({
        id: `${route.id}_timeoff_${idx}`,
        start: new Date(b.start),
        end: new Date(b.end),
        group: _.get(route, 'resource.id', route.id),
        className: 'timeoff-item',
        entity: 'timeoff',
        routeId: route.id,
        index: idx,
      }));
    },
    isWithinRoute(start, end, r) {
      return isWithin(r.planned_start, r.planned_end, start, end);
    },
    moveItem(item) {
      if (item.entity === 'activity') {
        const activity = _.get(this.activitiesObject, item.id);
        const route = _.get(this.routesObject, activity.route_id);
        this.swapActivity(activity, item.start, item.end, route, item.group)
          .then((routes) => {
            this.reorderRoutes(routes);
            this.updateItem(item);
            this.removeActivitiesFromSelection({ data: [activity] });
          })
          .catch(() => {
            item.start = new Date(activity.planned_start);
            item.end = new Date(activity.planned_end);
            item.group = _.get(route, 'resource.id');
          });
      }

      if (item.entity === 'timeoff') {
        const route = _.get(this.routesObject, item.routeId);
        const timeoff = route.timeoffs[item.index];
        timeoff.start = item.start;
        timeoff.end = item.end;
        this.extendRoute(route, timeoff.start, timeoff.end).then(() => {
          route.reorder();
          this.queueAction({
            action: 'patch',
            type: 'route',
            data: route,
          });
          this.updateItem(item);
        });
      }
    },
    updateItem(item) {
      const originItem = _.find(this.items, ['id', item.id]);
      originItem.start = _.get(item, 'start', originItem.start);
      originItem.end = _.get(item, 'end', originItem.end);
      originItem.group = _.get(item, 'group', originItem.group);
    },
    swapActivity(a, start, end, r, g) {
      let route;

      if (this.resourceRoutes[g]) {
        route = _.find(this.resourceRoutes[g], (resourceRoute) =>
          this.isWithinRoute(start, end, resourceRoute)
        );

        if (!route) {
          route = this.closestRoute(start, this.resourceRoutes[g]);
        }
      } else {
        route = this.routesObject[g];
      }

      if (_.get(route, 'locked')) {
        const message = this.$t('error.route.locked', [route.name]);
        this.$message(message);
        return Promise.reject(new Error(message));
      }

      return this.extendRoute(route, start, end).then(() => {
        const changes = new Set([route]);
        a.planned_start = start;
        a.planned_end = end;
        a.route_id = route.id;
        // remove activity from old route
        if (_.get(r, 'id') !== route.id) {
          route.deliveries.push(a);
          if (r) {
            r.shouldUpdate = false;
            r.deliveries.splice(
              _.findIndex(r.deliveries, (d) => d.id === a.id),
              1
            );
            changes.add(r);
          }
        }
        return changes;
      });
    },
    reorderRoutes(set) {
      return Promise.all(
        [...set].map((route) => {
          route.shouldUpdate = false;
          return route.reorder().then(() =>
            this.queueAction({
              action: 'patch',
              type: 'route',
              data: route,
            })
          );
        })
      );
    },
    setBoundaries(routeId, ...args) {
      const boundary = _.has(this.boundaries, routeId)
        ? _.get(this.boundaries, routeId)
        : {};
      boundary.start = null;
      boundary.end = null;

      args.forEach((arg) => {
        const date = Date.parse(arg);
        if (!boundary.start || date < boundary.start) {
          boundary.start = date;
        }
        if (!boundary.end || date > boundary.end) {
          boundary.end = date;
        }
        this.$set(this.boundaries, routeId, boundary);
      });
    },
    getSla(s, id, groupId, extraClasses) {
      const sla = {
        id,
        start: s.start,
        end: s.end,
        className: 'timeline-sla',
        type: 'background',
      };

      if (groupId) {
        sla.group = groupId;
      } else {
        sla.className = `${sla.className} unrouted`;
      }

      if (extraClasses) {
        sla.className = `${sla.className} ${extraClasses}`;
      }

      return sla;
    },
  },
};
</script>

<style lang="scss">
.gantt-menu {
  position: fixed;
  z-index: 2;
  height: 27px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
</style>
