<template>
  <div class="workflow">
    <div class="steps">
      <step
        v-for="(item, idx) in currentWorkflow"
        :key="idx"
        v-model="currentWorkflow[idx]"
        :default-workflow="defaultSteps"
        :name="item.name"
        :required="item.required"
        :enabled="item.enabled"
        :end="item.end"
        @change="updateWorkflow"
      ></step>
    </div>
  </div>
</template>

<script>
import { concat, last, map, includes, without } from 'lodash';
import Step from './Step';

export default {
  components: { Step },
  props: {
    defaultSteps: {
      type: Array,
      default: () => [],
    },
    requiredSteps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lastStep() {
      return last(this.defaultSteps);
    },
    currentWorkflow() {
      return map(this.defaultSteps, (name) => ({
        name,
        required: includes(this.requiredSteps, name),
        end: name === this.lastStep,
        enabled: includes(this.value, name),
      }));
    },
  },
  methods: {
    updateWorkflow(e) {
      if (e.enable) {
        this.$emit('input', concat(this.value, e.step));
      } else {
        this.$emit('input', without(this.value, e.step));
      }
    },
  },
};
</script>
<style scoped>
.workflow {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 2em;
}
.steps {
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
}
</style>
