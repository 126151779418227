<template lang="html">
  <span>
    <span v-if="!isMutiple" class="value">
      {{ preview | formatDate }}
    </span>
    <span v-else>
      <table v-if="preview.length" class="cell-preview">
        <tr>
          <th
            v-for="(label, idx_label) in labels"
            :key="idx_label"
            class="array-mapper-item"
          >
            {{ label }}
          </th>
        </tr>
        <tr v-for="(item, idx) in preview" :key="idx">
          <td v-for="(value, name) in item" :key="name">
            {{ value | formatDate }}
          </td>
        </tr>
      </table>
    </span>
  </span>
</template>

<script>
import { isArray, isDate } from 'lodash';
import { format } from 'date-fns';
import { LONG_DATETIME_FORMAT } from '@/utils/date';

export default {
  name: 'Preview',
  props: {
    preview: {
      type: [String, Number, Array, Date],
      default: '',
    },
  },
  filters: {
    formatDate: (v) => (isDate(v) ? format(v, LONG_DATETIME_FORMAT) : v),
  },
  computed: {
    isMutiple() {
      return isArray(this.preview);
    },
    labels() {
      return this.preview[0] ? Object.keys(this.preview[0]) : [];
    },
  },
};
</script>

<style>
.cell-preview {
  width: max-content !important;
}
</style>
