var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sidebar-head-bg primary-color-dark",style:({ backgroundColor: _vm.route.color })},[_c('el-row',{staticClass:"activity-head",attrs:{"type":"flex"}},[_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('el-col',{attrs:{"span":21}},[_c('h5',{staticClass:"sidebar-title"},[_vm._v(_vm._s(_vm.route.name))])]),_c('el-col',{attrs:{"span":3}},[_c('cs-button',{attrs:{"outline":"","icon":"close","icon-type":"material"},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}})],1)],1),(_vm.route)?_c('div',{staticClass:"activity-head-plusBtn"},[(!_vm.showDetails)?_c('i',{staticClass:"el-icon-plus"}):_c('i',{staticClass:"el-icon-minus"})]):_vm._e(),_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{style:({
          display: 'flex',
          flexDirection: 'column',
          color: '#6D737F',
        }),attrs:{"span":6}},[_c('span',[_vm._v(_vm._s(_vm._f("upperFirst")(_vm.$t("commons.state"))))]),_c('span',[_vm._v(_vm._s(_vm._f("upperFirst")(_vm.$t("commons.duration"))))]),_c('span',[_vm._v(_vm._s(_vm._f("upperFirst")(_vm.$t("commons.distance"))))]),_c('span',[_vm._v(_vm._s(_vm._f("upperFirst")(_vm.$t("commons.resource.plural"))))])]),_c('el-col',{style:({
          color: '#32363B',
          textTransform: 'capitalize',
          display: 'flex',
          flexDirection: 'column',
        }),attrs:{"span":18}},[_c('span',{staticClass:"sidebar-details"},[_vm._v(_vm._s(_vm._f("upperFirst")(_vm.$t(("commons.states.route." + (_vm.route.state))))))]),_c('span',{staticClass:"sidebar-details"},[_vm._v(_vm._s(_vm._f("empty")(_vm._f("seconds")(_vm.route.duration),'N/A')))]),_c('span',{staticClass:"sidebar-details"},[_vm._v(_vm._s(_vm._f("empty")(_vm._f("km")(_vm.route.distance),'N/A')))]),_c('span',{staticClass:"sidebar-details"},[_vm._v(_vm._s(_vm._f("empty")(_vm._f("get")(_vm.route,'resource.name'),'N/A')))])])],1),(_vm.route)?_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":2}},[_c('el-tooltip',{attrs:{"content":_vm.$tc('components.selection.remove', 1, 1)}},[_c('cs-button',{staticClass:"activity-head-linkBtn",attrs:{"circle":"","icon":"link","icon-type":"material"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('unplan')}}})],1)],1),_c('el-col',{style:({ cursor: 'pointer' }),attrs:{"span":20,"offset":1}},[_c('a',{staticClass:"activity-head-link",on:{"click":function($event){$event.stopPropagation();return _vm.goToActivities($event)}}},[_vm._v(" "+_vm._s(_vm.route.deliveries.length)+" "+_vm._s(_vm.$tc('commons.activity.choice', _vm.route.deliveries.length))+" ")])])],1):_c('el-row',[_c('el-col',[_c('span',[_vm._v(_vm._s(_vm.$t('commons.none.female'))+" "+_vm._s(_vm.$t('commons.activity.single')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }