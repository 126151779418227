<template>
  <base-layout class="dashboard">
    <h3 slot="title">{{ $t('pages.HomePage.welcomeMessage') }}</h3>

    <div v-if="$isAllowed('statistics')" class="chart">
      <iframe class="dashboard_frame" :src="dashboardUrl" />
    </div>
  </base-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['organization']),
    dashboardUrl() {
      return `${this.organization.dashboard_url}&refresh=3600`;
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  .layout-head {
    margin-bottom: 0 !important;
  }

  .layout-main {
    padding: 0 !important;
  }

  .dashboard_frame {
    height: 100%;
    width: 100%;
    border: none;
  }

  .chart {
    height: 100%;
    padding: 0px;
  }
}
</style>
