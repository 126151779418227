import { isEmpty, isNil } from 'lodash';
import ApiEntity from './ApiEntity';

export default class Organization extends ApiEntity {
  id;
  name;
  email;
  image;
  siret;
  shifts;
  modules;
  workflow;
  engine;
  max_engine_activities;
  reason_prefix;
  tools;
  capacities;
  dashboard_url;
  dashboard_slug;

  constructor(o) {
    super();
    this.fill(o);
  }

  static create(data) {
    return Organization.createInstance(data);
  }

  get has_details() {
    return !isEmpty(this.input) && !isNil(this.output);
  }
}
