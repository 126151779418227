<template>
  <base-layout :load="loading">
    <h3 slot="title">{{ $t('pages.role.roleList') }}</h3>

    <el-tabs class="col-x s-12 role-page" type="card" @tab-click="handleClick">
      <el-tab-pane v-for="role in rolesList" :key="role.id" :label="role.name">
        <edit-role
          :role="role"
          :advised-abilities="initData.module_abilities"
          @change="setActive"
        />
      </el-tab-pane>
    </el-tabs>

    <cs-button
      v-if="!isEmpty(activeRole)"
      slot="footer"
      icon="delete"
      icon-type="material"
      type="danger"
      @click.stop="deleteRole"
    >
      {{ $t('commons.remove') }}
    </cs-button>
    <cs-button
      slot="footer"
      icon="add"
      icon-type="material"
      type="primary"
      @click.stop="createRole"
    >
      {{ $t('commons.add') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      @click="saveRole"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { first, isEmpty, values } from 'lodash';
import EditRole from './EditRole';

export default {
  components: {
    EditRole,
  },
  data: () => ({
    activeRole: {},
    initData: {},
    loading: true,
    saving: false,
  }),
  computed: {
    ...mapGetters(['roles']),
    rolesList() {
      return values(this.roles.items);
    },
  },
  created() {
    this.axios.get('role/create').then(({ data }) => {
      this.initData = data;
    });
    this.get({ type: 'role', id: 'all' })
      .then(() => {
        this.setActive(this.roles.items[Object.keys(this.roles.items)[0]]);
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['get', 'delete', 'patch', 'post']),
    handleClick(tab) {
      this.activeRole =
        this.roles.items[Object.keys(this.roles.items)[tab.index]];
    },
    isEmpty(data) {
      return isEmpty(data);
    },
    deleteRole() {
      this.$confirm(
        `${this.$t('pages.role.wantedToDeleteRole')} ${this.activeRole.name}`
      )
        .then(
          () => {
            this.loading = true;
            return this.delete({ type: 'role', id: this.activeRole.id });
          },
          () => null
        )
        .then(() => {
          this.loading = false;
          this.setActive(first(this.rolesList));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    saveRole() {
      this.saving = true;
      this.patch({
        type: 'role',
        id: this.activeRole.id,
        data: this.activeRole,
      })
        .then(() => {
          this.saving = false;
          this.$router.push({ name: 'indexRole' });
        })
        .catch(() => {
          this.saving = false;
        });
    },
    createRole() {
      this.$prompt(this.$t('role.name'), this.$t('role.create'), {
        confirmButtonText: this.$t('commons.save'),
        cancelButtonText: this.$t('commons.cancel'),
      })
        .then(({ value }) => {
          this.post({ type: 'role', data: { name: value } });
        })
        .catch(Function);
    },
    setActive(role) {
      this.activeRole = role;
    },
  },
};
</script>

<style lang="scss">
.role-page {
  li {
    list-style: none;
  }
  label {
    margin-right: 2px;
  }
  .el-tabs__new-tab i {
    color: red;
  }
}
</style>
