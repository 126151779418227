import axios from './axios';

// TODO Doublon  => Push it to utils ?

const formatUrl = (payload) => {
  let url = payload.type;

  if (payload.id) {
    url += `/${payload.id}`;
  }

  if (payload.transition) {
    url += `/${payload.transition}`;
  }

  if (payload.query) {
    url += `?${payload.query}`;
  }

  return url;
};

export default {
  geocode(payload) {
    payload.id = payload.data.id;
    payload.transition = 'geocode';
    return axios.patch(formatUrl(payload), payload.data);
  },
};
