<template lang="html">
  <div>
    <address v-if="!full" class="address-place">
      <p>
        {{ address.street_number }} {{ address.street_name }}
        {{ address.postcode }} {{ address.city }}
      </p>
    </address>

    <address v-else class="address-place">
      <object-display :data="address" :only="only" />
    </address>
  </div>
</template>

<script>
import ObjectDisplay from './ObjectDisplay';

export default {
  name: 'AddressEntity',
  components: {
    ObjectDisplay,
  },
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
    full: {
      type: Boolean,
      default: true,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    only() {
      if (!this.noLabel) {
        return {
          street_number: 'commons.street_number',
          street_name: 'commons.street_name',
          secondary: 'commons.secondaryAddress',
          postcode: 'commons.postcode',
          city: 'commons.city',
        };
      } else {
        return {
          street_number: null,
          street_name: null,
          secondary: null,
          postcode: null,
          city: null,
        };
      }
    },
  },
};
</script>

<style lang="scss">
.address-place {
  width: 100%;
  margin: 0;
  p {
    margin: 0;
    vertical-align: middle;
  }
}
</style>
