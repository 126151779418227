<template>
  <section>
    <el-row type="flex" justify="space-between">
      <el-col :span="20">
        <h4>{{ $t('pages.track.TrackingPage.recapRoute') }}</h4>

        <el-steps class="stepper" :align-center="true">
          <template v-for="state in states">
            <el-step
              v-if="state.visible"
              :key="state.id"
              :title="$t(state.translationKey) | upperFirst"
              :description="values[state.id] + ' / ' + routes.length"
              :status="values[state.id + '_status']"
            >
              <span slot="icon" class="material-icons">{{ state.icon }}</span>
            </el-step>
          </template>
        </el-steps>
      </el-col>
      <el-col :span="3">
        <div class="counter">
          <strong class="big-text">{{ routes.length }}</strong>
          <em v-if="routes.length > 1" class="upperFirst">{{
            $t('commons.route.plural')
          }}</em>
          <em v-else class="upperFirst">{{ $t('commons.route.single') }}</em>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { forEach } from 'lodash';

export default {
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    values() {
      const result = {};
      forEach(this.states, (state) => {
        result[state.id] = 0;
      });
      forEach(this.routes, (route) => {
        if (this.states[route.state].visible) {
          result[route.state] += 1;
        } else {
          result[this.states[route.state].groupWith] += 1;
        }
      });
      forEach(this.states, (state) => {
        let status = '';
        if (result[state.id] === 0) {
          if (this.routes.length !== 0) status = 'wait';
          else status = 'success';
        } else {
          status = 'process';
        }
        result[`${state.id}_status`] = status;
      });
      return result;
    },
  },
};
</script>
