<template>
  <section>
    <h4>{{ $t('components.optim.routeList') }}</h4>
    <el-table :data="routes" style="width: 100%">
      <el-table-column prop="state" :label="$t('commons.state')" sortable>
        <template slot-scope="scope">
          <el-tag :class="`state-${scope.row.state}`">
            {{ $t(`commons.states.route.${scope.row.state}`) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="name" sortable :label="$t('commons.name')">
      </el-table-column>
      <el-table-column prop="start" sortable :label="$t('commons.start')">
        <template slot-scope="scope">
          <div class="info-badge" v-if="scope.row.start">
            {{ scope.row.start | format(SHORT_DATETIME_FORMAT) }}
          </div>
          <el-badge
            v-else
            class="info-badge"
            :value="$t('commons.planned.female.single')"
          >
            {{ scope.row.planned_start | format(SHORT_DATETIME_FORMAT) }}
          </el-badge>
        </template>
      </el-table-column>
      <el-table-column prop="end" sortable :label="$t('commons.end')">
        <template slot-scope="scope">
          <div class="info-badge" v-if="scope.row.end">
            {{ scope.row.end | format(SHORT_DATETIME_FORMAT) }}
          </div>
          <el-badge
            v-else
            class="info-badge"
            :value="$t('commons.planned.female.single')"
          >
            {{ scope.row.planned_end | format(SHORT_DATETIME_FORMAT) }}
          </el-badge>
        </template>
      </el-table-column>
      <el-table-column
        prop="resource"
        sortable
        :min-width="120"
        :label="$t('commons.driver')"
        :formatter="formatResource"
      >
      </el-table-column>
      <el-table-column
        prop="deliveries.length"
        sortable
        :min-width="130"
        :label="$t('pages.track.TrackingPage.totalActivity')"
      >
      </el-table-column>
      <el-table-column
        prop="deliveries"
        :min-width="300"
        :label="$t('pages.track.TrackingPage.stateActivity')"
      >
        <template slot-scope="scope">
          <el-popover
            v-for="d in scope.row.deliveries"
            :key="d.id"
            placement="top"
            width="300"
            trigger="hover"
            :open-delay="300"
          >
            <router-link
              slot="reference"
              :to="{ name: 'deliveryDetail', params: { id: d.id } }"
              class="delivery-pack"
              :class="'state-' + d.state"
            >
            </router-link>
            <activity-card :activity="d" />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('commons.action.single')"
        width="140"
      >
        <template slot-scope="scope">
          <span class="actions">
            <cs-button
              icon="remove_red_eye"
              icon-type="material"
              @click="
                $router.push({
                  name: 'routeDetail',
                  params: { id: scope.row.id },
                })
              "
            />
            <cs-button
              slot="footer"
              icon-type="material"
              icon="print"
              target="_blank"
              :to="{ name: 'print', params: { id: scope.row.id } }"
            />
          </span>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { get, filter, includes } from 'lodash';
import ActivityCard from '@/components/ActivityCard';
import { SHORT_DATETIME_FORMAT } from '@/utils/date';

export default {
  components: { ActivityCard },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    routeStates: {
      type: Object,
      default: () => ({}),
    },
    deliveryStates: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    SHORT_DATETIME_FORMAT: SHORT_DATETIME_FORMAT,
  }),
  methods: {
    formatResource(r) {
      return get(r, 'resource.name');
    },
    count(list, prop, validStates) {
      return filter(list, (object) => includes(validStates, object[prop]))
        .length;
    },
  },
};
</script>
