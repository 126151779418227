import ApiEntity from './ApiEntity';

export default class Range extends ApiEntity {
  from;
  to;
  constructor(o) {
    super();
    this.from = this.castDate(o.from);
    this.to = this.castDate(o.to);
  }
}
