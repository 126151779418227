<template lang="html">
  <div
    ref="resizer"
    draggable="true"
    class="resizer"
    :style="style"
    :class="{ active, customClass }"
    @drag="drag"
    @dragstart="handleDragStart($event)"
    @dragend="handleDragEnd($event)"
    @mousedown="active = true"
    @mouseup="active = false"
  >
    <i class="material-icons">{{ icon }}</i>
  </div>
</template>

<script>
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#a8abb5',
    },
    zIndex: {
      type: Number,
      default: 1001,
    },
    size: {
      type: Number,
      default: 26,
    },
  },
  data: () => ({
    active: false,
    startEvent: {},
  }),
  computed: {
    icon() {
      return this.horizontal ? 'code' : 'unfold_more';
    },
    style() {
      return {
        'background-color': this.backgroundColor,
        'z-index': this.zIndex,
        'margin-top': `-${this.size / 2}px`,
        cursor: this.horizontal ? 'ew-resize' : 'ns-resize',
        height: `${this.size}px`,
        width: `${this.size}px`,
        left: `calc(50% - ${this.size / 2}px)`,
      };
    },
  },
  methods: {
    handleDragStart(event) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/plain', ' ');
      this.startEvent = {
        screenX: event.screenX,
        screenY: event.screenY,
        x: event.x,
        y: event.y,
      };
    },
    handleDragEnd(event) {
      this.active = false;
      this.$emit('resized', {
        top: this.startEvent.y - (this.startEvent.screenY - event.screenY),
        bottom:
          event.view.innerHeight -
          this.startEvent.y -
          this.size +
          (this.startEvent.screenY - event.screenY),
        left: event.screenX,
        right: event.view.innerWidth - event.screenX - this.size,
      });
    },
    drag(event) {
      if (this.horizontal && event.screenX > 0) {
        this.$emit('change-x', event.screenX);
      } else if (event.screenY > 0) {
        this.$emit('change-y', event.screenY);
      }
    },
  },
};
</script>

<style lang="scss">
.resizer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  &.active {
    background-color: white !important;
  }
}
</style>
