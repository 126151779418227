<script>
import ActivityIcon from '@/components/ActivityIcon';
import { mapActions } from 'vuex';

export default {
  props: {
    activityId: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions([
      'addActivitiesToSelection',
      'setSelectedActivities',
      'setSelectionSidebar',
    ]),
  },
  render: function (createElement) {
    const activity =
      this.$parent.activitiesObject[this.activityId] ||
      this.$store.getters.deliveries.items[this.activityId];
    if (activity) {
      const icon = createElement(
        ActivityIcon,
        {
          props: {
            ganttCentered: true,
            fontSize: '0.8em',
            lineHeight: '22px',
            text: activity.icon.options.text,
            color: activity.color,
            svg: activity.icon.options.svg,
          },
        },
        []
      );
      const activityLine = createElement('div', {
        class: 'activity-line',
        style: { backgroundColor: activity.color },
      });

      const eventPayload = { data: [activity] };

      return createElement(
        'div',
        {
          class: 'gantt-activity main',
          style: {
            color: 'blue',
          },
          on: {
            click: (event) => {
              if (event.shiftKey) {
                this.addActivitiesToSelection(eventPayload);
              } else {
                this.setSelectedActivities(eventPayload);
              }
              if (event.altKey) {
                this.setSelectionSidebar(true);
              }
            },
            dblclick: () => {
              this.setSelectedActivities(eventPayload);
              this.setSelectionSidebar(true);
            },
          },
        },
        [icon, activityLine]
      );
    }
    return undefined;
  },
};
</script>
