<template lang="html">
  <section class="select-column">
    <el-select
      :value="value"
      clearable
      size="small"
      placeholder="Select"
      @change="handleChangeValue"
    >
      <el-option
        v-for="item in options"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
    </el-select>
    <span v-if="defaultField">
      <label for="default-value">{{ defaultField.label }}</label>
      <p class="excel-uploader__desc">
        {{ defaultField.desc }}
      </p>
      <el-input
        v-model="defaultValue"
        name="default-value"
        :placeholder="defaultField.label"
        @change="handleChangeDefaultValue"
      />
    </span>
  </section>
</template>

<script>
export default {
  name: 'SelectColumn',
  props: {
    value: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      required: true,
    },
    defaultField: {
      type: Object,
    },
    path: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    defaultValue: null,
  }),
  methods: {
    handleChangeValue(value) {
      this.$emit('change', {
        value,
        defaultValue: this.defaultValue,
        path: this.path,
      });
    },
    handleChangeDefaultValue(defaultValue) {
      this.$emit('change', {
        value: this.value,
        defaultValue,
        path: this.path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-column {
  display: flex;
  flex-direction: column;
}
</style>
