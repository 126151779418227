<template>
  <el-table ref="table" :data="moduleList" row-key="name">
    <el-table-column prop="name" :label="$t('commons.name')" />
    <el-table-column width="55">
      <!-- eslint-disable-next-line -->
      <template slot-scope="scope" slot="header">
        <el-checkbox
          :value="checkAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
        </el-checkbox>
      </template>
      <template slot-scope="{ row }">
        <el-checkbox
          :value="row.value"
          @change="handleChange(row.name, $event)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { map, some, every } from 'lodash';
export default {
  model: {
    prop: 'modules',
    event: 'change',
  },
  props: {
    modules: {
      type: Object,
    },
  },
  computed: {
    moduleList() {
      return map(this.modules, (value, name) => ({ name, value }));
    },
    checkAll() {
      return every(this.moduleList, 'value');
    },
    isIndeterminate() {
      return some(this.moduleList, 'value') && !this.checkAll;
    },
  },
  methods: {
    handleCheckAllChange(value) {
      this.moduleList.forEach(({ name }) => this.handleChange(name, value));
    },
    handleChange(name, value) {
      const modules = this.modules;
      modules[name] = value;
      this.$emit('change', modules);
    },
  },
};
</script>

<style lang="css" scoped></style>
