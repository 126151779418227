const state = {
  sidebar: null,
};

const mutations = {
  SET_SIDEBAR: (myState, v) => {
    myState.sidebar = v;
  },
};

const getters = {
  sidebar: (myState) => myState.sidebar,
};

const actions = {
  setSidebar({ commit }, v) {
    commit('SET_SIDEBAR', v);
  },
  toggleSidebar({ commit, state: myState }, v) {
    if (myState.sidebar === v) {
      commit('SET_SIDEBAR', null);
    } else {
      commit('SET_SIDEBAR', v);
    }
  },
  setSelectionSidebar({ commit }, v) {
    commit('SET_SIDEBAR', v ? 'selection' : null);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
