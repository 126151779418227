<template>
  <base-layout v-if="place" :load="loading">
    <h3 v-if="place.name" slot="title">
      {{ $t('commons.warehouse.single') }} : {{ place.name }}
    </h3>
    <h4 slot="title">{{ $t('pages.place.spot.edit') }}</h4>

    <el-tabs class="col-x s-12 role-page" type="card">
      <el-tab-pane :label="$t('pages.place.spot.type.storage')">
        <spot-grid
          v-if="!loading"
          ref="spotGrid"
          v-model="spots"
          :place-numeric-id="placeNumber"
        />
      </el-tab-pane>

      <el-tab-pane :label="$t('pages.place.spot.type.dock')">
        <edit-docks v-model="docks" :place-numeric-id="placeNumber" />
      </el-tab-pane>
    </el-tabs>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="print"
      target="_blank"
      :disabled="!spots.length"
      :to="{
        name: 'printSpots',
        params: { type: 'storage', id: $route.params.id },
      }"
      class="button"
    >
      {{ $t('pages.place.spot.print.storage') }}
    </cs-button>
    <cs-button
      slot="footer"
      icon-type="material"
      icon="print"
      target="_blank"
      :disabled="!docks.length"
      :to="{
        name: 'printSpots',
        params: { type: 'loading_dock', id: $route.params.id },
      }"
      class="button"
    >
      {{ $t('pages.place.spot.print.loading_dock') }}
    </cs-button>
    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexPlace' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      :disabled="!shouldSave"
      @click="saveSpots"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>

<script>
import { mapActions } from 'vuex';

import { get, differenceBy, sortBy, groupBy } from 'lodash';

import SpotGrid from './SpotGrid';
import EditDocks from './EditDocks';
import { toAlphaNum } from '@/utils/helpers';

export default {
  name: 'SpotsEdit',
  components: {
    SpotGrid,
    EditDocks,
  },
  data: () => ({
    loading: false,
    saving: false,
    spots: [],
    docks: [],
  }),
  computed: {
    place() {
      return get(this.$store.getters.places.items, this.$route.params.id);
    },
    spotsGroups() {
      return groupBy(this.place.spots, 'type');
    },
    placeNumber() {
      return toAlphaNum(this.place.numeric_id, 1);
    },
    deleteSpots() {
      return differenceBy(this.spotsGroups.storage, this.spots, 'barcode');
    },
    addSpots() {
      return differenceBy(this.spots, this.spotsGroups.storage, 'barcode');
    },
    deleteDocks() {
      return differenceBy(this.spotsGroups.loading_dock, this.docks, 'barcode');
    },
    addDocks() {
      return differenceBy(this.docks, this.spotsGroups.loading_dock, 'barcode');
    },
    shouldSave() {
      return (
        this.deleteSpots.length ||
        this.addSpots.length ||
        this.addDocks.length ||
        this.deleteDocks.length
      );
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['get', 'post']),
    spotByType(type) {
      return get(this.spotsGroups, type, []);
    },
    fetchData() {
      this.loading = true;
      this.get({
        forceFetch: true,
        type: 'place',
        id: this.$route.params.id,
        query: 'include=spots',
      }).then(() => {
        this.loading = false;
        this.spots = this.spotByType('storage');
        this.docks = sortBy(this.spotByType('loading_dock'), 'barcode');
      });
    },
    saveSpots() {
      this.saving = true;
      this.post({
        type: 'spot',
        url: `spot/${this.$route.params.id}/batch`,
        data: {
          save: this.addSpots.concat(this.addDocks),
          delete: this.deleteSpots.concat(this.deleteDocks),
        },
      })
        .then(() => {
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style>
.el-tabs__content {
  overflow: inherit !important;
}
.button {
  padding: 0 8px;
}
</style>
