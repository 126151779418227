<template>
  <el-form
    v-if="!loading"
    ref="form"
    label-width="120px"
    label-position="top"
    autocomplete="off"
    :rules="rules"
    :model="client"
    @validate="validate"
  >
    <el-tabs
      class="col-x s-12"
      v-model="activeTab"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        name="informations"
        :label="$t('commons.infos')"
        @tab-click="handleClick"
      >
        <el-row type="flex">
          <el-col :md="{ offset: 3, span: 21 }" :lg="{ offset: 5, span: 19 }">
            <div class="panel panel-default">
              <h3 class="panel-heading">{{ $t('commons.generalInfos') }}</h3>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :md="{ span: 8, offset: 3 }" :lg="{ span: 8, offset: 5 }">
            <div class="panel panel-default">
              <div class="panel-body">
                <el-form-item :label="$t('commons.name')" prop="name">
                  <el-input v-model="client.name" name="name" type="text" />
                </el-form-item>

                <el-form-item
                  :label="$t('commons.companyName')"
                  :required="client.survey_enabled"
                  prop="company_name"
                >
                  <el-input
                    v-model="client.company_name"
                    name="company_name"
                    type="text"
                  />
                </el-form-item>

                <el-form-item
                  :label="$t('commons.address.single')"
                  :required="client.survey_enabled"
                  prop="address"
                >
                  <el-input
                    v-model="client.address"
                    name="address"
                    type="text"
                  />
                </el-form-item>

                <el-form-item
                  :label="$t('commons.hotlineEmail')"
                  :required="client.survey_enabled"
                  prop="hotline_email"
                >
                  <el-input
                    v-model="client.hotline_email"
                    name="hotline_email"
                    type="email"
                  />
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :md="{ span: 8, offset: 0 }" :lg="{ span: 8, offset: 0 }">
            <div class="panel panel-default">
              <div class="panel-body">
                <el-form-item
                  :label="$t('commons.hotlinePhone')"
                  :required="client.survey_enabled"
                  prop="hotline_phone"
                >
                  <el-input
                    v-model="client.hotline_phone"
                    name="hotline_phone"
                    type="tel"
                  />
                </el-form-item>

                <el-form-item
                  :label="$t('commons.dataOfficerEmail')"
                  :required="client.survey_enabled"
                  prop="client_data_officer_email"
                >
                  <el-input
                    v-model="client.client_data_officer_email"
                    name="client_data_officer_email"
                    type="email"
                  />
                </el-form-item>

                <el-form-item
                  :label="$t('commons.siret')"
                  prop="siret"
                  :required="siretRule.required"
                >
                  <el-input v-model="client.siret" name="siret" type="text" />
                </el-form-item>

                <el-form-item :label="$t('commons.mail')" prop="email">
                  <el-input v-model="client.email" name="mail" type="email" />
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="config" :label="$t('pages.client.ClientForm.config')">
        <el-row type="flex" :gutter="20">
          <el-col :md="{ offset: 3, span: 21 }" :lg="{ offset: 5, span: 19 }">
            <div class="panel panel-default">
              <h3 class="panel-heading">
                {{ $t('pages.client.ClientForm.configTech') }}
              </h3>

              <div class="panel-body">
                <el-form-item
                  :label="$t('pages.client.ClientForm.defaultDurationActivity')"
                  prop="activityDuration"
                >
                  <el-input-number
                    v-model="duration"
                    name="duration"
                    :step="1"
                    :min="0"
                    @change="validate"
                  />
                </el-form-item>
                <el-form-item
                  :label="
                    $t('pages.client.ClientForm.defaultActivityPolicy', [
                      client.policy,
                    ])
                  "
                  prop="policy"
                >
                  <el-input-number
                    v-model="client.policy"
                    label="jours"
                    name="policy"
                    :step="1"
                    :min="0"
                    @change="validate"
                  />
                </el-form-item>
              </div>
            </div>

            <div class="panel panel-default">
              <h3 class="panel-heading">
                {{ $t('pages.client.ClientForm.configOther') }}
              </h3>
              <el-form-item :label="$t('commons.survey')">
                <el-switch
                  v-model="client.survey_enabled"
                  :active-text="$t('commons.enable')"
                  :inactive-text="$t('commons.disable')"
                />
              </el-form-item>

              <span v-if="client.import_format == 'api' && client.api_key">
                <el-form-item
                  :label="$t('pages.client.ClientForm.apiKey')"
                  prop="api_key"
                >
                  <el-input
                    id="apiKey"
                    :value="client.api_key"
                    disabled="disabled"
                  >
                    <span
                      slot="append"
                      v-clipboard="client.api_key"
                      class="input-group-addon cursor"
                      @success="handleCopySuccess"
                      @error="handleCopyError"
                    >
                      <i class="material-icons">content_copy</i>
                    </span>
                  </el-input>
                </el-form-item>
              </span>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane name="mobile" :label="$t('pages.client.ClientForm.mobile')">
        <el-row>
          <el-col :md="{ offset: 3, span: 21 }" :lg="{ offset: 5, span: 19 }">
            <div class="panel panel-default">
              <h3 class="panel-heading">
                {{ $t('pages.client.ClientForm.configMobile') }}
              </h3>

              <div class="panel-body">
                <el-row type="flex" :gutter="1">
                  <el-col class="tag-col" :span="1">
                    <el-tag :type="tags[0].type">
                      {{ this.tags[0].label }}
                    </el-tag>
                  </el-col>
                  <el-col :span="22">
                    <h4>
                      {{ $t('pages.client.ClientForm.scanItems') }}
                    </h4>
                    <el-form-item prop="scan_for_prepare">
                      <el-checkbox
                        v-model="client.scan_for_prepare"
                        name="scan_for_prepare"
                      >
                        {{ $t('pages.client.ClientForm.scanForPrepare') }}
                      </el-checkbox>
                    </el-form-item>

                    <el-form-item prop="scan_for_load">
                      <el-checkbox
                        v-model="client.scan_for_load"
                        name="scan_for_load"
                      >
                        {{ $t('pages.client.ClientForm.scanForLoad') }}
                      </el-checkbox>
                    </el-form-item>

                    <el-form-item prop="scan_for_deliver">
                      <el-checkbox
                        v-model="client.scan_for_deliver"
                        name="scan_for_deliver"
                      >
                        {{ $t('pages.client.ClientForm.scanForDeliver') }}
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row type="flex" :gutter="1">
                  <el-col class="tag-col" :span="1">
                    <el-tag :type="tags[1].type">
                      {{ this.tags[1].label }}
                    </el-tag>
                  </el-col>
                  <el-col :span="22">
                    <h4 class="panel-sub-heading" ref="proof">
                      {{ $t('pages.client.ClientForm.deliveryProof') }}
                    </h4>

                    <el-form-item prop="signature_proof">
                      <el-checkbox
                        v-model="client.signature_proof"
                        name="signature_proof"
                      >
                        {{ $t('pages.client.ClientForm.signatureProof') }}
                      </el-checkbox>
                    </el-form-item>

                    <el-form-item prop="photo_proof">
                      <el-checkbox
                        v-model="client.photo_proof"
                        name="photo_proof"
                      >
                        {{ $t('pages.client.ClientForm.photoProof') }}
                      </el-checkbox>
                    </el-form-item>

                    <el-switch
                      v-if="tags[1].label == 'on'"
                      v-model="client.mandatory_proof"
                      :active-text="$t('pages.client.ClientForm.mandatory')"
                      :inactive-text="$t('pages.client.ClientForm.optionnal')"
                    >
                    </el-switch>
                  </el-col>
                </el-row>

                <el-row type="flex">
                  <el-col class="tag-col" :span="1">
                    <el-tag :type="tags[2].type">
                      {{ this.tags[2].label }}
                    </el-tag>
                  </el-col>
                  <el-col :span="22">
                    <h4 class="panel-sub-heading" ref="report">
                      {{ $t('pages.client.ClientForm.report') }}
                    </h4>

                    <el-form-item prop="disable_item_reasons">
                      <el-checkbox
                        v-model="client.disable_item_reasons"
                        name="disable_item_reasons"
                      >
                        {{ $t('pages.client.ClientForm.itemReasons') }}
                      </el-checkbox>
                    </el-form-item>

                    <el-form-item prop="disable_activity_reasons">
                      <el-checkbox
                        v-model="client.disable_activity_reasons"
                        name="disable_activity_reasons"
                      >
                        {{ $t('pages.client.ClientForm.activityReasons') }}
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex">
                  <el-col class="tag-col" :span="1"> </el-col>
                  <el-col>
                    <h4 class="panel-sub-heading">
                      {{ $t('pages.client.ClientForm.geolocation') }}
                    </h4>
                    <el-form-item
                      :label="$t('pages.client.ClientForm.distance')"
                      prop="distance_geoloc"
                    >
                      <el-input-number
                        v-model="client.distance_geoloc"
                        name="distance_geoloc"
                        :step="5"
                        :min="50"
                        @change="validate"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <slot></slot>
  </el-form>
</template>

<script>
import { getRequireRule, getTypeRule } from '@/validation';

export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    activeTab: 'informations',
    topPosScan: { top: '100px' },
    topPosProof: { top: '50px' },
    topPosReport: { top: '150px' },
  }),
  computed: {
    siretRule() {
      return {
        required:
          this.client.import_format === 'dispor' ||
          this.client.import_format === 'desadv',
        message: this.$t('commons.validateField', {
          field: this.$t('commons.siret'),
        }),
        trigger: 'blur',
      };
    },
    rules() {
      return {
        name: [getRequireRule('commons.name')],
        email: [getTypeRule('email')],
        siret: [this.siretRule],
        scan_for_load: { required: true, trigger: 'change' },
        scan_for_prepare: { required: true, trigger: 'change' },
        scan_for_deliver: { required: true, trigger: 'change' },
        signature_proof: { required: true, trigger: 'change' },
        photo_proof: { required: true, trigger: 'change' },
        disable_item_reasons: { required: true, trigger: 'change' },
        disable_activity_reasons: { required: true, trigger: 'change' },
      };
    },
    tags() {
      return [
        {
          type:
            this.client.scan_for_load ||
            this.client.scan_for_prepare ||
            this.client.scan_for_deliver
              ? 'success'
              : 'danger',
          label:
            this.client.scan_for_load ||
            this.client.scan_for_prepare ||
            this.client.scan_for_deliver
              ? 'on'
              : 'off',
          name: 'scan',
        },
        {
          type:
            this.client.photo_proof || this.client.signature_proof
              ? 'success'
              : 'danger',
          label:
            this.client.photo_proof || this.client.signature_proof
              ? 'on'
              : 'off',
          name: 'proof',
        },
        {
          type:
            this.client.disable_item_reasons ||
            this.client.disable_activity_reasons
              ? 'success'
              : 'danger',
          label:
            this.client.disable_item_reasons ||
            this.client.disable_activity_reasons
              ? 'on'
              : 'off',
          name: 'report',
        },
      ];
    },
    duration: {
      get() {
        return this.client.activityDuration / 60;
      },
      set(newValue) {
        this.client.activityDuration = newValue * 60;
      },
    },
  },
  created() {
    if (!this.client.policy) {
      this.client.policy = 1;
    }
    if (!this.client.report_type) {
      this.client.report_type = 'api';
    }
  },
  methods: {
    validate(name, valid) {
      this.$emit('validate', valid);
    },
    handleCopySuccess() {
      this.$notify({
        title: this.$t('pages.client.notifTitleDone'),
        message: this.$t('pages.client.notifTexteDone'),
        type: 'success',
        iconClass: this.$t('commons.cancel'),
        duration: 2000,
      });
    },
    handleClick() {
      this.$emit('changeTab', this.activeTab);
    },
    handleCopyError() {
      this.$notify({
        title: this.$t('commons.error'),
        message: this.$t('pages.client.notifTexteError'),
        type: 'success',
        iconClass: this.$t('commons.cancel'),
        duration: 2000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-col {
  min-width: 50px !important;
}
</style>
