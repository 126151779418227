<template>
  <el-row :gutter="10">
    <el-form
      ref="form"
      :model="resource"
      label-width="120px"
      :label-position="labelPosition"
      auto-complete="off"
      @submit.prevent
    >
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 10, offset: 0 }"
        :md="{ span: 10, offset: 0 }"
        :lg="{ span: 6, offset: 4 }"
      >
        <el-form-item :label="$t('commons.driver')" prop="driver">
          <el-select
            v-model="resource.user"
            filterable
            clearable
            value-key="id"
            name="user"
            :placeholder="
              $t('components.entities.ResourceDetails.selectDriver')
            "
          >
            <el-option
              v-for="user in users.items"
              :key="user.id"
              :value="user"
              :label="user.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('commons.vehicle.single')" prop="vehicle">
          <el-select
            v-model="resource.vehicle"
            clearable
            filterable
            value-key="id"
            name="vehicle"
            :placeholder="
              $t('components.entities.ResourceDetails.selectVehicle')
            "
          >
            <el-option
              v-for="vehicle in vehicles.items"
              :key="vehicle.id"
              :value="vehicle"
              :label="vehicle.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('commons.start_place')"
          prop="start_place"
          :required="nameRuleRequired.required"
        >
          <el-select
            v-model="resource.start_place"
            clearable
            filterable
            value-key="id"
            name="start_place"
            :placeholder="$t('components.entities.ResourceDetails.selectPlace')"
          >
            <el-option
              v-for="place in places.items"
              :key="place.id"
              :value="place"
              :label="place.name"
            >
              <span class="select-place">
                <span class="place-name">{{ place.name }}</span>
                <span class="place-type">{{ place.type }}</span>
              </span>
            </el-option>
          </el-select>
          <el-button @click="createPlace('start')">+</el-button>
          <create-place
            :visible="geocodeOpen"
            :edition="true"
            @close="closeCreate"
          />
        </el-form-item>
        <el-form-item
          :label="$t('commons.end_place')"
          prop="end_place"
          :required="nameRuleRequired.required"
        >
          <div class="el-form-item__label different">
            <el-switch v-model="differentEnd" @change="setEndPlace" />
            <span
              >{{ $t('sentence.endPlaceNotStartPlace') | upperFirst }}
            </span>
          </div>
          <el-select
            v-model="resource.end_place"
            :disabled="!differentEnd"
            clearable
            filterable
            value-key="id"
            name="end_place"
            :placeholder="$t('components.entities.ResourceDetails.selectPlace')"
          >
            <el-option
              v-for="place in places.items"
              :key="place.id"
              :value="place"
              :label="place.name"
            >
              <span class="select-place">
                <span class="place-name">{{ place.name }}</span>
                <span class="place-type">{{ place.type }}</span>
              </span>
            </el-option>
          </el-select>
          <el-button :disabled="!differentEnd" @click="createPlace('end')">
            +
          </el-button>
        </el-form-item>

        <el-form-item
          :label="$t('commons.name')"
          prop="name"
          :required="nameRuleRequired.required"
        >
          <el-input v-model="resource.name" name="name" type="text" />
        </el-form-item>

        <el-form-item :label="$t('commons.externalId')" prop="external_id">
          <el-input
            v-model="resource.external_id"
            name="external_id"
            type="text"
          />
        </el-form-item>

        <el-form-item :label="$t('commons.mobilityId')" prop="mobility_id">
          <el-input
            v-model="resource.mobility_id"
            name="mobility_id"
            type="text"
          />
        </el-form-item>
      </el-col>

      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 14, offset: 0 }"
        :md="{ span: 14, offset: 0 }"
        :lg="{ span: 12, offset: 1 }"
      >
        <el-form-item
          :label="$t('components.shift.assignPlanning')"
          prop="shifts"
        >
          <shift-type
            :editable="true"
            :planning="resource.shifts"
            @change="updatePlanning"
          >
          </shift-type>
        </el-form-item>
      </el-col>
    </el-form>

    <el-dialog
      :title="$t('components.shift.loadPlanning') | upperFirst"
      :visible.sync="visible"
      size="tiny"
    >
      <el-table :data="plannings" stripe style="width: 100%">
        <el-table-column prop="name" :label="$t('commons.name') | upperFirst">
        </el-table-column>

        <el-table-column :label="$t('commons.planning') | upperFirst">
          <template slot-scope="scope">
            <shift-popover :shifts="scope.row.shifts" />
          </template>
        </el-table-column>

        <el-table-column>
          <template slot-scope="scope">
            <el-button type="success" @click="setShift(scope.row.shifts)">{{
              $t('commons.select') | upperFirst
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="visible = false">{{
          $t('commons.close') | upperFirst
        }}</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import CreatePlace from '@/components/CreatePlace';
import ShiftType from './../../../components/shift-type/ShiftType';
import ShiftPopover from './../../../components/shift-type/ShiftPopover';

export default {
  name: 'ResourceForm',
  components: {
    CreatePlace,
    ShiftType,
    ShiftPopover,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
    },
    saving: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    loading: true,
    choiceShift: [],
    labelPosition: 'top',
    visible: false,
    geocodeOpen: false,
    targetCreate: 'start',
    differentEnd: false,
  }),
  computed: {
    ...mapGetters(['users', 'vehicles', 'organization', 'places']),
    nameRuleRequired() {
      return {
        required:
          _.isEmpty(this.resource.user) && _.isEmpty(this.resource.vehicle),
        message: this.$t('commons.validateField', { field: this.$t() }),
        trigger: 'blur',
      };
    },
    plannings() {
      const plannings = this.organization.shifts;
      return plannings;
    },
  },
  created() {
    this.fetchData();
    this.differentEnd = this.resource && this.resource.end_place;
  },
  methods: {
    ...mapActions(['get']),
    setShift(o) {
      this.resource.shifts = _.values(o);
      if (this.visible) {
        this.visible = !this.visible;
      }
      return this.resource.shifts;
    },
    updatePlanning(v) {
      this.resource.shifts = v;
    },
    fetchData() {
      Promise.all([
        this.get({ type: 'user', id: 'all' }),
        this.get({ type: 'vehicle', id: 'all' }),
        this.get({ type: 'place', id: 'all' }),
      ]).then(() => {
        this.loading = false;
      });
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => (valid ? resolve() : reject()));
      });
    },
    createPlace(target) {
      this.targetCreate = target;
      this.geocodeOpen = true;
    },
    closeCreate(newPlace) {
      this.geocodeOpen = false;
      if (newPlace) {
        if (this.targetCreate === 'start') this.resource.start_place = newPlace;
        if (this.targetCreate === 'end') this.resource.end_place = newPlace;
      }
    },
    setEndPlace(value) {
      if (!value) this.resource.end_place = null;
    },
  },
};
</script>

<style lang="scss">
.select-place {
  .place-name {
    float: left;
  }
  .place-type {
    float: right;
    color: #8492a6;
    font-size: 13px;
  }
}
.different {
  span {
    margin: 10px;
  }
}
</style>
