<template>
  <base-layout>
    <h3 slot="title">{{ $t('pages.place.create') }}</h3>
    <place-form
      ref="form"
      v-model="isValid"
      :place="place"
      :saving="saving"
    ></place-form>

    <cs-button
      slot="footer"
      icon-type="material"
      icon="chevron_left"
      @click="$router.push({ name: 'indexPlace' })"
    >
      {{ $t('commons.cancel') }}
    </cs-button>
    <cs-button
      slot="footer"
      type="primary"
      icon-type="material"
      icon="save"
      :loading="saving"
      :disabled="!isValid"
      @click="savePlace"
    >
      {{ $t('commons.save') }}
    </cs-button>
  </base-layout>
</template>
<script>
import { mapActions } from 'vuex';
import PlaceForm from './forms/PlaceForm';

export default {
  name: 'CreatePlace',
  components: { PlaceForm },
  data: () => ({
    place: {},
    saving: false,
    isValid: true,
  }),
  methods: {
    ...mapActions(['post']),
    savePlace() {
      this.saving = true;
      this.post({ type: 'place', data: this.place })
        .then(() => {
          this.saving = false;
          this.$router.push({ name: 'indexPlace' });
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
