var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticClass:"activityPrint-date",staticStyle:{"text-align":"left"},attrs:{"span":12}},[_c('p',[_vm._v(" "+_vm._s(_vm._f("format")(_vm.route.planned_start,'dd.MM.yyyy'))+" "),_c('br'),_c('object-display',{attrs:{"data":_vm.organization,"only":['siret']}})],1)]),_c('el-col',{attrs:{"span":9,"offset":3}},[_c('div',{staticClass:"activityPrint-logo"},[_c('img',{attrs:{"src":_vm.organization.image}})])])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{staticStyle:{"text-align":"left","margin-top":"30px"},attrs:{"span":24}},[_c('h3',{staticClass:"white-title upperFirst activityPrint-title"},[_vm._v(" "+_vm._s(_vm.$t('commons.route.single'))+" "),_c('span',{staticClass:"bullet-point"},[_vm._v(" . ")])]),(_vm.route)?_c('h3',{staticClass:"white-title activityPrint-routeName"},[_vm._v(" "+_vm._s(_vm.route.name)+" ")]):_vm._e(),_c('br')])],1),(_vm.route.resource)?_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"activityPrint-category-h4"},[_vm._v(" "+_vm._s(_vm.$t('commons.activity.plural'))+" ")]),_c('div',{staticClass:"print-round-orders"},[(_vm.deliveries)?_c('span',[_vm._v(" "+_vm._s(_vm.deliveries.length)+" ")]):_vm._e()]),_c('br')]),_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"activityPrint-category-h4"},[_vm._v(_vm._s(_vm.$t('commons.driver')))]),_c('object-display',{attrs:{"data":_vm.route.resource.user,"only":{
          first_name: 'commons.first_name',
          last_name: 'commons.last_name',
        }}}),_c('br')],1),_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"activityPrint-category-h4"},[_vm._v(" "+_vm._s(_vm.$t('commons.vehicle.single'))+" ")]),_c('object-display',{attrs:{"data":_vm.route.resource.vehicle,"only":['name']}})],1),_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"activityPrint-category-h4"},[_vm._v(" "+_vm._s(_vm.$t('commons.item.plural'))+" ")]),_c('object-display',{attrs:{"data":{
          total: _vm.deliveriesTotal,
          weight: ((_vm.total('weight')) + " kg"),
          volume: ((_vm.total('volume')) + " m3"),
        }}})],1)],1):_vm._e(),_c('div',{staticClass:"page-break"}),(_vm.deliveries)?_c('span',_vm._l((_vm.deliveries),function(activity){return _c('activity-print',{key:activity.id,attrs:{"route-print":true,"activity":activity,"deliveries":_vm.deliveries.length}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }